export type BuildingCountByStatus = {
  statusDescription: string;
  count: number;
};

export const buildingStatus = {
  effectiveDate: '2020-12-16T18:00:00',
  status: [
    {
      buildingNumber: 101,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 102,
      statusCode: 'Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 103,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 104,
      statusCode: 'Modified Economy Cycle',
    },
    {
      buildingNumber: 105,
      statusCode: 'Modified Economy Cycle',
    },
    {
      buildingNumber: 106,
      statusCode: 'Modified Economy Cycle',
    },
    {
      buildingNumber: 107,
      statusCode: 'Car Park Ventilation',
    },
    {
      buildingNumber: 108,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 109,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 110,
      statusCode: 'Full outside air',
    },
    {
      buildingNumber: 111,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 112,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 113,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 114,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 115,
      statusCode: 'Natural Ventilation',
    },
    {
      buildingNumber: 116,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 117,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 118,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 119,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 120,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 121,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 122,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 123,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 124,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 125,
      statusCode: 'Modified Economy Cycle',
    },
    {
      buildingNumber: 126,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 127,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 128,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 129,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 130,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 131,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 132,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 133,
      statusCode: 'Modified Economy Cycle',
    },
    {
      buildingNumber: 134,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 135,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 136,
      statusCode: 'Construction',
    },
    {
      buildingNumber: 137,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 138,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 139,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 140,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 141,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 142,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 143,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 144,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 145,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 146,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 147,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 148,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 149,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 150,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 151,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 152,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 153,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 154,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 155,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 156,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 157,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 158,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 159,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 160,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 161,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 162,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 163,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 164,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 165,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 166,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 167,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 168,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 169,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 170,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 171,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 172,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 173,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 174,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 175,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 176,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 177,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 178,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 179,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 180,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 181,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 182,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 183,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 184,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 185,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 186,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 187,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 188,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 189,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 190,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 191,
      statusCode: 'Natural Ventilation with fixed ventilation',
    },
    {
      buildingNumber: 192,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 193,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 194,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 195,
      statusCode: 'Natural Ventilation',
    },
    {
      buildingNumber: 196,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 197,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 198,
      statusCode: 'Natural Ventilation with fixed ventilation',
    },
    {
      buildingNumber: 199,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 200,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 201,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 202,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 203,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 204,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 205,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 206,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 207,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 208,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 209,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 210,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 211,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 212,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 213,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 214,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 215,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 216,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 217,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 218,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 219,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 220,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 221,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 222,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 223,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 224,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 225,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 226,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 227,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 228,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 229,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 230,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 231,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 232,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 233,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 234,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 235,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 236,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 237,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 238,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 239,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 240,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 241,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 242,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 243,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 244,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 245,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 246,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 247,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 248,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 249,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 250,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 251,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 252,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 253,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 254,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 255,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 256,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 257,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 258,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 259,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 260,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 261,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 262,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 263,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 264,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 265,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 266,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 267,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 268,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 269,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 270,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 271,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 272,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 273,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 274,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 275,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 276,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 277,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 278,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 279,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 280,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 281,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 282,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 283,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 284,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 285,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 286,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 287,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 288,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 289,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 290,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 291,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 292,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 293,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 294,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 295,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 296,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 297,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 298,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 299,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 300,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 301,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 302,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 303,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 304,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 305,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 306,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 307,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 308,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 309,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 310,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 311,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 312,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 313,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 314,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 315,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 316,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 317,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 318,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 319,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 320,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 321,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 322,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 323,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 324,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 325,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 326,
      statusCode: 'Natural Ventilation',
    },
    {
      buildingNumber: 327,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 328,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 329,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 330,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 331,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 332,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 333,
      statusCode: 'Car Park Ventilation',
    },
    {
      buildingNumber: 334,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 335,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 336,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 337,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 338,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 339,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 340,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 341,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 342,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 343,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 344,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 345,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 346,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 347,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 348,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 349,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 350,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 351,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 352,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 353,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 354,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 355,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 356,
      statusCode: 'Natural Ventilation',
    },
    {
      buildingNumber: 357,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 358,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 359,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 360,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 361,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 362,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 363,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 364,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 365,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 366,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 367,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 368,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 369,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 370,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 371,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 372,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 373,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 374,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 375,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 376,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 377,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 378,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 379,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
    {
      buildingNumber: 380,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 381,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 382,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 383,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 384,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 385,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 386,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 387,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 388,
      statusCode: 'Natural Ventilation',
    },
    {
      buildingNumber: 389,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 390,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 391,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 392,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 393,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 394,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 395,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 396,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 397,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 398,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 399,
      statusCode: 'Data Not Available',
    },
    {
      buildingNumber: 400,
      statusCode: 'Fixed Ventilation',
    },
    {
      buildingNumber: 401,
      statusCode: 'Mix Full outside air and some fixed ventilation',
    },
  ],
  /*  [
     {
       buildingNumber: 101,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '20/08/2020',
       effectiveEndDate: '',
       notes: '9/7 Closed, reopening 6 weeks later (est. 20/8)',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 102,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities, CSL Labs and experiments',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 103,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '20/08/2020',
       effectiveEndDate: '',
       notes: '9/7 Closed, reopening 6 weeks later (est. 20/8)',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 104,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 105,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Melbourne Institute for Economic and Social Research / Semester 2 teaching',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 106,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '23/11/2020',
       effectiveEndDate: '',
       notes:
         'OPENING POSTPONED - Was approved for 13th July but is continuing with 2 staff occupying level 2 ONLY for at least the next 6 weeks, after which there will be an increase in staff/GRDs.  Currently expected to be spread between Levels 2, 3, 6, 7, 8 and 9 of the building.',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 107,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Car parking',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 110,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Semester 2 Teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 112,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 113,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 115,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '22/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Full outside air',
     },
     {
       buildingNumber: 116,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 117,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 120,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 121,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 122,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Mosquito Lab, Critical research (Staff and GR students)',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 123,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/06/2020',
       effectiveEndDate: '',
       notes: 'Critical research (Staff and GR students)',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 125,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'As per PRG Building By Cycle document 18/5/20 / Semester 2 Teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 128,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 130,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '1/06/2020',
       effectiveEndDate: '',
       notes: 'Campus Services staff remote managing approved access from home.',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 133,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '6/07/2020',
       effectiveEndDate: '',
       notes:
         'Semester 2 Teaching - Including Makerspaces and studios.  All levels have planned occupancy except for B2',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 134,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes:
         'VaMP: Includes  G10 production office, edit suites, Studio 3, Elisabeth Murdoch Lecture theatre G06/G04',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 136,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'N/A',
     },
     {
       buildingNumber: 139,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 140,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 141,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Semester 2 Teaching',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 142,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 143,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'As per PRG Building By Cycle document 18/5/20 / Research nursery',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 144,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities, MDHS: Semester 2 teaching',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 145,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research nursery',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 146,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research nursery',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 147,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities, Critical research (Staff and GR students)',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 149,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 150,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 151,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Full outside air',
     },
     {
       buildingNumber: 152,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'VCAG',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 153,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'As per PRG Building By Cycle document 18/5/20, SCI: Semester 2 teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 154,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'As per PRG Building By Cycle document 18/5/20, SCI: Semester 2 teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 155,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 156,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes:
         '[Claosed as not being used during stage 4 restrictions] VaMP: Includes Studios 1 & 2 and Virtual Reality Lab',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 158,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 159,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 160,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '22/06/2020',
       effectiveEndDate: '',
       notes: 'All floors to be used',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 161,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 162,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'N/A',
     },
     {
       buildingNumber: 163,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 165,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Possibly some long term experiments',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 166,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '12/06/2020',
       effectiveEndDate: '',
       notes: 'Research / Engineering classes from Semester 2',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 167,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '10/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 168,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '12/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 169,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'N/A',
     },
     {
       buildingNumber: 170,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'As per PRG Building By Cycle document 18/5/20',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 171,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 173,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Teaching restarts 1/07, making use of EDS2 and 5',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 174,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '8/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 175,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 176,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Semester 2 Teaching',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 177,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '22/06/2020',
       effectiveEndDate: '',
       notes: 'Ground Floor open to student study',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 181,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 182,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 183,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities, MDHS: Semester 2 teaching',
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 184,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/06/2020',
       effectiveEndDate: '',
       notes: 'Critical research (Staff and GR students)',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 186,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 0,
     },
     {
       buildingNumber: 187,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 188,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 189,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'N/A',
     },
     {
       buildingNumber: 191,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 192,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Critical research (Staff and GR students)',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 193,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 194,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'N/A',
     },
     {
       buildingNumber: 195,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 198,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'N/A',
     },
     {
       buildingNumber: 199,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 200,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes:
         'Critical research - Activities are a mixture of lab-based work, office work and field work',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 201,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '22/06/2020',
       effectiveEndDate: '',
       notes: 'Grimwade: Phase 2 - 22/06 Winter Semester teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 202,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 203,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Security Control Room',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 204,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 206,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 207,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes:
         'Planned for reopening Ground Floor (labs) and Level 1 (offices) date potentially 15th June',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 0,
     },
     {
       buildingNumber: 213,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 214,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 215,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 216,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 218,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 220,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 221,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 222,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 224,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 225,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 232,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 234,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 237,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 242,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 243,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 244,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 245,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 246,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes:
         'Clinic Operational. Audiology: 1/5 limited, 22/06 near full operation emergency cases only, July 5 students in clinic.  Speech pathology: 15/06 limited service, 29/06 students in clinic. Both: 03/08 Semester 2 teaching',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 248,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities and Micro Diagnostic Unit',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 249,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 250,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Graduate Research Students',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 251,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 252,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 253,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 254,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '18/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 255,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 256,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 257,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 258,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Teaching',
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 260,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'As per PRG Building By Cycle document 18/5/20 / MDHS Winter Teaching',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 261,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '16/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
       __5: '23 essential; 20 more in wave 1 (2nd June)',
     },
     {
       buildingNumber: 262,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Childcare',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 263,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes:
         'MGSE: Semester 2 - Level 4 and Studio 5 spaces, MSPACE:  various teaching activities (August/September/November)',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 266,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Data Centre and nominated COO-P building',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 267,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 269,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 270,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 271,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 274,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 275,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 278,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '17/08/2020',
       effectiveEndDate: '',
       notes: 'MSPACE - various teaching activities (August/September/November)',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 279,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 281,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 0,
     },
     {
       buildingNumber: 283,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 284,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 290,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 291,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 292,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 295,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 296,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 298,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 300,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 301,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 302,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 308,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Childcare',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 310,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 326,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 328,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 333,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 335,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Childcare',
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 336,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 337,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 344,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 348,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Dental clinic / MDHS Winter Teaching',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 352,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 353,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 354,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '13/07/2020',
       effectiveEndDate: '',
       notes: 'MSPACE - various teaching activities',
       cleaningStatus: 'Not provided by UoM',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 356,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 357,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 358,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 359,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 363,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 368,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No data',
       hvacStatus: 0,
     },
     {
       buildingNumber: 378,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 379,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes:
         'Research critical dependency, GR students, Staff with home environments not conducive to work, Local fieldwork,  Levels 1-2 approved for Geography and 3-5 for Pop Health',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 381,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: 384,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 385,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Health Services & MDHS',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 386,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 387,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 388,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 390,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 393,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 394,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 395,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '10/06/2020',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 396,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 397,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 398,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 400,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 401,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'CSL Labs',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 402,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 0,
     },
     {
       buildingNumber: 403,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 0,
     },
     {
       buildingNumber: 404,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Tenants on levels 1 & 2',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 405,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 0,
     },
     {
       buildingNumber: 410,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 411,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 413,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 414,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 415,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 416,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '20/07/2020',
       effectiveEndDate: '',
       notes:
         'Pathology lab / Semester 2 for DVM 3 students commencing two weeks earlier than the standard main campus',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 417,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 418,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Semester 2 Teaching',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 419,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 420,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 421,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 422,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 423,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 425,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 426,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 427,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 428,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 429,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 430,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 431,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 432,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 433,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 434,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 436,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 437,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 438,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 439,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Live animal care (incl. quarantine)',
       cleaningStatus: 'Full clean reduced hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 440,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 442,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 450,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 451,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 452,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 453,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 463,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 464,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 465,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 466,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 467,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 468,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 469,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 470,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 471,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 472,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 473,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 474,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 476,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 477,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 478,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 0,
     },
     {
       buildingNumber: 479,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 480,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 497,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 498,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'Standard',
     },
     {
       buildingNumber: 501,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 502,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 503,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 504,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 505,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 506,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 507,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 508,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 509,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 510,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 511,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 512,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 513,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 514,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 515,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 516,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 517,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 519,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 520,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 521,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 522,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 523,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 524,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 525,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 526,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 527,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 528,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 529,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 530,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 531,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 534,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 535,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 536,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 537,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 539,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 540,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 541,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 542,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 543,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 544,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 545,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 546,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 547,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 548,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 549,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 550,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 551,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 552,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 553,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 554,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 555,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 556,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 557,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 558,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 559,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 560,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 561,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 562,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 563,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 564,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 565,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Research facilities',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 566,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 567,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 568,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 569,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 570,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 571,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 572,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 573,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 574,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 575,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 576,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 577,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 578,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 580,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 581,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 582,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'research nursery',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 583,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'research nursery',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 584,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 585,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 586,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 587,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 588,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 589,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 590,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 591,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 601,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Research facilities, MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 602,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 603,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 604,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 605,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 606,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 607,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 608,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 609,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 610,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 611,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 612,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 613,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 614,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 615,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'MDHS: Semester 2 Teaching',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 616,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 617,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 618,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 619,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 620,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 621,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 625,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 627,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 628,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 629,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 630,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 631,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 632,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 633,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '15/06/2020',
       effectiveEndDate: '',
       notes: 'MDHS Winter Semester',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 634,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 635,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 636,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 637,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 638,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 639,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 640,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 641,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 642,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 655,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 656,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 657,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 658,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 659,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 660,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 661,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 662,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 663,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 664,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 665,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 666,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 667,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 668,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 669,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 670,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 671,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 672,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 673,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 674,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 675,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 678,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'MDHS: Semester 2 Teaching',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 679,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 680,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 681,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 682,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 683,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 684,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 686,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 687,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 688,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 689,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 690,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 691,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 692,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 693,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 694,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 695,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Medical Clinic',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 696,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 697,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 698,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 699,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 700,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 701,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 702,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/06/2020',
       effectiveEndDate: '',
       notes: 'Critical research (Staff and GR Students)',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 703,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 704,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 705,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 706,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 707,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 708,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 709,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 710,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 711,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 712,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 713,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 714,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 715,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 716,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 717,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'research nursery (PC2)',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 718,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 719,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 720,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 721,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 722,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'serving residential accomodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 723,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 724,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 725,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 726,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 729,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 730,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 731,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 732,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 733,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 734,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 735,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 736,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 737,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 738,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 739,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 740,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 741,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 744,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 745,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 746,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 747,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 748,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 749,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 750,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 752,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 754,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 755,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 756,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 804,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 805,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 806,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 807,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 808,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 809,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 810,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 811,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 812,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 813,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 814,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 815,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 816,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 817,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 818,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 820,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/06/2020',
       effectiveEndDate: '',
       notes: 'Partially open',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 821,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 822,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 854,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '1/01/2020',
       effectiveEndDate: '',
       notes: 'Childcare',
       cleaningStatus: 'Full clean full hours',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 860,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '7/09/2020',
       effectiveEndDate: '',
       notes: 'Semester 2 Teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 861,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '6/07/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 1 plan - Workshops / Labs / Student stores',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 862,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 2 plan - Student stores / Project spaces',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 863,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '8/06/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 1 plan - Workshops / Labs',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 864,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '20/07/2020',
       effectiveEndDate: '',
       notes: 'FFAM: Sem1 Assessment / Workshops / Semester 2 Teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 865,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '27/06/2020',
       effectiveEndDate: '',
       notes: 'Winter Intensive - 27 June – 9 July and July Intensives 10 July – 21 July',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 866,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 867,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 868,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 869,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 871,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '31/07/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 2 plan',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 872,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '13/07/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 2 plan',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 873,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 2 plan - Workshops / Labs / Studios',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 874,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 2 plan - Workshops / Labs / Student stores',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 875,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '4/07/2020',
       effectiveEndDate: '',
       notes: 'FFAM: Winter Intensives / Semester 2 Teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 876,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Semester 2 Teaching',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 877,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 878,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 879,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '17/07/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 2 plan',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 880,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '8/06/2020',
       effectiveEndDate: '',
       notes: 'Faculty phase 1 plan',
       cleaningStatus: 'Usage based clean',
       hvacStatus: 'No data',
       __5: '19 essential; 30 more in wave 1',
     },
     {
       buildingNumber: 881,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 882,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '3/08/2020',
       effectiveEndDate: '',
       notes: 'Semester 2 Teaching',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 887,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 893,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 894,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 895,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 896,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 897,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 898,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 899,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 900,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 901,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 902,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 903,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 904,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 905,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 906,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 907,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 908,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 909,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 910,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 911,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 912,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 913,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 914,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 0,
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 915,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 916,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No cleaning',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 917,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 918,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 919,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 920,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 921,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 922,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 923,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 924,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 925,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 926,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 927,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 928,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 929,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 930,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 931,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 932,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 933,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 934,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 935,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 936,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 937,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 938,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 939,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 940,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 941,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 942,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 943,
       statusDescription: 'Pending Approval',
       statusCode: 'PENDING_APPROVAL',
       expectedStartDate: '2/06/2020',
       effectiveEndDate: '',
       notes: 'SCI: SEFS Research',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 944,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 945,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 946,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 947,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 948,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 949,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 950,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 951,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 952,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 953,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 954,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 955,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 956,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 981,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 982,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 983,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 984,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 985,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 986,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 987,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 988,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 989,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 991,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 992,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 993,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 994,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 995,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 996,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 997,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 998,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 999,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 1010,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5000,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5001,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5002,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5003,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5004,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5005,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5006,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5007,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5008,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5009,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5010,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5011,
       statusDescription: 'Operational',
       statusCode: 'OPERATIONAL',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5012,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5013,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5014,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5019,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5020,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5029,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5030,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5031,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5032,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5033,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5034,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5035,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5036,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5037,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5038,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5040,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5041,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5045,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5046,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5047,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5050,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5051,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5055,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: 'Residential accommodation',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 5591,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6191,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6192,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6851,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6852,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6853,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6854,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6855,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6856,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6857,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6858,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6961,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6962,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6963,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6964,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6965,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 6966,
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: '148A',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '10/07/2020',
       effectiveEndDate: '',
       notes:
         'Arts: Winter Teaching - French 1 - 10-Jul, 17-Jul, 24-Jul, Semester 2 Teaching',
       cleaningStatus: 'No data',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: '148B',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '10/07/2020',
       effectiveEndDate: '',
       notes:
         'Arts: Winter Teaching - French 1 - 10-Jul, 17-Jul, 24-Jul, Semester 2 Teaching',
       cleaningStatus: 'No data',
       hvacStatus: 'Mixed',
     },
     {
       buildingNumber: '416A',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: '417A',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: '542A',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: '544A',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: '544B',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
     {
       buildingNumber: 'EX001',
       statusDescription: 'Closed',
       statusCode: 'CLOSED',
       expectedStartDate: '',
       effectiveEndDate: '',
       notes: '',
       cleaningStatus: 'No data',
       hvacStatus: 'No data',
     },
   ], */
};
