import {
  pathOr,
  filter,
  reduce,
  Reduced,
  isNil,
  map,
  keys,
  path,
  descend,
  prop,
  sort,
  forEach,
  values,
  toUpper,
} from 'ramda';
import { Feature } from 'geojson';

import Logger from 'utils/logger';
import { FloorSpace } from 'models/Floor';
import { Room } from 'models/Building';
import MazemapDataService from './MazemapDataService';
import Status from '../../models/Status';
import { spaceComfortAlarmPopup } from '../HtmlService';
import { MapType, LongLat } from 'components/Mazemap/MapTypes';
import MazemapHighlightService from 'services/Map/MazemapHighlightService';
import { MazeMapCampusDetails, MAZEMAP_CAMPUS_TAG } from 'components/constants';
import { Summary, AlarmDetail } from 'models/Summary';
import { ObservationClass, Observation, ObservationType, SpaceUtilisationHeatMapModel } from 'models/Observation';
import { createMazemapMap, createMazemapHighlighter, createMazemapNavigationControl } from './MazemapFactory';
import { filterBuildingHeadCountsForHeatMap } from 'utils/filters';
import { SpaceUtilisationHeatMapTypes } from 'types';
import { buildingStatus } from '../../buildingStatus';

const MarkerOffset = 0.00005;
const AlarmPinOffset: LongLat = { lng: MarkerOffset, lat: 0 };

let mazemap: MapType; // Singleton
let markerList: any = [];
let roomMarkerList: any = [];
let highlightService: MazemapHighlightService;
let dataService: MazemapDataService;

declare let window: any;
declare let Mazemap: any;

Mazemap.Config.setApiBaseUrl('https://api.mazemap.com');
Mazemap.Config.setMMTileBaseUrl('https://tiles.mazemap.com');

export interface MapServiceType {
  getBuildingStatus(building: Summary): number;
  getMarkerColor(status: any): string;
  addMarkerForBuilding(building: Summary): void;
  addMarkerForRoom(locationCode: string, campusId: string): void;
  highlightLibraryRooms(campusId: string, rooms: Room[], removeHighlight?: boolean): void;
  highlightLibraryRoomByCode(campusId: string, locationCode: string, removeHighlight?: boolean): void;
  checkSmartMeterLocationsAndAddMarkers(spaceDetails: FloorSpace, campusId: string): void;
  addBuildingStatusPins(buildingSummary: Summary[]): void;
  addSpaceUtilisationLayerHeatMap(campusId: string, heatMapType: string, buildingSummary: Summary[]): void;
  addBuildingStatusLayer(spaceDetails: string): Promise<void>;
  clearSpaceUtilisationLayerHeatMap(): void;
  clearBuildingStatusLayer(): void;
  clearMapMarkers(): void;
  clearRoomMapMarkers(): void;
  getHighlightService(): MazemapHighlightService;
  getMap(): MapType;
  getDataService(): MazemapDataService;
}

const createMapElement = (): HTMLElement => {
  const mapElement = window.document.createElement('div');
  mapElement.style.height = '100%';
  mapElement.style.width = '100%';
  mapElement.style.display = 'none';

  return mapElement;
};

const createMarkerAtLngLat = (lngLat: LongLat, color: string, markerText: number | string): any => {
  return new window.Mazemap.MazeMarker({
    color,
    size: 32,
    shape: 'marker',
    noClick: false,
    innerCircle: true,
    innerCircleColor: '#FEFEFE',
    innerCircleScale: 0.75,
    glyph: `${markerText}`,
    glyphColor: '#000000',
    glyphSize: 12,
  })
    .setLngLat(lngLat)
    .addTo(mazemap);
};

interface AlarmsSummary {
  spaceComfortAlarms: AlarmDetail[];
}

const getAllAlarmsFromSummary = (summary: Summary): AlarmsSummary => {
  const alarms: AlarmDetail[] = pathOr([], ['alarms', 'abnormal'], summary);
  const observationClassFilter: string[] = [ObservationClass.spaceComfort];

  const spaceComfortAlarms = filter((alarms: AlarmDetail): boolean => {
    return observationClassFilter.includes(alarms.observationClass);
  }, alarms);

  return {
    spaceComfortAlarms,
  };
};

const getBuildingStatus = (building: Summary): number => {
  const { spaceComfortAlarms } = getAllAlarmsFromSummary(building);

  if (spaceComfortAlarms.length === 0) {
    return Status.Unknown;
  }

  const alarmCount = reduce(
    (acc: number, alarm: AlarmDetail): number | Reduced<number> => {
      if (alarm.observationClass === ObservationClass.spaceComfort) {
        return acc + alarm.value;
      }
      return acc;
    },
    0,
    spaceComfortAlarms,
  );

  return alarmCount > 0 ? Status.Alarm : Status.Normal;
};

const getMarkerColor = (status: any): string => {
  const colorMapping: any = {};
  colorMapping[Status.Normal] = 'MazeGreen';
  colorMapping[Status.Alarm] = 'MazeRed';
  colorMapping[Status.Unknown] = 'MazeYellow';

  return colorMapping[status];
};

const addPopupForMarker = (marker: any, building: Summary): any => {
  const { spaceComfortAlarms } = getAllAlarmsFromSummary(building);
  // TODO: extract helper method
  const buildingNumber = building.location.locationCode.split(';')[1];

  // spaceComfortAlarmPopup will generate html from a React component
  const popupHtml = spaceComfortAlarmPopup(buildingNumber, spaceComfortAlarms);

  const popup = new window.Mazemap.Popup({ closeOnClick: true, offset: [0, -32] })
    .setLngLat(marker.getLngLat())
    .setHTML(popupHtml);
  marker.setPopup(popup);
};

const offsetLngLat = (lngLat: LongLat, offset: LongLat): LongLat => ({
  lng: lngLat.lng + offset.lng,
  lat: lngLat.lat + offset.lat,
});

const checkSmartMeterLocationsAndAddMarkers = (spaceDetails: FloorSpace, campusId: string): void => {
  clearRoomMapMarkers();
  if (spaceDetails.smartMeterLocations && spaceDetails.smartMeterLocations.length > 0) {
    spaceDetails.smartMeterLocations.forEach((location) => {
      mapService.addMarkerForRoom(location, campusId);
    });
  }
};

const highlightLibraryRooms = (campusId: string, rooms: Room[], removeHighlight?: boolean): void => {
  rooms.forEach((room) => highlightLibraryRoomByCode(campusId, room.locationCode, removeHighlight));
};

const highlightLibraryRoomByCode = async (
  campusId: string,
  roomLocationCode: string,
  removeHighlight?: boolean,
): Promise<void> => {
  const poiByLocationCode = await dataService.getRoomPoiByLocationCode(roomLocationCode, campusId);

  highlightService.highlightLibraryRoom(poiByLocationCode, roomLocationCode, removeHighlight);
};

const addMarkerForRoom = async (roomLocationCode: string, campusId: string): Promise<void> => {
  const poiByLocationCode = await dataService.getRoomPoiByLocationCode(roomLocationCode, campusId);

  Logger.info(
    `markSmartMeterLocations Room ${roomLocationCode} coords=${JSON.stringify(
      path(['properties', 'point', 'coordinates'], poiByLocationCode),
      null,
      2,
    )}`,
  );

  const lngLatNum: number[] | null = pathOr(null, ['properties', 'point', 'coordinates'], poiByLocationCode);

  if (lngLatNum) {
    const lngLat: LongLat = { lng: lngLatNum[0], lat: lngLatNum[1] };

    const marker = createMarkerAtLngLat(lngLat, '#ff0000', `SM`);
    roomMarkerList.push(marker);
  }
};

const addMarkerForBuilding = (building: Summary): void => {
  const buildingNumber = building.location.locationCode.split(';')[1];
  if (buildingNumber) {
    const lngLat = dataService.getBuildingLngLat(buildingNumber);
    if (isNil(lngLat)) {
      Logger.debug(
        'Could not find long lat for building. This usually occurs as Mazemap does not know about the building:',
        buildingNumber,
      );
    } else {
      const sumAbnormalAlarmValues = (abnormalAlarmsDetails: AlarmDetail[]): number =>
        reduce<AlarmDetail, number>(
          (accumulator: number, abnormalAlarmDetails: AlarmDetail): number => accumulator + abnormalAlarmDetails.value,
          0,
          abnormalAlarmsDetails,
        );

      const marker = createMarkerAtLngLat(
        offsetLngLat(lngLat, AlarmPinOffset),
        '#ff0000',
        sumAbnormalAlarmValues(building.alarms.abnormal),
      );
      addPopupForMarker(marker, building);
      markerList.push(marker);
    }
  }
};

const addBuildingStatusPins = (buildingsStatus: Summary[]): void => {
  buildingsStatus.forEach((building: Summary): void => addMarkerForBuilding(building));
};

/**
 * Utility method to clear the space utilisation
 * heat map layer from mazemap.
 *
 * @returns void
 */
const clearSpaceUtilisationLayerHeatMap = (): void => {
  highlightService.removeSpaceUtilisationHeatMapLayer();
};

const clearBuildingStatusLayer = (): void => {
  highlightService.removeBuildingStatusMapLayer();
};

const clearMapMarkers = (): void => {
  markerList.forEach((marker: any): void => {
    marker.remove();
  });
  markerList = [];
};

const clearRoomMapMarkers = (): void => {
  roomMarkerList.forEach((marker: any): void => {
    marker.remove();
  });
  roomMarkerList = [];
};

/**
 * Utility method for the campus space utilisation
 * heat map. Includes all the building level space utilisation
 *
 * @param  Summary[] buildingSummary
 * @returns SpaceUtilisationHeatMapModel
 */
export const getSpaceUtilisationDetailsForHeatmap = (buildingSummary: Summary[]): SpaceUtilisationHeatMapModel[] => {
  const buildingSpaceUtilisationDetails: SpaceUtilisationHeatMapModel[] = [];
  forEach((building: Summary) => {
    const spaceUtilObservations: Observation[] = building.observations.filter(
      (observation: Observation): boolean =>
        observation.observationType === ObservationType.spaceUtilisation &&
        observation.observationTag.startsWith('HeadCount#'),
    );
    spaceUtilObservations.forEach((obs) => {
      buildingSpaceUtilisationDetails.push({
        locationCode: obs.locationCode,
        headCount: +obs.value,
        observationEndTime: obs.observationEndTime,
        observationTag: obs.observationTag,
      });
    });
  }, buildingSummary);
  return buildingSpaceUtilisationDetails;
};

/**
 * @param  {string} campusId
 * @param  {string} heatMapType
 * @param  {SpaceUtilisationHeatMapModel[]} buildingHeadCounts
 * @returns void
 */
const addSpaceUtilisationLayerHeatMap = (campusId: string, heatMapType: string, buildingSummary: Summary[]): void => {
  const features: Feature[] = [];
  const buildingHeadCounts: SpaceUtilisationHeatMapModel[] = getSpaceUtilisationDetailsForHeatmap(buildingSummary);
  let filteredBuildingHeadCounts = filterBuildingHeadCountsForHeatMap(buildingHeadCounts, heatMapType);

  if (filteredBuildingHeadCounts.length > 0) {
    filteredBuildingHeadCounts = sort(descend(prop('headCount')), filteredBuildingHeadCounts);
    // Will break if the highest count is zero.
    filteredBuildingHeadCounts = filteredBuildingHeadCounts.map((location: SpaceUtilisationHeatMapModel) => {
      switch (heatMapType) {
        case SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1:
        case SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE1: {
          const highestHeadCount: number = filteredBuildingHeadCounts[0].headCount;
          location.heatmapRatio = location.headCount / highestHeadCount;
          break;
        }
        case SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE2:
        case SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE2: {
          location.heatmapRatio = location.headCount / location.capacity!;
          break;
        }
      }
      return location;
    });

    filteredBuildingHeadCounts.forEach((x: SpaceUtilisationHeatMapModel) => {
      const buildingNumber = x.locationCode.split(';')[1];

      let longlat: LongLat | null;
      if (dataService.getBuildingLngLat(buildingNumber) !== null) {
        longlat = dataService.getBuildingLngLat(buildingNumber);
        features.push({
          type: 'Feature',
          properties: {
            heatmapRatio: x.heatmapRatio,
          },
          geometry: {
            type: 'Point',
            coordinates: [longlat!.lng, longlat!.lat],
          },
        });
      }
    });
    highlightService.highlightHeatMap(campusId, heatMapType, features);
  }
};

export const addBuildingStatusLayer = async (campusId: string): Promise<void> => {
  Logger.debug(`spaceDetails: ${JSON.stringify(campusId)}`);

  if (campusId.length > 0) {
    const buildingsResponse = dataService.getBuildingsList();
    // Logger.debug(`response: ${JSON.stringify(buildingsResponse)}`)
    const statusColorFeatures: Feature[] = [];
    values(buildingsResponse).forEach((building: any) => {
      const buildingNum = building.uomBuildingId;
      const currentStatus = filter((status: any) => {
        return String(status.buildingNumber) === buildingNum;
      }, buildingStatus.status);
      let colorCode: number = 0;
      if (currentStatus.length > 0) {
        switch (toUpper(currentStatus[0].statusCode)) {
          case 'NATURAL VENTILATION':
            colorCode = 3;
            break;
          case 'NATURAL VENTILATION WITH FIXED VENTILATION':
            colorCode = 3;
            break;
          case 'MIX FULL OUTSIDE AIR AND SOME FIXED VENTILATION':
            colorCode = 2;
            break;
          case 'FULL OUTSIDE AIR':
            colorCode = 2;
            break;
          case 'FULL OUTSIDE AIR AND SOME FIXED VENTILATION':
            colorCode = 2;
            break;
          case 'CAR PARK VENTILATION':
            colorCode = 1;
            break;
          case 'FIXED VENTILATION':
            colorCode = 4;
            break;
          case 'MODIFIED ECONOMY CYCLE':
            colorCode = 5;
            break;
          default:
            colorCode = 0;
            break;
        }
      }

      statusColorFeatures.push({
        type: 'Feature',
        properties: {
          colorCode,
          buildingNumber: buildingNum,
        },
        geometry: building.geometry,
      });
    });
    highlightService.highlightBuildingStatus(statusColorFeatures, campusId);
  }
};

const getMap = (): MapType => mazemap;
const getHighlightService = (): MazemapHighlightService => highlightService;
const getDataService = (): MazemapDataService => dataService;

export const mapService: MapServiceType = {
  getBuildingStatus,
  getMarkerColor,
  addMarkerForBuilding,
  addMarkerForRoom,
  highlightLibraryRooms,
  highlightLibraryRoomByCode,
  checkSmartMeterLocationsAndAddMarkers,
  addBuildingStatusPins,
  addSpaceUtilisationLayerHeatMap,
  addBuildingStatusLayer,
  clearSpaceUtilisationLayerHeatMap,
  clearBuildingStatusLayer,
  clearMapMarkers,
  clearRoomMapMarkers,
  getMap,
  getHighlightService,
  getDataService,
};

export const renderMap = (): Promise<MapServiceType> =>
  new Promise((resolve): void => {
    const mapElement = createMapElement();

    const defaultCampusDetails = MazeMapCampusDetails['PAR'];
    const mapOptions = {
      container: mapElement,
      campuses: MAZEMAP_CAMPUS_TAG,
      center: defaultCampusDetails.coordinates,
      zoom: defaultCampusDetails.defaultZoom,
      zLevel: 1,
      zLevelUpdater: true,
      zLevelControl: false,
    };
    mazemap = createMazemapMap(mapOptions);
    mazemap.on('load', (): void => {
      mazemap.highlighter = createMazemapHighlighter(mazemap, {
        showOutline: true,
        showFill: true,
        outlineColor: window.Mazemap.Util.Colors.MazeColors.MazeBlue,
        fillColor: window.Mazemap.Util.Colors.MazeColors.MazeBlue,
      });
      highlightService = new MazemapHighlightService(mazemap);
      mazemap.addControl(createMazemapNavigationControl({ showCompass: false }), 'bottom-left');
      resolve(mapService);
    });
    window.map = mazemap;
    dataService = new MazemapDataService(window.Mazemap);
    const buildingRequests = map(
      (campus: string | number): Promise<void> =>
        dataService.getBuildings(MazeMapCampusDetails[campus].id).catch((error: Error): void => {
          Logger.error(`Error getting buildings for ${campus}`);
          throw error;
        }),
    )(keys(MazeMapCampusDetails));
    Promise.all(buildingRequests).catch((error: any): void => {
      Logger.error(error);
    });
  });
