import { SET_MAP_SERVICE, MAP_SEARCH_RESULT } from './types';
import { AnyAction } from 'redux';
import { MapServiceType } from 'services/Map/MapService';
import { Feature } from 'geojson';

export const setMapServiceAction = (mapService: MapServiceType): AnyAction => ({
  type: SET_MAP_SERVICE,
  payload: mapService,
});

export const updateSearchResultAction = (searchResult: Feature): AnyAction => ({
  type: MAP_SEARCH_RESULT,
  payload: searchResult,
});
