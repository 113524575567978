import moment from 'moment-timezone';

export const DisplayTimeZone = 'Australia/Melbourne';

export const isToday = (dateTimeString: string): boolean =>
  moment.tz(dateTimeString, DisplayTimeZone).isSame(moment.tz(DisplayTimeZone), 'day');

export const getHalfYearlyDateRange = (inputQ: number): string[] => {
  const halfYearlyRanges = ['Jan-Jun', 'Jul-Dec'];
  const qStartDate = moment()
    .month(halfYearlyRanges[Math.ceil(Number(inputQ) / 2) - 1].split('-')[0])
    .startOf('month')
    .format('YYYY-MM-DD');
  const qEndDate = moment()
    .month(halfYearlyRanges[Math.ceil(Number(inputQ) / 2) - 1].split('-')[1])
    .endOf('month')
    .format('YYYY-MM-DD');
  return [qStartDate, qEndDate];
};

export const quarterlyRanges = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];
export const getQuarterlyDateRange = (inputQ: number): string[] => {
  const quarterStartDate = moment()
    .month(quarterlyRanges[Number(inputQ) - 1].split('-')[0])
    .startOf('month')
    .startOf('week')
    .format('YYYY-MM-DD');
  const quarterEndDate = moment()
    .month(quarterlyRanges[Number(inputQ) - 1].split('-')[1])
    .endOf('month')
    .format('YYYY-MM-DD');
  return [quarterStartDate, quarterEndDate];
};
