export const UnitOfMeasure = {
  Celsius: 'Celsius',
  Percent: 'Percent',
  Hectopascals: 'Hectopascals',
  'Kilometres per hour': 'Kilometres per hour',
  'Parts per million': 'Parts per million',
  'Watt hours': 'Watt hours',
  'Kilowatt hours': 'Kilowatt hours',
  'Volt-ampere hours': 'Volt-ampere hours',
  'Kilovolt-ampere hours': 'Kilovolt-ampere hours',
  Kilogram: 'Kilogram',
};

export type UnitOfMeasure = keyof typeof UnitOfMeasure;

interface UnitOfMeasureHash {
  [key: string]: string;
}

const UnitOfMeasureMap: UnitOfMeasureHash = {
  Celsius: '°C',
  Percent: '%',
  Hectopascals: 'hPa',
  'Kilometres per hour': 'km/h',
  'Parts per million': 'ppm',
  'Watt hours': 'Wh',
  'Kilowatt hours': 'kWh',
  'Volt-ampere hours': 'VAh',
  'Kilovolt-ampere hours': 'kVAh',
  Kilogram: 'kg',
  'N/A': '',
};

export const convertUnit = (unit: string): UnitOfMeasure => {
  return UnitOfMeasureMap[unit] as UnitOfMeasure;
};
