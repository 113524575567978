import React, { ReactElement } from 'react';
import RoomSection from '../RoomSection/RoomSection';
import BuildingSection from 'components/BuildingSection/BuildingSection';
import CampusSection from 'components/CampusSection/CampusSection';
import { FloorSection } from 'components/FloorSection/FloorSection';
import { makeStyles } from '@material-ui/styles';
import { APP_HEADER_HEIGHT } from 'styles/styleConstants';
import CampusControl from 'components/CampusSection/CampusControl';
import Navigation from 'components/Navigation/Navigation';
import { SecureRoute } from '@okta/okta-react';

export const useStyles = makeStyles({
  panelContainer: {
    flexDirection: 'row',
    width: '40vw',
    boxShadow: '-1px 0 7px rgba(0, 0, 0, 0.87)',
    flexGrow: 1,
    zIndex: 1,
    overflow: 'auto',
    maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
  },
  panelHeader: {
    padding: '24px 24px 0 24px',
  },
});

export enum ROUTES {
  root = '/',
  campus = '/campus/:campusId',
  building = '/campus/:campusId/building/:buildingNumber',
  floor = '/campus/:campusId/building/:buildingNumber/floor/:floorId',
  room = '/campus/:campusId/building/:buildingNumber/floor/:floorId/room/:roomNumber',
}

const LeftPanel = (): ReactElement => {
  const classes = useStyles();

  return (
    <div id="left-panel" className={classes.panelContainer}>
      <Navigation />
      <SecureRoute
        exact
        path={[ROUTES.room, ROUTES.floor, ROUTES.building, ROUTES.campus, ROUTES.root]}
        component={CampusControl}
      />
      <SecureRoute exact path={[ROUTES.room, ROUTES.floor, ROUTES.building, ROUTES.campus]} component={CampusSection} />
      <SecureRoute exact path={[ROUTES.room, ROUTES.floor, ROUTES.building]} component={BuildingSection} />
      <SecureRoute exact path={[ROUTES.room, ROUTES.floor]} component={FloorSection} />
      <SecureRoute exact path={[ROUTES.room]} component={RoomSection} />
    </div>
  );
};

export default LeftPanel;
