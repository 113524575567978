import { Backdrop, Button, Fade, Grid, IconButton, makeStyles, Modal, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ForecastObservation } from 'models/Observation';
import { forEach, prop, sortBy } from 'ramda';
import React, { useState } from 'react';
import moment from 'moment';

import { DisplayTimeZone, quarterlyRanges } from 'components/common/DateTime';
import { UOM_LIGHT_BLUE } from 'styles/styleConstants';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '5',
    width: '43%',
    height: '63%',
  },
  closeButton: {
    leftPadding: '',
  },
  formControl: {
    margin: '1px',
    minWidth: 120,
    backgroundColor: UOM_LIGHT_BLUE,
  },
});

NoDataToDisplay(Highcharts);

export const EnergyPopup = (props: any): React.ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const createAreaChart = (forecastObs: any, actualObs: any): Highcharts.Options => {
    const forecastRanges: any[] = [];
    const forecastAverages: any[] = [];
    let actuals: any[] = [];

    /* const startOfMonth = momentTz()
      .tz(DisplayTimeZone)
      .startOf('month')
      .format('YYYY-MM-DD') */
    const startOfQuarter = moment()
      .month(quarterlyRanges[moment.tz(DisplayTimeZone).quarter() - 1].split('-')[0])
      .startOf('month')
      .format('YYYY-MM-DD');
    forEach((observation: any) => {
      const observationTime = observation.observationStartTime.split('T')[0];
      if (observationTime >= startOfQuarter) {
        actuals.push({
          x: Date.parse(observationTime),
          y: Number(observation.value) / 1000,
        });
        actuals = sortBy(prop('x'), actuals);
        // actuals.push([Date.parse(observationTime), Number(observation.value) / 1000])
      }
    }, actualObs);
    forEach((observation: ForecastObservation) => {
      if (observation.forecastStartTime.split('T')[0] >= startOfQuarter) {
        forecastRanges.push([
          Date.parse(observation.forecastStartTime),
          Number(Number(observation.valueHat_lower).toPrecision(8)),
          Number(Number(observation.valueHat_higher).toPrecision(8)),
        ]);
        forecastAverages.push([
          Date.parse(observation.forecastStartTime),
          Number(Number(observation.valueHat).toPrecision(8)),
        ]);
      }
    }, forecastObs);

    const fromDate = moment(new Date(forecastRanges[0][0]).toISOString().split('T')[0]).format('DD MMM YY');
    const toDate = moment(new Date(forecastRanges[forecastRanges.length - 1][0]).toISOString().split('T')[0]).format(
      'DD MMM YY',
    );

    const options: Highcharts.Options = {
      title: {
        text: `Quarterly Energy forecast: ${fromDate}-${toDate}`,
      },
      xAxis: {
        type: 'datetime',
        accessibility: {
          rangeDescription: 'Range:',
        },
      },
      yAxis: {
        title: {
          text: 'kwh',
        },
      },
      tooltip: {
        shared: true,
        valueSuffix: 'kw',
      },
      lang: {
        noData: 'No forecast available for the period',
      },
      noData: {
        position: {
          align: 'center',
          verticalAlign: 'middle',
        },
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
      series: [
        {
          name: 'Forecasted Value',
          data: forecastAverages,
          zIndex: 1,
          type: 'line',
          color: 'green',
        },
        {
          name: 'Actual Value',
          data: actuals,
          type: 'line',
          zIndex: 2,
          color: 'black',
        },
        {
          name: 'Forecasted Range(Lower-Upper)',
          data: forecastRanges,
          type: 'arearange',
          lineWidth: 0,
          color: '#4074B2',
          fillOpacity: 0.3,
          zIndex: 0,
        },
      ],
    };
    return options;
  };

  return (
    <div>
      <div>
        <Button variant="contained" color="primary" onClick={handleOpen} style={{ width: '100%', height: '100%' }}>
          <Typography variant="body2" style={{ textTransform: 'none' }}>
            Show Quarterly Forecast
          </Typography>
        </Button>
      </div>
      <div style={{ backgroundColor: 'yellow', borderRadius: '50px' }}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 100,
          }}>
          <Fade in={open} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={classes.paper}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: '2%',
                  }}>
                  <Typography variant="h5">Actual vs Predicted Energy</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}>
                  <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <HighchartsReact
                key="energyForecasts"
                highcharts={Highcharts}
                immutable={true}
                options={createAreaChart(props.energyForecastObservations, props.historicalObservations)}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};
