import { makeStyles } from '@material-ui/core';
import { FONT_SIZE_SMALL } from './styleConstants';

export const useObservationDetailStyles = makeStyles((theme) => ({
  titleTypographyProps: {
    titleTypographyProps: {
      align: 'center',
      variant: 'h6',
      style: {
        fontWeight: 'bold',
        paddingTop: '0px',
        paddingBottom: '2px',
      },
    },
  },
  gridContainer: {
    flexGrow: 1,
  },
  chevronStyle: {
    display: 'flex',
    position: 'absolute',
    width: 'auto',
    top: 0,
    right: 0,
    paddingTop: '3%',
  },
  covidAccessControlReportGroupTitle: {
    fontWeight: 'bold',
  },
  captionText: {
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  covidAccessControlReportGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2%',
    paddingBottom: '1%',
    position: 'relative',
  },
  covidSafeTrainingBackCardLayout: {
    transitionDuration: '0.3s',
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  returnCampusResearchCardLayout: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '10% 10% 0 0',
  },
  covidSafeTrainingCardLayout: {
    transitionDuration: '0.3s',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    minWidth: 180,
  },
  captionBackText: {
    fontSize: '0.9em',
    color: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
  },
  observationTime: {
    fontSize: FONT_SIZE_SMALL,
  },
}));
