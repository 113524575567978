export type SpaceType = 'room' | 'floor' | 'building' | 'campus';
export enum SpaceTypes {
  room = 'room',
  floor = 'floor',
  building = 'building',
  campus = 'campus',
}

export enum SpaceUtilisationHeatMapTypes {
  CMX_HEATMAP_TYPE1 = 'cmx-relative-to-max-building-count',
  CMX_HEATMAP_TYPE2 = 'cmx-relative-to-building-capacity',
  ACCESS_CARD_TYPE1 = 'access-card-relative-to-max-building-count',
  ACCESS_CARD_TYPE2 = 'access-card-relative-to-building-capacity',
  NONE = 'none',
}

// Should ideally be mapped to common/ObservationDict.js
export enum SpaceUtilisationObservationTags {
  CMX_HEADCOUNT = 'HeadCount#cmx-active-clients',
  ACCESS_CARD_HEADCOUNT = 'HeadCount#access-card-transactions',
}
