import axios, { AxiosInstance, AxiosError } from 'axios';
import { RoomSpace } from '../models/Room';
import Logger from '../utils/logger';
import { equals, filter, includes, isEmpty, isNil, path } from 'ramda';
import { ApiResourceNotFound } from './Errors/ApiResourceNotFound';
import { ApiUnexpectedError } from './Errors/ApiUnexpectedError';
import { BuildingSpace } from 'models/Building';
import { map } from 'ramda';
import { CampusSpace } from 'models/Campus';
import { Summary } from 'models/Summary';
import {
  BuildingEnergyInterventionPOSTResponse,
  BuildingPopularTimingsResponse,
  ForecastApiResponse,
  ObservationsApiResponse,
  ObservationType,
} from 'models/Observation';
import { FloorSpace } from 'models/Floor';
// import { DefaultAuthParameters, acquireBearerToken } from './AuthService'
import { HAS_SSO } from 'components/authConstants';
import { ForbiddenError } from './Errors/ForbiddenError';
import { MazemapArchibusMapping } from 'components/constants';
// import { OktaAuth } from '@okta/okta-auth-js'

export let SPACE_API_HOST = process.env.REACT_APP_SPACE_API_HOST || 'http://localhost:4000';
export let OBSERVATION_API_HOST = process.env.REACT_APP_OBSERVATION_API_HOST || 'http://localhost:4000';
export let FORECAST_API_HOST = process.env.REACT_APP_FORECAST_API_HOST || 'http://localhost:4000';

export const updateSpaceApiHost = (host: string): void => {
  SPACE_API_HOST = host;
};

export const updateObservationApiHost = (host: string): void => {
  OBSERVATION_API_HOST = host;
};

export const updateForecastApiHost = (host: string): void => {
  FORECAST_API_HOST = host;
};

/* export const createAuthClient = (): OktaAuth => {
  const authClient = new OktaAuth({
    issuer: 'https://sso-preprod.unimelb.edu.au/oauth2/default',
  })
  return authClient
} */

const createInstance = (host: string, accessToken?: string | undefined): AxiosInstance => {
  const headers: any = {
    Accept: 'application/json',
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
    // headers['Access-Control-Allow-Origin'] = '*'
  }
  const api = axios.create({
    baseURL: host,
    withCredentials: false,
    headers,
  });
  return api;
};

/* const acquireAndConfigureBearerToken = (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> =>
  acquireBearerToken(DefaultAuthParameters).then(
    (token: string | null): Promise<AxiosRequestConfig> => {
      const authorizationHeader = token !== null ? `Bearer ${token}` : undefined
      return Promise.resolve({
        ...config,
        headers: {
          ...config.headers,
          common: {
            ...config.headers.common,
            Authorization: authorizationHeader,
          },
        },
      })
    },
  ) */

/* const addAuthInterceptor = (apiInstance: AxiosInstance): AxiosInstance => {
  apiInstance.interceptors.request.use(
    (config: AxiosRequestConfig): Promise<AxiosRequestConfig> =>
      acquireAndConfigureBearerToken(config),
    (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
  )

  return apiInstance
} */

const createInstanceWithAuth = (host: string, accessToken: string | undefined): AxiosInstance => {
  const instance = createInstance(host, accessToken);
  // return addAuthInterceptor(instance)
  return instance;
};

const spaceApi = (accessToken: string | undefined): AxiosInstance => {
  return HAS_SSO ? createInstanceWithAuth(SPACE_API_HOST, accessToken) : createInstance(SPACE_API_HOST, accessToken);
};

const observationApi = (accessToken: string | undefined): AxiosInstance => {
  return HAS_SSO
    ? createInstanceWithAuth(OBSERVATION_API_HOST, accessToken)
    : createInstance(OBSERVATION_API_HOST, accessToken);
};

const forecastApi = (accessToken: string | undefined): AxiosInstance => {
  return HAS_SSO
    ? createInstanceWithAuth(FORECAST_API_HOST, accessToken)
    : createInstance(FORECAST_API_HOST, accessToken);
};

export const getArchibusCodefromMap = (buildingNumber: string, floorNumber: string): any => {
  const archibusMazemapMap = filter(
    (mapping: any) => equals(buildingNumber, mapping.buildingCode) && equals(floorNumber, mapping.mazemapCode),
    MazemapArchibusMapping,
  );
  if (!isEmpty(archibusMazemapMap)) {
    floorNumber = archibusMazemapMap[0].archibusCode;
  }
  return floorNumber;
};

export const getBuildingsSummary = (campus = 'PAR', accessToken: string | undefined): Promise<Summary[]> => {
  // fetchNewAccessToken(accessToken, idToken)
  return observationApi(accessToken)
    .get(`/observations/campuses/${campus}/buildings/summary`)
    .then((response): Summary[] => map((status): Summary => status, response.data))
    .catch((error: AxiosError): Summary[] => {
      Logger.error('ApiService: getBuildingsSummary error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Building not found');
        default:
          throw new ApiUnexpectedError('Building Summary: unexpected  error');
      }
    });
};

export const getRoomObservations = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  roomNumber: string,
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  /* if (contains(buildingNumber, ['290', '291']) && equals(floorNumber, 'GM')) {
    floorNumber = '0.5'
  } */
  Logger.debug('getRoomObservations floorNumber before', floorNumber);
  floorNumber = getArchibusCodefromMap(buildingNumber, floorNumber);
  Logger.debug('floorNumber after', floorNumber);
  if (isEmpty(buildingNumber)) {
    Logger.error(`buildingNumber invalid: "${buildingNumber}"`);
  }

  return observationApi(accessToken)
    .get(
      `/observations/campuses/${campus}/buildings/${buildingNumber}/floors/${floorNumber}/rooms/${roomNumber}/observations`,
    )
    .then((response): ObservationsApiResponse => response.data)
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getRoomObservations error code', error.message);
      const status = path(['response', 'status'], error);
      const message = path(['response', 'message'], error);
      Logger.error('error message', message);
      if (error.message.includes('Network Error')) {
        throw new ForbiddenError('Unauthorized access');
      }
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Room not found');
        default:
          throw new ApiUnexpectedError('Room Observations: unexpected error');
      }
    });
};

export const getFloorHistoricalObservations = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  if (isEmpty(buildingNumber) || isEmpty(floorNumber) || isEmpty(campus)) {
    Logger.error(`campus, buildingNumber or floorNumber invalid: "${campus}, ${buildingNumber}, ${floorNumber}"`);
  }
  /* if (contains(buildingNumber, ['290', '291']) && equals(floorNumber, 'GM')) {
    floorNumber = '0.5'
  } */
  Logger.debug('getFloorHistoricalObservations floorNumber before', floorNumber);
  floorNumber = getArchibusCodefromMap(buildingNumber, floorNumber);
  Logger.debug('floorNumber after', floorNumber);

  const params = {
    class: observationClass,
    type: observationType,
    tag: observationTag,
    startDateTime,
    endDateTime,
  };

  return observationApi(accessToken)
    .get(`/observations/campuses/${campus}/buildings/${buildingNumber}/floors/${floorNumber}/observations`, { params })
    .then((response): ObservationsApiResponse => response.data)
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getFloorHistoricalObservations error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Floor not found');
        default:
          throw new ApiUnexpectedError('Floor Observations: unexpected error');
      }
    });
};

export const getRoomHistoricalObservations = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  roomNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  if (isEmpty(buildingNumber) || isEmpty(floorNumber) || isEmpty(roomNumber) || isEmpty(campus)) {
    Logger.error(
      `campus, buildingNumber, floorNumber or roomNumber invalid: "${campus}, ${buildingNumber}, ${floorNumber}, ${roomNumber}"`,
    );
  }
  /* if (contains(buildingNumber, ['290', '291']) && equals(floorNumber, 'GM')) {
    floorNumber = '0.5'
  } */
  Logger.debug('getRoomHistoricalObservations floorNumber before', floorNumber);
  floorNumber = getArchibusCodefromMap(buildingNumber, floorNumber);
  Logger.debug('floorNumber after', floorNumber);
  const params = {
    class: observationClass,
    type: observationType,
    tag: observationTag,
    startDateTime,
    endDateTime,
  };

  return observationApi(accessToken)
    .get(
      `/observations/campuses/${campus}/buildings/${buildingNumber}/floors/${floorNumber}/rooms/${roomNumber}/observations`,
      { params },
    )
    .then((response): ObservationsApiResponse => response.data)
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getRoomHistoricalObservations error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Room not found');
        default:
          throw new ApiUnexpectedError('Room Observations: unexpected error');
      }
    });
};

export const getRoomSpaceDetails = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  roomNumber: string,
  accessToken: string | undefined,
): Promise<RoomSpace> => {
  if (isEmpty(buildingNumber)) {
    Logger.error(`buildingNumber invalid: "${buildingNumber}"`);
  }
  /* if (contains(buildingNumber, ['290', '291']) && equals(floorNumber, 'GM')) {
    floorNumber = '0.5'
  } */
  Logger.debug('getRoomSpaceDetails floorNumber before', floorNumber);
  floorNumber = getArchibusCodefromMap(buildingNumber, floorNumber);
  Logger.debug('floorNumber after', floorNumber);

  return spaceApi(accessToken)
    .get(`/space/campuses/${campus}/buildings/${buildingNumber}/floors/${floorNumber}/rooms/${roomNumber}`)
    .then((response): RoomSpace => response.data)
    .catch((error: AxiosError): RoomSpace => {
      Logger.error('ApiService: getRoomSpaceDetails error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Room not found');
        default:
          throw new ApiUnexpectedError('Room Space: unexpected error');
      }
    });
};

export const getRoomsSummary = (
  campusId = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  accessToken: string | undefined,
): Promise<Summary[]> => {
  if (isEmpty(buildingNumber)) {
    Logger.error(`buildingNumber invalid: got "${buildingNumber}"`);
  }
  /* if (contains(buildingNumber, ['290', '291']) && equals(floorId, 'GM')) {
    floorId = '0.5'
  } */
  Logger.debug('getRoomsSummary floorNumber before', floorNumber);
  floorNumber = getArchibusCodefromMap(buildingNumber, floorNumber);
  Logger.debug('floorNumber after', floorNumber);
  return observationApi(accessToken)
    .get(`/observations/campuses/${campusId}/buildings/${buildingNumber}/floors/${floorNumber}/rooms/summary`)
    .then((response): Summary[] => response.data)
    .catch((error: AxiosError): Summary[] => {
      Logger.error('ApiService: getRoomsSummary error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Rooms not found');
        default:
          throw new ApiUnexpectedError('Rooms Summary: unexpected error');
      }
    });
};

export const getFloorObservations = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  if (isEmpty(buildingNumber)) {
    Logger.error(`buildingNumber invalid: "${buildingNumber}"`);
  }
  /* if (contains(buildingNumber, ['290', '291']) && equals(floorNumber, 'GM')) {
    floorNumber = '0.5'
  } */
  Logger.debug('getFloorObservations floorNumber before', floorNumber);
  floorNumber = getArchibusCodefromMap(buildingNumber, floorNumber);
  Logger.debug('floorId after', floorNumber);

  return observationApi(accessToken)
    .get(`/observations/campuses/${campus}/buildings/${buildingNumber}/floors/${floorNumber}/observations`)
    .then((response): ObservationsApiResponse => response.data)
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getFloorObservations error code', error.message);
      const status = path(['response', 'status'], error);
      const message = path(['response', 'message'], error);
      Logger.error('error message: ', message);
      if (error.message.includes('Network Error')) {
        throw new ForbiddenError('Unauthorized access');
      }
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Floor not found');
        default:
          throw new ApiUnexpectedError('Floor Observations: unexpected error');
      }
    });
};

export const getFloorSpaceDetails = (
  campusId = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  accessToken: string | undefined,
): Promise<FloorSpace> => {
  if (isEmpty(buildingNumber) || isEmpty(floorNumber)) {
    Logger.error(`buildingNumber or floorNumber invalid: got "${buildingNumber}" and "${floorNumber}" respectively`);
  }
  /* if (contains(buildingNumber, ['290', '291']) && equals(floorId, 'GM')) {
    floorId = '0.5'
  } */
  Logger.debug('getFloorSpaceDetails floorNumber before', floorNumber);
  floorNumber = getArchibusCodefromMap(buildingNumber, floorNumber);
  Logger.debug('floorId after', floorNumber);
  return spaceApi(accessToken)
    .get(`/space/campuses/${campusId}/buildings/${buildingNumber}/floors/${floorNumber}`)
    .then((response): FloorSpace => response.data)
    .catch((error: AxiosError): FloorSpace => {
      Logger.error('ApiService: getFloorSpaceDetails error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Floor not found');
        default:
          throw new ApiUnexpectedError('Floor Space: unexpected error');
      }
    });
};

export const getFloorsSummary = (
  campusId = 'PAR',
  buildingNumber: string,
  accessToken: string | undefined,
): Promise<Summary[]> => {
  if (isEmpty(buildingNumber)) {
    Logger.error(`buildingNumber invalid: got "${buildingNumber}"`);
  }

  return observationApi(accessToken)
    .get(`/observations/campuses/${campusId}/buildings/${buildingNumber}/floors/summary`)
    .then((response): Summary[] => response.data)
    .catch((error: AxiosError): Summary[] => {
      Logger.error('ApiService: getFloorsSummary error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Floor not found');
        default:
          throw new ApiUnexpectedError('Floor Summary: unexpected error');
      }
    });
};

export const getBuildingSpaceDetails = (
  campus = 'PAR',
  buildingNumber: string,
  accessToken: string | undefined,
): Promise<BuildingSpace> => {
  if (isEmpty(buildingNumber)) {
    Logger.error(`buildingNumber invalid: "${buildingNumber}"`);
  }

  return spaceApi(accessToken)
    .get(`/space/campuses/${campus}/buildings/${buildingNumber}`)
    .then((response): BuildingSpace => response.data as BuildingSpace)
    .catch((error: AxiosError): BuildingSpace => {
      Logger.error('ApiService: getBuildingSpaceDetails error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Building not found');
        default:
          throw new ApiUnexpectedError('Building Space: unexpected error');
      }
    });
};

export const getBuildingObservations = (
  campus = 'PAR',
  buildingNumber: string,
  params: any,
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  if (isEmpty(buildingNumber)) {
    Logger.error(`buildingNumber invalid: "${buildingNumber}"`);
  }
  return observationApi(accessToken)
    .get(`/observations/campuses/${campus}/buildings/${buildingNumber}/observations`, {
      params,
    })
    .then((response): ObservationsApiResponse => response.data)
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getBuildingObservations error code', error.message);
      const status = path(['response', 'status'], error);
      const message = path(['response', 'message'], error);
      Logger.error('error message: ', message);
      if (error.message.includes('Network Error')) {
        throw new ForbiddenError('Unauthorized access');
      }
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Building not found');
        default:
          throw new ApiUnexpectedError('Building Observations: unexpected error');
      }
    });
};

export const getBuildingHistoricalObservations = (
  campus = 'PAR',
  buildingNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  if (isEmpty(buildingNumber)) {
    Logger.error(`campus or buildingNumber invalid: "${campus}, ${buildingNumber}"`);
  }

  if (includes('148', buildingNumber) && equals(ObservationType.energy, observationType)) {
    buildingNumber = 'PC_ARTSWEST';
  }

  const params = {
    class: observationClass,
    type: observationType,
    tag: observationTag,
    startDateTime,
    endDateTime,
  };

  return observationApi(accessToken)
    .get(`/observations/campuses/${campus}/buildings/${buildingNumber}/observations`, {
      params,
    })
    .then((response): ObservationsApiResponse => response.data)
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getBuildingHistoricalObservations error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        default:
          throw new ApiUnexpectedError('unexpected error');
      }
    });
};

export const getBuildingPopularTimings = (
  /* campusId: string, */
  buildingNumber: string,
  accessToken: string,
): any => {
  return observationApi(accessToken)
    .get(`/observations/buildings/${buildingNumber}/popularTimings`)
    .then((res): BuildingPopularTimingsResponse => res.data)
    .catch((error: AxiosError) => {
      Logger.error('error: getBuildingPopularTimings', error);
      throw new ApiUnexpectedError('unexpected error');
    });
};

export const getCampusSpaceDetails = (campusId = 'PAR', accessToken: string | undefined): Promise<CampusSpace> => {
  if (isEmpty(campusId)) {
    Logger.error(`campusId invalid: "${campusId}"`);
  }
  return spaceApi(accessToken)
    .get(`/space/campuses/${campusId}`)
    .then((response): CampusSpace => response.data as CampusSpace)
    .catch((error: AxiosError): CampusSpace => {
      Logger.error('ApiService: getCampusSpaceDetails error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Campus not found');
        default:
          throw new ApiUnexpectedError('Campus Space: unexpected error');
      }
    });
};

export const getCampusObservations = (
  campus = 'PAR',
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  return observationApi(accessToken)
    .get(`/observations/campuses/${campus}/observations`)
    .then((response): ObservationsApiResponse => {
      if (!isNil(response)) {
        return response.data;
      } else {
        throw new ApiUnexpectedError(response);
      }
    })
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getCampusObservations error code', error.message);
      const status = path(['response', 'status'], error);
      const message = path(['response', 'message'], error);
      Logger.error('error message: ', message);
      if (error.message.includes('Network Error')) {
        throw new ForbiddenError('Unauthorized access');
      }
      switch (status) {
        case 404:
          throw new ApiResourceNotFound('Campus not found');
        case 403:
          throw new ApiUnexpectedError('AccessDenied');
        default:
          throw new ApiUnexpectedError('Campus Observation: unexpected error');
      }
    });
};

export const getCampusHistoricalObservations = (
  campus = 'PAR',
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): Promise<ObservationsApiResponse> => {
  if (isEmpty(campus)) {
    Logger.error(`campus invalid: "${campus}"`);
  }

  const params = {
    class: observationClass,
    type: observationType,
    tag: observationTag,
    startDateTime,
    endDateTime,
  };

  return observationApi(accessToken)
    .get(`/observations/campuses/${campus}/observations`, { params })
    .then((response): ObservationsApiResponse => response.data)
    .catch((error: AxiosError): ObservationsApiResponse => {
      Logger.error('ApiService: getCampusHistoricalObservations error', error);
      const status = path(['response', 'status'], error);
      switch (status) {
        default:
          throw new ApiUnexpectedError('unexpected error');
      }
    });
};

export const getCampusTemperatureForecastObservations = (
  campus = 'PAR',
  accessToken: string | undefined,
  startDate: string,
  endDate: string,
): Promise<ForecastApiResponse> => {
  const params = {
    startDate,
    endDate,
  };
  return forecastApi(accessToken)
    .get(`/forecast/api/temperature/campus/${campus}`, { params })
    .then((response): ForecastApiResponse => response.data)
    .catch((error: AxiosError): ForecastApiResponse => {
      Logger.error('ApiService: getCampusTemperatureForecastObservations Error', error);
      throw new ApiUnexpectedError(`Forecast observation error ${error}`);
    });
};

export const getCampusEnergyForecastObservations = (
  campus = 'PAR',
  startDate: string,
  endDate: string,
  accessToken: string | undefined,
): Promise<ForecastApiResponse> => {
  const params = {
    startDate,
    endDate,
  };
  return forecastApi(accessToken)
    .get(`/forecast/api/energy/campus/${campus}`, { params })
    .then((response): ForecastApiResponse => response.data)
    .catch((error: AxiosError): ForecastApiResponse => {
      Logger.error('ApiService: getCampusEnergyForecastObservations Error', error);
      throw new ApiUnexpectedError(`Energy Forecast observation error ${error}`);
    });
};

export const getBuildingEnergyForecastObservations = (
  campus = 'PAR',
  buildingNumber: string,
  startDate: string,
  endDate: string,
  accessToken: string | undefined,
): Promise<ForecastApiResponse> => {
  const params = {
    startDate,
    endDate,
  };
  if (includes('148', buildingNumber)) {
    buildingNumber = 'PC_ARTSWEST';
  }
  return forecastApi(accessToken)
    .get(`/forecast/api/energy/campus/${campus}/buildings/${buildingNumber}`, { params })
    .then((response): ForecastApiResponse => response.data)
    .catch((error: AxiosError): ForecastApiResponse => {
      Logger.error('ApiService: getBuildingEnergyForecastObservations Error', error);
      throw new ApiUnexpectedError(`Building Energy Forecast observation error ${error}`);
    });
};

export const postBuildingEnergyIntervention = (
  accessToken: string,
  comments: string,
  buildingNumber: string,
  campusId: string,
  cost: number,
  energySavings: number,
  interventionDate: string,
  interventionType: string,
): Promise<any> => {
  const body = {
    comments,
    cost,
    energySavings,
    interventionDate,
    interventionType,
  };
  return spaceApi(accessToken)
    .post(`/space/campuses/${campusId}/buildings/${buildingNumber}/maintenanceEventLogs`, body)
    .then((response: any): BuildingEnergyInterventionPOSTResponse => response.data)
    .catch((error: AxiosError): ForecastApiResponse => {
      Logger.error('ApiService: postBuildingEnergyIntervention Error', error);
      throw new ApiUnexpectedError(`Building Energy Intervention POST error ${error}`);
    });
};

export const getBuildingEnergyInterventions = (accessToken: string, campusId: string, buildingNumber: string): any => {
  return spaceApi(accessToken)
    .get(`/space/campuses/${campusId}/buildings/${buildingNumber}/maintenanceEventLogs`)
    .then((response: any): any => response.data)
    .catch((error: AxiosError): any => {
      throw new ApiUnexpectedError(`Building Energy Intervention GET error ${error}`);
    });
};

export const removeSelectedIntervention = (
  accessToken: string,
  campusId: string,
  buildingNumber: string,
  interventionDate: string,
  interventionType: string,
): any => {
  return spaceApi(accessToken)
    .delete(
      `/space/campuses/${campusId}/buildings/${buildingNumber}/maintenanceEventLogs?interventionDate=${interventionDate}&interventionType=${encodeURIComponent(
        interventionType,
      )}`,
    )
    .then((response: any): any => response.data)
    .catch((error: AxiosError): any => {
      throw new ApiUnexpectedError(`Building Energy Intervention DELETE error ${error}`);
    });
};
