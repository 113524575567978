import React, { useState, Fragment } from 'react';
import { CampusSpace, getCampusSpaceProperty } from 'models/Campus';
import { Grid } from '@material-ui/core';
import { FlipCardSpaceAttribute } from 'components/common/FlipCard';
import { useSpaceAttributeStyles } from 'styles/spaceAttributeStyle';

interface CampusAttributeProps {
  campusAttributes: CampusSpace | undefined;
}

const CampusAttributes = (props: CampusAttributeProps): JSX.Element => {
  const classes = useSpaceAttributeStyles();
  const [showSection] = useState(true);

  const campusId = getCampusSpaceProperty('locationCode', props.campusAttributes);

  return (
    <div className={classes.FlipCardItem}>
      <div>
        {' '}
        <Fragment>
          <div>
            {/* Header */}
            {showSection ? (
              <div style={{ backgroundColor: 'white' }}>
                <Grid container item xs={12} spacing={1} className={classes.gridContainer} sm>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexGrow: 1,
                      width: '100%',
                    }}>
                    <FlipCardSpaceAttribute
                      spaceAttribute={{
                        name: 'Buildings Count',
                        value: getCampusSpaceProperty('buildingsCount', props.campusAttributes),
                        description: `Total number of buildings in the campus.
                          ${
                            campusId === 'PAR'
                              ? ' Includes buildings from Parkville, Residential & Commercial sites.'
                              : ''
                          }`,
                      }}
                    />
                  </div>
                </Grid>
              </div>
            ) : null}
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default CampusAttributes;
