import { makeStyles } from '@material-ui/core';
import { FONT_SIZE_SMALL } from './styleConstants';

export const useSpaceAttributeStyles = makeStyles(() => ({
  FlipCardItem: {
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      padding: '4px 4px',
    },
  },
  gridContainer: {
    flexGrow: 1,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  },
  frontardLayout: {
    marginBottom: 4,
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  frontCaptionText: {
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  backCardLayout: {
    transitionDuration: '0.3s',
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backCaptionText: {
    fontSize: '70%',
    color: 'white',
  },
  frontAction: {
    fontSize: FONT_SIZE_SMALL,
  },
}));
