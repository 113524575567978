import { propOr } from 'ramda';

export interface ObservationsApiResponse {
  location: Location;
  observations: Observations;
}

export interface BuildingPopularTimingsResponse {
  popularTimings: PopularTimings;
}

export interface BuildingEnergyInterventionPOSTResponse {
  message: string;
}

export interface BuildingEnergyIntervention {
  comments: string;
  cost: string;
  energySavings: string;
  interventionType: string;
  interventionDate: string;
}
export interface BuildingEnergyInterventionGETResponse {
  buildingEnergyInterventions: BuildingEnergyIntervention[];
}

export interface HistoricPopularTimings {
  dayOfTheWeek: string;
  hours: string;
  busyIndex: string;
}
export interface PopularTimings {
  historicPopularTimings: HistoricPopularTimings[];
  type: string;
  locationCode: string;
  academicCalendarActivity: string;
  hours: string;
  liveBusyIndex: string;
  liveBusyIndexDescription: string;
  historicalBusyIndexDescription: string;
}
interface GeoCode {
  lat: string;
  lon: string;
}

export interface ForecastExtension {
  closest_geocode: GeoCode;
  actual_geocode: GeoCode;
  campus_station_id: string;
}

export interface ForecastObservation {
  location: Location;
  observationType: ObservationType;
  observationTag: ObservationTag;
  forecastStartTime: string;
  forecastEndTime: string;
  uuid: string;
  unitOfMeasure: string;
  valueHat: number | string;
  valueHat_lower?: number | string;
  valueHat_higher?: number | string;
  extension?: ForecastExtension;
}

export interface ForecastObservations {
  forecasts: ForecastObservation[];
}

export interface ForecastApiResponse {
  location: Location;
  forecasts: ForecastObservation[];
  // forecastObservation: ForecastObservations
}

export interface ObservationsHistoryApiResponse {
  location: Location;
  observations: ObservationsHistoryResponse;
}

interface Location {
  locationCode: string;
}

export interface Observations {
  alarms: AlarmObservation[];
  latest: Observation[];
}

export interface ObservationsHistoryResponse {
  historical: Observation[];
}

export interface AlarmObservation {
  locationCode: string;
  reportedTime: string;
  observationClass: ObservationClass;
  observationType: AlarmObservationType;
  observationTag: string;
  status: AlarmStatus;
  description: string;
}

export interface ObservationExtension {
  contextType: string;
  activityName: string | null;
  classPlannedSize: string | null;
  totalRegistered: string | null;
  CountByCardholderDivision?: any; // Return To Campus Observations
  CountByCompany?: any; // Return To Campus Observations
  CountByDivision?: any; // Return To Campus Observations
  CountByCohort?: any; // Return To Campus Observations
  CountByStatus?: any; // Return To Campus Observations
  CountByOverallComplianceStatus?: any;
  CountByAcademicDivision?: any; // Return To Campus Access ControlObservations
  CountByType?: any; // Return To Campus Access ControlObservations
  CountByReturnToCampusComplianceStatus?: any;
}

export interface Observation {
  locationCode: string;
  observationStartTime: string;
  observationEndTime: string;
  observationClass: ObservationClass;
  observationType: ObservationType;
  observationTag: string;
  unitOfMeasure: string;
  value: string;
  extension?: ObservationExtension;
}

export interface EnergyObservation {
  location: Location;
  observationStartTime: string;
  observationEndTime: string;
  observationClass: ObservationClass;
  observationType: ObservationType;
  observationTag: string;
  unitOfMeasure: string;
  value: string;
  extension?: ObservationExtension;
}

export interface SpaceUtilisationType {
  capacity: number;
  observations: Observation[];
}

export interface SpaceUtilisationHeatMapModel {
  locationCode: string;
  headCount: number;
  observationEndTime: string;
  observationTag: string;
  capacity?: number | 1;
  heatmapRatio?: number | 0;
}

enum AlarmStatus {
  abnormal = 'abnormal',
  normal = 'normal',
  unknown = 'unknown',
}

export enum ObservationClass {
  spaceComfort = 'space-comfort',
  energy = 'energy',
}

export enum ObservationType {
  temperature = 'Temperature',
  temperatureSetPoint = 'TemperatureSetPoint',
  co2 = 'CO2',
  co2SetPoint = 'CO2SetPoint',
  humidity = 'Humidity',
  humiditySetPoint = 'HumiditySetPoint',
  pressure = 'Pressure',
  windSpeed = 'Wind',
  windDirection = 'Wind',
  spaceUtilisation = 'SpaceUtilisation',
  energy = 'Energy',
  covidSafe = 'CovidSafe',
  ventilation = 'Ventilation',
}

export enum HeatmapType {
  spaceUtilisation = 'SpaceUtilisation',
}

export enum ObservationTag {
  // space comfort
  temperatureSetPoint = 'SETPOINT',
  temperatureEffSetPoint = 'EFF_SP',
  temperatureRoomSetPoint = 'ROOM_SP',
  temperatureCoolingSetPoint = 'EFF_COOLING_SP',
  temperatureHeatingSetPoint = 'EFF_HEATING_SP',
  humiditySetPoint = 'ROOM_HUMIDITY_SP',
  co2 = 'ROOM_CO2_LEVEL',
  co2SetPoint = 'ROOM_CO2_SP',
  airTemperature = 'AIR_TEMPERATURE',
  meanMINAirTemperature = 'MEAN_MINIMUM_AIR_TEMPERATURE',
  meanMAXAirTemperature = 'MEAN_MAXIMUM_AIR_TEMPERATURE',
  apparentTemperature = 'APPARENT_TEMP',
  humidity = 'REL-HUMIDITY',
  pressure = 'PRES',
  windSpeed = 'WIND_SPD_KMH',
  windDirection = 'WIND_DIR',
  // energy
  minPfConsumption = 'MINIMUM-PFCONSUMPTION',
  peakWhConsumption = 'PEAK-WHCONSUMPTION',
  totalKgConsumption = 'TOTAL-KGCONSUMPTION',
  totalVahConsumption = 'TOTAL-VAHCONSUMPTION',
  totalWhConsumption = 'TOTAL-WHCONSUMPTION',
  totalCost = 'TOTAL-COST',
  totalCostExcGST = 'TOTAL-COST-EXC-GST#INVOICE',
  historicAvgWHConsumption = 'HISTORIC_AVG_TOTAL-WHCONSUMPTION',
  totalVahGeneration = 'TOTAL-VAHGENERATION',
  totalWhGeneration = 'TOTAL-WHGENERATION',
  whConsumption = 'WHCONSUMPTION',
  whGeneration = 'WHGENERATION',

  //Covid Safe
  covidSafeBuildingAccessApprovals = 'BUILDING_ACCESS_APPROVALS',
  covidSafeHealthDeclaration = 'HEALTH_DECLARATION',
  covidSageR2CWorkflow = 'R2C_WORKFLOW',
  covidSafeTraining = 'TRAINING',
  covidSafeReturnToCampusResearch = 'RETURN_TO_CAMPUS',
  covidOverallComplianceStatus = 'COMPLIANCE_STATUS',
}

export enum AlarmObservationType {
  temperature = 'TemperatureAlarm',
  humidity = 'HumidityAlarm',
  co2 = 'CO2Alarm',
}

export const BomHistoryIndex = 'BOM_HISTORY';

export const defaultUnknownValue = '—';

export const getObservationProperty = (propertyName: string, observation: Observation | undefined): string =>
  propOr(defaultUnknownValue, propertyName, observation);

export const getObservationValue = (observation: Observation | undefined): string =>
  getObservationProperty('value', observation);

export const getObservationTag = (observation: Observation | undefined): string =>
  getObservationProperty('observationTag', observation);

export const getObservationEndTime = (observation: Observation | undefined): string =>
  getObservationProperty('observationEndTime', observation);
