import { cond, equals, always, T, compose, SafePred, isNil } from 'ramda';
import { UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import { SEVERITY_LOW as LOW, SEVERITY_MEDIUM as MEDIUM, SEVERITY_SEVERE as SEVERE } from 'components/constants';

// Extracts building number from name e.g. 'LAW BUILDING (106)' => 106
export const extractUomBuildingId = (buildingName: string): string => {
  const match = /.*\(BUILDING (\d+[a-zA-z]?)\)/g.exec(buildingName);
  return match ? String(match[1]) : '';
};

const getFirstChar = (s: string): string => s.charAt(0);
const getFirstTwoChar = (s: string): string => s.substring(0, 2);
const getFirstLastChar = (s: string): string => s.substring(0, 1).concat(s.charAt(s.length - 1));
const getLastChar = (s: string): string => s.charAt(s.length - 1);
const isFirstCharacter = (letter: string): SafePred<string> => compose(equals(letter), getFirstChar);
const isLastCharacter = (letter: string): SafePred<string> => compose(equals(letter), getLastChar);
const isMezzanine = (): SafePred<string> => compose(equals('LM'), getFirstLastChar);
const isFirstTwoCharacters = (letters: string): SafePred<string> => compose(equals(letters), getFirstTwoChar);
const removeFirstCharacter = (s: string): string => s.substring(1);
const mapBasementId = (s: string): string => s.replace('B', '0.');
const mapBasementIdM = (s: string): string => s.replace('M', '.5');
const mapMezzanineId = (s: string): string => s.replace('M', '.5').replace('L', '');
const unparsedFloorId = (s: string): string => s;

const mazemapToArchibusId = cond([
  [isFirstTwoCharacters('UG'), always('0.2')],
  [isFirstTwoCharacters('GM'), always('0.5')],
  [isMezzanine(), mapMezzanineId],
  [equals('G'), always('0')],
  [isFirstCharacter('L'), removeFirstCharacter],
  [isFirstTwoCharacters('BM'), always('0.5')],
  [isLastCharacter('M'), mapBasementIdM],
  [equals('B'), always('0.1')],
  [isFirstCharacter('B'), mapBasementId],
  [T, unparsedFloorId],
]);

const basementRegex = /^0\.(\d+)$/;
const levelRegex = /^(\d+)$/;

const isArchibusBasement = (floorId: string): boolean => {
  return basementRegex.test(floorId);
};

const isLevel = (floorId: string): boolean => {
  return levelRegex.test(floorId);
};

const returnMazemapBasementId = (floorId: string): string => {
  const match = floorId.match(basementRegex);
  return `-${match![1]}`;
};

const returnMazemapBasementLabel = (floorId: string): string => {
  const match = floorId.match(basementRegex);
  return `B${match![1]}`;
};

const returnMazemapLevelId = (floorId: string): string => {
  const match = floorId.match(levelRegex);
  return `${parseInt(match![1]) + 1}`;
};

const returnMazemapLevelLabel = (floorId: string): string => {
  const match = floorId.match(levelRegex);
  return `L${match![1]}`;
};

const archibusToMazemapId: Function = cond([
  [equals('0'), always('1')],
  [isLevel, returnMazemapLevelId],
  [isArchibusBasement, returnMazemapBasementId],
  [T, unparsedFloorId],
]);

const archibusToMazemapLabel: Function = cond([
  [equals('0'), always('G')],
  [isLevel, returnMazemapLevelLabel],
  [isArchibusBasement, returnMazemapBasementLabel],
  [T, unparsedFloorId],
]);

export const FloorMapper = {
  archibusToMazemapId,
  mazemapToArchibusId,
  archibusToMazemapLabel,
};

export const scrollToBottomOfLeftPanel = (): void => {
  const leftPanel = document.getElementById('left-panel');
  if (!isNil(leftPanel)) {
    leftPanel.scrollTo(0, leftPanel.scrollHeight);
  }
};

export const getHighlightColor = (severity: string): string => {
  switch (severity) {
    case LOW:
      return UOM_SUPPORTING_COLOURS.orange.dark;
    case MEDIUM:
      return UOM_SUPPORTING_COLOURS.pink.dark;
    case SEVERE:
      return UOM_SUPPORTING_COLOURS.purple.dark;
    default:
      return UOM_SUPPORTING_COLOURS.purple.dark;
  }
};
