import React, { Fragment, ReactElement, useState, useEffect } from 'react';
import { not } from 'ramda';
import { Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Close } from '@material-ui/icons';
/* import { useDispatch } from 'react-redux'
import { createPath, parsePath } from 'history'
import {
  getCampusObservationsAction,
  getCampusSpaceDetailsAction,
} from 'store/details/actions' */

interface FeedbackProps {
  statusCode: number;
  open?: boolean;
  history: any;
  message: any;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '10px',
  },
  title: {
    fontSize:
      'calc(' +
      13 +
      'px + (' +
      16 +
      ' - ' +
      13 +
      ') * ((' +
      100 +
      'vw - ' +
      320 +
      'px) / (' +
      1366 +
      ' - ' +
      320 +
      ')))',
    fontWeight: 'bold',
  },
  content: {
    fontSize:
      'calc(' +
      11 +
      'px + (' +
      14 +
      ' - ' +
      11 +
      ') * ((' +
      100 +
      'vw - ' +
      320 +
      'px) / (' +
      1366 +
      ' - ' +
      320 +
      ')))',
  },
  collapse: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

const Feedback = ({ statusCode, message, history }: FeedbackProps): ReactElement => {
  const classes = useStyles();
  let isRegistrationSuccessful = not(statusCode > 199 && statusCode < 300);
  const [registerAlertOpen, setRegisterAlert] = useState(isRegistrationSuccessful);
  // setRegisterAlert(isRegistrationSuccessful)
  useEffect(() => {
    setRegisterAlert(not(statusCode > 199 && statusCode < 300));
  }, [statusCode]);

  const messageParts = message?.split(':');
  const parameter = messageParts[0];
  const type = messageParts[1]?.split(/\s+/)[1];

  const closeAlertHandler = (): void => {
    const path = history.location.pathname;
    const indexOfSubPathToRemove = path.lastIndexOf('/', path.lastIndexOf('/') - 1);
    const newPath = path.substring(0, indexOfSubPathToRemove);
    if (newPath !== '') {
      history.push(newPath);
    } else {
      window.location.pathname = '/campus/PAR';
      history.push('/campus/PAR');
    }
    isRegistrationSuccessful = !registerAlertOpen;
    setRegisterAlert(!registerAlertOpen);
  };

  return (
    <Fragment>
      {' '}
      {isRegistrationSuccessful ? (
        <Collapse in={registerAlertOpen} className={classes.collapse}>
          <Alert
            color="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={(): void => {
                  closeAlertHandler();
                }}>
                <Close fontSize="inherit" />
              </IconButton>
            }>
            <div>
              <Typography className={classes.title} color="textPrimary" gutterBottom>
                Something went wrong!! {type} {parameter} could not be found.
              </Typography>
            </div>
          </Alert>
        </Collapse>
      ) : null}
    </Fragment>
  );
};

export default Feedback;
