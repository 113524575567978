import { Feature } from 'geojson';

export const SET_MAP_SERVICE = 'SET_MAP_SERVICE';

interface SetMapServiceAction {
  type: typeof SET_MAP_SERVICE;
  payload: any;
}

export const MAP_SEARCH_RESULT = 'MAP_SEARCH_RESULT';

interface MapSearchResultAction {
  type: typeof MAP_SEARCH_RESULT;
  payload: Feature;
}

export type MapActionTypes = SetMapServiceAction | MapSearchResultAction;
