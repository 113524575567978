export const BUILDING_ZOOM_LEVEL = 19.5;
export const ROOM_LEVEL_CLICK_ZOOM_THRESHOLD = 18.3;

export const MAZEMAP_CAMPUS_TAG = 'unimelb';
export const FLY_TO_CAMPUS_SPEED = 1.0;

interface MazeMapCoordinates {
  lng: number;
  lat: number;
}
interface MazeMapCampusDetails {
  id: number;
  coordinates: MazeMapCoordinates;
  defaultZoom: number;
}

export const MazeMapCampusDetails: { [index: string]: MazeMapCampusDetails } = {};
// MazeMap campus ids are available from https://use.mazemap.com/api/campuscollections/?tag=unimelb
MazeMapCampusDetails['PAR'] = {
  id: 200,
  coordinates: { lng: 144.962, lat: -37.7999 },
  defaultZoom: 15.5,
};
MazeMapCampusDetails['STH'] = {
  id: 216,
  coordinates: { lng: 144.9667, lat: -37.826 },
  defaultZoom: 16,
};
MazeMapCampusDetails['WER'] = {
  id: 217,
  coordinates: { lng: 144.6929, lat: -37.8899 },
  defaultZoom: 17.5,
};
MazeMapCampusDetails['BUR'] = {
  id: 218,
  coordinates: { lng: 145.022, lat: -37.8289 },
  defaultZoom: 17.5,
};
MazeMapCampusDetails['CRE'] = {
  id: 219,
  coordinates: { lng: 143.8994, lat: -37.4226 },
  defaultZoom: 17.1,
};
MazeMapCampusDetails['DOO'] = {
  id: 220,
  coordinates: { lng: 145.7065, lat: -36.383 },
  defaultZoom: 16.9,
};
MazeMapCampusDetails['SPT'] = {
  id: 221,
  coordinates: { lng: 145.4037, lat: -36.37 },
  defaultZoom: 14.7,
};
MazeMapCampusDetails['HAW'] = {
  id: 243,
  coordinates: { lng: 145.041175, lat: -37.8377 },
  defaultZoom: 17.8,
};

interface CampusNameIndex {
  [campusId: string]: string;
}
export const CampusNames: CampusNameIndex = {};
CampusNames['PAR'] = 'Parkville';
CampusNames['STH'] = 'Southbank';
CampusNames['WER'] = 'Werribee';
CampusNames['BUR'] = 'Burnley';
CampusNames['CRE'] = 'Creswick';
CampusNames['DOO'] = 'Dookie';
CampusNames['SPT'] = 'Shepparton';
CampusNames['HAW'] = 'Hawthorn';

export const API_REQUEST_STATUS_NOT_REQUESTED = 'not requested';
export const API_REQUEST_STATUS_REQUESTED = 'waiting';
export const API_REQUEST_STATUS_RECEIVED = 'received';
export const API_REQUEST_STATUS_FAILED = 'failed';

const TWO_MINUTES_IN_MILLISECONDS = 1000 * 60 * 2;
export const FETCH_INTERVAL = TWO_MINUTES_IN_MILLISECONDS;

export const DEFAULT_USER_NAME: string = '';
export const CMX_HEATMAP_RECENT_OBSERVATION_THRESHOLD_IN_MINS: number = 15; // Every 15 minutes
export const ACCCARD_HEATMAP_RECENT_OBSERVATION_THRESHOLD_IN_MINS: number = 420; // Every 7 hours
export const BMS_ALARMS_OBSERVATION_THRESHOLD_IN_MINS: number = 1440; // Every 24 hours

// Return to campus specific constants.
export const TRANSACTION_CATEGORY = {
  RETURN_TO_CAMPUS: 'RETURN_TO_CAMPUS',
  HEALTH_DECLARATION: 'HEALTH_DECLARATION',
  COVID_SAFE_TRAINING: 'COVID_SAFE_TRAINING',
};
export const HEALTH_DECLARATION_STATES = {
  COMPLETED: 'Completed',
};

export const R2C_WORKFLOW_STATES = {
  COMPLETED: 'Completed',
  ASSIGNED: 'Assigned',
  CANCELLED: 'Cancelled',
  DEFERRED: 'Deferred',
  PENDING_ENDORSEMENT: 'Pending Endorsement',
};

export const COVID_SAFE_TRAINING_STATES = {
  COMPLETED: 'Completed',
  NOT_YET_STARTED: 'Not Yet Started',
  IN_PROGRESS: 'In Progress',
};

type MazemapArchibusCode = {
  archibusCode: string;
  mazemapCode: string;
  buildingCode: string;
};
export const MazemapArchibusMapping: MazemapArchibusCode[] = [
  {
    buildingCode: '290',
    archibusCode: '0.5',
    mazemapCode: 'GM',
  },
  {
    buildingCode: '290',
    archibusCode: '0.1',
    mazemapCode: 'B1',
  },
  {
    buildingCode: '290',
    archibusCode: '0',
    mazemapCode: 'G',
  },
  {
    buildingCode: '291',
    archibusCode: '0.5',
    mazemapCode: 'GM',
  },
  {
    buildingCode: '291',
    archibusCode: '0.1',
    mazemapCode: 'B1',
  },
  {
    buildingCode: '291',
    archibusCode: '0',
    mazemapCode: 'G',
  },
];

export const daysInAWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const interventionTypes = [
  'Building Optimisation',
  'Building HVAC Tuning',
  'Building Envelope Improvement',
  'Plant Upgrade',
  'HVAC Deoperationalisation',
  'Lighting & Power Deoperationalisation',
  'Lighting Occupancy Sensors',
  'LED Upgrade',
];

export const SEVERITY_LOW = 'LOW';
export const SEVERITY_MEDIUM = 'MEDIUM';
export const SEVERITY_SEVERE = 'SEVERE';

export const ALARM_SEVERITY_LIST = [SEVERITY_LOW, SEVERITY_MEDIUM, SEVERITY_SEVERE];
