import { FloorSpace } from 'models/Floor';
import { AppState } from 'store';
import {
  Observation,
  ObservationType,
  SpaceUtilisationType,
  AlarmObservation,
  BomHistoryIndex,
  ObservationTag,
  HistoricPopularTimings,
  BuildingEnergyIntervention,
} from 'models/Observation';
import { Summary } from 'models/Summary';
import MazemapHighlightService from 'services/Map/MazemapHighlightService';
import { MapType } from 'components/Mazemap/MapTypes';
import { BuildingSpace, Room } from 'models/Building';
import { MapServiceType } from 'services/Map/MapService';
import { CampusSpace } from 'models/Campus';
import { RoomSpace } from 'models/Room';
import MazemapDataService from 'services/Map/MazemapDataService';
import {
  abnormalAlarmSummaryFilter,
  severeAlarmSummaryFilter,
  mediumAlarmSummaryFilter,
  lowAlarmSummaryFilter,
} from 'utils/filters';
import {
  pathOr,
  find,
  curry,
  filter,
  forEachObjIndexed,
  includes,
  isNil,
  complement,
  head,
  sortBy,
  forEach,
  path,
} from 'ramda';
import { isGroupedSpaceComfortObservation } from 'components/Observation/SpaceComfortPredicates';
import {
  isEnergyObservationType,
  isGroupedEnergyObservation,
  isWhConsumptionOrGenerationObservation,
} from 'components/Observation/EnergyPredicates';
import { Feature } from 'geojson';
import momentTz from 'moment-timezone';
import { COVID_SAFE_TRAINING_STATES, daysInAWeek } from 'components/constants';
import { SpaceUtilisationObservationTags } from 'types';
import { DisplayTimeZone } from 'components/common/DateTime';

const filterEnergyObservations = (observations: Observation[]): Observation[] =>
  filter(isGroupedEnergyObservation)(observations);

// Campus
export const selectCampusSpaceDetails = (state: AppState): CampusSpace => state.details.campusDetails.spaceDetails;
export const selectCampusObservationsLatest = (state: AppState): Observation[] =>
  state.details.campusDetails.observations.latest;
export const selectEnergyCampusObservationLatest = (state: AppState): Observation[] =>
  filter(isEnergyObservationType, selectCampusObservationsLatest(state));
export const selectCampusBuildingsSummary = (state: AppState): Summary[] =>
  state.details.campusDetails.buildingsSummary;
export const selectCampusSpaceDetailsRequestStatus = (state: AppState): { status: string; message: string } =>
  state.details.campusSpaceDetailsRequestStatus;
export const selectObservationsFailed = (state: AppState): string | undefined => state.details.error;
const selectCampusBomObservationsHistory = (
  historyIndex: string,
  observationTag: string,
  state: AppState,
): Observation[] =>
  pathOr([], ['details', 'campusDetails', 'historicalObservations', historyIndex, observationTag], state);
export const selectBomObservationsHistory = (
  historyIndex: string,
  observationTag: string,
): ((state: AppState) => Observation[]) => curry(selectCampusBomObservationsHistory)(historyIndex, observationTag);

// CovidSafe selectors
export const selectCovidSafeLatestObservation = (state: AppState): Observation[] =>
  filter((item) => item.observationType === ObservationType.covidSafe, selectCampusObservationsLatest(state)) || [];
/*
export const selectCovidSafeHealthDecObservation = (
  state: AppState,
): Observation | undefined => {
  const observations = filter(
    item =>
      item.observationTag ===
      `${ObservationTag.covidSafeHealthDeclaration}#${HEALTH_DECLARATION_STATES.COMPLETED}`,
    selectCovidSafeLatestObservation(state),
  )
  return !isNil(observations) ? head(observations) : undefined
}

export const selectCovidSafeCompletionObservation = (
  state: AppState,
): Observation | undefined => {
  const observations = filter(
    item =>
      item.observationTag ===
      `${ObservationTag.covidSafeTraining}#${COVID_SAFE_TRAINING_STATES.COMPLETED}`,
    selectCovidSafeLatestObservation(state),
  )
  return !isNil(observations) ? head(observations) : undefined
}

export const selectCovidSafeOverallStatusObservation = (
  state: AppState,
): Observation | undefined => {
  const observations = filter(
    item =>
      item.observationTag === `${ObservationTag.covidOverallComplianceStatus}#OVERALL`,
    selectCovidSafeLatestObservation(state),
  )
  return !isNil(observations) ? head(observations) : undefined
}

export const selectEnrolmentsCovidSafeObservations = (
  state: AppState,
): Observation | undefined => {
  const observations = filter(
    item => item.observationTag === `${ObservationTag.covidSafeTraining}#Enrolments`,
    selectCovidSafeLatestObservation(state),
  )
  return !isNil(observations) ? head(observations) : undefined
}

export const selectCovidSafePendingObservations = (
  state: AppState,
): Observation[] | undefined =>
  filter(
    item =>
      item.observationTag ===
        `${ObservationTag.covidSafeTraining}#${COVID_SAFE_TRAINING_STATES.IN_PROGRESS}` ||
      item.observationTag ===
        `${ObservationTag.covidSafeTraining}#${COVID_SAFE_TRAINING_STATES.NOT_YET_STARTED}`,
    selectCovidSafeLatestObservation(state),
  ) || undefined
 */
/* export const selectReturnToCampusResearchObservations = (
  state: AppState,
): Observation | undefined => {
  const observation = filter(
    item =>
      item.observationTag ===
      `${ObservationTag.covidSafeReturnToCampusResearch}#${COVID_SAFE_TRAINING_STATES.COMPLETED}`,
    selectCovidSafeLatestObservation(state),
  )
  return !isNil(observation) ? head(observation) : undefined
} */

/* export const selectReturnToCampusResearchComplianceStatusObservations = (
  state: AppState,
): Observation | undefined => {
  const observation = filter(
    item =>
      item.observationTag ===
      `${ObservationTag.covidOverallComplianceStatus}#${ObservationTag.covidSafeReturnToCampusResearch}`,
    selectCovidSafeLatestObservation(state),
  )
  return !isNil(observation) ? head(observation) : undefined
} */

//CAMPUS forecast
export const createDateMinMaxTempMapping = (forecastTemperatureObs: any): any => {
  // const daysInAWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
  const dateMinMaxTempMapping: any[] = [];
  forEach((obs: any) => {
    let min = '';
    let max = '';
    const date = obs.forecastStartTime.split('T')[0];
    forEach((observation: any) => {
      if (observation.forecastStartTime.split('T')[0] === obs.forecastStartTime.split('T')[0]) {
        if (observation.observationTag === ObservationTag.meanMINAirTemperature) {
          min = String(observation.valueHat);
        }
        if (observation.observationTag === ObservationTag.meanMAXAirTemperature) {
          max = String(observation.valueHat);
        }
      }
    }, forecastTemperatureObs);
    if (isNil(find((mapping) => mapping.date === date, dateMinMaxTempMapping))) {
      dateMinMaxTempMapping.push({
        date,
        observationDate: momentTz(date).tz(DisplayTimeZone).format('DD/MM'),
        observationDay: daysInAWeek[momentTz(date).tz(DisplayTimeZone).weekday()],
        min,
        max,
      });
    }
  }, forecastTemperatureObs);
  return dateMinMaxTempMapping;
};

export const selectNext5daysTempForecast = (state: AppState): any => {
  let forecastTemperatureObs = state.details.campusDetails.temperatureForecastObservations.forecasts;
  forecastTemperatureObs = sortBy((obs: any) => obs.forecastStartTime, forecastTemperatureObs);
  const dateMinMaxTempMapping: any[] = createDateMinMaxTempMapping(forecastTemperatureObs);
  return dateMinMaxTempMapping;
};

export const selectCampusEnergyForecastObservations = (state: AppState): any =>
  state.details.campusDetails.energyForecastObservations.forecasts;

export const selectCampusTotalEnergyHistoryObservations = (state: AppState): any =>
  pathOr([], ['TotalEnergyHistory', 'Energy'], state.details.campusDetails.energyHistoryObservations);
export const selectCampusEnergyObservations = (state: AppState): Observation[] =>
  filterEnergyObservations(state.details.campusDetails.observations.latest);

/* export const selectCampusTotalWHConsumptionHistory = (state: AppState): any =>
  filter((obs: Observation): boolean => {
    return obs.observationTag === ObservationTag.totalWhConsumption.concat('#CLARITI')
  }, pathOr([], ['Energy', 'Energy'], state.details.campusDetails.historicalObservations))
 */
// Building
export const selectBuildingTotalWHConsumptionHistory = (state: AppState): any =>
  filter((obs: Observation): boolean => {
    return obs.observationTag === ObservationTag.totalWhConsumption.concat('#CLARITI');
  }, pathOr([], ['Energy', 'Energy'], state.details.buildingDetails.monthlyHistoricalObservations));

export const selectBuildingEnergyForecastObservations = (state: AppState): any =>
  state.details.buildingDetails.energyForecastObservations.forecasts;

export const selectBuildingSpaceDetails = (state: AppState): BuildingSpace =>
  state.details.buildingDetails.spaceDetails;
export const selectBuildingAlarms = (state: AppState): AlarmObservation[] =>
  state.details.buildingDetails.observations.alarms;
export const selectBuildingObservationsLatest = (state: AppState): Observation[] =>
  state.details.buildingDetails.observations.latest;
export const selectBuildingAbnormalFloorsSummary = (state: AppState): Summary[] =>
  state.details.buildingDetails.floorsSummary.filter(abnormalAlarmSummaryFilter);
export const selectBuildingSpaceDetailsRequestStatus = (state: AppState): { status: string; message: string } =>
  state.details.buildingSpaceDetailsRequestStatus;

const selectBuildingHeadCountObservations = (state: AppState): Observation[] =>
  state.details.buildingDetails.observations.latest.filter(
    (observation: Observation): boolean =>
      observation.observationType === ObservationType.spaceUtilisation &&
      observation.observationTag.startsWith('HeadCount#'),
  );
export const selectBuildingHeadCountActivityHistoryObservations = (appState: AppState): Observation[] =>
  pathOr(
    [],
    ['details', 'buildingDetails', 'historicalObservations', 'SpaceUtilisation', 'SpaceUtilisation'],
    appState,
  );
const selectBuildingCapacity = (state: AppState): number | undefined =>
  state.details.buildingDetails.spaceDetails.capacity;
export const selectBuildingSpaceUtilisation = (state: AppState): SpaceUtilisationType => {
  const headCountObservations = selectBuildingHeadCountObservations(state);
  const capacity = selectBuildingCapacity(state) || -1;
  return { capacity, observations: headCountObservations };
};
export const selectReturnToCampusBuildingObservations = (state: AppState): Observation | undefined => {
  const observation = filter(
    (item) =>
      item.observationTag ===
      `${ObservationTag.covidSafeReturnToCampusResearch}#${COVID_SAFE_TRAINING_STATES.COMPLETED}`,
    selectBuildingObservationsLatest(state),
  );
  return !isNil(observation) ? head(observation) : undefined;
};
// start SCIOT-940
const filterAccessCardObservation = (latestObservations: Observation[]): Observation[] => {
  return filter((observation) => {
    return (
      observation.observationType === 'SpaceUtilisation' &&
      observation.observationTag === SpaceUtilisationObservationTags.ACCESS_CARD_HEADCOUNT
    );
  }, latestObservations);
};

export const selectCampusAccessCardObservation = (state: AppState): SpaceUtilisationType | null => {
  const capacity = 0;
  const observations = filterAccessCardObservation(state.details.campusDetails.observations.latest);
  return { capacity, observations };
};
// end SCIOT 940

export const selectBuildingEnergyObservations = (state: AppState): Observation[] =>
  filterEnergyObservations(state.details.buildingDetails.observations.latest);

const buildingObservationsHistoryIndexSelector = (
  observationsHistoryIndex: string,
  state: AppState,
): { [index: string]: Observation[] } =>
  pathOr({}, ['details', 'buildingDetails', 'historicalObservations', observationsHistoryIndex], state);
export const selectBuildingEnergyObservationsHistory =
  (observationsHistoryIndex: string): ((state: AppState) => { [index: string]: Observation[] }) =>
  (state: AppState): { [index: string]: Observation[] } => {
    const allObservationHistory = curry(buildingObservationsHistoryIndexSelector)(observationsHistoryIndex)(state);

    const whConsumptionAndGenerationHistory: { [index: string]: Observation[] } = {};
    forEachObjIndexed<{ [index: string]: Observation[] }>((observations: Observation[], key: string | number): void => {
      whConsumptionAndGenerationHistory[key] = filter(isWhConsumptionOrGenerationObservation, observations);
    }, allObservationHistory);
    return whConsumptionAndGenerationHistory;
  };

export const selectBuildingPopularTimings = (state: AppState): HistoricPopularTimings[] | undefined =>
  !isNil(state.details.buildingDetails.popularTimings)
    ? state.details.buildingDetails.popularTimings.historicPopularTimings
    : undefined;
export const selectBuildingPopularTimingsType = (state: AppState): string | undefined =>
  !isNil(state.details.buildingDetails.popularTimings)
    ? state.details.buildingDetails.popularTimings.academicCalendarActivity
    : undefined;
export const selectBuildingPopularTimingsLiveIndex = (state: AppState): string | undefined =>
  !isNil(state.details.buildingDetails.popularTimings)
    ? state.details.buildingDetails.popularTimings.liveBusyIndex
    : undefined;
export const selectBuildingPopularTimingsLiveDescription = (state: AppState): string | undefined =>
  !isNil(state.details.buildingDetails.popularTimings)
    ? state.details.buildingDetails.popularTimings.liveBusyIndexDescription
    : undefined;
export const selectPopularTimingsHistoricalDescripton = (state: AppState): string | undefined =>
  !isNil(state.details.buildingDetails.popularTimings)
    ? state.details.buildingDetails.popularTimings.historicalBusyIndexDescription
    : undefined;
export const selectBuildingQuarterlyHistoricalObservations = (state: AppState): any =>
  pathOr([], ['Energy', 'Energy'], state.details.buildingDetails.quarterlyHistoricalObservations);
export const selectBuildingQuarterlyBaselineObservations = (state: AppState): any =>
  pathOr([], ['Energy', 'Energy'], state.details.buildingDetails.quarterlyBaselineObservations);
export const selectBuildingCostTagObservations = (state: AppState): any =>
  path(['Energy', 'Cost'], state.details.buildingDetails.halfYearlyCostTagsObservations);

// Floor
export const selectFloorSpaceDetails = (state: AppState): FloorSpace => state.details.floorDetails.spaceDetails;
export const selectFloorAlarms = (state: AppState): AlarmObservation[] =>
  state.details.floorDetails.observations.alarms;
export const selectFloorObservationsLatest = (state: AppState): Observation[] =>
  state.details.floorDetails.observations.latest;
export const selectFloorAbnormalRoomsSummary = (state: AppState): Summary[] =>
  state.details.floorDetails.roomsSummary.filter(abnormalAlarmSummaryFilter);
export const selectFloorSevereAlarmRoomsSummary = (state: AppState): Summary[] =>
  state.details.floorDetails.roomsSummary.filter(severeAlarmSummaryFilter);
export const selectFloorMediumAlarmRoomsSummary = (state: AppState): Summary[] =>
  state.details.floorDetails.roomsSummary.filter(mediumAlarmSummaryFilter);
export const selectFloorLowAlarmRoomsSummary = (state: AppState): Summary[] =>
  state.details.floorDetails.roomsSummary.filter(lowAlarmSummaryFilter);
export const selectFloorSpaceDetailsRequestStatus = (state: AppState): { status: string; message: string } =>
  state.details.floorSpaceDetailsRequestStatus;

const selectFloorHeadCountObservation = (state: AppState): Observation[] =>
  state.details.floorDetails.observations.latest.filter(
    (observation: Observation): boolean =>
      observation.observationType === ObservationType.spaceUtilisation &&
      observation.observationTag.startsWith('HeadCount#'),
  );
const selectFloorCapacity = (state: AppState): number | undefined => state.details.floorDetails.spaceDetails.capacity;
export const selectFloorSpaceUtilisation = (state: AppState): SpaceUtilisationType => {
  const headCountObservations = selectFloorHeadCountObservation(state);
  const capacity = selectFloorCapacity(state) || -1;
  return { capacity, observations: headCountObservations };
};
export const selectFloorHeadCountActivityHistoryObservations = (appState: AppState): Observation[] =>
  pathOr([], ['details', 'floorDetails', 'historicalObservations', 'SpaceUtilisation', 'SpaceUtilisation'], appState);

// Room
export const selectRoomSpaceDetails = (state: AppState): RoomSpace => state.details.roomDetails.spaceDetails;
export const selectRoomAlarms = (state: AppState): AlarmObservation[] => state.details.roomDetails.observations.alarms;

/* const getLatestSpaceComfortObservations = (observation: Observation): boolean => {
  const currentTime = moment.tz(moment(), DisplayTimeZone)
  const obsTime = moment.tz(observation.observationEndTime, DisplayTimeZone)
  return true // currentTime.diff(obsTime, 'minutes') <= BMS_ALARMS_OBSERVATION_THRESHOLD_IN_MINS
} */

export const selectRoomObservationsLatest = (state: AppState): Observation[] =>
  state.details.roomDetails.observations.latest;
export const selectRoomSpaceComfortObservations = (state: AppState): Observation[] =>
  filter(isGroupedSpaceComfortObservation)(pathOr([], ['details', 'roomDetails', 'observations', 'latest'], state));

export const roomObservationsHistoryIndexSelector = (
  observationsHistoryIndex: string,
  state: AppState,
): { [index: string]: Observation[] } =>
  pathOr({}, ['details', 'roomDetails', 'historicalObservations', observationsHistoryIndex], state);
export const selectRoomObservationsHistory = (
  observationsHistoryIndex: string,
): ((state: AppState) => { [index: string]: Observation[] }) =>
  curry(roomObservationsHistoryIndexSelector)(observationsHistoryIndex);

export const selectRoomObservationsHistoryWithBomTemperature =
  (observationHistoryIndex: string): ((state: AppState) => { [index: string]: Observation[] }) =>
  (state: AppState): { [index: string]: Observation[] } => {
    const roomObservations = selectRoomObservationsHistory(observationHistoryIndex)(state);
    const bomObservations = selectBomObservationsHistory(BomHistoryIndex, ObservationType.temperature)(state);
    return {
      ...roomObservations,
      BOM: bomObservations,
    };
  };
export const selectRoomObservationsHistoryWithBomHumidity =
  (observationHistoryIndex: string): ((state: AppState) => { [index: string]: Observation[] }) =>
  (state: AppState): { [index: string]: Observation[] } => {
    const roomObservations = selectRoomObservationsHistory(observationHistoryIndex)(state);
    const bomObservations = selectBomObservationsHistory(BomHistoryIndex, ObservationType.humidity)(state);
    return {
      ...roomObservations,
      BOM: bomObservations,
    };
  };

export const selectRoomSpaceDetailsRequestStatus = (state: AppState): { status: string; message: string } =>
  state.details.roomSpaceDetailsRequestStatus;
const selectRoomHeadCountObservation = (state: AppState): Observation[] =>
  state.details.roomDetails.observations.latest.filter(
    (observation: Observation): boolean =>
      observation.observationType === ObservationType.spaceUtilisation &&
      observation.observationTag.startsWith('HeadCount#'),
  );
const selectRoomCapacity = (state: AppState): number | undefined => state.details.roomDetails.spaceDetails.capacity;
export const selectRoomSpaceUtilisation = (state: AppState): SpaceUtilisationType => {
  const headCountObservations = selectRoomHeadCountObservation(state);
  const capacity = selectRoomCapacity(state) || -1;
  return { capacity, observations: headCountObservations };
};
export const selectRoomHeadCountActivityHistoryObservations = (appState: AppState): Observation[] =>
  pathOr([], ['details', 'roomDetails', 'historicalObservations', 'SpaceUtilisation', 'SpaceUtilisation'], appState);

export const selectMapService = (state: AppState): MapServiceType => state.map.mapService;
export const selectMap = (state: AppState): MapType => state.map.mapService.getMap();
export const selectMapHighlightService = (state: AppState): MazemapHighlightService =>
  state.map.mapService.getHighlightService();
export const selectMapDataService = (state: AppState): MazemapDataService => state.map.mapService.getDataService();
export const selectMapSearchResult = (state: AppState): Feature | null => state.map.searchResult;

export const selectAuthenticatedStatus = (state: AppState): boolean => state.auth.isAuthenticated;
export const selectAuthAccessToken = (state: AppState): any => state.auth.accessToken;
export const selectAuthIDToken = (state: AppState): any => state.auth.idToken;
export const selectAuthUserName = (state: AppState): string | null => pathOr(null, ['auth', 'idToken', 'name'], state);

// user
export const selectUserName = (state: AppState): string => state.user.name;

export const selectUserInitials = (state: AppState): string => state.user.initials;

export const selectUserGroups = (state: AppState): string[] => state.user.groups;

// ui settings
export const selectRightDrawerIsOpen = (state: AppState): boolean => state.UISettings.rightDrawer.isOpen;

export const selectIsDrawerOpen = (state: AppState): boolean => state.UISettings.energyInterventionDrawer.isDrawerOpen;
export const selectIsNotesOpen = (state: AppState): boolean => state.UISettings.energyInterventionDrawer.isNotesOpen;
export const selectIsUpdateNotesOpen = (state: AppState): boolean =>
  state.UISettings.energyInterventionDrawer.isUpdateNotesOpen;
export const selectInterventionPostStatus = (state: AppState): string =>
  state.details.buildingEnergyInterventionPostStatus;
export const selectInterventionDeleteStatus = (state: AppState): string =>
  state.details.buildingEnergyInterventionDeleteStatus;
export const selectBuildingEnergyInterventions = (state: AppState): BuildingEnergyIntervention[] =>
  state.details.buildingDetails.energyInterventions;
// visibility
export const selectIsVisibleAlerts = (state: AppState): boolean => state.UISettings.visibility.isVisibleAlerts;

export const selectIsVisibleEnergy = (state: AppState): boolean => state.UISettings.visibility.isVisibleEnergy;

export const selectIsVisibleSpaceComfort = (state: AppState): boolean =>
  state.UISettings.visibility.isVisibleSpaceComfort;

export const selectIsVisibleSpaceUtilisation = (state: AppState): boolean =>
  state.UISettings.visibility.isVisibleSpaceUtilisation;

export const selectSpaceUtilisationHeatMapSelection = (state: AppState): string =>
  state.UISettings.visibility.spaceUtilisationHeatMapSelection;

/* const getBuildingStatusCounts = (state: AppState): BuildingCountByStatus[] => {
  let output: BuildingCountByStatus[] = []
  const buildings: string[] | undefined = prop(
    'buildings',
    state.details.campusDetails.spaceDetails,
  )

  if (!isNil(buildings) && !isEmpty(buildings)) {
    const getBuildingNumber = (building: string): string =>
      slice(4, building.length, building).toString()

    const buildingNumbers: string[] = map(
      getBuildingNumber,
      prop('buildings', state.details.campusDetails.spaceDetails),
    )
    const statusByCampus: object[] = map((buildingNumber: string): any => {
      const statusRecord = find(
        (rec: any) => buildingNumber === String(rec.buildingNumber),
      )(buildingStatus.status)
      return isNil(statusRecord)
        ? {
            buildingNumber: `${buildingNumber}`,
            statusDescription: 'Closed',
            statusCode: 'CLOSED',
            expectedStartDate: '',
            effectiveEndDate: '',
          }
        : statusRecord
    }, buildingNumbers)

    const countByBuildingStatusFunc = groupBy((status: any): string =>
      prop('statusDescription', status),
    )
    const countByBuildingStatus = countByBuildingStatusFunc(statusByCampus)
    const arrKeys: (string | number)[] = keys(countByBuildingStatus)
    output = map((key: string | number): BuildingCountByStatus => {
      return {
        statusDescription: key.toString(),
        count: length(countByBuildingStatus[key]),
      }
    }, arrKeys)
  }

  return output
}

export const selectBuildingStatusByCampus = (
  state: AppState,
): BuildingCountByStatus[] | null => {
  return getBuildingStatusCounts(state)
} */
