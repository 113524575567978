import React, { ReactElement, useState, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Collapse, Typography, Box } from '@material-ui/core';
import Chevron from './Chevron';
import { UOM_LIGHT_BLUE, UOM_LIGHT_GRAY, UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';

const useStyles = makeStyles({
  detail: {
    backgroundColor: UOM_LIGHT_GRAY,
    color: UOM_LIGHT_BLUE,
  },
});

interface DetailCardProps {
  title: string;
  children?: ReactElement | ReactElement[];
}

const DetailCard = (props: DetailCardProps): ReactElement => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(true);

  const handleDetailsClick = (): void => {
    setShowDetails(!showDetails);
  };
  return (
    <Box className={classes.detail}>
      <DetailCardHeader
        title={props.title}
        clickHandler={handleDetailsClick}
        show={showDetails}
        backgroundColor={UOM_SUPPORTING_COLOURS.grey.dark}
      />
      {showDetails ? null : <Divider />}
      <Collapse in={showDetails}>{props.children}</Collapse>
    </Box>
  );
};

const useCardHeaderStyles = makeStyles({
  header: {
    display: 'flex',
    backgroundColor: (props: DetailCardHeaderProps): string => props.backgroundColor,
  },
  title: {
    padding: '5px',
    fontWeight: 'bold',
    fontSize: 'large',
    flexGrow: 1,
  },
});

interface DetailCardHeaderProps {
  clickHandler: (event: MouseEvent<HTMLDivElement>) => void;
  show: boolean;
  title: string;
  backgroundColor: string;
}

const DetailCardHeader = (props: DetailCardHeaderProps): ReactElement => {
  const classes = useCardHeaderStyles(props);
  return (
    <div role="button" onClick={props.clickHandler} className={classes.header}>
      <Typography className={classes.title} variant="subtitle2" color="secondary">
        {props.title}
      </Typography>
      <Chevron direction={props.show ? 'up' : 'down'} />
    </div>
  );
};

export default DetailCard;
