import React, { ReactElement } from 'react';
import { FaCheckCircle, FaQuestionCircle, FaTimesCircle } from 'react-icons/fa';

const green = '#00cc00';
const yellow = '#ffd300';
const red = '#ff0000';

export const GreenTick = (): ReactElement => <FaCheckCircle color={green} />;
export const YellowQuestionMark = (): ReactElement => <FaQuestionCircle color={yellow} />;
export const RedCross = (): ReactElement => <FaTimesCircle color={red} />;

export type IconTypes =
  | 'address'
  | 'area'
  | 'capacity'
  | 'co2'
  | 'cost-center'
  | 'humidity'
  | 'light-bulb'
  | 'line-chart'
  | 'notification'
  | 'temperature'
  | 'utilization'
  | 'building-quality'
  | 'head-count'
  | 'energy'
  | 'solar-energy'
  | 'filter'
  | 'pie-chart'
  | 'keep-distance'
  | 'building-shutdown'
  | 'health-trainme'
  | 'access-control'
  | 'cloudy'
  | 'thermometer'
  | 'energy-costs'
  | 'ventilate'
  | 'enterprise'
  | 'bar-chart'
  | 'info'
  | 'notes'
  | 'calendar'
  | 'plus-sign'
  | 'update-intervention'
  | 'delete';

export enum IconSizes {
  large = 50,
  medium = 35,
  small = 24,
  extraSmall = 16,
}

interface SVGIconProps {
  type: IconTypes;
  size: number;
  alttext: string;
}

interface IconTypeHash {
  [index: string]: IconTypes;
}

export const ObservationTypeToIconType: IconTypeHash = {
  Temperature: 'temperature',
  TemperatureSetPoint: 'temperature',
  TemperatureAlarm: 'temperature', // field was renamed, here for backwards compatibility
  AlarmTemperature: 'temperature',
  WeatherTemperature: 'cloudy',
  Humidity: 'humidity',
  HumidityAlarm: 'humidity', // field was renamed, here for backwards compatibility
  AlarmHumidity: 'humidity',
  SpaceUtilisation: 'head-count',
  CO2: 'co2',
  AlarmCO2: 'co2',
  Energy: 'energy',
  Solar: 'solar-energy',
  CovidReport: 'health-trainme',
  AccessControl: 'access-control',
  EnergyCosts: 'energy-costs',
  Ventilate: 'ventilate',
  BuildingOccupancy: 'enterprise',
  BusyIndex: 'bar-chart',
  Information: 'info',
  Notes: 'notes',
  Calendar: 'calendar',
  CreateNote: 'plus-sign',
  UpdateIntervention: 'update-intervention',
  Delete: 'delete',
};

export const getIconTypeFromObservationType = (type: string): IconTypes => {
  return ObservationTypeToIconType[type] as IconTypes;
};

export const SVGIcon = ({ type, size, alttext }: SVGIconProps): ReactElement => {
  return (
    <img
      height={`${size}px`}
      alt={`${alttext}`}
      title={`${alttext}`}
      src={`${process.env.PUBLIC_URL}/icons/${type}.svg`}
    />
  );
};
