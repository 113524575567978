import { propOr } from 'ramda';
import { Space } from './Space';

export type BuildingSpace = {
  floors?: string[];
  buildingQuality?: string;
  buildingType?: string;
  functionalityType?: string;
  ownershipType?: string;
  statusType?: string;
  floorsCount?: number;
  rooms?: Room[];
} & Space;

export interface Location {
  locationCode: string;
}

export interface Room {
  locationCode: string;
  roomCategory: string;
  areaSize: number;
  capacity: number;
}

// BuildingPoiResponse
interface Geometry {
  coordinates: [];
  type: string;
}

interface MazemapFloor {
  accessLevel: number;
  buildingId: number;
  campusId: number;
  flags: [];
  floorOutlineId: 888327;
  floorReferences: [];
  id: number;
  moh: any | null;
  name: string;
  viewId: any | null;
  z: number;
}

interface PoiProperties {
  name: string;
  campusId: number;
  floors: MazemapFloor[];
  id: number;
}

export interface BuildingPoiResponse {
  geometry: Geometry;
  properties: PoiProperties;
  type: string;
}

export const defaultUnknownValue = '—';

export const getBuildingSpaceProperty = (name: string, buildingSpace: BuildingSpace | undefined): string =>
  propOr(defaultUnknownValue, name, buildingSpace);
