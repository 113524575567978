import React, { Fragment, ReactElement, useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HightchartsExporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import { Observation } from 'models/Observation';
import { map, equals, groupBy, compose, keys, flatten, values } from 'ramda';
import moment from 'moment-timezone';
import { DisplayTimeZone } from '../common/DateTime';

// apply Highcharts exporting module to enable navigation button for full screen mode and export to image
HightchartsExporting(Highcharts);

const defaultOptions: Highcharts.Options = {
  title: {
    text: 'My chart',
  },
  time: {
    useUTC: false,
  },
  chart: {
    zoomType: 'xy',
  },
  navigation: {
    buttonOptions: {
      enabled: true,
    },
  },
  xAxis: {
    type: 'datetime',
  },
  yAxis: {},
  legend: {
    maxHeight: 100,
  },
  series: [],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 550,
        },
        chartOptions: {
          chart: {
            height: 380,
          },
        },
      },
    ],
  },
};

const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect((): void => {
    ref.current = value;
  });
  return ref.current;
};

interface ObservationHistoryProps {
  title: string;
  unitOfMeasure: string;
  observations: { [index: string]: Observation[] };
  seriesNameMapping?: { [index: string]: string };
}

const ObservationHistory = (props: ObservationHistoryProps): ReactElement => {
  const { title, unitOfMeasure, observations, seriesNameMapping } = props;

  const [options, setOptions] = useState(defaultOptions);
  const previousOptions = usePrevious(options);

  useEffect((): void => {
    const groupedObservations = compose<
      Observation[],
      { [index: string]: Observation[] },
      { [index: string]: { [index: string]: Observation[] } }
    >(
      map<{ [index: string]: Observation[] }, { [index: string]: { [index: string]: Observation[] } }>(
        groupBy((observation: Observation): string => observation.observationTag),
      ),
      groupBy((observation: Observation): string => observation.observationType),
    )(compose<{ [index: string]: Observation[] }, Observation[][], Observation[]>(flatten, values)(observations));

    const mapToDataSeries = (observations: { [index: string]: Observation[] }): Highcharts.SeriesSplineOptions[] =>
      map(
        (observationTag: string): Highcharts.SeriesSplineOptions => ({
          type: 'spline',
          name:
            seriesNameMapping && seriesNameMapping[observationTag] ? seriesNameMapping[observationTag] : observationTag,
          data: map(
            (observation: Observation): [number, number] => [
              moment.tz(observation.observationEndTime, DisplayTimeZone).valueOf(),
              Number.parseFloat(observation.value),
            ],
            observations[observationTag],
          ),
        }),
        Object.keys(observations ? observations : {}),
      );

    const observationTypes = keys(groupedObservations);
    const groupedDataSeries: Highcharts.SeriesSplineOptions[][] = map(
      (observationType: string | number): Highcharts.SeriesSplineOptions[] =>
        mapToDataSeries(groupedObservations[observationType]),
    )(observationTypes);

    const dataSeries: Highcharts.SeriesSplineOptions[] = flatten(groupedDataSeries);

    const newOptions = {
      ...previousOptions,
      title: { text: title },
      yAxis: {
        title: {
          text: unitOfMeasure,
        },
      },
      series: [...dataSeries],
    };
    if (!equals(newOptions, previousOptions)) {
      setOptions(newOptions);
    }
  }, [observations, options, previousOptions, title, unitOfMeasure, seriesNameMapping]);

  return (
    <Fragment>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Fragment>
  );
};

export default ObservationHistory;
