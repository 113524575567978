import React, { ReactElement } from 'react';
import { UOM_SUPPORTING_COLOURS, UOM_WHITE, UOM_LIGHT_BLUE } from 'styles/styleConstants';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  button: {
    color: '#494949',
    textDecoration: 'none',
    background: UOM_SUPPORTING_COLOURS.grey.light,
    padding: '10px',
    border: '1px solid #494949',
    transition: 'all 0.1s ease 0s',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    userSelect: 'none',
    '&:hover': {
      background: UOM_SUPPORTING_COLOURS.blue.light,
      borderColor: UOM_LIGHT_BLUE,
      transition: 'all 0.2s ease 0s',
      cursor: 'pointer',
    },
    '&:active': {
      color: UOM_WHITE,
      boxShadow: 'inset 0 0 3px #000000',
      backgroundColor: UOM_SUPPORTING_COLOURS.blue.dark,
    },
  },
});

interface ButtonProps {
  label: string;
  clickHandler: () => void;
}

const Button = ({ label, clickHandler }: ButtonProps): ReactElement => {
  const classes = useStyles();
  return (
    <div role="button" className={classes.button} onClick={clickHandler}>
      <Typography>{label}</Typography>
    </div>
  );
};

export default Button;
