import { Space } from 'models/Space';
import { Summary } from 'models/Summary';
import {
  BuildingEnergyIntervention,
  BuildingEnergyInterventionPOSTResponse,
  BuildingPopularTimingsResponse,
  ForecastApiResponse,
  ObservationsApiResponse,
  ObservationsHistoryApiResponse,
} from 'models/Observation';

// Room

export const ROOM_OBSERVATIONS_REQUESTED = 'ROOM_OBSERVATIONS_REQUESTED';
export const ROOM_OBSERVATIONS_RECEIVED = 'ROOM_OBSERVATIONS_RECEIVED';
export const ROOM_OBSERVATIONS_FAILED = 'ROOM_OBSERVATIONS_FAILED';

interface RoomObservationsRequestedAction {
  type: typeof ROOM_OBSERVATIONS_REQUESTED;
}

interface RoomObservationsReceivedAction {
  type: typeof ROOM_OBSERVATIONS_RECEIVED;
  payload: ObservationsApiResponse;
}

interface RoomObservationsFailedAction {
  type: typeof ROOM_OBSERVATIONS_FAILED;
  payload: Error;
}

export type RoomObservationsActionTypes =
  | RoomObservationsRequestedAction
  | RoomObservationsReceivedAction
  | RoomObservationsFailedAction;

export const ROOM_OBSERVATIONS_HISTORY_REQUESTED = 'ROOM_OBSERVATIONS_HISTORY_REQUESTED';
export const ROOM_OBSERVATIONS_HISTORY_RECEIVED = 'ROOM_OBSERVATIONS_HISTORY_RECEIVED';
export const ROOM_OBSERVATIONS_HISTORY_FAILED = 'ROOM_OBSERVATIONS_HISTORY_FAILED';

interface RoomObservationsHistoryRequestedAction {
  type: typeof ROOM_OBSERVATIONS_HISTORY_REQUESTED;
}

interface RoomHistoricalObservationsReceivedAction {
  type: typeof ROOM_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface RoomHistoricalObservationsFailedAction {
  type: typeof ROOM_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type RoomHistoricalObservationsActionTypes =
  | RoomObservationsHistoryRequestedAction
  | RoomHistoricalObservationsReceivedAction
  | RoomHistoricalObservationsFailedAction;

export const ROOM_SPACE_DETAILS_REQUESTED = 'ROOM_SPACE_DETAILS_REQUESTED';
export const ROOM_SPACE_DETAILS_RECEIVED = 'ROOM_SPACE_DETAILS_RECEIVED';
export const ROOM_SPACE_DETAILS_FAILED = 'ROOM_SPACE_DETAILS_FAILED';

interface RoomSpaceDetailsRequestedAction {
  type: typeof ROOM_SPACE_DETAILS_REQUESTED;
}

interface RoomSpaceDetailsReceivedAction {
  type: typeof ROOM_SPACE_DETAILS_RECEIVED;
  payload: Space;
}

interface RoomSpaceDetailsFailedAction {
  type: typeof ROOM_SPACE_DETAILS_FAILED;
  payload: Error;
}

export type RoomSpaceDetailsActionTypes =
  | RoomSpaceDetailsRequestedAction
  | RoomSpaceDetailsReceivedAction
  | RoomSpaceDetailsFailedAction;

export const ROOMS_SUMMARY_REQUESTED = 'ROOMS_SUMMARY_REQUESTED';
export const ROOMS_SUMMARY_RECEIVED = 'ROOMS_SUMMARY_RECEIVED';
export const ROOMS_SUMMARY_FAILED = 'ROOMS_SUMMARY_FAILED';

interface RoomsSummaryRequestedAction {
  type: typeof ROOMS_SUMMARY_REQUESTED;
}

interface RoomsSummaryReceivedAction {
  type: typeof ROOMS_SUMMARY_RECEIVED;
  payload: Summary[];
}

interface RoomsSummaryFailedAction {
  type: typeof ROOMS_SUMMARY_FAILED;
}

export type RoomsSummaryActionsTypes =
  | RoomsSummaryRequestedAction
  | RoomsSummaryReceivedAction
  | RoomsSummaryFailedAction;

// Floor

export const FLOOR_OBSERVATIONS_REQUESTED = 'FLOOR_OBSERVATIONS_REQUESTED';
export const FLOOR_OBSERVATIONS_RECEIVED = 'FLOOR_OBSERVATIONS_RECEIVED';
export const FLOOR_OBSERVATIONS_FAILED = 'FLOOR_OBSERVATIONS_FAILED';

interface FloorObservationsRequestedAction {
  type: typeof FLOOR_OBSERVATIONS_REQUESTED;
}

interface FloorObservationsReceivedAction {
  type: typeof FLOOR_OBSERVATIONS_RECEIVED;
  payload: any; // TODO: update model
}

interface FloorObservationsFailedAction {
  type: typeof FLOOR_OBSERVATIONS_FAILED;
  payload: Error;
}

export type FloorObservationsActionTypes =
  | FloorObservationsRequestedAction
  | FloorObservationsReceivedAction
  | FloorObservationsFailedAction;

export const FLOOR_OBSERVATIONS_HISTORY_REQUESTED = 'FLOOR_OBSERVATIONS_HISTORY_REQUESTED';
export const FLOOR_OBSERVATIONS_HISTORY_RECEIVED = 'FLOOR_OBSERVATIONS_HISTORY_RECEIVED';
export const FLOOR_OBSERVATIONS_HISTORY_FAILED = 'FLOOR_OBSERVATIONS_HISTORY_FAILED';

interface FloorObservationsHistoryRequestedAction {
  type: typeof FLOOR_OBSERVATIONS_HISTORY_REQUESTED;
}

interface FloorObservationsHistoryReceivedAction {
  type: typeof FLOOR_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface FloorObservationsHistoryFailedAction {
  type: typeof FLOOR_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type FloorObservationsHistoryActionTypes =
  | FloorObservationsHistoryRequestedAction
  | FloorObservationsHistoryReceivedAction
  | FloorObservationsHistoryFailedAction;

export const FLOOR_SPACE_DETAILS_REQUESTED = 'FLOOR_SPACE_DETAILS_REQUESTED';
export const FLOOR_SPACE_DETAILS_RECEIVED = 'FLOOR_SPACE_DETAILS_RECEIVED';
export const FLOOR_SPACE_DETAILS_FAILED = 'FLOOR_SPACE_DETAILS_FAILED';

interface FloorSpaceDetailsRequestedAction {
  type: typeof FLOOR_SPACE_DETAILS_REQUESTED;
}

interface FloorSpaceDetailsReceivedAction {
  type: typeof FLOOR_SPACE_DETAILS_RECEIVED;
  payload: Space;
}

interface FloorSpaceDetailsFailedAction {
  type: typeof FLOOR_SPACE_DETAILS_FAILED;
  payload: Error;
}

export type FloorSpaceDetailsActionTypes =
  | FloorSpaceDetailsRequestedAction
  | FloorSpaceDetailsReceivedAction
  | FloorSpaceDetailsFailedAction;

export const FLOORS_SUMMARY_REQUESTED = 'FLOORS_SUMMARY_REQUESTED';
export const FLOORS_SUMMARY_RECEIVED = 'FLOORS_SUMMARY_RECEIVED';
export const FLOORS_SUMMARY_FAILED = 'FLOORS_SUMMARY_FAILED';

interface FloorsSummaryRequestedAction {
  type: typeof FLOORS_SUMMARY_REQUESTED;
}

interface FloorsSummaryReceivedAction {
  type: typeof FLOORS_SUMMARY_RECEIVED;
  payload: Summary[];
}

interface FloorsSummaryFailedAction {
  type: typeof FLOORS_SUMMARY_FAILED;
}

export type FloorsSummaryActionsTypes =
  | FloorsSummaryRequestedAction
  | FloorsSummaryReceivedAction
  | FloorsSummaryFailedAction;

// Building

export const BUILDING_SPACE_DETAILS_REQUESTED = 'BUILDING_SPACE_DETAILS_REQUESTED';
export const BUILDING_SPACE_DETAILS_RECEIVED = 'BUILDING_SPACE_DETAILS_RECEIVED';
export const BUILDING_SPACE_DETAILS_FAILED = 'BUILDING_SPACE_DETAILS_FAILED';

interface BuildingSpaceDetailsRequestedAction {
  type: typeof BUILDING_SPACE_DETAILS_REQUESTED;
}

interface BuildingSpaceDetailsReceivedAction {
  type: typeof BUILDING_SPACE_DETAILS_RECEIVED;
  payload: any; // TODO: update model
}

interface BuildingSpaceDetailsFailedAction {
  type: typeof BUILDING_SPACE_DETAILS_FAILED;
  payload: Error;
}

export type BuildingSpaceDetailsActionTypes =
  | BuildingSpaceDetailsRequestedAction
  | BuildingSpaceDetailsReceivedAction
  | BuildingSpaceDetailsFailedAction;

export const BUILDING_OBSERVATIONS_REQUESTED = 'BUILDING_OBSERVATIONS_REQUESTED';
export const BUILDING_OBSERVATIONS_RECEIVED = 'BUILDING_OBSERVATIONS_RECEIVED';
export const BUILDING_OBSERVATIONS_FAILED = 'BUILDING_OBSERVATIONS_FAILED';

interface BuildingObservationsRequestedAction {
  type: typeof BUILDING_OBSERVATIONS_REQUESTED;
}

interface BuildingObservationsReceivedAction {
  type: typeof BUILDING_OBSERVATIONS_RECEIVED;
  payload: ObservationsApiResponse;
}

interface BuildingObservationsFailedAction {
  type: typeof BUILDING_OBSERVATIONS_FAILED;
  payload: Error;
}

export type BuildingObservationsActionTypes =
  | BuildingObservationsRequestedAction
  | BuildingObservationsReceivedAction
  | BuildingObservationsFailedAction;

export const BUILDING_OBSERVATIONS_HISTORY_REQUESTED = 'BUILDING_OBSERVATIONS_HISTORY_REQUESTED';
export const BUILDING_OBSERVATIONS_HISTORY_RECEIVED = 'BUILDING_OBSERVATIONS_HISTORY_RECEIVED';
export const BUILDING_OBSERVATIONS_HISTORY_FAILED = 'BUILDING_OBSERVATIONS_HISTORY_FAILED';

interface BuildingObservationsHistoryRequestedAction {
  type: typeof BUILDING_OBSERVATIONS_HISTORY_REQUESTED;
}

interface BuildingObservationsHistoryReceivedAction {
  type: typeof BUILDING_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface BuildingObservationsHistoryFailedAction {
  type: typeof BUILDING_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type BuildingObservationsHistoryActionTypes =
  | BuildingObservationsHistoryRequestedAction
  | BuildingObservationsHistoryReceivedAction
  | BuildingObservationsHistoryFailedAction;

export const BUILDING_MONTHLY_OBSERVATIONS_HISTORY_REQUESTED = 'BUILDING_MONTHLY_OBSERVATIONS_HISTORY_REQUESTED';
export const BUILDING_MONTHLY_OBSERVATIONS_HISTORY_RECEIVED = 'BUILDING_MONTHLY_OBSERVATIONS_HISTORY_RECEIVED';
export const BUILDING_MONTHLY_OBSERVATIONS_HISTORY_FAILED = 'BUILDING_MONTHLY_OBSERVATIONS_HISTORY_FAILED';

interface BuildingMonthlyObservationsHistoryRequestedAction {
  type: typeof BUILDING_MONTHLY_OBSERVATIONS_HISTORY_REQUESTED;
}

interface BuildingMonthlyObservationsHistoryReceivedAction {
  type: typeof BUILDING_MONTHLY_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface BuildingMonthlyObservationsHistoryFailedAction {
  type: typeof BUILDING_MONTHLY_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type BuildingMonthlyObservationsHistoryActionTypes =
  | BuildingMonthlyObservationsHistoryRequestedAction
  | BuildingMonthlyObservationsHistoryReceivedAction
  | BuildingMonthlyObservationsHistoryFailedAction;

// Quarterly energy observation constants
export const BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_REQUESTED = 'BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_REQUESTED';
export const BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_RECEIVED = 'BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_RECEIVED';
export const BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_FAILED = 'BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_FAILED';

interface BuildingQuarterlyObservationsHistoryRequestedAction {
  type: typeof BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_REQUESTED;
}

interface BuildingQuarterlyObservationsHistoryReceivedAction {
  type: typeof BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface BuildingQuarterlyObservationsHistoryFailedAction {
  type: typeof BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type BuildingQuarterlyObservationsHistoryActionTypes =
  | BuildingQuarterlyObservationsHistoryRequestedAction
  | BuildingQuarterlyObservationsHistoryReceivedAction
  | BuildingQuarterlyObservationsHistoryFailedAction;

// BASELINE Quarterly actions types
export const BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_REQUESTED =
  'BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_REQUESTED';
export const BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_RECEIVED =
  'BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_RECEIVED';
export const BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_FAILED =
  'BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_FAILED';

interface BuildingQuarterlyBaselineObservationsRequestedAction {
  type: typeof BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_REQUESTED;
}

interface BuildingQuarterlyBaselineObservationsReceivedAction {
  type: typeof BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface BuildingQuarterlyBaselineObservationsFailedAction {
  type: typeof BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type BuildingQuarterlyBaselineObservationsActionTypes =
  | BuildingQuarterlyBaselineObservationsRequestedAction
  | BuildingQuarterlyBaselineObservationsReceivedAction
  | BuildingQuarterlyBaselineObservationsFailedAction;

// Half-yearly cost tag action types
export const BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_REQUESTED =
  'BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_REQUESTED';
export const BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_RECEIVED =
  'BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_RECEIVED';
export const BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_FAILED =
  'BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_FAILED';

interface BuildingHalfYearlyCostTagObservationsRequestedAction {
  type: typeof BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_REQUESTED;
  payload: { locationCode: string };
}

interface BuildingHalfYearlyCostTagObservationsReceivedAction {
  type: typeof BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface BuildingHalfYearlyCostTagObservationsFailedAction {
  type: typeof BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type BuildingHalfYearlyCostTagObservationsActionTypes =
  | BuildingHalfYearlyCostTagObservationsRequestedAction
  | BuildingHalfYearlyCostTagObservationsReceivedAction
  | BuildingHalfYearlyCostTagObservationsFailedAction;

// Building popular timings
export const BUILDING_POPULAR_TIMINGS_REQUESTED = 'BUILDING_POPULAR_TIMINGS_REQUESTED';
export const BUILDING_POPULAR_TIMINGS_RECEIVED = 'BUILDING_POPULAR_TIMINGS_RECEIVED';
export const BUILDING_POPULAR_TIMINGS_FAILED = 'BUILDING_POPULAR_TIMINGS_FAILED';

interface BuildingPopularTimingsRequestedAction {
  type: typeof BUILDING_POPULAR_TIMINGS_REQUESTED;
}

interface BuildingPopularTimingsReceivedAction {
  type: typeof BUILDING_POPULAR_TIMINGS_RECEIVED;
  payload: BuildingPopularTimingsResponse;
}

interface BuildingPopularTimingsFailedAction {
  type: typeof BUILDING_POPULAR_TIMINGS_FAILED;
  payload: Error;
}

export type BuildingPopularTimingsActionTypes =
  | BuildingPopularTimingsRequestedAction
  | BuildingPopularTimingsReceivedAction
  | BuildingPopularTimingsFailedAction;

export const BUILDINGS_SUMMARY_REQUESTED = 'BUILDINGS_SUMMARY_REQUESTED';
export const BUILDINGS_SUMMARY_RECEIVED = 'BUILDINGS_SUMMARY_RECEIVED';
export const BUILDINGS_SUMMARY_FAILED = 'BUILDINGS_SUMMARY_FAILED';

interface BuildingsSummaryRequestedAction {
  type: typeof BUILDINGS_SUMMARY_REQUESTED;
}

interface BuildingsSummaryReceivedAction {
  type: typeof BUILDINGS_SUMMARY_RECEIVED;
  payload: Summary[];
}

interface BuildingsSummaryFailedAction {
  type: typeof BUILDINGS_SUMMARY_FAILED;
  payload: Error;
}

export type BuildingsSummaryActionTypes =
  | BuildingsSummaryRequestedAction
  | BuildingsSummaryReceivedAction
  | BuildingsSummaryFailedAction;

// Building Energy Intervention post
export const BUILDING_ENERGY_INTERVENTION_POST_NOT_REQUESTED = 'BUILDING_ENERGY_INTERVENTION_POST_NOT_REQUESTED';
export const BUILDING_ENERGY_INTERVENTION_POST_REQUESTED = 'BUILDING_ENERGY_INTERVENTION_POST_REQUESTED';
export const BUILDING_ENERGY_INTERVENTION_POST_RECEIVED = 'BUILDING_ENERGY_INTERVENTION_POST_RECEIVED';
export const BUILDING_ENERGY_INTERVENTION_POST_FAILED = 'BUILDING_ENERGY_INTERVENTION_POST_FAILED';

interface BuildingEnergyInterventionPostNotRequestedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_POST_NOT_REQUESTED;
}
interface BuildingEnergyInterventionPostRequestedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_POST_REQUESTED;
}

interface BuildingEnergyInterventionPostReceivedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_POST_RECEIVED;
  payload: BuildingEnergyInterventionPOSTResponse;
}

interface BuildingEnergyInterventionPostFailedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_POST_FAILED;
  payload: Error;
}

export const BUILDING_ENERGY_INTERVENTION_GET_REQUESTED = 'BUILDING_ENERGY_INTERVENTION_GET_REQUESTED';
export const BUILDING_ENERGY_INTERVENTION_GET_RECEIVED = 'BUILDING_ENERGY_INTERVENTION_GET_RECEIVED';
export const BUILDING_ENERGY_INTERVENTION_GET_FAILED = 'BUILDING_ENERGY_INTERVENTION_GET_FAILED';

interface BuildingEnergyInterventionGetRequestedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_GET_REQUESTED;
}

interface BuildingEnergyInterventionGetReceivedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_GET_RECEIVED;
  payload: BuildingEnergyIntervention[]; // BuildingEnergyInterventionGETResponse
}

interface BuildingEnergyInterventionGetFailedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_GET_FAILED;
  payload: Error;
}

export const BUILDING_ENERGY_INTERVENTION_DELETE_REQUESTED = 'BUILDING_ENERGY_INTERVENTION_DELETE_REQUESTED';
export const BUILDING_ENERGY_INTERVENTION_DELETE_RECEIVED = 'BUILDING_ENERGY_INTERVENTION_DELETE_RECEIVED';
export const BUILDING_ENERGY_INTERVENTION_DELETE_FAILED = 'BUILDING_ENERGY_INTERVENTION_DELETE_FAILED';
export const BUILDING_ENERGY_INTERVENTION_DELETE_NOT_REQUESTED = 'BUILDING_ENERGY_INTERVENTION_DELETE_NOT_REQUESTED';

interface BuildingEnergyInterventionDeleteRequestedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_DELETE_REQUESTED;
}

interface BuildingEnergyInterventionDeleteReceivedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_DELETE_RECEIVED;
}

interface BuildingEnergyInterventionDeleteNotRequestedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_DELETE_NOT_REQUESTED;
}
interface BuildingEnergyInterventionDeleteFailedAction {
  type: typeof BUILDING_ENERGY_INTERVENTION_DELETE_FAILED;
  payload: Error;
}

export type BuildingEnergyInterventionActionTypes =
  | BuildingEnergyInterventionPostRequestedAction
  | BuildingEnergyInterventionPostReceivedAction
  | BuildingEnergyInterventionPostFailedAction
  | BuildingEnergyInterventionPostNotRequestedAction
  | BuildingEnergyInterventionGetRequestedAction
  | BuildingEnergyInterventionGetReceivedAction
  | BuildingEnergyInterventionGetFailedAction
  | BuildingEnergyInterventionDeleteRequestedAction
  | BuildingEnergyInterventionDeleteReceivedAction
  | BuildingEnergyInterventionDeleteFailedAction
  | BuildingEnergyInterventionDeleteNotRequestedAction;

// Campus

export const CAMPUS_SPACE_DETAILS_REQUESTED = 'CAMPUS_SPACE_DETAILS_REQUESTED';
export const CAMPUS_SPACE_DETAILS_RECEIVED = 'CAMPUS_SPACE_DETAILS_RECEIVED';
export const CAMPUS_SPACE_DETAILS_FAILED = 'CAMPUS_SPACE_DETAILS_FAILED';

interface CampusSpaceDetailsRequestedAction {
  type: typeof CAMPUS_SPACE_DETAILS_REQUESTED;
}

interface CampusSpaceDetailsReceivedAction {
  type: typeof CAMPUS_SPACE_DETAILS_RECEIVED;
  payload: Space;
}

interface CampusSpaceDetailsFailedAction {
  type: typeof CAMPUS_SPACE_DETAILS_FAILED;
  payload: Error;
}

export type CampusSpaceDetailsActionTypes =
  | CampusSpaceDetailsRequestedAction
  | CampusSpaceDetailsReceivedAction
  | CampusSpaceDetailsFailedAction;

export const CAMPUS_OBSERVATIONS_REQUESTED = 'CAMPUS_OBSERVATIONS_REQUESTED';
export const CAMPUS_OBSERVATIONS_RECEIVED = 'CAMPUS_OBSERVATIONS_RECEIVED';
export const CAMPUS_OBSERVATIONS_FAILED = 'CAMPUS_OBSERVATIONS_FAILED';

interface CampusObservationsRequestedAction {
  type: typeof CAMPUS_OBSERVATIONS_REQUESTED;
}

interface CampusObservationsReceivedAction {
  type: typeof CAMPUS_OBSERVATIONS_RECEIVED;
  payload: ObservationsApiResponse;
}

interface CampusObservationsFailedAction {
  type: typeof CAMPUS_OBSERVATIONS_FAILED;
  payload: Error;
}

export type CampusObservationsActionTypes =
  | CampusObservationsRequestedAction
  | CampusObservationsReceivedAction
  | CampusObservationsFailedAction;

export const CAMPUS_OBSERVATIONS_HISTORY_REQUESTED = 'CAMPUS_OBSERVATIONS_HISTORY_REQUESTED';
export const CAMPUS_OBSERVATIONS_HISTORY_RECEIVED = 'CAMPUS_OBSERVATIONS_HISTORY_RECEIVED';
export const CAMPUS_OBSERVATIONS_HISTORY_FAILED = 'CAMPUS_OBSERVATIONS_HISTORY_FAILED';

interface CampusObservationsHistoryRequestedAction {
  type: typeof CAMPUS_OBSERVATIONS_HISTORY_REQUESTED;
}

interface CampusObservationsHistoryReceivedAction {
  type: typeof CAMPUS_OBSERVATIONS_HISTORY_RECEIVED;
  observationHistoryIndex: string;
  observationClass: string;
  observationType: string;
  observationTag: string;
  payload: ObservationsHistoryApiResponse;
}

interface CampusObservationsHistoryFailedAction {
  type: typeof CAMPUS_OBSERVATIONS_HISTORY_FAILED;
  payload: Error;
}

export type CampusObservationsHistoryActionTypes =
  | CampusObservationsHistoryRequestedAction
  | CampusObservationsHistoryReceivedAction
  | CampusObservationsHistoryFailedAction;

// Space change types
export const ROOM_SPACE_CHANGED = 'ROOM_SPACE_CHANGED';
export const FLOOR_SPACE_CHANGED = 'FLOOR_SPACE_CHANGED';
export const BUILDING_SPACE_CHANGED = 'BUILDING_SPACE_CHANGED';
export const CAMPUS_SPACE_CHANGED = 'CAMPUS_SPACE_CHANGED';

interface RoomSpaceChangedAction {
  type: typeof ROOM_SPACE_CHANGED;
  payload: {
    locationCode: string;
  };
}

interface FloorSpaceChangedAction {
  type: typeof FLOOR_SPACE_CHANGED;
  payload: {
    locationCode: string;
  };
}

interface BuildingSpaceChangedAction {
  type: typeof BUILDING_SPACE_CHANGED;
  payload: {
    locationCode: string;
  };
}

interface CampusSpaceChangedAction {
  type: typeof CAMPUS_SPACE_CHANGED;
  payload: {
    locationCode: string;
  };
}

export type SpaceChangedActionTypes =
  | RoomSpaceChangedAction
  | FloorSpaceChangedAction
  | BuildingSpaceChangedAction
  | CampusSpaceChangedAction;

export const TEMPERATURE_FORECAST_REQUESTED = 'TEMPERATURE_FORECAST_REQUESTED';
export const TEMPERATURE_FORECAST_RECEIVED = 'TEMPERATURE_FORECAST_RECEIVED';
export const TEMPERATURE_FORECAST_ERROR = 'TEMPERATURE_FORECAST_ERROR';

interface TemperatureForecastRequested {
  type: typeof TEMPERATURE_FORECAST_REQUESTED;
}

interface TemperatureForecastReceived {
  type: typeof TEMPERATURE_FORECAST_RECEIVED;
  payload: ForecastApiResponse;
}

interface TemperatureForecastError {
  type: typeof TEMPERATURE_FORECAST_ERROR;
  error: Error;
}

export type TemperatureForecastActionTypes =
  | TemperatureForecastError
  | TemperatureForecastRequested
  | TemperatureForecastReceived;

export const CAMPUS_ENERGY_FORECAST_REQUESTED = 'CAMPUS_ENERGY_FORECAST_REQUESTED';
export const CAMPUS_ENERGY_FORECAST_RECEIVED = 'CAMPUS_ENERGY_FORECAST_RECEIVED';
export const CAMPUS_ENERGY_FORECAST_ERROR = 'CAMPUS_ENERGY_FORECAST_ERROR';

interface CampusEnergyForecastRequested {
  type: typeof CAMPUS_ENERGY_FORECAST_REQUESTED;
}

interface CampusEnergyForecastReceived {
  type: typeof CAMPUS_ENERGY_FORECAST_RECEIVED;
  payload: ForecastApiResponse;
}

interface CampusEnergyForecastError {
  type: typeof CAMPUS_ENERGY_FORECAST_ERROR;
  error: Error;
}

export type CampusEnergyForecastActionTypes =
  | CampusEnergyForecastError
  | CampusEnergyForecastRequested
  | CampusEnergyForecastReceived;

export const CAMPUS_TOTAL_ENERGY_HISTORY_REQUESTED = 'CAMPUS_TOTAL_ENERGY_REQUESTED';
export const CAMPUS_TOTAL_ENERGY_HISTORY_RECEIVED = 'CAMPUS_TOTAL_ENERGY_RECEIVED';
export const CAMPUS_TOTAL_ENERGY_HISTORY_ERROR = 'CAMPUS_TOTAL_ENERGY_ERROR';

interface CampusTotalEnergyHistoryRequested {
  type: typeof CAMPUS_TOTAL_ENERGY_HISTORY_REQUESTED;
}

interface CampusTotalEnergyHistoryReceived {
  type: typeof CAMPUS_TOTAL_ENERGY_HISTORY_RECEIVED;
  payload: ObservationsHistoryApiResponse;
}

interface CampusTotalEnergyHistoryError {
  type: typeof CAMPUS_TOTAL_ENERGY_HISTORY_ERROR;
  error: Error;
}

export type CampusTotalEnergyHistoryActionTypes =
  | CampusTotalEnergyHistoryRequested
  | CampusTotalEnergyHistoryReceived
  | CampusTotalEnergyHistoryError;

// BUILDING FORECAST

export const BUILDING_ENERGY_FORECAST_REQUESTED = 'BUILDING_ENERGY_FORECAST_REQUESTED';
export const BUILDING_ENERGY_FORECAST_RECEIVED = 'BUILDING_ENERGY_FORECAST_RECEIVED';
export const BUILDING_ENERGY_FORECAST_ERROR = 'BUILDING_ENERGY_FORECAST_ERROR';

interface BuildingEnergyForecastRequested {
  type: typeof BUILDING_ENERGY_FORECAST_REQUESTED;
}

interface BuildingEnergyForecastReceived {
  type: typeof BUILDING_ENERGY_FORECAST_RECEIVED;
  payload: ForecastApiResponse;
}

interface BuildingEnergyForecastError {
  type: typeof BUILDING_ENERGY_FORECAST_ERROR;
  error: Error;
}

export type BuildingEnergyForecastActionTypes =
  | BuildingEnergyForecastError
  | BuildingEnergyForecastRequested
  | BuildingEnergyForecastReceived;

// All types

export type DetailsActionTypes =
  | RoomObservationsActionTypes
  | RoomHistoricalObservationsActionTypes
  | RoomSpaceDetailsActionTypes
  | RoomsSummaryActionsTypes
  | FloorObservationsActionTypes
  | FloorObservationsHistoryActionTypes
  | FloorSpaceDetailsActionTypes
  | FloorsSummaryActionsTypes
  | BuildingObservationsActionTypes
  | BuildingObservationsHistoryActionTypes
  | BuildingMonthlyObservationsHistoryActionTypes
  | BuildingQuarterlyObservationsHistoryActionTypes
  | BuildingQuarterlyBaselineObservationsActionTypes
  | BuildingHalfYearlyCostTagObservationsActionTypes
  | BuildingSpaceDetailsActionTypes
  | BuildingsSummaryActionTypes
  | BuildingPopularTimingsActionTypes
  | BuildingEnergyInterventionActionTypes
  | CampusObservationsActionTypes
  | CampusObservationsHistoryActionTypes
  | CampusSpaceDetailsActionTypes
  | SpaceChangedActionTypes
  | TemperatureForecastActionTypes
  | CampusEnergyForecastActionTypes
  | CampusTotalEnergyHistoryActionTypes
  | BuildingEnergyForecastActionTypes;
