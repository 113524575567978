import { AuthActionTypes, TOKEN_UPDATED, AUTH_ERROR } from './types';
import { IdToken } from 'msal/lib-commonjs/IdToken';

export const initialAuthState: AuthReducer = {
  idToken: null,
  accessToken: null,
  scopes: [],
  expiryDate: null,
  isAuthenticated: false,
  isErrored: false,
  errorMessage: null,
};

interface AuthReducer {
  idToken: IdToken | null;
  accessToken: string | null;
  scopes: string[];
  expiryDate: Date | null;
  isAuthenticated: boolean;
  isErrored: boolean;
  errorMessage: string | null;
}

export const authReducer = (state = initialAuthState, action: AuthActionTypes): AuthReducer => {
  switch (action.type) {
    case TOKEN_UPDATED:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case AUTH_ERROR:
      state = {
        ...state,
        ...action.payload,
      };
      break;
  }
  return state;
};
