import React, { useRef, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { match } from 'react-router';
import { History, Location } from 'history';
import FloorControl from 'components/FloorSection/FloorControl';
import { UrlParams } from 'components/Mazemap/MapTypes';
import Section from 'components/common/Section';
import { equals, isNil } from 'ramda';
import SpaceDetails from 'components/common/SpaceDetails';
import SummaryDetails from 'components/common/SummaryDetails';
import { mapService } from 'services/Map/MapService';
import { SpaceTypes } from 'types';
import {
  selectFloorSpaceDetails,
  selectBuildingAbnormalFloorsSummary,
  selectBuildingSpaceDetails,
  selectBuildingSpaceUtilisation,
  selectBuildingAlarms,
  selectBuildingEnergyObservations,
  selectBuildingEnergyForecastObservations,
  selectBuildingTotalWHConsumptionHistory,
  selectBuildingPopularTimings,
  selectBuildingPopularTimingsType,
  selectBuildingPopularTimingsLiveIndex,
  selectBuildingPopularTimingsLiveDescription,
  selectPopularTimingsHistoricalDescripton,
} from 'store/selectors';
import Logger from 'utils/logger';
import SpaceUtilisation from 'components/Observation/SpaceUtilisation';
import AlarmDetails from 'components/common/AlarmDetails';
import { EnergyDetails } from 'components/Observation/EnergyGroupDetails';
import VentilationDetails from 'components/common/VentilationDetails';
/* import CovidResponseBuildingSection from 'components/Observation/CovidResponseBuildingSection'
 */
interface BuildingSectionProps {
  match: match<UrlParams>;
  history: History;
  location: Location;
}

const BuildingSection = ({ match, history, location }: BuildingSectionProps): ReactElement => {
  const floorSpaceDetails = useSelector(selectFloorSpaceDetails);
  const spaceDetails = useSelector(selectBuildingSpaceDetails);
  const spaceUtilisation = useSelector(selectBuildingSpaceUtilisation);
  const alarms = useSelector(selectBuildingAlarms);
  const energyObservations = useSelector(selectBuildingEnergyObservations);
  const energyMonthlyHistoryObservations = useSelector(selectBuildingTotalWHConsumptionHistory);
  const energyForecastObservations = useSelector(selectBuildingEnergyForecastObservations);
  const floorsSummary = useSelector(selectBuildingAbnormalFloorsSummary);
  const popularTimings = useSelector(selectBuildingPopularTimings);
  const type = useSelector(selectBuildingPopularTimingsType);
  const liveBusyIndex = useSelector(selectBuildingPopularTimingsLiveIndex);
  const liveDescription = useSelector(selectBuildingPopularTimingsLiveDescription);
  const historicalDescription = useSelector(selectPopularTimingsHistoricalDescripton);

  const [imgSrc, setImgSrc] = useState('');
  const [locationCode, setLocationCode] = useState('');

  // update image if url changes
  useEffect((): void => {
    const campusId = match.params.campusId;
    const buildingNumber = match.params.buildingNumber;
    const isValidParams = !isNil(campusId) && !isNil(buildingNumber);

    if (!isValidParams) {
      Logger.error(`Invalid image parameters - campusId: '${campusId}', buildingNumber: '${buildingNumber}'`);
      return;
    }
    const newImgSrc = `https://spaceinfo.staff.unimelb.edu.au/UMELDATA/photos/thumb/${campusId}/${campusId};${buildingNumber}_1.jpg`;
    if (!equals(imgSrc, newImgSrc)) {
      setImgSrc(newImgSrc);
    }
  }, [imgSrc, match.params.campusId, match.params.buildingNumber]);

  useEffect((): void => {
    const campusId: string = `${match.params.campusId}`;
    const buildingNumber = match.params.buildingNumber;
    const newLocationCode = `${match.params.campusId};${buildingNumber}`;
    const notNavigatingFloor = match.params.floorId === undefined;
    const libraryRooms = spaceDetails.rooms?.filter((room) => room.roomCategory === '5');
    const isGroundFloor = floorSpaceDetails.name === '' || floorSpaceDetails.name === 'Ground';
    const buildingDetailUpdated = buildingNumber === (libraryRooms && libraryRooms[0]?.locationCode.split(';')[1]);

    if (isGroundFloor && notNavigatingFloor && buildingDetailUpdated && libraryRooms && libraryRooms.length > 0) {
      mapService.highlightLibraryRooms(campusId, libraryRooms, !notNavigatingFloor);
    }

    if (notNavigatingFloor) {
      mapService.checkSmartMeterLocationsAndAddMarkers(spaceDetails, campusId);
    }

    if (!equals(locationCode, newLocationCode)) {
      setLocationCode(newLocationCode);
    }
  }, [spaceDetails, locationCode, match.params.campusId, match.params.buildingNumber, match.params.floorId]);
  return (
    <div>
      <Section title={spaceDetails ? `${spaceDetails.name} (${spaceDetails.locationCode.split(';')[1]})` : 'Building'}>
        <SpaceDetails spaceDetails={spaceDetails} spaceType={SpaceTypes.building} imgSrc={imgSrc} />
        {/* <CovidResponseBuildingSection locationCode={locationCode} /> */}
        <AlarmDetails alarms={alarms} />
        <SpaceUtilisation
          data={spaceUtilisation}
          locationCode={locationCode}
          popularTimings={popularTimings}
          popularTimingsType={type}
          liveBusyIndex={liveBusyIndex}
          liveDescription={liveDescription}
          historicalDescription={historicalDescription}
        />
        <VentilationDetails locationCode={String(match.params.buildingNumber)} />
        <EnergyDetails
          locationCode={locationCode}
          observations={energyObservations}
          energyForecast={energyForecastObservations}
          energyMonthlyHistory={energyMonthlyHistoryObservations}
        />
        <SummaryDetails summaries={floorsSummary} spaceType={SpaceTypes.floor} match={match} history={history} />
        <FloorControl match={match} history={history} location={location} />
      </Section>
    </div>
  );
};

export default BuildingSection;
