const Status = Object.freeze({
  Normal: 1,
  Alarm: 2,
  Unknown: 99,
});

export const ColourStatus: StringIndex = Object.freeze({
  MazeRed: 'Alarm',
  MazeYellow: 'Unknown',
  MazeGreen: 'Normal',
});

interface StringIndex {
  [iterable: string]: string;
}

export default Status;
