import { prop } from 'ramda';
import { Observation } from 'models/Observation';
import React, { ReactElement } from 'react';
import { getIconTypeFromObservationType, SVGIcon, IconSizes } from 'components/common/Icons';
import { Typography, makeStyles } from '@material-ui/core';
import { convertUnit } from 'components/common/UnitOfMeasure';
import { formatObservationTime } from 'components/common/ObservationFormatters';
import { APP_GUTTER, FONT_SIZE_SMALL } from 'styles/styleConstants';

const useObservationDetailStyles = makeStyles({
  observationItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: `${APP_GUTTER}px`,
    width: '100%',
    '& img': {
      padding: '4px 16px',
    },
  },
  observationMetadata: {
    flexGrow: 1,
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: 0,
    listStyle: 'none',
    maxWidth: '100%',
  },
  observationTime: {
    fontSize: FONT_SIZE_SMALL,
  },
});

const getValue: Function = prop('value');

interface ObservationComponentProps {
  observation: Observation;
}

export const ObservationComponent = ({ observation }: ObservationComponentProps): ReactElement => {
  const classes = useObservationDetailStyles();
  const iconType = getIconTypeFromObservationType(observation.observationType);
  return (
    <div className={classes.observationItem}>
      <SVGIcon size={IconSizes.large} type={iconType} alttext={iconType} />
      <ul className={classes.observationMetadata}>
        <li>
          <Typography>{`${getValue(observation)} ${convertUnit(observation.unitOfMeasure)}`}</Typography>
        </li>
        <li>
          <Typography>{observation.observationTag}</Typography>
        </li>
        <li className={classes.observationTime}>
          <Typography variant="caption">{formatObservationTime(observation.observationEndTime)}</Typography>
        </li>
      </ul>
    </div>
  );
};
