import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { match } from 'react-router';
import { History, Location } from 'history';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { makeStyles } from '@material-ui/styles';
import { FloorSpace } from 'models/Floor';
import Section from 'components/common/Section';
import SpaceDetails from 'components/common/SpaceDetails';
import RoomControl from 'components/RoomSection/RoomControl';
import { AlarmObservation } from 'models/Observation';
import { Summary } from 'models/Summary';
import SummaryDetails from 'components/common/SummaryDetails';
import { SpaceTypes } from 'types';
import MazemapHighlightService from 'services/Map/MazemapHighlightService';
import { Feature } from 'geojson';
import { isEmpty, equals, pathOr } from 'ramda';
import {
  selectFloorSpaceDetails,
  selectFloorAbnormalRoomsSummary,
  selectFloorSevereAlarmRoomsSummary,
  selectFloorMediumAlarmRoomsSummary,
  selectFloorLowAlarmRoomsSummary,
  selectMapHighlightService,
  selectMapDataService,
  selectFloorSpaceUtilisation,
  selectFloorAlarms,
  selectIsVisibleAlerts,
} from 'store/selectors';
import { scrollToBottomOfLeftPanel } from 'utils/mapUtils';
import MazemapDataService from 'services/Map/MazemapDataService';
import SpaceUtilisation from 'components/Observation/SpaceUtilisation';
import AlarmDetails from 'components/common/AlarmDetails';
import { SEVERITY_LOW, SEVERITY_MEDIUM, SEVERITY_SEVERE } from 'components/constants';

const useStyles = makeStyles({
  floorSection: {
    display: 'block',
    '& ul': {
      listStyle: 'none',
    },
  },
});

interface FloorSectionProps {
  match: match<UrlParams>;
  history: History;
  location: Location;
}

export const FloorSection = ({ match, history, location }: FloorSectionProps): ReactElement => {
  const [roomsAbnormalSummary, setRoomsAbnormalSummary] = useState<Summary[]>([]);
  const [roomsSevereAlarmSummary, setRoomsSevereAlarmSummary] = useState<Summary[]>([]);
  const [roomsMediumAlarmSummary, setRoomsMediumAlarmSummary] = useState<Summary[]>([]);
  const [roomsLowAlarmSummary, setRoomsLowAlarmSummary] = useState<Summary[]>([]);

  const spaceDetails: FloorSpace = useSelector(selectFloorSpaceDetails);
  const alarms: AlarmObservation[] = useSelector(selectFloorAlarms);
  const isVisibleAlerts: boolean = useSelector(selectIsVisibleAlerts);
  const rolledUpAbnormalRoomsSummary: Summary[] = useSelector(selectFloorAbnormalRoomsSummary);
  const rolledUpSevereAlarmRoomsSummary: Summary[] = useSelector(selectFloorSevereAlarmRoomsSummary);
  const rolledUpMediumAlarmRoomsSummary: Summary[] = useSelector(selectFloorMediumAlarmRoomsSummary);
  const rolledUpLowAlarmRoomsSummary: Summary[] = useSelector(selectFloorLowAlarmRoomsSummary);

  const spaceUtilisation = useSelector(selectFloorSpaceUtilisation);
  const mapHighlightService: MazemapHighlightService = useSelector(selectMapHighlightService);
  const mapDataService: MazemapDataService = useSelector(selectMapDataService);

  const [locationCode, setLocationCode] = useState('');

  if (JSON.stringify(roomsAbnormalSummary) !== JSON.stringify(rolledUpAbnormalRoomsSummary)) {
    setRoomsAbnormalSummary(rolledUpAbnormalRoomsSummary);
  }
  if (JSON.stringify(roomsSevereAlarmSummary) !== JSON.stringify(rolledUpSevereAlarmRoomsSummary)) {
    setRoomsSevereAlarmSummary(rolledUpSevereAlarmRoomsSummary);
  }
  if (JSON.stringify(roomsMediumAlarmSummary) !== JSON.stringify(rolledUpMediumAlarmRoomsSummary)) {
    setRoomsMediumAlarmSummary(rolledUpMediumAlarmRoomsSummary);
  }
  if (JSON.stringify(roomsLowAlarmSummary) !== JSON.stringify(rolledUpLowAlarmRoomsSummary)) {
    setRoomsLowAlarmSummary(rolledUpLowAlarmRoomsSummary);
  }

  useEffect((): void => {
    scrollToBottomOfLeftPanel();

    const fetchAllAbnormalPois = async (): Promise<void> => {
      mapHighlightService.removeAllAbnormalRoomsLayersAndSources();
      const abnormalRoomPois: Feature[] = await mapDataService.getPoisFromSummaries(roomsAbnormalSummary);

      const severeAlarmRoomPois: Feature[] = abnormalRoomPois.filter((roomPois) =>
        roomsSevereAlarmSummary.some(
          (room) => room.location.locationCode === pathOr('', ['properties', 'identifier'], roomPois),
        ),
      );
      const mediumAlarmRoomPois: Feature[] = abnormalRoomPois.filter((roomPois) =>
        roomsMediumAlarmSummary.some(
          (room) => room.location.locationCode === pathOr('', ['properties', 'identifier'], roomPois),
        ),
      );
      const lowAlarmRoomPois: Feature[] = abnormalRoomPois.filter((roomPois) =>
        roomsLowAlarmSummary.some(
          (room) => room.location.locationCode === pathOr('', ['properties', 'identifier'], roomPois),
        ),
      );

      if (severeAlarmRoomPois && !isEmpty(severeAlarmRoomPois) && isVisibleAlerts) {
        mapHighlightService.highlightRoomPois(severeAlarmRoomPois, SEVERITY_SEVERE);
      }
      if (mediumAlarmRoomPois && !isEmpty(mediumAlarmRoomPois) && isVisibleAlerts) {
        mapHighlightService.highlightRoomPois(mediumAlarmRoomPois, SEVERITY_MEDIUM);
      }
      if (lowAlarmRoomPois && !isEmpty(lowAlarmRoomPois) && isVisibleAlerts) {
        mapHighlightService.highlightRoomPois(lowAlarmRoomPois, SEVERITY_LOW);
      }
    };
    fetchAllAbnormalPois();
  }, [
    mapHighlightService,
    mapDataService,
    roomsAbnormalSummary,
    isVisibleAlerts,
    roomsLowAlarmSummary,
    roomsMediumAlarmSummary,
    roomsSevereAlarmSummary,
  ]);

  useEffect((): void => {
    const newLocationCode = `${match.params.campusId};${match.params.buildingNumber};${match.params.floorId}`;
    if (!equals(locationCode, newLocationCode)) {
      setLocationCode(newLocationCode);
    }
  }, [locationCode, match.params.campusId, match.params.buildingNumber, match.params.floorId]);

  const classes = useStyles();
  return (
    <div className={classes.floorSection}>
      <Section title={spaceDetails.name || 'Floor'}>
        <SpaceDetails spaceDetails={spaceDetails} />
        <SpaceUtilisation data={spaceUtilisation} locationCode={locationCode} />
        <AlarmDetails alarms={alarms} />
        <SummaryDetails summaries={roomsAbnormalSummary} spaceType={SpaceTypes.room} match={match} history={history} />
        <RoomControl match={match} history={history} location={location} />
      </Section>
    </div>
  );
};

export default FloorSection;
