import React, { ReactElement, useEffect, useState } from 'react';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { APP_GUTTER } from 'styles/styleConstants';

const useStyles = makeStyles({
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  progressIndicator: {
    margin: `${APP_GUTTER}px`,
  },
  progressMessage: {},
});

interface WaitingProgressIndicatorProps {
  progressMessage: string;
}

export const WaitingProgressIndicator = (props: WaitingProgressIndicatorProps): ReactElement => {
  const { progressMessage } = props;
  const classes = useStyles(props);
  const [displayMessage, setDisplayMessage] = useState('');

  useEffect((): void => {
    setDisplayMessage(progressMessage);
  }, [progressMessage]);

  return (
    <div className={classes.progressContainer}>
      <CircularProgress className={classes.progressIndicator} />
      <Typography className={classes.progressMessage}>{displayMessage}</Typography>
    </div>
  );
};
