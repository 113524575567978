import React, { ReactElement, useState, Fragment, useCallback, useEffect } from 'react';
import {
  Observation,
  ObservationType,
  getObservationValue,
  ObservationTag,
  getObservationProperty,
  ObservationClass,
  getObservationTag,
} from 'models/Observation';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { FONT_SIZE_SMALL, APP_GUTTER, UOM_SUPPORTING_COLOURS, UOM_LIGHT_BLUE } from 'styles/styleConstants';
import { getIconTypeFromObservationType, SVGIcon, IconSizes } from 'components/common/Icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  isNil,
  propOr,
  includes,
  forEach,
  compose,
  pathOr,
  find,
  isEmpty,
  any,
  values,
  map,
  filter,
  prop,
  sortWith,
  descend,
} from 'ramda';
import { convertUnit, UnitOfMeasure } from 'components/common/UnitOfMeasure';
import { truncateDecimalString, truncateDecimalDigits } from 'components/common/ObservationFormatters';
import { DisplayTimeZone, getHalfYearlyDateRange } from 'components/common/DateTime';
import moment from 'moment-timezone';
import Chevron from 'components/common/Chevron';
import { Button, Collapse, Grid } from '@material-ui/core';
import ObservationHistory from './ObservationHistory';
import DetailCard from 'components/common/DetailCard';
import { useDispatch, useSelector } from 'react-redux';
import { getCampusId, getBuildingId, isBuildingLocationCode } from 'components/common/LocationCodeUtils';
import {
  getBuildingCostTagObservations,
  getBuildingMonthlyObservationsHistoryAction,
  getBuildingObservationsHistoryAction,
  getBuildingQuarterlyBaselineObservationsAction,
  getBuildingQuarterlyObservationsHistoryAction,
} from 'store/details/actions';
import {
  selectBuildingEnergyObservationsHistory,
  selectIsVisibleEnergy,
  selectAuthAccessToken,
  selectAuthIDToken,
  selectBuildingQuarterlyHistoricalObservations,
  selectBuildingQuarterlyBaselineObservations,
} from 'store/selectors';
import { FETCH_INTERVAL } from 'components/constants';
import { EnergyForecastComponent } from 'components/common/EnergyForecastComponent';
import { toggleIsEnergyInterventionDrawerOpened } from 'store/UISettings/actions';
import jwtDecode from 'jwt-decode';
import { EnergyImpactComponent } from 'components/common/EnergyImpactComponent';
import { Summary } from 'models/Summary';
import { BuildingkWHComponent } from 'components/common/BuildingkWHComponent';
import momentTZ from 'moment-timezone';
import { EnergyCostDetails } from './EnergyCostDetails';
import logger from '../../utils/logger';

const unscaledValue = (value: string): string => value;

export const formatObservationTime = (dateTimeString: string): string => {
  const observationMoment = moment.tz(dateTimeString, DisplayTimeZone);
  return observationMoment.isValid() ? observationMoment.format('LT') : dateTimeString;
};
export const formatObservationDate = (dateTimeString: string): string => {
  const observationMoment = moment.tz(dateTimeString, DisplayTimeZone);
  return observationMoment.isValid() ? observationMoment.format('D MMM') : dateTimeString;
};

const useMetricCardStyles = makeStyles({
  card: {
    maxWidth: `calc(50% - ${APP_GUTTER}px)`,
    minWidth: '15%',
    marginRight: `${APP_GUTTER}px`,
    marginBottom: `${APP_GUTTER}px`,
  },
  cardTitle: {
    padding: `${APP_GUTTER}px ${APP_GUTTER}px 0px ${APP_GUTTER}px`,
    borderBottom: `1px solid ${UOM_SUPPORTING_COLOURS.grey.dark}`,
  },
  cardMetricSection: {
    padding: `0px ${APP_GUTTER}px ${APP_GUTTER}px ${APP_GUTTER}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  cardMetric: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    '& .unit': {
      marginLeft: '0.1em',
    },
  },
  cardDateRange: {
    alignSelf: 'flex-end',
  },
});

interface MetricCardProps {
  title: string;
  observation: Observation | undefined;
  valueFormatter: (value: string | number) => string | number;
  valueScaler?: (value: string) => string | number;
  unitOfMeasure?: UnitOfMeasure | undefined;
  displayTime?: boolean;
  displayDate?: boolean;
  'data-testid'?: string;
  isCampus?: boolean;
}

export const MetricCard = (props: MetricCardProps): ReactElement | null => {
  const {
    title,
    observation,
    valueFormatter,
    valueScaler = unscaledValue,
    unitOfMeasure,
    displayTime = false,
    displayDate = false,
  } = props;
  const classes = useMetricCardStyles();

  const value = valueScaler(getObservationValue(observation));
  const startTime = getObservationProperty('observationStartTime', observation);
  const endTime = getObservationProperty('observationEndTime', observation);

  const formatDateTimeForCaption = (): string => {
    const observationDate = !isEmpty(startTime) ? formatObservationDate(startTime) : formatObservationDate(endTime);
    const observationStartTime = formatObservationTime(startTime);
    const observationEndTime = formatObservationTime(endTime);
    return displayDate || displayTime
      ? `at ${displayDate ? `${observationDate}, ` : ''}${
          !isEmpty(startTime) ? `${observationStartTime} - ` : ''
        }${observationEndTime}`
      : '';
  };
  return !isNil(observation) ? (
    <Card className={classes.card} data-testid={props['data-testid']}>
      <CardContent>
        <Typography className={classes.cardTitle}>{title}</Typography>
        <div className={classes.cardMetricSection}>
          <div className={classes.cardMetric}>
            <Typography>{valueFormatter(value)}</Typography>
            <Typography variant="caption" className="unit">
              {unitOfMeasure}
            </Typography>
          </div>
          {displayTime || displayDate ? (
            <div className={classes.cardDateRange}>
              <Typography variant="caption">{formatDateTimeForCaption()}</Typography>
            </div>
          ) : null}
        </div>
      </CardContent>
    </Card>
  ) : null;
};

const useObservationDetailStyles = makeStyles({
  observationGroupItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${APP_GUTTER}px`,
    '& img': {
      padding: '4px 16px',
    },
  },
  observationGroupMetadata: {
    flexGrow: 1,
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: 0,
    listStyle: 'none',
    maxWidth: '100%',
  },
  observationIcon: {
    paddingTop: '16px',
  },
  observationTime: {
    fontSize: FONT_SIZE_SMALL,
  },
  observationGroupHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      padding: '4px 6px',
    },
    position: 'relative',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& div div': {
      marginLeft: '0.4em',
      marginRight: '0.3em',
    },
  },
  observationGroupTitle: {
    fontWeight: 'bold',
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: `-${APP_GUTTER}px`,
    /* stylelint-disable-next-line no-descending-specificity */
    '& div': {
      flexGrow: 1,
    },
    '& div:first-child': {
      flexBasis: `calc(50% - ${APP_GUTTER}px)`,
    },
    '& div:nth-child(2)': {
      flexBasis: `calc(50% - ${APP_GUTTER}px)`,
    },
  },
  observationGroupChartCollapser: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const formatkWhValue = (number: string | number): string | number =>
  typeof number === 'number' ? truncateDecimalDigits(number, 1) : truncateDecimalString(number, 1);
const formatkVahValue = formatkWhValue;
const formatPercentValue = (number: string | number): string | number =>
  typeof number === 'number' ? truncateDecimalDigits(number, 2) : truncateDecimalString(number, 1);

const formatCo2Value = (number: string | number): string | number =>
  typeof number === 'number' ? truncateDecimalDigits(number, 1) : truncateDecimalString(number, 1);

const scaleWhTokwh = (value: string): string | number => {
  const numericValue = Number.parseFloat(value);
  return !Number.isNaN(numericValue) ? numericValue / 1000 : value;
};
const scaleVahTokva = scaleWhTokwh;
const scaleRatioToPercentage = (value: string): string | number => {
  const numericValue = Number.parseFloat(value);
  return !Number.isNaN(numericValue) ? numericValue * 100 : value;
};

const isRealtimeObservation = (observation: Observation | undefined): boolean =>
  includes('MONDO', propOr<string, Observation | undefined, string>('', 'observationTag', observation));

const isEnergyGenerationAvailable = (observations: Observation[]): boolean =>
  find((observation: Observation): boolean => {
    const tag = propOr<string, Observation, string>('', 'observationTag', observation);
    return tag.startsWith(ObservationTag.totalWhGeneration) || tag.startsWith(ObservationTag.totalVahGeneration);
  }, observations) !== undefined;

const kWhUnit = convertUnit(UnitOfMeasure['Kilowatt hours']);
const kVaUnit = convertUnit(UnitOfMeasure['Kilovolt-ampere hours']);
const percentUnit = convertUnit(UnitOfMeasure.Percent);
const kgUnit = convertUnit(UnitOfMeasure.Kilogram);

interface DisplayParameters {
  title: string;
  valueFormatter: (value: string | number) => string | number;
  valueScaler?: (value: string) => string | number;
  unitOfMeasure: UnitOfMeasure;
  displayTime?: boolean;
}

const displayParameters: { [index: string]: DisplayParameters } = {};
displayParameters[ObservationTag.totalWhConsumption] = {
  title: 'Total',
  valueFormatter: formatkWhValue,
  valueScaler: scaleWhTokwh,
  unitOfMeasure: kWhUnit,
};
displayParameters[ObservationTag.totalVahConsumption] = {
  title: 'Total Apparent',
  valueFormatter: formatkVahValue,
  valueScaler: scaleVahTokva,
  unitOfMeasure: kVaUnit,
};
displayParameters[ObservationTag.peakWhConsumption] = {
  title: 'Peak',
  valueFormatter: formatkWhValue,
  valueScaler: scaleWhTokwh,
  unitOfMeasure: kWhUnit,
  displayTime: true,
};
displayParameters[ObservationTag.minPfConsumption] = {
  title: 'Min. PF',
  valueFormatter: formatPercentValue,
  valueScaler: scaleRatioToPercentage,
  unitOfMeasure: percentUnit,
  displayTime: true,
};
displayParameters[ObservationTag.totalKgConsumption] = {
  title: 'Total Emissions (CO₂)',
  valueFormatter: formatCo2Value,
  unitOfMeasure: kgUnit,
};
displayParameters[ObservationTag.totalWhGeneration] = {
  title: 'Total',
  valueFormatter: formatkWhValue,
  valueScaler: scaleWhTokwh,
  unitOfMeasure: kWhUnit,
};
displayParameters[ObservationTag.totalVahGeneration] = {
  title: 'Total Apparent',
  valueFormatter: formatkVahValue,
  valueScaler: scaleVahTokva,
  unitOfMeasure: kVaUnit,
};

const consumptionIconType = getIconTypeFromObservationType(ObservationType.energy);
const generationIconType = getIconTypeFromObservationType('Solar');

export interface EnergyGroupDetailsProps {
  locationCode: string;
  observations: any[]; // Observation[]
  energyForecast?: any[];
  energyMonthlyHistory?: any[];
  buildingsSummary?: Summary[];
}

export interface Token {
  name: string;
  preferred_username: string;
  CampusNowGroups: string[];
}

const filterTopFiveEnergyBuildings = (buildingsSummary: Summary[]): any =>
  sortWith([descend(prop('value'))])(
    map(
      (summary: Summary): any => {
        const filtered = filter((observation: Observation): boolean => {
          return observation.observationTag === ObservationTag.totalWhConsumption.concat('#CLARITI');
        }, summary.observations)[0];
        if (!isNil(filtered)) {
          return {
            ...filtered,
            value: Number(filtered.value),
          };
        }
      },
      filter((summary: Summary): boolean => summary.location.locationCode.split(';').length > 1, buildingsSummary),
    ),
  );

export const EnergyGroupDetails = ({
  buildingsSummary,
  locationCode,
  observations,
  energyForecast,
  energyMonthlyHistory,
}: EnergyGroupDetailsProps): ReactElement => {
  const dispatch = useDispatch();
  const classes = useObservationDetailStyles();
  const accessToken = useSelector(selectAuthAccessToken);
  const idToken = useSelector(selectAuthIDToken);
  const isCampus = !(locationCode.split(';').length > 1);
  const isBuilding = locationCode.split(';').length === 2;
  const actualObservations = useSelector(selectBuildingQuarterlyHistoricalObservations);
  const baselineObservations = useSelector(selectBuildingQuarterlyBaselineObservations);
  const consumptionTags = [
    ObservationTag.totalWhConsumption,
    ObservationTag.totalVahConsumption,
    ObservationTag.minPfConsumption,
    ObservationTag.peakWhConsumption,
    ObservationTag.totalKgConsumption,
  ];
  const generationTags = [ObservationTag.totalWhGeneration, ObservationTag.totalVahGeneration];

  let topEnergyConsumptionBuildings;
  if (!isNil(buildingsSummary)) {
    topEnergyConsumptionBuildings = filter(
      (item: any) => !isNil(item) && item.value > 0,
      filterTopFiveEnergyBuildings(buildingsSummary),
    );
  }

  const observationsToDisplay: { [key: string]: Observation | undefined } = {};

  forEach((observation: Observation): void => {
    const observationTag = getObservationTag(observation).split('#')[0];
    const observationIsRealtime = isRealtimeObservation(observation);
    if (observationIsRealtime || isNil(observationsToDisplay[observationTag])) {
      observationsToDisplay[observationTag] = observation;
    }
  }, observations);

  const [showRealtimeObservations, setShowRealtimeObservations] = useState(false);

  const areRealtimeObservationsAvailable = compose<
    { [index: string]: Observation | undefined },
    (Observation | undefined)[],
    boolean
  >(
    any(isRealtimeObservation),
    values,
  )(observationsToDisplay);

  useEffect((): void => {
    if (showRealtimeObservations !== areRealtimeObservationsAvailable) {
      setShowRealtimeObservations(areRealtimeObservationsAvailable);
    }
  }, [areRealtimeObservationsAvailable, showRealtimeObservations]);

  const [showLineChart, setShowLineChart] = useState(false);
  const [showForecastChart, setShowForecastChart] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [showTop5EnergyUsagePie, setShowTop5EnergyUsagePie] = useState(false);
  // const [showImpact, setShowImpact] = useState(false)

  const handleNotes = (): void => {
    dispatch(toggleIsEnergyInterventionDrawerOpened());
  };

  /* const handleImpact = (): void => {
    setShowImpact(!showImpact)
  } */

  const handleLineChartClick = (): void => {
    if (!isCampus) {
      setShowLineChart(!showLineChart);
      setShowForecastChart(false);
      setShowTop5EnergyUsagePie(false);
      setShowChart(!showLineChart);
    }
  };

  const handleShowChartClick = (): void => {
    setShowChart(!showChart);
    setShowForecastChart(!showChart);
    if (!isCampus) {
      setShowLineChart(!showChart);
    }
    if (isCampus) {
      setShowTop5EnergyUsagePie(!showChart);
    }
  };

  const handleForecastChartClick = (): void => {
    setShowForecastChart(!showForecastChart);
    if (!isCampus) {
      setShowLineChart(false);
    }
    setShowChart(!showForecastChart);
  };

  const handleEnergyPieChartClick = (): void => {
    setShowTop5EnergyUsagePie(!showTop5EnergyUsagePie);
    setShowChart(!showTop5EnergyUsagePie);
  };

  const showGeneration = isEnergyGenerationAvailable(observations);

  // SCIOT-945
  const delayedObservationDate: string = showRealtimeObservations
    ? ''
    : propOr(
        '',
        'observationStartTime',
        observationsToDisplay[`${ObservationTag.totalWhConsumption}`], //CLARITI
      ); // Use Total WHConsumption observation tag as the driver to get the display date.

  const historyDate = areRealtimeObservationsAvailable
    ? moment.tz(DisplayTimeZone).format('YYYY-MM-DD')
    : moment.tz(delayedObservationDate, DisplayTimeZone).format('YYYY-MM-DD');
  const [qStartDate, qEndDate] = getHalfYearlyDateRange(moment().quarter());

  const fetchHistory = useCallback(
    (locationCode: string, accessToken: any): void => {
      if (!isEmpty(locationCode) && isBuildingLocationCode(locationCode)) {
        const campusId = getCampusId(locationCode);
        const buildingId = getBuildingId(locationCode);
        dispatch(
          getBuildingObservationsHistoryAction(
            'Energy',
            campusId,
            buildingId,
            historyDate,
            historyDate,
            ObservationClass.energy,
            ObservationType.energy,
            undefined,
            accessToken,
          ),
        );
        dispatch(
          getBuildingMonthlyObservationsHistoryAction(
            'Energy',
            campusId,
            buildingId,
            qStartDate, // historyStartDate,
            moment(historyDate).tz(DisplayTimeZone).add(1, 'day').format('YYYY-MM-DD'),
            ObservationClass.energy,
            ObservationType.energy,
            ObservationTag.totalWhConsumption.concat('#CLARITI'),
            accessToken,
          ),
        );

        dispatch(
          getBuildingQuarterlyObservationsHistoryAction(
            'Energy',
            locationCode.split(';')[0],
            locationCode.split(';')[1],
            qStartDate,
            qEndDate,
            ObservationClass.energy,
            ObservationType.energy,
            ObservationTag.totalWhConsumption.concat('#CLARITI'),
            accessToken,
          ),
        );
        dispatch(
          getBuildingQuarterlyBaselineObservationsAction(
            'Energy',
            locationCode.split(';')[0],
            locationCode.split(';')[1],
            qStartDate,
            qEndDate,
            ObservationClass.energy,
            ObservationType.energy,
            ObservationTag.historicAvgWHConsumption.concat('#CLARITI'),
            accessToken,
          ),
        );
      }
    },
    [dispatch, historyDate],
  );

  const fetchCostTagObservations = useCallback(
    (accessToken, locationCode) => {
      if (!isEmpty(locationCode) && isBuildingLocationCode(locationCode)) {
        const campusId = getCampusId(locationCode);
        const buildingId = getBuildingId(locationCode);
        const startDateTime = momentTZ.tz(DisplayTimeZone).subtract(6, 'months').format('YYYY-MM-DD');
        const endDateTime = momentTZ.tz(DisplayTimeZone).format('YYYY-MM-DD');

        dispatch(
          getBuildingCostTagObservations(
            'Cost',
            campusId,
            buildingId,
            startDateTime,
            endDateTime,
            ObservationType.energy,
            ObservationClass.energy,
            ObservationTag.totalCostExcGST, // costTags,
            accessToken,
          ),
        );
      }
    },
    [dispatch],
  );

  useEffect((): void => {
    fetchHistory(locationCode, accessToken);
    fetchCostTagObservations(accessToken, locationCode);
  }, [fetchHistory, fetchCostTagObservations, locationCode, observations[0].location.locationCode, accessToken]);

  useEffect((): (() => void) => {
    const fetch = (): void => fetchHistory(locationCode, accessToken);
    const fetchInterval: NodeJS.Timeout = setInterval(fetch, FETCH_INTERVAL);

    return (): void => {
      clearInterval(fetchInterval);
    };
  }, [fetchHistory, locationCode, accessToken]);

  const observationHistory: { [index: string]: Observation[] } = useSelector(
    selectBuildingEnergyObservationsHistory('Energy'),
  );

  let isAdmin: boolean = false;
  if (!isNil(idToken)) {
    const decodedToken: Token = jwtDecode(idToken);
    const isTokenAdmin: boolean[] = map(
      (group: string) => includes('Campus-Services-Admin', group),
      decodedToken.CampusNowGroups,
    );
    if (includes(true, isTokenAdmin)) {
      isAdmin = true;
    }
  }
  logger.debug(`showRealtimeObservations: ${showRealtimeObservations}`);
  logger.debug(`delayedObservationDate ${delayedObservationDate}`);
  return (
    <div className={classes.observationGroupItem}>
      <div className={classes.observationGroupMetadata}>
        <div className={classes.observationGroupHeader}>
          <SVGIcon size={IconSizes.small} type={consumptionIconType} alttext="Energy" />
          <div style={{ paddingRight: '15%' }}>
            <Typography className={classes.observationGroupTitle}>Energy Demand - Grid</Typography>
            {!showRealtimeObservations ? (
              <div>
                <Typography>for {formatObservationDate(delayedObservationDate)}</Typography>
              </div>
            ) : null}
          </div>
          {!isCampus ? (
            <div
              role="button"
              onClick={handleLineChartClick}
              style={{
                display: 'flex',
                position: 'absolute',
                width: 'auto',
                top: 0,
                right: '11%',
              }}>
              <SVGIcon size={IconSizes.small} type={'line-chart'} alttext="Daily energy trend" />
            </div>
          ) : null}
          {isCampus ? (
            <div
              role="button"
              onClick={handleEnergyPieChartClick}
              style={{
                display: 'flex',
                position: 'absolute',
                width: 'auto',
                top: 0,
                right: '11%',
              }}>
              <SVGIcon size={IconSizes.small} type={'pie-chart'} alttext="Energy usage - top 5" />
            </div>
          ) : null}
          <div
            role="button"
            style={{
              display: 'flex',
              position: 'absolute',
              width: 'auto',
              top: 0,
              right: '4%',
            }}
            onClick={handleForecastChartClick}>
            <SVGIcon size={IconSizes.small} type={'energy-costs'} alttext="Energy Forecast - current week" />
          </div>
          <div
            role="button"
            onClick={handleShowChartClick}
            style={{
              display: 'flex',
              position: 'absolute',
              width: 'auto',
              top: 0,
              right: 0,
            }}>
            <Chevron styles={{ color: UOM_LIGHT_BLUE }} direction={showChart ? 'up' : 'down'} />
          </div>
        </div>
        {isBuilding ? <EnergyCostDetails observations={observations} locationCode={locationCode} /> : null}
        <div className={classes.cardSection}>
          {consumptionTags.map((observationTag: string, index: number): ReactElement | null => {
            const observation = observationsToDisplay[observationTag];
            const isObservationRealtime = isRealtimeObservation(observation);
            const showDate = isObservationRealtime;
            const showTime = isObservationRealtime || pathOr(false, [observationTag, 'displayTime'], displayParameters);
            return (
              <MetricCard
                key={`${index}-${observationTag}`}
                data-testid={observationTag}
                title={displayParameters[observationTag].title}
                observation={observationsToDisplay[observationTag]}
                valueFormatter={displayParameters[observationTag].valueFormatter}
                valueScaler={displayParameters[observationTag].valueScaler}
                unitOfMeasure={displayParameters[observationTag].unitOfMeasure}
                displayTime={showTime}
                displayDate={showDate}
                isCampus={isCampus}
              />
            );
          })}
        </div>
        {showGeneration ? (
          <Fragment>
            <div className={classes.observationGroupHeader}>
              <SVGIcon size={IconSizes.small} type={generationIconType} alttext="Solar Energy" />
              <div>
                <Typography className={classes.observationGroupTitle}>Solar Generation</Typography>
                {!showRealtimeObservations ? (
                  <div>
                    <Typography>for {formatObservationDate(delayedObservationDate)}</Typography>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={classes.cardSection}>
              {generationTags.map((observationTag: string, index: number): ReactElement | null => {
                const observation = observationsToDisplay[observationTag];
                const isObservationRealtime = isRealtimeObservation(observation);
                const showDate = isObservationRealtime;
                const showTime =
                  isObservationRealtime || pathOr(false, [observationTag, 'displayTime'], displayParameters);
                return (
                  <MetricCard
                    key={`${index}-${observationTag}`}
                    data-testid={observationTag}
                    title={displayParameters[observationTag].title}
                    observation={observationsToDisplay[observationTag]}
                    valueFormatter={displayParameters[observationTag].valueFormatter}
                    valueScaler={displayParameters[observationTag].valueScaler}
                    unitOfMeasure={displayParameters[observationTag].unitOfMeasure}
                    displayTime={showTime}
                    displayDate={showDate}
                    isCampus={false}
                  />
                );
              })}
            </div>
          </Fragment>
        ) : null}
        <Collapse in={showChart && (showForecastChart || showLineChart || showTop5EnergyUsagePie)}>
          {showLineChart ? (
            <div>
              <ObservationHistory
                title={
                  areRealtimeObservationsAvailable
                    ? "Today's Trend"
                    : `Trend for ${formatObservationDate(delayedObservationDate)}`
                }
                unitOfMeasure={kWhUnit}
                observations={observationHistory}
              />
            </div>
          ) : null}
          {showForecastChart ? (
            <EnergyForecastComponent
              energyForecastObservations={energyForecast}
              energyHistoryObservations={energyMonthlyHistory}
              locationCode={locationCode}
            />
          ) : null}
          {showTop5EnergyUsagePie &&
          !isNil(topEnergyConsumptionBuildings) &&
          !isEmpty(topEnergyConsumptionBuildings[0]) ? (
            <BuildingkWHComponent energyConsumption={topEnergyConsumptionBuildings.slice(0, 5)} />
          ) : null}
        </Collapse>
        {isBuilding && !isEmpty(actualObservations) && !isEmpty(baselineObservations) ? (
          <Grid container style={{ paddingTop: '3%' }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNotes}
                style={{
                  textTransform: 'none',
                  padding: '0%',
                  width: '100%',
                  height: '100%',
                }}>
                {isAdmin ? 'Manage Energy Intervention' : 'Show Energy Intervention'}
              </Button>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <EnergyImpactComponent
                locationCode={locationCode}
                actualObservations={actualObservations}
                baselineObservations={baselineObservations}
              />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        ) : null}
      </div>
    </div>
  );
};

interface EnergyDetailsProps {
  locationCode: string;
  observations: Observation[];
  energyForecast?: any[];
  energyMonthlyHistory?: any[];
  buildingsSummary?: Summary[];
}

export const EnergyDetails = ({
  locationCode,
  observations,
  energyForecast,
  energyMonthlyHistory,
  buildingsSummary,
}: EnergyDetailsProps): ReactElement | null => {
  const isVisibleEnergy = useSelector(selectIsVisibleEnergy);
  if (isEmpty(observations) || !isVisibleEnergy) {
    return null;
  }

  return (
    <DetailCard title={'Energy'}>
      <EnergyGroupDetails
        buildingsSummary={buildingsSummary}
        locationCode={locationCode}
        observations={observations}
        energyForecast={energyForecast}
        energyMonthlyHistory={energyMonthlyHistory}
      />
    </DetailCard>
  );
};
