import React from 'react';
import { Flippy, FrontSide, BackSide } from 'react-flippy';
import { Card, CardHeader, Typography, CardContent, CardActions } from '@material-ui/core';
import { UOM_SUPPORTING_COLOURS, UOM_DARK_BLUE, UOM_WHITE } from 'styles/styleConstants';
import { useObservationDetailStyles } from '../../styles/accessControlStyle';
import { useSpaceAttributeStyles } from 'styles/spaceAttributeStyle';
import { isEmpty } from 'ramda';

interface ReturnToCampusResearchCardValues {
  returnToCampusResearchCardValues: { count: number; dateTime: string };
  type: string;
}

interface SpaceAttribute {
  spaceAttribute: { name: string; value: string; description: string };
}

export const FlipCard = (props: ReturnToCampusResearchCardValues): JSX.Element => {
  const classes = useObservationDetailStyles();
  const returnToCampusResearchCardValues = props.returnToCampusResearchCardValues;
  return (
    <Flippy
      flipOnHover={false} // default false
      flipOnClick={true} // default false
      flipDirection="horizontal" // horizontal or vertical
      style={{
        height: '100%',
        width: '99%',
        paddingLeft: '0.5%',
        paddingTop: '0.5%',
      }}>
      <FrontSide animationDuration={1000} isFlipped={false} style={{}}>
        <Card className={classes.returnCampusResearchCardLayout}>
          <CardHeader
            component="span"
            titleTypographyProps={{
              align: 'center',
              variant: 'h6',
              style: {
                fontWeight: 'bold',
              },
            }}
            style={{ paddingTop: '0px', paddingBottom: '2px' }}
            title="Return To Campus - Building Access"
          />
          <CardContent>
            <Typography
              variant="h3"
              align="center"
              style={{
                fontWeight: 'bold',
                color: UOM_SUPPORTING_COLOURS.emerald.dark,
              }}>
              {returnToCampusResearchCardValues.count.toLocaleString()}
            </Typography>
            <Typography align="center" className={classes.captionText}>
              Individuals Access Approved
            </Typography>
          </CardContent>
          <CardActions>
            <Typography className={classes.observationTime}>
              {!isEmpty(returnToCampusResearchCardValues.dateTime)
                ? ` (at ${returnToCampusResearchCardValues.dateTime})`
                : ''}
            </Typography>
          </CardActions>
        </Card>
      </FrontSide>
      <BackSide animationDuration={1000} isFlipped={false} style={{}}>
        <Card className={classes.covidSafeTrainingBackCardLayout} style={{ backgroundColor: UOM_DARK_BLUE }}>
          <CardHeader
            component="span"
            titleTypographyProps={{
              align: 'center',
              variant: 'caption',
              style: {
                fontWeight: 'bold',
              },
            }}
            style={{ backgroundColor: UOM_DARK_BLUE }}
            title=""
          />
          <CardContent
            style={{
              backgroundColor: UOM_DARK_BLUE,
            }}>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: 'bold',
                color: 'white',
              }}>
              Source: ServiceNow
            </Typography>
            <Typography align="center" className={classes.captionBackText}>
              <b>Details:</b>{' '}
              {`Total number of individuals that have been approved to
              access the ${props.type}. Includes student, staff, research community and
              not contractors.`}
            </Typography>
          </CardContent>
        </Card>
      </BackSide>
    </Flippy>
  );
};

export const FlipCardSpaceAttribute = (props: SpaceAttribute): JSX.Element => {
  const classes = useSpaceAttributeStyles();
  const spaceAttribute = props.spaceAttribute;
  return (
    <Flippy
      flipOnHover={false} // default false
      flipOnClick={true} // default false
      flipDirection="horizontal" // horizontal or vertical
      style={{
        height: '100%',
        width: '100%',
        paddingLeft: '0.5%',
        paddingTop: '0.5%',
      }}>
      <FrontSide animationDuration={1000} isFlipped={false} style={{ padding: 4, boxShadow: 'none' }}>
        <Card className={classes.frontardLayout}>
          <CardHeader
            component="span"
            titleTypographyProps={{
              align: 'center',
              variant: 'h6',
              style: {
                fontWeight: 'bold',
              },
            }}
            style={{ paddingTop: '0px', paddingBottom: '2px' }}
            title={spaceAttribute.name}
          />
          <CardContent>
            <Typography
              variant="h3"
              align="center"
              style={{
                fontWeight: 'bold',
                fontSize: spaceAttribute.name.includes('Count') ? '2rem' : '1.1rem',
                color: UOM_SUPPORTING_COLOURS.emerald.dark,
              }}>
              {spaceAttribute.value}
            </Typography>
            <Typography align="center" className={classes.frontCaptionText}></Typography>
          </CardContent>
          <CardActions>
            <Typography className={classes.frontAction}></Typography>
          </CardActions>
        </Card>
      </FrontSide>
      <BackSide animationDuration={1000} isFlipped={false} style={{ padding: 4 }}>
        <Card className={classes.backCardLayout} style={{ backgroundColor: UOM_DARK_BLUE }}>
          <CardHeader
            component="span"
            titleTypographyProps={{
              align: 'center',
              variant: 'caption',
              style: {
                fontWeight: 'bold',
              },
            }}
            style={{ backgroundColor: UOM_DARK_BLUE, color: UOM_WHITE }}
            title="Source: Archibus"
          />
          <CardContent
            style={{
              backgroundColor: UOM_DARK_BLUE,
            }}>
            <Typography align="center" className={classes.backCaptionText}>
              {spaceAttribute.description}
            </Typography>
          </CardContent>
        </Card>
      </BackSide>
    </Flippy>
  );
};
