import { IdToken } from 'msal/lib-commonjs/IdToken';

// Authentication types
export const TOKEN_UPDATED = 'TOKEN_UPDATED';
export const AUTH_ERROR = 'AUTH_ERROR';

interface TokenUpdated {
  type: typeof TOKEN_UPDATED;
  payload: {
    idToken: IdToken;
    accessToken: string;
    scopes: string[];
    expiryDate: Date;
    isAuthenticated: boolean;
  };
}

interface AuthError {
  type: typeof AUTH_ERROR;
  payload: {
    isErrored: boolean;
    errorMessage: string | null;
  };
}

export type AuthenticationActionTypes = TokenUpdated | AuthError;

// All types

export type AuthActionTypes = AuthenticationActionTypes;
