import { propOr } from 'ramda';
import { Space } from './Space';

export type CampusSpace = {
  buildings?: string[];
  buildingsCount?: number;
} & Space;

type Building = {
  locationCode: string;
  activityStatus: string;
};

export const defaultUnknownValue = '—';

export const getCampusSpaceProperty = (name: string, campusSpace: CampusSpace | undefined): string =>
  propOr(defaultUnknownValue, name, campusSpace);
