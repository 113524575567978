import React, { Fragment, useState } from 'react';
import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { History, Location } from 'history';
import { match } from 'react-router';
import BuildingControl from 'components/BuildingSection/BuildingControl';
import { UrlParams } from 'components/Mazemap/MapTypes';
import CampusSectionSelector from './CampusSectionSelector';
// import CovidResponseSection from './CovidResponseSection'
import SpaceDetails from 'components/common/SpaceDetails';
import { abnormalAlarmSummaryFilter } from 'utils/filters';
import {
  selectCampusBuildingsSummary,
  selectCampusSpaceDetails,
  selectMapService,
  selectIsVisibleAlerts,
  selectSpaceUtilisationHeatMapSelection,
  selectCampusAccessCardObservation,
  selectCampusEnergyForecastObservations,
  selectCampusTotalEnergyHistoryObservations,
  selectCampusEnergyObservations,
  /*  selectBuildingStatusByCampus, */
} from 'store/selectors';
import { Summary } from 'models/Summary';
import { equals } from 'ramda';
import { SpaceUtilisationHeatMapTypes } from 'types';
import SpaceUtilisation from 'components/Observation/SpaceUtilisation';
import { EnergyDetails } from 'components/Observation/EnergyGroupDetails';
import { SpaceTypes } from 'types';

interface CampusSectionProps {
  match: match<UrlParams>;
  history: History;
  location: Location;
}

const CampusSection = ({ match, history, location }: CampusSectionProps): ReactElement => {
  const mapService = useSelector(selectMapService);
  const spaceDetails = useSelector(selectCampusSpaceDetails);
  const buildingsSummary = useSelector(selectCampusBuildingsSummary);
  const isVisibleAlerts = useSelector(selectIsVisibleAlerts);
  const spaceObservations = useSelector(selectCampusAccessCardObservation);
  const energyObservations = useSelector(selectCampusEnergyObservations);
  const energyForecastObservations = useSelector(selectCampusEnergyForecastObservations);
  const totalEnergyHistoryObservations = useSelector(selectCampusTotalEnergyHistoryObservations);
  // const buildingStatusByCampus = useSelector(selectBuildingStatusByCampus)
  const [buildings, setBuildings] = useState<Summary[]>([]);
  const [campusId, setCampusId] = useState(match.params.campusId);
  const spaceUtilisationHeatMapSelection = useSelector(selectSpaceUtilisationHeatMapSelection);

  useEffect(() => {
    mapService.clearSpaceUtilisationLayerHeatMap();
    switch (spaceUtilisationHeatMapSelection) {
      case SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1:
        //Space Utilisation Heat Map
        mapService.addSpaceUtilisationLayerHeatMap(
          campusId,
          SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1,
          buildingsSummary,
        );
        break;
      case SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE1:
        //Space Utilisation Heat Map
        mapService.addSpaceUtilisationLayerHeatMap(
          campusId,
          SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE1,
          buildingsSummary,
        );
        break;
      case SpaceUtilisationHeatMapTypes.NONE:
        mapService.clearSpaceUtilisationLayerHeatMap();
        break;
    }
  }, [spaceUtilisationHeatMapSelection, buildingsSummary, campusId, mapService]);

  useEffect((): void => {
    if (!equals(buildingsSummary, buildings)) {
      setBuildings(buildingsSummary);
    }
  }, [buildings, buildingsSummary]);

  // redraw map markers whenever buildingsSummary changes
  useEffect((): void => {
    mapService.clearMapMarkers();
    if (isVisibleAlerts) {
      const abnormalBuildingAlarms = buildings.filter(abnormalAlarmSummaryFilter);
      mapService.addBuildingStatusPins(abnormalBuildingAlarms);
    }
  }, [buildings, isVisibleAlerts, mapService]);

  useEffect((): void => {
    const currentCampusId = match.params.campusId;
    if (currentCampusId !== campusId) {
      setCampusId(currentCampusId);
    }
  }, [match.params.campusId, campusId]);

  useEffect((): void => {
    mapService.clearBuildingStatusLayer();
    mapService.addBuildingStatusLayer(campusId);
  }, [campusId, mapService]);

  const spaceUtilisationComponent = spaceObservations ? (
    <SpaceUtilisation data={spaceObservations} locationCode={campusId} />
  ) : null;

  /* const covidResponseComponent = ( // buildingStatusByCampus ? (
    <CovidResponseSection /* buildingStatuses={buildingStatusByCampus} / />
  ) */
  // ) : null

  return (
    <Fragment>
      <CampusSectionSelector campusId={campusId} match={match} history={history} location={location}>
        <SpaceDetails spaceDetails={spaceDetails} spaceType={SpaceTypes.campus} />
        {/* <Fragment>{covidResponseComponent}</Fragment> */}
        <Fragment>{spaceUtilisationComponent}</Fragment>
        <EnergyDetails
          locationCode={campusId}
          buildingsSummary={buildingsSummary}
          observations={energyObservations}
          energyForecast={energyForecastObservations}
          energyMonthlyHistory={totalEnergyHistoryObservations}
        />
        <BuildingControl match={match} history={history} location={location} />
      </CampusSectionSelector>
    </Fragment>
  );
};

export default CampusSection;
