import { Grid, Paper, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import logger from 'utils/logger';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HightchartsExporting from 'highcharts/modules/exporting';
import Highcharts3d from 'highcharts/highcharts-3d';
import { equals, filter, forEach, indexOf, isNil, map, prop, sortBy } from 'ramda';
import moment from 'moment';
import { HistoricPopularTimings } from 'models/Observation';
import PropTypes from 'prop-types';
import { UOM_DARK_BLUE, UOM_SECONDARY_BLUE, UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import './busyTime.css';
import { daysInAWeek } from 'components/constants';
import { useEffect } from 'react';

HightchartsExporting(Highcharts);
Highcharts3d(Highcharts);

/* const defaultOptions: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  title: {
    text: isNil(undefined) ? 'test' : 'dev',
  },
  lang: {
    noData: 'Data not available for selected day',
  },
  xAxis: {
    categories: ['08', '09', '10', '11', '12', '13', '14', '15', '16'],
    labels: {
      skew3d: true,
      style: {
        fontSize: '16px',
      },
    },
  },
  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: 'Busy Index',
      skew3d: true,
    },
  },
  tooltip: {
    headerFormat: '<b>{point.key}</b><br>',
    pointFormat:
      '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y}0% at {point.x} hrs',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      borderWidth: 40,
    },
  },
  series: [
    {
      name: 'Teaching',
      type: 'column',
      data: [],
      stack: 'Teaching',
    },
    {
      type: 'column',
      name: 'Non-Teaching',
      data: [],
      stack: 'Non-Teaching',
    },
  ],
} */

const styles = {
  root: {
    minWidth: '20%',
    fontSize: '16px',
    fontWeight: 900,
  },
};

const BuildingBusyTimings = (props: any): React.ReactElement => {
  const currentDay = String(indexOf(moment.tz('Australia/Melbourne').format('ddd'), daysInAWeek));
  const [dayOfWeek, setDayOfWeek] = useState(currentDay);
  // const [options, setOptions] = useState(defaultOptions)
  const { classes, popularTimings, type } = props;

  const changeValue = (event: any, newValue: any): void => {
    logger.info('newValue', newValue);
    switch (newValue) {
      case 0:
        setDayOfWeek('0');
        break;
      case 1:
        setDayOfWeek('1');
        break;
      case 2:
        setDayOfWeek('2');
        break;
      case 3:
        setDayOfWeek('3');
        break;
      case 4:
        setDayOfWeek('4');
        break;
      case 5:
        setDayOfWeek('5');
        break;
      case 6:
        setDayOfWeek('6');
        break;
    }
    logger.info('newValue', newValue);
    logger.info('dayOfWeek', dayOfWeek);
  };

  const create3DColumnChart = (day: string): any => {
    let chartName = '';
    const todayHour = moment().tz('Australia/Melbourne').format('HH');
    // filter for 7 hrs before and after current hr
    const dayStats = filter((popularTime: HistoricPopularTimings) => {
      chartName = type;
      return (
        Number(popularTime.dayOfTheWeek) === Number(day) &&
        ((0 <= Number(popularTime.hours.substring(0, 2)) - Number(todayHour) &&
          Number(popularTime.hours.substring(0, 2)) - Number(todayHour) <= 7) ||
          (0 <= Number(todayHour) - Number(popularTime.hours.substring(0, 2)) &&
            Number(todayHour) - Number(popularTime.hours.substring(0, 2)) <= 7))
      );
    }, popularTimings);

    let teaching: any[] = [];

    teaching = sortBy(prop('hours'))(dayStats);
    logger.info({ teaching });
    const xAxisCategories: any[] = [];
    forEach((day) => {
      xAxisCategories.push(moment(day.hours.substring(0, 2), 'HH').format('ha'));
    }, teaching);
    logger.info({ xAxisCategories });
    teaching = map((item: any) => Number(item.busyIndex), teaching);
    const current: any[] = [];
    const currentHrIndex = indexOf(moment(todayHour, 'HH').format('ha'), xAxisCategories);
    const graphColors: any[] = map((i) => {
      return UOM_SECONDARY_BLUE;
    }, Array(15));

    const currentIndexColor = '#FF0000';

    const series: any[] = [];
    for (let i = 0; i < xAxisCategories.length; i++) {
      if (equals(Number(i), Number(currentHrIndex)) && Number(currentDay) === Number(dayOfWeek)) {
        if (!isNil(props.liveBusyIndex)) {
          current.push(props.liveBusyIndex);
        }
        logger.info(`${currentDay} ${props.liveBusyIndex} -- ${day} ${teaching[i]}`);
        graphColors[i] = UOM_SUPPORTING_COLOURS.grey.light;
      } else {
        current.push(0);
      }
    }
    const currentIndexOptions = {
      name: null,
      type: 'column',
      data: current,
      stack: 'Current',
      color: currentIndexColor,
      pointPadding: 0.1,
      pointPlacement: 0,
      showInLegend: false,
    };
    const historyIndexOptions = {
      name: null,
      type: 'column',
      data: teaching,
      stack: chartName,
      colorByPoint: true,
      colors: graphColors,
      showInLegend: false,
    };

    series.push(historyIndexOptions);
    series.push(currentIndexOptions);
    const newoptions: Highcharts.Options = {
      chart: {
        type: 'column',
        animation: true,
        height: '60%',
      },
      lang: {
        noData: 'Data not available for selected day',
      },
      title: {
        useHTML: true,
        style: {
          fontSize: '16px',
          fontWeight: '900',
          color: UOM_DARK_BLUE,
        },
        text: historyIndexOptions.data.length > 0 ? props.historicalDescription : 'Data not available',
      },
      xAxis: {
        categories: xAxisCategories,
        allowDecimals: true,
        title: undefined,
        labels: {
          skew3d: false,
          style: {
            fontSize: '10px',
          },
        },
      },
      yAxis: {
        max: 10,
        visible: false,
        allowDecimals: false,
        min: 0,
        title: {
          text: 'Busy Index',
        },
      },
      tooltip: {
        headerFormat: '<b>Busy Index: {point.y}0% at {point.key}</b><br>',
        pointFormat: undefined,
      },
      plotOptions: {
        column: {
          depth: 40,
          borderRadius: 8,
          borderWidth: 1,
          grouping: false,
          shadow: false,
          pointWidth: 15,
        },
      },
      series: series,
    };
    // setOptions(newoptions)
    return newoptions;
  };

  /* useEffect(() => {
    create3DColumnChart(dayOfWeek)
  }, [dayOfWeek]) */
  useEffect(() => {
    setDayOfWeek(currentDay);
  }, [props.locationCode, currentDay]);

  return (
    <div>
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}>
        <Tabs
          value={Number(dayOfWeek)}
          onChange={changeValue}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          centered={false}>
          <Tab label="SUN" className={classes.root} /> {/* starts with 0*/}
          <Tab label="MON" className={classes.root} />
          <Tab label="TUE" className={classes.root} />
          <Tab label="WED" className={classes.root} />
          <Tab label="THU" className={classes.root} />
          <Tab label="FRI" className={classes.root} />
          <Tab label="SAT" className={classes.root} />
        </Tabs>
        <div className="parentDiv">
          <Grid container>
            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="numberCircle">
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    color: 'red',
                  }}></div>
              </div>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <Typography variant="subtitle1" color="primary">
                  <b>Live</b>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="subtitle2" color="primary">
                {moment().tz('Australia/Melbourne').format('hh:mma')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}>
              <Typography variant="subtitle2" color="primary">
                &nbsp;- {props.liveDescription}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <div
        style={{
          height: '10%',
          backgroundColor: 'yellow',
        }}>
        <HighchartsReact options={create3DColumnChart(dayOfWeek)} highcharts={Highcharts} />
      </div>
    </div>
  );
};
BuildingBusyTimings.propTypes = {
  classes: PropTypes.object.isRequired,
  popularTimings: PropTypes.any,
  type: PropTypes.any,
  liveBusyIndex: PropTypes.any,
  liveDescription: PropTypes.any,
  historicalDescription: PropTypes.any,
  locationCode: PropTypes.any,
};
export default withStyles(styles)(BuildingBusyTimings);
