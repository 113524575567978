import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronDown from '@material-ui/icons/ExpandMore';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronUp from '@material-ui/icons/ExpandLess';
import { UOM_WHITE } from 'styles/styleConstants';
import { pathOr } from 'ramda';

interface ChevronStyleProps {
  color: string;
}

interface ChevronProps {
  styles?: ChevronStyleProps;
  direction?: 'right' | 'down' | 'left' | 'up';
}

const useStyles = makeStyles<Theme, ChevronProps>((): any => ({
  root: {
    color: (props: ChevronProps): string => pathOr(UOM_WHITE, ['styles', 'color'], props),
  },
  link: {
    height: 24,
  },
}));

const Chevron: React.FC<ChevronProps> = (props: ChevronProps): ReactElement => {
  let Chevron = ChevronRight;
  switch (props.direction) {
    case 'down':
      Chevron = ChevronDown;
      break;
    case 'left':
      Chevron = ChevronLeft;
      break;
    case 'up':
      Chevron = ChevronUp;
  }
  const classes = useStyles(props);
  return <Chevron className={classes.root} />;
};

Chevron.defaultProps = {
  direction: 'right',
};

export default Chevron;
