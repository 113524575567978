import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { RouteComponentProps } from 'react-router-dom';
import { getRoomObservationsAction, getRoomSpaceDetailsAction, roomChangedAction } from 'store/details/actions';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { FETCH_INTERVAL } from 'components/constants';
import { RoomPoiResponse } from 'models/Room';
import { FloorMapper } from 'utils/mapUtils';
import { selectMapHighlightService, selectAuthAccessToken } from 'store/selectors';
// import { useOktaAuth } from '@okta/okta-react'

const RoomControl = ({ match, history }: RouteComponentProps<UrlParams>): null => {
  const dispatch = useDispatch();
  /* const { authState } = useOktaAuth()
  const accessToken: string | undefined = prop('accessToken', authState) */
  const accessToken = useSelector(selectAuthAccessToken);

  const mapHighlightService = useSelector(selectMapHighlightService);

  const fetchRoomDetails = useCallback(
    (campusId: string, buildingNumber: string, floorId: string, roomNumber: string, accessToken: any): void => {
      const formattedFloorId = FloorMapper.mazemapToArchibusId(floorId);
      dispatch(roomChangedAction(campusId, buildingNumber, floorId, roomNumber));
      dispatch(getRoomObservationsAction(campusId, buildingNumber, formattedFloorId, roomNumber, accessToken));
      dispatch(getRoomSpaceDetailsAction(campusId, buildingNumber, formattedFloorId, roomNumber, accessToken));
    },
    [dispatch],
  );

  const refreshRoomDetails = useCallback(
    (campusId: string, buildingNumber: string, floorId: string, roomNumber: string, accessToken: any): void => {
      const formattedFloorId = FloorMapper.mazemapToArchibusId(floorId);
      dispatch(getRoomObservationsAction(campusId, buildingNumber, formattedFloorId, roomNumber, accessToken));
    },
    [dispatch],
  );

  // triggers whenever url parameters change
  useEffect((): void => {
    const campusId = match.params.campusId;
    const buildingNumber = match.params.buildingNumber;
    const floorId = match.params.floorId;
    const roomNumber = match.params.roomNumber;
    if (!isNil(buildingNumber) && !isNil(floorId) && !isNil(roomNumber) && !isNil(accessToken)) {
      fetchRoomDetails(campusId, buildingNumber, floorId, roomNumber, accessToken);
      mapHighlightService.selectRoomByLocationCode(
        `${campusId};${buildingNumber};${FloorMapper.mazemapToArchibusId(floorId)};${roomNumber}`,
      );
    }
  }, [
    match.params.campusId,
    match.params.buildingNumber,
    match.params.floorId,
    match.params.roomNumber,
    fetchRoomDetails,
    mapHighlightService,
    accessToken,
  ]);

  // Register/unregister building click handler
  useEffect((): (() => void) => {
    const callback = (room: RoomPoiResponse): void => {
      const [campusId, buildingNumber, floorId, roomNumber] = room.properties.identifier.split(';');

      const existingPath = history.location.pathname;

      const newPath = `/campus/${campusId}/building/${buildingNumber}/floor/${floorId}/room/${roomNumber}`;
      if (existingPath.indexOf(`/room/${roomNumber}`) === -1) {
        history.push(newPath);
      }
    };

    mapHighlightService.addRoomClickHandler(callback);
    return (): void => {
      mapHighlightService.removeRoomClickHandler(callback);
      mapHighlightService.removeRoomHighlight();
    };
  }, [dispatch, fetchRoomDetails, match.url, history, mapHighlightService]);

  // fetch latest data every 2 minutes
  useEffect((): (() => void) => {
    const campusId = match.params.campusId;
    const buildingNumber = match.params.buildingNumber;
    const floorId = match.params.floorId;
    const roomNumber = match.params.roomNumber;

    let fetchInterval: NodeJS.Timeout;

    if (!isNil(buildingNumber) && !isNil(floorId) && !isNil(roomNumber) && !isNil(accessToken)) {
      const fetch = (): void => refreshRoomDetails(campusId, buildingNumber, floorId, roomNumber, accessToken);
      fetchInterval = setInterval(fetch, FETCH_INTERVAL);
    }

    return (): void => {
      clearInterval(fetchInterval);
    };
  }, [
    match.params.campusId,
    match.params.buildingNumber,
    match.params.floorId,
    match.params.roomNumber,
    refreshRoomDetails,
    accessToken,
  ]);

  return null;
};

export default RoomControl;
