import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { Observation, ObservationTag } from 'models/Observation';
import { find, propEq, propOr, ifElse, isNil, reduce, always, assoc, equals, map } from 'ramda';
import { convertUnit, UnitOfMeasure } from 'components/common/UnitOfMeasure';
import moment from 'moment-timezone';
import { selectCampusObservationsLatest, selectNext5daysTempForecast } from 'store/selectors';
import { DisplayTimeZone } from 'components/common/DateTime';
import { TemperatureForecastWidget } from './TemperatureForecast';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'flex-end',
    // backgroundColor: 'blue'
  },
  summaryContent: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    paddingRight: '10px',
    width: '420px',
    maxHeight: '100%',
    // backgroundColor: 'green'
  },
  weatherTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px white',
    maxWidth: '70%',
    // backgroundColor: 'red'
  },
  temperatureContent: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '70%',
    // backgroundColor: 'yellow'
  },
  temperatureItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // paddingLeft: '25%',
    // backgroundColor: 'orange'
  },

  detailedContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '150px',
    // width: '15%',
  },
  detailedItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const OBSERVATION_TAG = 'observationTag';

//const isMatchingTag = (tagName: string): ((_: object) => boolean) =>
const isMatchingTag = (tagName: string) => propEq(OBSERVATION_TAG, tagName);

interface ObservationArray {
  [key: string]: Observation;
}

const weatherObservations = (observations: Observation[]): ObservationArray => {
  const observationTags = [
    ObservationTag.airTemperature,
    ObservationTag.apparentTemperature,
    ObservationTag.humidity,
    ObservationTag.pressure,
    ObservationTag.windSpeed,
    ObservationTag.windDirection,
  ];

  return reduce(
    (matchingObservations: ObservationArray, tag: string): ObservationArray => {
      const observation = find(isMatchingTag(tag), observations);
      return ifElse(
        isNil,
        always(matchingObservations),
        (value: Observation): ObservationArray => assoc(tag, value, matchingObservations),
      )(observation);
    },
    {},
    observationTags,
  );
};

const WeatherSection: React.FC = (): ReactElement => {
  const classes = useStyles();

  const observations = useSelector(selectCampusObservationsLatest);
  const tempForecastObservations = useSelector(selectNext5daysTempForecast);

  const bomObservations = weatherObservations(observations);

  const defaultValue = '—';
  const airTemperature = propOr(defaultValue, 'value', bomObservations[ObservationTag.airTemperature]);
  const airTemperatureUnits = convertUnit(
    propOr(UnitOfMeasure.Celsius, 'unitOfMeasure', bomObservations[ObservationTag.airTemperature]),
  );
  const airTemperatureDateTime: string = propOr(
    '',
    'observationEndTime',
    bomObservations[ObservationTag.airTemperature],
  );
  const airTemperatureMoment = moment.tz(airTemperatureDateTime, DisplayTimeZone);
  const observationTime = ifElse(
    equals(''),
    always(''),
    always(airTemperatureMoment.format('LT')),
  )(airTemperatureDateTime);

  const apparentTemperature = propOr(defaultValue, 'value', bomObservations[ObservationTag.apparentTemperature]);
  const apparentTemperatureUnits = convertUnit(
    propOr(UnitOfMeasure.Celsius, 'unitOfMeasure', bomObservations[ObservationTag.apparentTemperature]),
  );

  const humidity = propOr(defaultValue, 'value', bomObservations[ObservationTag.humidity]);
  const humidityUnits = convertUnit(
    propOr(UnitOfMeasure.Percent, 'unitOfMeasure', bomObservations[ObservationTag.humidity]),
  );

  const pressure = propOr(defaultValue, 'value', bomObservations[ObservationTag.pressure]);
  const pressureUnits = convertUnit(
    propOr(UnitOfMeasure.Hectopascals, 'unitOfMeasure', bomObservations[ObservationTag.pressure]),
  );

  const windSpeed = propOr(defaultValue, 'value', bomObservations[ObservationTag.windSpeed]);
  const windSpeedUnits = convertUnit(
    propOr(UnitOfMeasure['Kilometres per hour'], 'unitOfMeasure', bomObservations[ObservationTag.windSpeed]),
  );

  const windDirection = propOr(defaultValue, 'value', bomObservations[ObservationTag.windDirection]);
  const tempWidgets = map((observation: any) => {
    return (
      <TemperatureForecastWidget
        key={observation.date}
        type="temperature-white"
        date={observation.observationDate}
        min={observation.min}
        max={observation.max}
        day={observation.observationDay}
      />
    );
  }, tempForecastObservations);

  return (
    <div className={classes.content}>
      <div className={classes.summaryContent}>
        <div style={{ paddingLeft: '18%' }}>
          <div className={classes.weatherTitle}>
            <Typography>Today&apos; Weather</Typography>
            <Typography data-testid="time">{`${observationTime}`}</Typography>
          </div>
          <Grid container className={classes.temperatureContent}>
            <Grid item xs={6}>
              <Typography
                data-testid="air-temperature"
                variant="h5">{`${airTemperature} ${airTemperatureUnits}`}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.temperatureItems}>
              <Typography>Feels like: {`${apparentTemperature} ${apparentTemperatureUnits}`}</Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            // backgroundColor: 'orange',
            height: 'auto',
            width: 'auto',
            // overflowX: 'scroll',
          }}>
          {' '}
          {tempWidgets}
        </div>
      </div>
      <div className={classes.detailedContent}>
        <div className={classes.detailedItems}>
          <Typography>Humidity:</Typography>
          <Typography data-testid="humidity">{`${humidity} ${humidityUnits}`}</Typography>
        </div>
        <div className={classes.detailedItems}>
          <Typography>Pressure:</Typography>
          <Typography data-testid="pressure">{`${pressure} ${pressureUnits}`}</Typography>
        </div>
        <div className={classes.detailedItems}>
          <Typography>Wind speed:</Typography>
          <Typography data-testid="wind-speed">{`${windSpeed} ${windSpeedUnits}`}</Typography>
        </div>
        <div className={classes.detailedItems}>
          <Typography>Wind direction:</Typography>
          <Typography data-testid="wind-direction">{`${windDirection}`}</Typography>
        </div>
      </div>
    </div>
  );
};

export default WeatherSection;
