import {
  getRoomObservations,
  getBuildingSpaceDetails,
  getFloorObservations,
  getRoomSpaceDetails,
  getFloorSpaceDetails,
  getCampusSpaceDetails,
  getFloorsSummary,
  getBuildingObservations,
  getBuildingsSummary,
  getRoomsSummary,
  getCampusObservations,
  getRoomHistoricalObservations,
  getCampusHistoricalObservations,
  getBuildingHistoricalObservations,
  getFloorHistoricalObservations,
  getCampusTemperatureForecastObservations,
  getCampusEnergyForecastObservations,
  getBuildingEnergyForecastObservations,
  getBuildingPopularTimings,
  postBuildingEnergyIntervention,
  getBuildingEnergyInterventions,
  removeSelectedIntervention,
} from 'services/ApiService';
import { Dispatch } from 'redux';
import {
  ROOM_OBSERVATIONS_REQUESTED,
  ROOM_OBSERVATIONS_RECEIVED,
  ROOM_OBSERVATIONS_FAILED,
  BUILDING_SPACE_DETAILS_REQUESTED,
  BUILDING_SPACE_DETAILS_FAILED,
  BUILDING_SPACE_DETAILS_RECEIVED,
  ROOM_SPACE_DETAILS_REQUESTED,
  ROOM_SPACE_DETAILS_RECEIVED,
  ROOM_SPACE_DETAILS_FAILED,
  FLOOR_OBSERVATIONS_REQUESTED,
  FLOOR_OBSERVATIONS_RECEIVED,
  FLOOR_OBSERVATIONS_FAILED,
  FLOOR_SPACE_DETAILS_REQUESTED,
  FLOOR_SPACE_DETAILS_RECEIVED,
  FLOOR_SPACE_DETAILS_FAILED,
  CAMPUS_SPACE_DETAILS_REQUESTED,
  CAMPUS_SPACE_DETAILS_RECEIVED,
  CAMPUS_SPACE_DETAILS_FAILED,
  FLOORS_SUMMARY_REQUESTED,
  FLOORS_SUMMARY_RECEIVED,
  FLOORS_SUMMARY_FAILED,
  BUILDING_OBSERVATIONS_REQUESTED,
  BUILDING_OBSERVATIONS_RECEIVED,
  BUILDING_OBSERVATIONS_FAILED,
  BUILDINGS_SUMMARY_REQUESTED,
  BUILDINGS_SUMMARY_RECEIVED,
  BUILDINGS_SUMMARY_FAILED,
  ROOMS_SUMMARY_REQUESTED,
  ROOMS_SUMMARY_RECEIVED,
  ROOMS_SUMMARY_FAILED,
  CAMPUS_OBSERVATIONS_REQUESTED,
  CAMPUS_OBSERVATIONS_RECEIVED,
  CAMPUS_OBSERVATIONS_FAILED,
  ROOM_OBSERVATIONS_HISTORY_REQUESTED,
  ROOM_OBSERVATIONS_HISTORY_RECEIVED,
  ROOM_OBSERVATIONS_HISTORY_FAILED,
  CAMPUS_SPACE_CHANGED,
  BUILDING_SPACE_CHANGED,
  FLOOR_SPACE_CHANGED,
  ROOM_SPACE_CHANGED,
  CAMPUS_OBSERVATIONS_HISTORY_REQUESTED,
  CAMPUS_OBSERVATIONS_HISTORY_RECEIVED,
  CAMPUS_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_OBSERVATIONS_HISTORY_FAILED,
  FLOOR_OBSERVATIONS_HISTORY_FAILED,
  FLOOR_OBSERVATIONS_HISTORY_RECEIVED,
  FLOOR_OBSERVATIONS_HISTORY_REQUESTED,
  TEMPERATURE_FORECAST_REQUESTED,
  TEMPERATURE_FORECAST_RECEIVED,
  TEMPERATURE_FORECAST_ERROR,
  CAMPUS_ENERGY_FORECAST_REQUESTED,
  CAMPUS_ENERGY_FORECAST_RECEIVED,
  CAMPUS_ENERGY_FORECAST_ERROR,
  CAMPUS_TOTAL_ENERGY_HISTORY_REQUESTED,
  CAMPUS_TOTAL_ENERGY_HISTORY_RECEIVED,
  BUILDING_ENERGY_FORECAST_REQUESTED,
  BUILDING_ENERGY_FORECAST_RECEIVED,
  BUILDING_ENERGY_FORECAST_ERROR,
  CAMPUS_TOTAL_ENERGY_HISTORY_ERROR,
  BUILDING_MONTHLY_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_MONTHLY_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_MONTHLY_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_POPULAR_TIMINGS_REQUESTED,
  BUILDING_POPULAR_TIMINGS_RECEIVED,
  BUILDING_POPULAR_TIMINGS_FAILED,
  BUILDING_ENERGY_INTERVENTION_POST_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_POST_RECEIVED,
  BUILDING_ENERGY_INTERVENTION_POST_FAILED,
  BUILDING_ENERGY_INTERVENTION_POST_NOT_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_GET_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_GET_RECEIVED,
  BUILDING_ENERGY_INTERVENTION_GET_FAILED,
  BUILDING_ENERGY_INTERVENTION_DELETE_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_DELETE_RECEIVED,
  BUILDING_ENERGY_INTERVENTION_DELETE_FAILED,
  BUILDING_ENERGY_INTERVENTION_DELETE_NOT_REQUESTED,
  BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_FAILED,
} from './types';
import { BuildingSpace } from 'models/Building';
import { FloorSpace } from 'models/Floor';
import { Space } from 'models/Space';
import { Summary } from 'models/Summary';
import { CampusSpace } from 'models/Campus';
import {
  BuildingEnergyInterventionGETResponse,
  BuildingEnergyInterventionPOSTResponse,
  BuildingPopularTimingsResponse,
  ForecastApiResponse,
  ObservationClass,
  ObservationsApiResponse,
  ObservationType,
} from 'models/Observation';
import Logger from 'utils/logger';
import { DisplayTimeZone } from 'components/common/DateTime';
import momentTz from 'moment-timezone';
import { isNil } from 'ramda';

export const getRoomObservationsAction = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  roomNumber: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ROOM_OBSERVATIONS_REQUESTED,
    });

    getRoomObservations(campus, buildingNumber, floorNumber, roomNumber, accessToken)
      .then((roomObservations: ObservationsApiResponse): void => {
        dispatch({
          type: ROOM_OBSERVATIONS_RECEIVED,
          payload: roomObservations,
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: ROOM_OBSERVATIONS_FAILED,
          payload: { error: error.message },
        });
      });
  };
};

export const getRoomHistoricalObservationsAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  roomNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ROOM_OBSERVATIONS_HISTORY_REQUESTED,
    });
    Logger.debug('getRoomHistoricalObsrvationActions observationType', observationType);
    Logger.debug('getRoomHistoricalObsrvationActions roomNumber', roomNumber);
    getRoomHistoricalObservations(
      campus,
      buildingNumber,
      floorNumber,
      roomNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((historicalRoomObservations: ObservationsApiResponse): void => {
        dispatch({
          type: ROOM_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...historicalRoomObservations,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: ROOM_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getFloorHistoricalObservationsAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: FLOOR_OBSERVATIONS_HISTORY_REQUESTED,
    });

    getFloorHistoricalObservations(
      campus,
      buildingNumber,
      floorNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((historicalFloorObservations: ObservationsApiResponse): void => {
        dispatch({
          type: FLOOR_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...historicalFloorObservations,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: FLOOR_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getRoomSpaceDetailsAction = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  roomNumber: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ROOM_SPACE_DETAILS_REQUESTED,
    });

    getRoomSpaceDetails(campus, buildingNumber, floorNumber, roomNumber, accessToken)
      .then((roomSpaceDetails: Space): void => {
        dispatch({
          type: ROOM_SPACE_DETAILS_RECEIVED,
          payload: roomSpaceDetails,
        });
      })
      .catch((error: Error): void => {
        error.name = `${roomNumber} at Level ${floorNumber} in Building ${buildingNumber}`;
        dispatch({
          type: ROOM_SPACE_DETAILS_FAILED,
          payload: error,
        });
      });
  };
};

export const roomChangedAction = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  roomNumber: string,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ROOM_SPACE_CHANGED,
      payload: {
        locationCode: `${campus};${buildingNumber};${floorNumber};${roomNumber}`,
      },
    });
  };
};

export const getRoomsSummaryAction = (
  campusId = 'PAR',
  buildingNumber: string,
  floorId: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ROOMS_SUMMARY_REQUESTED,
    });

    getRoomsSummary(campusId, buildingNumber, floorId, accessToken)
      .then((roomsSummary: Summary[]): void => {
        dispatch({
          type: ROOMS_SUMMARY_RECEIVED,
          payload: roomsSummary,
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: ROOMS_SUMMARY_FAILED,
          payload: error,
        });
      });
  };
};

export const getFloorSpaceDetailsAction = (
  campusId = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: FLOOR_SPACE_DETAILS_REQUESTED,
    });

    getFloorSpaceDetails(campusId, buildingNumber, floorNumber, accessToken)
      .then((floorSpaceDetails: FloorSpace): void => {
        dispatch({
          type: FLOOR_SPACE_DETAILS_RECEIVED,
          payload: floorSpaceDetails,
        });
      })
      .catch((error: Error): void => {
        error.name = `${floorNumber} in building ${buildingNumber}`;
        dispatch({
          type: FLOOR_SPACE_DETAILS_FAILED,
          payload: error,
        });
      });
  };
};

// TODO: add ThunkAction type safety here
export const getFloorObservationDetailsAction = (
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: FLOOR_OBSERVATIONS_REQUESTED,
    });

    // Do the real thing (and update the state)
    getFloorObservations(campus, buildingNumber, floorNumber, accessToken)
      .then((floorObservations: ObservationsApiResponse): void => {
        dispatch({
          type: FLOOR_OBSERVATIONS_RECEIVED,
          payload: floorObservations,
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: FLOOR_OBSERVATIONS_FAILED,
          payload: { error: error.message },
        });
      });
  };
};

export const getFloorsSummaryAction = (
  campusId = 'PAR',
  buildingNumber: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: FLOORS_SUMMARY_REQUESTED,
    });

    getFloorsSummary(campusId, buildingNumber, accessToken)
      .then((floorsSummary: Summary[]): void => {
        dispatch({
          type: FLOORS_SUMMARY_RECEIVED,
          payload: floorsSummary,
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: FLOORS_SUMMARY_FAILED,
          payload: error,
        });
      });
  };
};

// TODO: add ThunkAction type safety here
export const getBuildingSpaceDetailsAction = (
  campus = 'PAR',
  buildingNumber: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_SPACE_DETAILS_REQUESTED,
    });

    // Do the real thing (and update the state)
    getBuildingSpaceDetails(campus, buildingNumber, accessToken)
      .then((buildingDetails: BuildingSpace): void => {
        dispatch({
          type: BUILDING_SPACE_DETAILS_RECEIVED,
          payload: buildingDetails,
        });
      })
      .catch((error: Error): void => {
        error.name = buildingNumber;
        dispatch({
          type: BUILDING_SPACE_DETAILS_FAILED,
          payload: error,
        });
      });
  };
};

export const buildingChangedAction = (campus = 'PAR', buildingNumber: string): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_SPACE_CHANGED,
      payload: {
        locationCode: `${campus};${buildingNumber}`,
      },
    });
  };
};

export const floorChangedAction = (campus = 'PAR', buildingNumber: string, floorId: string): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: FLOOR_SPACE_CHANGED,
      payload: {
        locationCode: `${campus};${buildingNumber};${floorId}`,
      },
    });
  };
};

export const getBuildingObservationsAction = (
  campus = 'PAR',
  buildingNumber: string,
  accessToken: string | undefined,
  type: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_OBSERVATIONS_REQUESTED,
    });
    let params = null;
    if (!isNil(type)) {
      params = {
        type,
      };
    }
    getBuildingObservations(campus, buildingNumber, params, accessToken)
      .then((buildingObservations: ObservationsApiResponse): void => {
        dispatch({
          type: BUILDING_OBSERVATIONS_RECEIVED,
          payload: buildingObservations,
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: BUILDING_OBSERVATIONS_FAILED,
          payload: { error: error.message },
        });
      });
  };
};

export const getBuildingObservationsHistoryAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_OBSERVATIONS_HISTORY_REQUESTED,
    });

    getBuildingHistoricalObservations(
      campus,
      buildingNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((buildingObservationsHistory: ObservationsApiResponse): void => {
        dispatch({
          type: BUILDING_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...buildingObservationsHistory,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: BUILDING_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getBuildingMonthlyObservationsHistoryAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_MONTHLY_OBSERVATIONS_HISTORY_REQUESTED,
    });

    getBuildingHistoricalObservations(
      campus,
      buildingNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((buildingObservationsHistory: ObservationsApiResponse): void => {
        dispatch({
          type: BUILDING_MONTHLY_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...buildingObservationsHistory,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: BUILDING_MONTHLY_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getBuildingQuarterlyObservationsHistoryAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_REQUESTED,
    });

    getBuildingHistoricalObservations(
      campus,
      buildingNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((buildingObservationsHistory: ObservationsApiResponse): void => {
        dispatch({
          type: BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...buildingObservationsHistory,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getBuildingQuarterlyBaselineObservationsAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_REQUESTED,
    });

    getBuildingHistoricalObservations(
      campus,
      buildingNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((buildingObservationsHistory: ObservationsApiResponse): void => {
        dispatch({
          type: BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...buildingObservationsHistory,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getBuildingPopularTimingsAction = (
  /* campusId: string, */
  buildingNumber: string,
  accessToken: string,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_POPULAR_TIMINGS_REQUESTED,
    });
    getBuildingPopularTimings(/* campusId, */ buildingNumber, accessToken)
      .then((popularTimings: BuildingPopularTimingsResponse): void => {
        dispatch({
          type: BUILDING_POPULAR_TIMINGS_RECEIVED,
          payload: { popularTimings },
        });
      })
      .catch((err: Error): void => {
        Logger.error('Error getBuildingPopularTimingsAction', { err });
        dispatch({
          type: BUILDING_POPULAR_TIMINGS_FAILED,
          payload: {},
        });
      });
  };
};

export const getFloorObservationsHistoryAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  floorNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: FLOOR_OBSERVATIONS_HISTORY_REQUESTED,
    });

    getFloorHistoricalObservations(
      campus,
      buildingNumber,
      floorNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((floorObservationsHistory: ObservationsApiResponse): void => {
        dispatch({
          type: FLOOR_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...floorObservationsHistory,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: FLOOR_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getCampusSpaceDetailsAction = (campus = 'PAR', accessToken: string | undefined): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CAMPUS_SPACE_DETAILS_REQUESTED,
    });

    getCampusSpaceDetails(campus, accessToken)
      .then((campusDetails: CampusSpace): void => {
        dispatch({
          type: CAMPUS_SPACE_DETAILS_RECEIVED,
          payload: campusDetails,
        });
      })
      .catch((error: Error): void => {
        error.name = campus;
        dispatch({
          type: CAMPUS_SPACE_DETAILS_FAILED,
          payload: error,
        });
      });
  };
};

export const campusChangedAction = (campus = 'PAR'): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CAMPUS_SPACE_CHANGED,
      payload: {
        locationCode: `${campus}`,
      },
    });
  };
};

export const getCampusObservationsAction = (campus = 'PAR', accessToken: string | undefined): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CAMPUS_OBSERVATIONS_REQUESTED,
    });

    getCampusObservations(campus, accessToken)
      .then((campusObservations: ObservationsApiResponse): void => {
        dispatch({
          type: CAMPUS_OBSERVATIONS_RECEIVED,
          payload: campusObservations,
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: CAMPUS_OBSERVATIONS_FAILED,
          payload: { error: error.message },
        });
      });
  };
};

export const getCampusObservationsHistoryAction = (
  historyResponseIndex: string,
  campus = 'PAR',
  startDateTime: string,
  endDateTime: string,
  observationClass: string,
  observationType: string,
  observationTag: string | undefined = undefined,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CAMPUS_OBSERVATIONS_HISTORY_REQUESTED,
    });

    getCampusHistoricalObservations(
      campus,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((campusObservationsHistory: ObservationsApiResponse): void => {
        dispatch({
          type: CAMPUS_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...campusObservationsHistory,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: CAMPUS_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};

export const getBuildingsSummaryAction = (campus = 'PAR', accessToken: string | undefined): any => {
  return (dispatch: any): void => {
    dispatch({
      type: BUILDINGS_SUMMARY_REQUESTED,
    });

    getBuildingsSummary(campus, accessToken)
      .then((buildingStatuses: Summary[]): void => {
        dispatch({
          type: BUILDINGS_SUMMARY_RECEIVED,
          payload: buildingStatuses,
        });
      })
      .catch((error: any): void =>
        dispatch({
          type: BUILDINGS_SUMMARY_FAILED,
          payload: error,
        }),
      );
  };
};

export const getCampusTemperatureForecastAction = (campus = 'PAR', accessToken: string | undefined): any => {
  return (dispatch: any): void => {
    dispatch({
      type: TEMPERATURE_FORECAST_REQUESTED,
    });
    const startDate = momentTz.tz(DisplayTimeZone).add(1, 'days').format('YYYY-MM-DD');
    const endDate = momentTz.tz(DisplayTimeZone).add(5, 'days').format('YYYY-MM-DD');
    getCampusTemperatureForecastObservations(campus, accessToken, startDate, endDate)
      .then((data: ForecastApiResponse) => {
        dispatch({
          type: TEMPERATURE_FORECAST_RECEIVED,
          payload: data,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: TEMPERATURE_FORECAST_ERROR,
          error,
        });
      });
  };
};

export const getCampusTotalEnergyHistoryAction = (
  campus = 'PAR',
  startDate: string,
  endDate: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: any): void => {
    dispatch({
      type: CAMPUS_TOTAL_ENERGY_HISTORY_REQUESTED,
    });

    getCampusHistoricalObservations(
      campus,
      startDate,
      endDate,
      ObservationClass.energy,
      ObservationType.energy,
      'TOTAL-WHCONSUMPTION#CLARITI',
      accessToken,
    )
      .then((data: ObservationsApiResponse) => {
        dispatch({
          type: CAMPUS_TOTAL_ENERGY_HISTORY_RECEIVED,
          payload: data,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: CAMPUS_TOTAL_ENERGY_HISTORY_ERROR,
          error,
        });
      });
  };
};

export const getCampusEnergyForecastAction = (
  campus = 'PAR',
  startDate: string,
  endDate: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: any): void => {
    dispatch({
      type: CAMPUS_ENERGY_FORECAST_REQUESTED,
    });

    getCampusEnergyForecastObservations(campus, startDate, endDate, accessToken)
      .then((data: ForecastApiResponse) => {
        dispatch({
          type: CAMPUS_ENERGY_FORECAST_RECEIVED,
          payload: data,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: CAMPUS_ENERGY_FORECAST_ERROR,
          error,
        });
      });
  };
};

export const getBuildingEnergyForecastAction = (
  campus = 'PAR',
  buildingNumber: string,
  startDate: string,
  endDate: string,
  accessToken: string,
): any => {
  return (dispatch: any): void => {
    dispatch({
      type: BUILDING_ENERGY_FORECAST_REQUESTED,
    });
    getBuildingEnergyForecastObservations(campus, buildingNumber, startDate, endDate, accessToken)
      .then((data: ForecastApiResponse) =>
        dispatch({
          type: BUILDING_ENERGY_FORECAST_RECEIVED,
          payload: data,
        }),
      )
      .catch((error: any) => {
        dispatch({
          type: BUILDING_ENERGY_FORECAST_ERROR,
          error,
        });
      });
  };
};

export const getBuildingEnergyInterventionsAction =
  (campusId: string, buildingNumber: string, accessToken: string): any =>
  (dispatch: any): void => {
    dispatch({
      type: BUILDING_ENERGY_INTERVENTION_GET_REQUESTED,
    });
    getBuildingEnergyInterventions(accessToken, campusId, buildingNumber)
      .then((response: BuildingEnergyInterventionGETResponse): void => {
        dispatch({
          type: BUILDING_ENERGY_INTERVENTION_GET_RECEIVED,
          payload: response,
        });
      })
      .catch((error: any): void => {
        dispatch({
          type: BUILDING_ENERGY_INTERVENTION_GET_FAILED,
          error,
        });
      });
  };

export const addOrUpdateMaintenanceNote =
  (
    accessToken: string,
    comments: string,
    buildingNumber: string,
    campusId: string,
    cost: string,
    energySavings: string,
    interventionDate: string,
    interventionType: string,
    triggered: boolean,
  ): any =>
  (dispatch: any): void => {
    if (!triggered) {
      dispatch({
        type: BUILDING_ENERGY_INTERVENTION_POST_NOT_REQUESTED,
      });
    } else {
      dispatch({
        type: BUILDING_ENERGY_INTERVENTION_POST_REQUESTED,
      });
      postBuildingEnergyIntervention(
        accessToken,
        comments,
        buildingNumber,
        campusId,
        Number(cost),
        Number(energySavings),
        momentTz(interventionDate).tz(DisplayTimeZone).format('YYYY-MM-DD'),
        interventionType,
      )
        .then((data: BuildingEnergyInterventionPOSTResponse): void => {
          dispatch({
            type: BUILDING_ENERGY_INTERVENTION_POST_RECEIVED,
            payload: data,
          });
          dispatch(getBuildingEnergyInterventionsAction(campusId, buildingNumber, accessToken));
        })
        .catch((error: any) => {
          dispatch({
            type: BUILDING_ENERGY_INTERVENTION_POST_FAILED,
            error,
          });
        });
    }
  };

export const removeSelectedInterventionAction =
  (
    accessToken: string,
    campusId: string,
    buildingNumber: string,
    interventionDate: string,
    interventionType: string,
  ): any =>
  (dispatch: any): void => {
    dispatch({
      type: BUILDING_ENERGY_INTERVENTION_DELETE_REQUESTED,
    });

    removeSelectedIntervention(accessToken, campusId, buildingNumber, interventionDate, interventionType)
      .then((response: any) => {
        Logger.debug('Response: removeSelectedIntervention', { response });
        dispatch({
          type: BUILDING_ENERGY_INTERVENTION_DELETE_RECEIVED,
        });
        dispatch(getBuildingEnergyInterventionsAction(campusId, buildingNumber, accessToken));
      })
      .catch((error: any) => {
        dispatch({
          type: BUILDING_ENERGY_INTERVENTION_DELETE_FAILED,
          error,
        });
      });
  };

export const resetInterventionDeletion =
  (): any =>
  (dispatch: any): void =>
    dispatch({
      type: BUILDING_ENERGY_INTERVENTION_DELETE_NOT_REQUESTED,
    });

export const getBuildingCostTagObservations = (
  historyResponseIndex: string,
  campus = 'PAR',
  buildingNumber: string,
  startDateTime: string,
  endDateTime: string,
  observationType: string,
  observationClass: string,
  observationTag: string,
  accessToken: string | undefined,
): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_REQUESTED,
      payload: {
        locationCode: campus.concat(`;${buildingNumber}`),
      },
    });

    getBuildingHistoricalObservations(
      campus,
      buildingNumber,
      startDateTime,
      endDateTime,
      observationClass,
      observationType,
      observationTag,
      accessToken,
    )
      .then((buildingObservationsHistory: ObservationsApiResponse): void => {
        dispatch({
          type: BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_RECEIVED,
          observationHistoryIndex: historyResponseIndex,
          observationClass,
          observationType,
          payload: {
            ...buildingObservationsHistory,
          },
        });
      })
      .catch((error: Error): void => {
        dispatch({
          type: BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_FAILED,
          payload: error,
        });
      });
  };
};
