import React, { ReactElement } from 'react';
import { history } from 'components/App/AppHistory';
import Button from './Button';
import makeStyles from '@material-ui/styles/makeStyles';
import { UOM_LIGHT_BLUE } from 'styles/styleConstants';
import { matchPath, match } from 'react-router';
import { UrlParams } from 'components/Mazemap/MapTypes';

const homeClickHandler = (): void => {
  const path = history.location.pathname;
  const matchedPath: match<UrlParams> | null = matchPath(path, {
    path: '/campus/:campusId',
  });
  if (matchedPath) {
    history.push(`/campus/${matchedPath.params.campusId}`);
  }
};

const backClickHandler = (): void => {
  const path = history.location.pathname;
  const indexOfSubPathToRemove = path.lastIndexOf('/', path.lastIndexOf('/') - 1);
  const newPath = path.substring(0, indexOfSubPathToRemove);
  if (newPath !== '') {
    history.push(newPath);
  }
};

const useStyles = makeStyles({
  navigation: {
    background: UOM_LIGHT_BLUE,
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
});

const Navigation = (): ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.navigation}>
      <Button label="Home" clickHandler={homeClickHandler} />
      <Button label="Back" clickHandler={backClickHandler} />
    </div>
  );
};

export default Navigation;
