import { pathOr, path } from 'ramda';
import MapBuilding from 'models/MapBuilding';
import { MapType, Bounds, LongLat } from 'components/Mazemap/MapTypes';
import { Feature } from 'geojson';
import { MazeMapCampusDetails } from 'components/constants';
import { Summary } from 'models/Summary';
import Logger from 'utils/logger';
import { getCampusId } from 'components/common/LocationCodeUtils';

const getLongitude = (mazeMapCoordinate: Bounds): any => pathOr(null, [0], mazeMapCoordinate);
const getLatitude = (mazeMapCoordinate: Bounds): any => pathOr(null, [1], mazeMapCoordinate);

function reduceLngLat(previousCoordinateRange: Bounds, lngLat: Bounds): Bounds {
  return {
    minLng: Math.min(previousCoordinateRange.minLng, getLongitude(lngLat)),
    maxLng: Math.max(previousCoordinateRange.maxLng, getLongitude(lngLat)),
    minLat: Math.min(previousCoordinateRange.minLat, getLatitude(lngLat)),
    maxLat: Math.max(previousCoordinateRange.maxLat, getLatitude(lngLat)),
  };
}

function getMinMaxLngLat(mazeMapCoordinateArray: any): Bounds {
  return mazeMapCoordinateArray.reduce(reduceLngLat, {
    minLng: Number.MAX_VALUE,
    maxLng: -Number.MAX_VALUE,
    minLat: Number.MAX_VALUE,
    maxLat: -Number.MAX_VALUE,
  });
}

class MazemapDataService {
  private Mazemap: MapType;
  private Buildings: { [index: string]: any };
  private BuildingsByMazemapId: { [index: string]: any };

  private buildingsByMazemapIdIndex = (campusId: number, buildingId: number): string => `${campusId}-${buildingId}`;

  public constructor(mazemap: MapType) {
    this.Mazemap = mazemap;
    this.Buildings = {};
    this.BuildingsByMazemapId = {};
  }

  public getBuildings = async (campusId: number): Promise<any> => {
    const buildingsResponse = await this.Mazemap.Data.getBuildingsByCampusId(campusId);
    buildingsResponse.forEach((building: Feature): any => {
      const newBuilding = new MapBuilding(
        building.properties!.id,
        campusId,
        building.properties!.name,
        this.getCenterLngLat(pathOr([[0, 0]], ['geometry', 'coordinates', 0], building)),
        building.geometry,
        building.properties!.floors,
      );
      this.Buildings[String(newBuilding.uomBuildingId)] = newBuilding;
      this.BuildingsByMazemapId[this.buildingsByMazemapIdIndex(campusId, newBuilding.id)] = newBuilding;
    });
    return buildingsResponse;
  };

  public getBuildingsList = (): any => {
    return path(['Buildings'], this);
  };

  public getBuildingsLngLat = async (campusId: number): Promise<LongLat[]> => {
    const buildingsResponse = await this.Mazemap.Data.getBuildingsByCampusId(campusId);
    const buildingLongLat: LongLat[] = [];
    buildingsResponse.forEach((building: Feature): any => {
      buildingLongLat.push(this.getCenterLngLat(pathOr([[0, 0]], ['geometry', 'coordinates', 0], building)));
    });
    return buildingLongLat;
  };

  public getBuilding = (buildingNumber: string): MapBuilding | undefined => {
    return path(['Buildings', buildingNumber], this);
  };

  public getBuildingByMazemapId = (campusId: number, buildingId: number): MapBuilding | undefined => {
    return path(['BuildingsByMazemapId', this.buildingsByMazemapIdIndex(campusId, buildingId)], this);
  };

  public getCenterLngLat = (mazeMapCoordinates: any): LongLat => {
    const lngLatRange = getMinMaxLngLat([...mazeMapCoordinates]);
    return {
      lng: (lngLatRange.maxLng + lngLatRange.minLng) / 2,
      lat: (lngLatRange.maxLat + lngLatRange.minLat) / 2,
    };
  };

  public getBuildingLngLat = (uomBuildingId: string): LongLat | null => {
    const lngLat = pathOr(null, ['lngLat'], this.Buildings[uomBuildingId]);
    return lngLat;
  };

  public getRoomPoiByLocationCode = async (locationCode: string, campus: string): Promise<Feature> => {
    const [roomPoiResponse]: Feature[] = await this.Mazemap.Data.getPois({
      identifier: locationCode,
      campusid: path([campus, 'id'], MazeMapCampusDetails),
    });

    if (!roomPoiResponse) {
      Logger.error(`MazemapDataService - could not find a poi for location code '${locationCode}'`);
    }

    return roomPoiResponse;
  };

  public getPoisFromSummaries = async (summaries: Summary[]): Promise<Feature[]> => {
    const poiResults = await Promise.all(
      summaries.map(
        async (s: Summary): Promise<Feature> =>
          await this.getRoomPoiByLocationCode(s.location.locationCode, getCampusId(s.location.locationCode)),
      ),
    );
    const validPois = poiResults.filter((poi): boolean => !!poi);
    return validPois;
  };
}

export default MazemapDataService;
