/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Control, IControl, Map } from 'mapbox-gl';

declare let Mazemap: any;

type ClickHandler = (this: Element, event: Event) => void;

/**
 * Wraps the Mazemap ZLevelBarControl so we can attach a click handler
 * to it for our own nefarious needs.
 */
export class ZLevelControl extends Mazemap.ZLevelBarControl implements Control, IControl {
  private containerElement: HTMLElement | null = null;
  private clickHandler: ClickHandler;

  public constructor(options: any, clickHandler: ClickHandler) {
    super(options);
    this.clickHandler = clickHandler;
  }

  public on(type: string, listener: Function): this {
    super.on(type, listener);
    return this;
  }

  public off(type?: string, listener?: Function): this {
    super.off(type, listener);
    return this;
  }

  public once(type: string, listener: Function): this {
    super.once(type, listener);
    return this;
  }

  public fire(type: string, properties?: { [key: string]: any }): this {
    super.fire(type, properties);
    return this;
  }

  public onAdd(map: Map): HTMLElement {
    this.containerElement = super.onAdd(map);
    this.containerElement!.addEventListener('click', this.clickHandler);

    return this.containerElement!;
  }

  public onRemove(map: Map): void {
    return super.onRemove(map);
  }
}
