import React, { ReactElement, useEffect, useState, Fragment } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from './AppHistory';
import { getStore } from 'store/index';
import { ROUTES } from '../LeftPanel/LeftPanel';
import { ThemeProvider } from '@material-ui/styles';
import { theme, useAppStyles } from './AppStyles';
import { selectMapService } from 'store/selectors';
import { isNil } from 'ramda';
import { WaitingProgressIndicator } from 'components/common/WaitingProgressIndicator';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, /* LoginCallback */ SecureRoute } from '@okta/okta-react';
import { authConfig } from 'components/Auth/AuthConfig';
import Login from 'components/Auth/Login';
import { SecureApp } from './SecureApp';
import { ErrorBoundary } from './ErrorBoundary';
import LoginCallback from 'components/Auth/LoginCallback';

const oktaAuth = new OktaAuth(authConfig);

const RootAppContents: React.FC = (): ReactElement => {
  const classes = useAppStyles();

  const mapService = useSelector(selectMapService);
  const [isMapLoaded, setMapLoaded] = useState(false);

  const [progressMessage, setProgressMessage] = useState('');

  useEffect((): void => {
    setMapLoaded(!isNil(mapService));
    if (!isMapLoaded /* && isAuthenticated */) {
      setProgressMessage('Loading map components...');
    }
  }, [isMapLoaded, /* isAuthenticated */ mapService]);

  return (
    <Router history={history}>
      <div className={classes.appContainer}>
        {
          /* isAuthenticated && */ isMapLoaded ? (
            <Fragment>
              <ErrorBoundary>
                <Security oktaAuth={oktaAuth}>
                  <Switch>
                    <Route path="/" exact={true} component={Login} />
                    <Route path="/implicit/callback" component={LoginCallback} />
                    <SecureRoute
                      path={[ROUTES.room, ROUTES.floor, ROUTES.building, ROUTES.campus]}
                      component={SecureApp}
                    />
                  </Switch>
                </Security>
              </ErrorBoundary>
            </Fragment>
          ) : (
            <div className={classes.appStartupProgressIndicator}>
              <WaitingProgressIndicator progressMessage={progressMessage} />
            </div>
          )
        }
      </div>
    </Router>
  );
};

const RootApp = RootAppContents;

const App: React.FC = (): ReactElement => {
  return (
    <Provider store={getStore()}>
      <ThemeProvider theme={theme}>
        <RootApp />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
