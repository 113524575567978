import highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { ForecastObservation } from 'models/Observation';
import { filter, forEach, includes, isEmpty, isNil, prop, sortBy } from 'ramda';
import React from 'react';
import momentTz from 'moment-timezone';
import { DisplayTimeZone } from 'components/common/DateTime';
import moment from 'moment';
import { Button, Grid, Typography } from '@material-ui/core';
import { EnergyPopup } from 'components/common/EnergyPopup';
import { CSVLink } from 'react-csv';
interface EnergyObservations {
  energyForecastObservations?: any;
  energyHistoryObservations?: any;
  locationCode: string;
}

NoDataToDisplay(highcharts);
more(highcharts);

export const createArearangeChart = (
  forecastObs: any[],
  actualEnergyObs: any[],
  locationCode: string,
): highcharts.Options => {
  const forecastRanges: any[] = [];
  const forecastAverages: any[] = [];
  let actual: any[] = [];
  const startOfWeek = momentTz().tz(DisplayTimeZone).startOf('week').format('YYYY-MM-DD');
  const endOfWeek = momentTz().tz(DisplayTimeZone).endOf('week').format('YYYY-MM-DD');
  const xAxisDates: any[] = [];
  // get actual value for current week
  actualEnergyObs = filter((observation: any) => {
    const isInWeekRange =
      startOfWeek <= observation.observationStartTime.split('T')[0] &&
      observation.observationStartTime.split('T')[0] <= endOfWeek;
    return isInWeekRange;
  }, actualEnergyObs);
  //get predicted value for current week
  const currentWeekForecast = filter((observation: any) => {
    const isInWeekRange =
      startOfWeek <= observation.forecastStartTime.split('T')[0] &&
      observation.forecastStartTime.split('T')[0] <= endOfWeek;
    if (isInWeekRange) {
      xAxisDates.push(moment(observation.forecastStartTime.split('T')[0]).format('DD/MM'));
    }
    return isInWeekRange;
  }, forecastObs);

  forEach((observation: any) => {
    /* actual.push([
      Date.parse(observation.observationStartTime.split('T')[0]),
      Number(observation.value) / 1000,
    ]) */
    actual.push({
      x: Date.parse(observation.observationStartTime.split('T')[0]),
      y: Number(observation.value) / 1000,
    });
    actual = sortBy(prop('x'), actual);
  }, actualEnergyObs);

  forEach((observation: ForecastObservation) => {
    forecastRanges.push([
      Date.parse(observation.forecastStartTime.split('T')[0]),
      Number(Number(observation.valueHat_lower).toPrecision(8)),
      Number(Number(observation.valueHat_higher).toPrecision(8)),
    ]);
    forecastAverages.push([
      Date.parse(observation.forecastStartTime.split('T')[0]),
      Number(Number(observation.valueHat).toPrecision(8)),
    ]);
  }, currentWeekForecast);
  let title: string = 'Current week energy forecast';
  if (locationCode.split(';').length === 1 && includes('PAR', locationCode)) {
    title = 'Current week energy forecast<br><sub>(includes Parkville, Residential and Commercial sites)</sub>';
  }
  const options: highcharts.Options = {
    title: {
      text: title,
      useHTML: true,
      align: 'center',
    },
    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Range:',
      },
    },
    yAxis: {
      title: {
        text: 'kwh',
      },
    },
    tooltip: {
      shared: true,
      valueSuffix: 'kw',
    },
    lang: {
      noData: 'No forecast available for the period',
    },
    noData: {
      position: {
        align: 'center',
        verticalAlign: 'middle',
      },
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
      },
    },
    series: [
      {
        name: 'Forecasted Value',
        data: forecastAverages,
        color: 'green',
        zIndex: 1,
        type: 'line',
      },
      {
        name: 'Actual Value',
        data: actual,
        type: 'line',
        zIndex: 2,
        color: 'black',
      },
      {
        name: 'Forecasted Range(Lower-Upper)',
        data: forecastRanges,
        type: 'arearange',
        lineWidth: 0,
        color: '#4074B2',
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
      },
    ],
  };
  return options;
};

export const EnergyForecastComponent = (props: EnergyObservations): React.ReactElement => {
  const csvData: any[] = [['Location', 'Date', 'Value', 'Value Lower', 'Value Upper']];
  let fileName = '';
  if (!isNil(props.energyForecastObservations) && !isEmpty(props.energyForecastObservations)) {
    forEach((rec: any) => {
      csvData.push([
        props.locationCode,
        moment(rec.forecastStartTime.split('T')[0]).format('YYYY-MM-DD'),
        rec.valueHat,
        rec.valueHat_lower,
        rec.valueHat_higher,
      ]);
    }, props.energyForecastObservations);
    const startDate = moment(csvData[1][1], 'YYYY-MM-DD')
      .tz(DisplayTimeZone)
      /* .startOf('month') */
      .format('DD MMM YY');
    fileName = `${props.locationCode}_forecast_${startDate}-${moment(
      csvData[csvData.length - 1][1],
      'YYYY-MM-DD',
    ).format('DD MMM YY')}`;
  }

  return (
    <React.Fragment>
      <div data-testid="highchartsForecast">
        <HighchartsReact
          key="energyForecasts"
          highcharts={highcharts}
          immutable={true}
          options={createArearangeChart(
            props.energyForecastObservations,
            props.energyHistoryObservations,
            props.locationCode,
          )}
        />
      </div>
      {!isEmpty(props.energyForecastObservations) && !isNil(props.energyForecastObservations) ? (
        <div>
          <Grid container style={{ backgroundColor: 'white' }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <EnergyPopup
                energyForecastObservations={props.energyForecastObservations}
                historicalObservations={props.energyHistoryObservations}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Button variant="contained" color="primary" style={{ width: '100%', height: '100%' }}>
                <CSVLink data={csvData} style={{ color: 'white' }} filename={fileName}>
                  <Typography variant="body2" style={{ textTransform: 'none' }}>
                    Download Quarterly Forecast{' '}
                  </Typography>
                </CSVLink>
              </Button>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </div>
      ) : null}
    </React.Fragment>
  );
};
