import React, { ReactElement, RefObject } from 'react';
import { createMazemapSearchController, createMazemapSearchInput } from 'services/Map/MazemapFactory';
import './MapSearch.css';
import { Feature } from 'geojson';
import { MazeMapSearchItem } from 'components/Mazemap/MapTypes';
import { MazeMapCampusDetails, CampusNames } from 'components/constants';
import { includes, keys } from 'ramda';
import { Dispatch } from 'redux';
import { getCampusSpaceDetailsAction } from 'store/details/actions';

interface MapSearchProps {
  campusCode: string;
  searchCallback: (event: Feature) => void;
  dispatchProp: Dispatch;
  accessToken: string | undefined;
}

interface MapSearchState {
  campusId: number;
}

export class MapSearch extends React.Component<MapSearchProps, MapSearchState> {
  private searchController: any | null = null;
  private searchInput: any | null = null;

  private searchInputContainerRef: RefObject<HTMLDivElement>;
  private searchFormRef: RefObject<HTMLFormElement>;
  private searchInputRef: RefObject<HTMLInputElement>;
  private searchSuggestionsRef: RefObject<HTMLDivElement>;

  public constructor(props: MapSearchProps) {
    super(props);
    this.searchInputContainerRef = React.createRef();
    this.searchFormRef = React.createRef();
    this.searchInputRef = React.createRef();
    this.searchSuggestionsRef = React.createRef();
    const code = this.getCampusId(props.campusCode);
    if (code === 0) {
      this.state = {
        campusId: code,
      };
      this.props.dispatchProp(getCampusSpaceDetailsAction(props.campusCode, props.accessToken));
    } else {
      this.state = {
        campusId: code, // this.getCampusId(props.campusCode),
      };
    }
  }

  private getCampusId = (campusCode: string): number => {
    const campusId = includes(campusCode, keys(CampusNames))
      ? MazeMapCampusDetails[campusCode].id || MazeMapCampusDetails['PAR'].id
      : 0;
    return campusId;
  };

  public componentDidMount(): void {
    this.searchController = createMazemapSearchController({
      campusid: this.state.campusId,

      rows: 10,

      withpois: false,
      withbuilding: true,
      withtype: false,
      withcampus: false,

      resultsFormat: 'geojson',
    });

    this.searchInput = createMazemapSearchInput({
      container: this.searchInputContainerRef.current,
      input: this.searchInputRef.current,
      suggestions: this.searchSuggestionsRef.current,
      searchController: this.searchController,
    });
    this.searchInput.on('itemclick', (event: MazeMapSearchItem): void => {
      this.props.searchCallback(event.item);
      this.clearSearch();
    });
  }

  public componentDidUpdate(prevProps: Readonly<MapSearchProps>, prevState: Readonly<MapSearchState>): void {
    if (this.hasCampusChanged(prevProps, this.props)) {
      const campusId = this.getCampusId(this.props.campusCode);
      this.setState({
        ...prevState,
        campusId,
      });

      const newOptions: any = {
        ...this.searchController.options,
        campusid: campusId,
      };
      this.searchController.options = newOptions;
      this.clearSearch();
    }
  }

  private hasCampusChanged = (prevProps: Readonly<MapSearchProps>, currentProps: MapSearchProps): boolean =>
    prevProps.campusCode !== currentProps.campusCode;

  private clearSearch = (): void => this.searchInput.clearSearch();

  private submitHandler(event: React.FormEvent<HTMLFormElement>): void {
    // prevent form submission
    event.preventDefault();
  }

  public render(): ReactElement {
    return (
      <div>
        <div ref={this.searchInputContainerRef} className="search-control-default">
          <form
            ref={this.searchFormRef}
            className="search-form default"
            onSubmit={(event): void => this.submitHandler(event)}>
            <input
              ref={this.searchInputRef}
              className="search-input"
              type="text"
              name="search"
              placeholder="Search buildings"
              autoComplete="off"></input>
          </form>
          <div ref={this.searchSuggestionsRef} className="search-suggestions default"></div>
        </div>
      </div>
    );
  }
}

export default MapSearch;
