import { useEffect } from 'react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { includes, prop, isNil } from 'ramda';
import { useAppStyles } from './AppStyles';
import { history } from './AppHistory';
import {
  selectCampusSpaceDetailsRequestStatus,
  selectBuildingSpaceDetailsRequestStatus,
  selectFloorSpaceDetailsRequestStatus,
  selectRoomSpaceDetailsRequestStatus,
  selectObservationsFailed,
} from 'store/selectors';
import Feedback from 'components/Feedback/Feedback';
import Header from 'components/Header/Header';
import LeftPanel from 'components/LeftPanel/LeftPanel';
import Map from '../Map/Map';
import { API_REQUEST_STATUS_FAILED } from 'components/constants';
import { useOktaAuth } from '@okta/okta-react';
import { updateAccessTokenAction } from 'store/auth/actions';
import { setUser } from 'store/user/actions';

export const SecureApp = (): React.ReactElement => {
  const classes = useAppStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken: string | undefined = prop('accessToken', authState.accessToken);
  const campusFetchStatus = useSelector(selectCampusSpaceDetailsRequestStatus);
  const buildingFetchStatus = useSelector(selectBuildingSpaceDetailsRequestStatus);
  const floorFetchStatus = useSelector(selectFloorSpaceDetailsRequestStatus);
  const roomFetchStatus = useSelector(selectRoomSpaceDetailsRequestStatus);
  const observationsAPIErrorStatus = useSelector(selectObservationsFailed);
  const state = includes(API_REQUEST_STATUS_FAILED, [
    campusFetchStatus.status,
    buildingFetchStatus.status,
    floorFetchStatus.status,
    roomFetchStatus.status,
  ])
    ? 400
    : 200;
  const message =
    campusFetchStatus.status === API_REQUEST_STATUS_FAILED
      ? campusFetchStatus.message
      : buildingFetchStatus.status === API_REQUEST_STATUS_FAILED
      ? buildingFetchStatus.message
      : floorFetchStatus.status === API_REQUEST_STATUS_FAILED
      ? floorFetchStatus.message
      : roomFetchStatus.status === API_REQUEST_STATUS_FAILED
      ? roomFetchStatus.message
      : '';
  const dispatch = useDispatch();
  useEffect(() => {
    if (isNil(accessToken) || oktaAuth.tokenManager.hasExpired(accessToken)) {
      oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/' });
      // authService.login('/campus/PAR')
      // history.push('/campus/PAR')
    }
    dispatch(updateAccessTokenAction(authState));
    oktaAuth.getUser().then((info: any) => {
      const name = info.name.split(' ');
      dispatch(
        setUser({
          initials: name[0].charAt(0) + name[1].charAt(0),
          name: info.name,
          groups: info['CampusNowGroups'],
        }),
      );
    });
  }, [accessToken, authState, oktaAuth, dispatch]);

  useEffect(() => {
    if (!isNil(observationsAPIErrorStatus) && observationsAPIErrorStatus.includes('Unauthorized')) {
      oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/' });
    }
  }, [observationsAPIErrorStatus, oktaAuth]);

  return (
    <React.Fragment>
      <Feedback statusCode={state} message={message} history={history} />
      <Header />
      <div className={classes.appContent}>
        <LeftPanel />
        <Map />
      </div>
    </React.Fragment>
  );
};
