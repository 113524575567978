import { extractUomBuildingId } from 'utils/mapUtils';

class MapBuilding {
  public readonly id: number;
  private campusId: number;
  public name: string;
  public readonly uomBuildingId: string;
  public readonly lngLat: any;
  public readonly geometry: any;
  public readonly floors: any;

  public constructor(id: number, campusId: number, name: string, lngLat: any, geometry: any, floors: number[]) {
    this.id = id;
    this.campusId = campusId;
    this.name = name;
    this.uomBuildingId = extractUomBuildingId(this.name);
    this.lngLat = lngLat;
    this.geometry = geometry;
    this.floors = floors;
  }
}

export default MapBuilding;
