export const TOGGLE_ALARM_FILTER = 'TOGGLE_ALARM_FILTER';
export const TOGGLE_UNKNOWN_FILTER = 'TOGGLE_UNKNOWN_FILTER';
export const TOGGLE_NORMAL_FILTER = 'TOGGLE_NORMAL_FILTER';

interface ToggleAlarmFilterAction {
  type: typeof TOGGLE_ALARM_FILTER;
}

interface ToggleUnknownFilterAction {
  type: typeof TOGGLE_UNKNOWN_FILTER;
}

interface ToggleNormalFilterAction {
  type: typeof TOGGLE_NORMAL_FILTER;
}

export type FilterActionTypes = ToggleAlarmFilterAction | ToggleUnknownFilterAction | ToggleNormalFilterAction;
