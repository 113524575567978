import React, { ReactElement, Fragment } from 'react';
import {
  Observation,
  ObservationTag,
  ObservationType,
  getObservationValue,
  getObservationTag,
  getObservationProperty,
  defaultUnknownValue,
} from 'models/Observation';
import { propOr, find, propEq, filter } from 'ramda';
import { truncateDecimalString, formatObservationTime, formatTemperatureValue } from '../common/ObservationFormatters';
import {
  ObservationGroupHeaderProps,
  ObservationGroupRowProps,
  ObservationGroupDetails,
} from './ObservationGroupDetails';
import { TableRow, TableCell, Typography } from '@material-ui/core';

const formatHumidityValue = (numberStr: string): string => truncateDecimalString(numberStr, 2);

const SpaceComfortGroupHeader = ({ classes }: ObservationGroupHeaderProps): ReactElement => (
  <TableRow>
    <TableCell className={classes.observationGroupObservationTime}>
      <Typography variant="caption">Time</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="caption">Current</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="caption">Set Point</Typography>
    </TableCell>
    <TableCell className={classes.observationGroupZoneName}>
      <Typography variant="caption">Zone / Sensor</Typography>
    </TableCell>
  </TableRow>
);

const TemperatureGroupRow = ({
  zoneName,
  index,
  zoneObservations,
  classes,
}: ObservationGroupRowProps): ReactElement => {
  const coolingSetPoint = getObservationValue(
    find((observation: Observation): boolean =>
      getObservationTag(observation).startsWith(ObservationTag.temperatureCoolingSetPoint),
    )(zoneObservations),
  );
  const heatingSetPoint = getObservationValue(
    find((observation: Observation): boolean =>
      getObservationTag(observation).startsWith(ObservationTag.temperatureHeatingSetPoint),
    )(zoneObservations),
  );

  const coolingHeatingSetPoint = `Cooling Set Point: ${coolingSetPoint}, Heating Set Point: ${heatingSetPoint}`;

  const isTemperatureObservation = propEq('observationType', ObservationType.temperature);

  const observationTime = getObservationProperty(
    'observationEndTime',
    find(isTemperatureObservation, zoneObservations),
  );

  const setPoint = getObservationValue(
    find((observation: Observation): boolean => {
      const observationTag = getObservationTag(observation);
      return (
        observationTag.startsWith(ObservationTag.temperatureEffSetPoint) ||
        observationTag.startsWith(ObservationTag.temperatureSetPoint) ||
        observationTag.startsWith(ObservationTag.temperatureRoomSetPoint)
      );
    })(zoneObservations),
  );

  const currentTemperatureObservations = filter(isTemperatureObservation, zoneObservations);
  const isMultipleObservationsInZone = currentTemperatureObservations.length > 1;
  const tableRows = currentTemperatureObservations.map((observation: Observation): ReactElement => {
    const observationValue = getObservationValue(observation);
    const observationTag = getObservationTag(observation);
    const observationTagBeforeZone = propOr('', '0', observationTag.split('#'));
    const observationZoneName = isMultipleObservationsInZone ? `${zoneName} (${observationTagBeforeZone})` : zoneName;
    return (
      <TableRow
        key={`temp-group-${zoneName}-${index}-${observationTag}`}
        className={classes.observationGroupTypeRow}
        title={coolingHeatingSetPoint}
        data-testid={`temp-group-${zoneName}-${index}-${observationTag}`}>
        <TableCell className={classes.observationGroupObservationTime}>
          <Typography>{formatObservationTime(observationTime)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{formatTemperatureValue(observationValue)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{setPoint}</Typography>
        </TableCell>
        <TableCell className={classes.observationGroupZoneName}>
          <Typography>{observationZoneName}</Typography>
        </TableCell>
      </TableRow>
    );
  });

  return <Fragment>{tableRows}</Fragment>;
};

const HumidityGroupRow = ({ zoneName, index, zoneObservations, classes }: ObservationGroupRowProps): ReactElement => {
  const setPoint = getObservationValue(
    find((observation: Observation): boolean =>
      getObservationTag(observation).startsWith(ObservationTag.humiditySetPoint),
    )(zoneObservations),
  );
  const isHumidityObservation = propEq('observationType', ObservationType.humidity);

  const observationTime = getObservationProperty('observationEndTime', find(isHumidityObservation, zoneObservations));

  const currentHumidityObservations = filter(isHumidityObservation, zoneObservations);
  const isMultipleObservationsInZone = currentHumidityObservations.length > 1;
  const tableRows = currentHumidityObservations.map((observation: Observation): ReactElement => {
    const observationValue = getObservationValue(observation);
    const observationTag = getObservationTag(observation);
    const observationTagBeforeZone = propOr('', '0', observationTag.split('#'));
    const observationZoneName = isMultipleObservationsInZone ? `${zoneName} (${observationTagBeforeZone})` : zoneName;
    return (
      <TableRow
        key={`humidity-group-${zoneName}-${index}-${observationTag}`}
        className={classes.observationGroupTypeRow}
        data-testid={`humidity-group-${zoneName}-${index}-${observationTag}`}>
        <TableCell className={classes.observationGroupObservationTime}>
          <Typography>{formatObservationTime(observationTime)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{formatHumidityValue(observationValue)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{setPoint}</Typography>
        </TableCell>
        <TableCell className={classes.observationGroupZoneName}>
          <Typography>{observationZoneName}</Typography>
        </TableCell>
      </TableRow>
    );
  });

  return <Fragment>{tableRows}</Fragment>;
};

const Co2GroupRow = ({ zoneName, index, zoneObservations, classes }: ObservationGroupRowProps): ReactElement => {
  const isCo2Observation = propEq('observationType', ObservationType.co2);

  let observationTime = getObservationProperty('observationEndTime', find(isCo2Observation, zoneObservations));

  const co2SetPoint = find((observation: Observation): boolean =>
    getObservationTag(observation).startsWith(ObservationTag.co2SetPoint),
  )(zoneObservations);

  const setPointValue = getObservationValue(co2SetPoint);

  const currentCo2Observations = filter(isCo2Observation, zoneObservations);
  const isMultipleObservationsInZone = currentCo2Observations.length > 1;

  if (currentCo2Observations.length === 0 && co2SetPoint) {
    // set point available but missing observation
    observationTime = getObservationProperty('observationEndTime', co2SetPoint);
    currentCo2Observations.push({
      ...co2SetPoint,
      observationType: ObservationType.co2,
      observationTag: ObservationTag.co2,
      value: defaultUnknownValue,
    });
  }

  const tableRows = currentCo2Observations.map((observation: Observation): ReactElement => {
    const observationValue = getObservationValue(observation);
    const observationTag = getObservationTag(observation);
    const observationTagBeforeZone = propOr('', '0', observationTag.split('#'));
    const observationZoneName = isMultipleObservationsInZone ? `${zoneName} (${observationTagBeforeZone})` : zoneName;
    return (
      <TableRow
        key={`co2-group-${zoneName}-${index}-${observationTag}`}
        className={classes.observationGroupTypeRow}
        data-testid={`co2-group-${zoneName}-${index}-${observationTag}`}>
        <TableCell className={classes.observationGroupObservationTime}>
          <Typography>{formatObservationTime(observationTime)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{observationValue}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{setPointValue}</Typography>
        </TableCell>
        <TableCell className={classes.observationGroupZoneName}>
          <Typography>{observationZoneName}</Typography>
        </TableCell>
      </TableRow>
    );
  });

  return <Fragment>{tableRows}</Fragment>;
};

export const TemperatureGroupDetails = ObservationGroupDetails(SpaceComfortGroupHeader, TemperatureGroupRow);
export const HumidityGroupDetails = ObservationGroupDetails(SpaceComfortGroupHeader, HumidityGroupRow);

export const Co2GroupDetails = ObservationGroupDetails(SpaceComfortGroupHeader, Co2GroupRow);
