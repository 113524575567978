import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  getBuildingsSummaryAction,
  getCampusObservationsAction,
  getCampusSpaceDetailsAction,
  campusChangedAction,
  getCampusObservationsHistoryAction,
  getCampusTemperatureForecastAction,
  getCampusEnergyForecastAction,
  getCampusTotalEnergyHistoryAction,
} from 'store/details/actions';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { FETCH_INTERVAL } from 'components/constants';
import { isNil, includes, keys, prop } from 'ramda';
import moment from 'moment';
import { DisplayTimeZone, getQuarterlyDateRange } from 'components/common/DateTime';
import { ObservationClass, ObservationTag, ObservationType, BomHistoryIndex } from 'models/Observation';
import { selectMapService, selectMapDataService } from 'store/selectors';
import Logger from '../../utils/logger';
import { CampusNames } from '../constants';
import { useOktaAuth } from '@okta/okta-react';

const CampusControl = ({ match, location }: RouteComponentProps<UrlParams>): null => {
  const dispatch = useDispatch();
  const mapService = useSelector(selectMapService);
  const mapDataService = useSelector(selectMapDataService);
  const { authState } = useOktaAuth();
  const accessToken: string | undefined = prop('accessToken', authState.accessToken);

  const currentDate = moment.tz(DisplayTimeZone).format('YYYY-MM-DD');
  const fetchWeatherObservationsHistory = useCallback(
    (campusId: string, accessToken: string | undefined): void => {
      dispatch(
        getCampusObservationsHistoryAction(
          BomHistoryIndex,
          campusId,
          currentDate,
          currentDate,
          ObservationClass.spaceComfort,
          ObservationType.temperature,
          ObservationTag.apparentTemperature,
          accessToken,
        ),
      );
      dispatch(
        getCampusObservationsHistoryAction(
          BomHistoryIndex,
          campusId,
          currentDate,
          currentDate,
          ObservationClass.spaceComfort,
          ObservationType.humidity,
          ObservationTag.humidity,
          accessToken,
        ),
      );
    },
    [dispatch, currentDate],
  );

  const fetchCampusDetails = useCallback(
    (campusId: string, startDate: string, endDate: string, accessToken: string | undefined): void => {
      fetchWeatherObservationsHistory(campusId, accessToken);
      dispatch(campusChangedAction(campusId));
      dispatch(getCampusSpaceDetailsAction(campusId, accessToken));
      dispatch(getCampusObservationsAction(campusId, accessToken));
      dispatch(getCampusTemperatureForecastAction(campusId, accessToken));
      dispatch(getCampusEnergyForecastAction(campusId, startDate, endDate, accessToken));
      dispatch(getBuildingsSummaryAction(campusId, accessToken));
      dispatch(
        getCampusTotalEnergyHistoryAction(
          campusId,
          startDate,
          moment.tz(DisplayTimeZone).format('YYYY-MM-DD'),
          accessToken,
        ),
      );
    },
    [dispatch, fetchWeatherObservationsHistory],
  );

  const refreshCampusDetails = useCallback(
    (campusId: string, accessToken: string | undefined): void => {
      fetchWeatherObservationsHistory(campusId, accessToken);
      dispatch(getCampusObservationsAction(campusId, accessToken));
      dispatch(getBuildingsSummaryAction(campusId, accessToken));
    },
    [dispatch, fetchWeatherObservationsHistory],
  );

  const [currentCampusId, setCurrentCampusId] = useState('');
  const [currentBuildingNumber, setCurrentBuildingNumber] = useState<string | undefined>(undefined);

  // triggers whenever url parameters change
  useEffect((): void => {
    const campusId = match.params.campusId;
    const [startDate, endDate] = getQuarterlyDateRange(moment.tz('Australia/Melbourne').quarter());
    /*const startDate =  moment
      .tz(DisplayTimeZone)
      .startOf('month')
      .format('YYYY-MM-DD')
     const endDate =  moment(startDate)
      .add(3, 'months')
      .subtract(1, 'day')
      .format('YYYY-MM-DD') */
    if (includes(campusId, keys(CampusNames)) && !isNil(accessToken)) {
      const buildingNumber = match.params.buildingNumber;
      if (!isNil(campusId)) {
        const isCampusChanged = currentCampusId !== campusId;
        if (isCampusChanged) {
          setCurrentCampusId(campusId);
          fetchCampusDetails(campusId, startDate, endDate, accessToken);
        }
        const isBuildingUnselected = !isCampusChanged && !isNil(currentBuildingNumber) && isNil(buildingNumber);
        if (isCampusChanged || isBuildingUnselected) {
          mapService.getHighlightService().resetToCampusView(campusId);
        }
        setCurrentBuildingNumber(buildingNumber);
      }
    }
  }, [
    currentCampusId,
    currentBuildingNumber,
    match.params.buildingNumber,
    match.params.campusId,
    fetchCampusDetails,
    mapService,
    mapDataService,
    accessToken,
  ]);

  // fetch latest data every 2 minutes
  useEffect((): (() => void) => {
    const campusId = match.params.campusId;
    if (includes(campusId, keys(CampusNames)) && !isNil(accessToken)) {
      const fetch = (): void => refreshCampusDetails(campusId, accessToken);
      const fetchInterval = setInterval(fetch, FETCH_INTERVAL);
      return (): void => clearInterval(fetchInterval);
    }
    return (): void => {
      Logger.debug('campus :', campusId);
    };
  }, [match.params.campusId, refreshCampusDetails, accessToken]);

  return null;
};

export default CampusControl;
