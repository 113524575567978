// visibility
export const TOGGLE_ALERTS_VISIBILITY = 'TOGGLE_ALERTS_VISIBILITY';
export const TOGGLE_ENERGY_VISIBILITY = 'TOGGLE_ENERGY_VISIBILITY';
export const TOGGLE_SPACE_COMFORT_VISIBILITY = 'TOGGLE_SPACE_COMFORT_VISIBILITY';
//Space Utilisation
export const TOGGLE_SPACE_UTILISATION_OBSERVATION_VISIBILITY = 'TOGGLE_SPACE_UTILISATION_OBSERVATION_VISIBILITY';
export const CHANGE_SPACE_UTILISATION_HEATMAP_VISIBILITY = 'CHANGE_SPACE_UTILISATION_HEATMAP_VISIBILITY';

export const SET_ALERTS_VISIBILITY = 'SET_ALERTS_VISIBILITY';
export const SET_ENERGY_VISIBILITY = 'SET_ENERGY_VISIBILITY';
export const SET_SPACE_COMFORT_VISIBILITY = 'SET_SPACE_COMFORT_VISIBILITY';

//Space Utilisation
export const SET_SPACE_UTILISATION_OBSERVATION_VISIBILITY = 'SET_SPACE_UTILISATION_OBSERVATION_VISIBILITY';
export const SET_SPACE_UTILISATION_HEATMAP_VISIBILITY = 'SET_SPACE_UTILISATION_HEATMAP_VISIBILITY';

// map drawer
export const TOGGLE_RIGHT_DRAWER_OPEN_CLOSED = 'TOGGLE_RIGHT_DRAWER_OPEN_CLOSED';

// Energy Intervention Notes drawer
export const TOGGLE_ENERGY_INTERVENTION_DRAWER_IS_OPEN = 'TOGGLE_ENERGY_INTERVENTION_DRAWER_IS_OPEN';
export const TOGGLE_NOTES_IS_OPEN = 'TOGGLE_NOTES_IS_OPEN';
export const TOGGLE_UPDATE_NOTES_IS_OPEN = 'TOGGLE_UPDATE_NOTES_IS_OPEN';

// visibility
interface ToggleAlertsVisibilityAction {
  type: typeof TOGGLE_ALERTS_VISIBILITY;
}

interface SetAlertsVisibilityAction {
  type: typeof SET_ALERTS_VISIBILITY;
  payload: boolean;
}

interface ToggleEnergyObservationsVisibilityAction {
  type: typeof TOGGLE_ENERGY_VISIBILITY;
}

interface SetEnergyObservationsVisibilityAction {
  type: typeof SET_ENERGY_VISIBILITY;
  payload: boolean;
}

interface ToggleSpaceComfortVisibilityAction {
  type: typeof TOGGLE_SPACE_COMFORT_VISIBILITY;
}

interface SetSpaceComfortVisibilityAction {
  type: typeof SET_SPACE_COMFORT_VISIBILITY;
  payload: boolean;
}

interface ToggleSpaceUtilisationObservationVisibilityAction {
  type: typeof TOGGLE_SPACE_UTILISATION_OBSERVATION_VISIBILITY;
}

interface SetSpaceUtilisationObservationVisibilityAction {
  type: typeof SET_SPACE_UTILISATION_OBSERVATION_VISIBILITY;
  payload: boolean;
}

// Heat Map
interface ChangeSpaceUtilisationHeatMapVisibilityAction {
  type: typeof CHANGE_SPACE_UTILISATION_HEATMAP_VISIBILITY;
  payload: string;
}

// map drawer
interface ToggleRightDrawerOpenClosed {
  type: typeof TOGGLE_RIGHT_DRAWER_OPEN_CLOSED;
}

interface ToggleEnergyInterventionDrawerIsOpened {
  type: typeof TOGGLE_ENERGY_INTERVENTION_DRAWER_IS_OPEN;
}

interface ToggleNotesIsOpened {
  type: typeof TOGGLE_NOTES_IS_OPEN;
}

interface ToggleUpdateNotesIsOpened {
  type: typeof TOGGLE_UPDATE_NOTES_IS_OPEN;
}

export type UISettingsActionTypes =
  | ToggleAlertsVisibilityAction
  | ToggleEnergyObservationsVisibilityAction
  | ToggleSpaceComfortVisibilityAction
  | ToggleSpaceUtilisationObservationVisibilityAction
  | ChangeSpaceUtilisationHeatMapVisibilityAction
  | ToggleRightDrawerOpenClosed
  | ToggleEnergyInterventionDrawerIsOpened
  | ToggleNotesIsOpened
  | ToggleUpdateNotesIsOpened
  | SetAlertsVisibilityAction
  | SetEnergyObservationsVisibilityAction
  | SetSpaceComfortVisibilityAction
  | SetSpaceUtilisationObservationVisibilityAction;
