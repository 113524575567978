import {
  Drawer,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  Button,
  NativeSelect,
  styled,
  InputBase,
  AppBar,
  Toolbar,
  InputLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthIDToken,
  selectBuildingEnergyInterventions,
  selectIsDrawerOpen,
  selectIsNotesOpen,
} from 'store/selectors';
import { toggleIsEnergyInterventionDrawerOpened, toggleIsNotesOpened } from 'store/UISettings/actions';
import { LEFT_DRAWER_PADDING, LEFT_DRAWER_WIDTH, UOM_DARK_BLUE, UOM_LIGHT_GRAY } from 'styles/styleConstants';
import 'react-calendar/dist/Calendar.css';
import { IconSizes, SVGIcon } from 'components/common/Icons';
import momentTZ from 'moment-timezone';
import { DisplayTimeZone } from 'components/common/DateTime';
import { addOrUpdateMaintenanceNote } from 'store/details/actions';
import { equals, filter, includes, isEmpty, isNil, map, prop, sortBy } from 'ramda';
import { MaintenanceLogForm } from './MaintenanceLogForm';
import { history } from './../App/AppHistory';
import { BuildingEnergyIntervention } from 'models/Observation';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { InterventionTable } from './InterventionTable';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Token } from 'components/Observation/EnergyGroupDetails';

const useStyles = makeStyles({
  drawer: {
    width: `${LEFT_DRAWER_WIDTH}px`,
    flexShrink: 0,
  },
  '& table': {
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  '& th': {
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  '& td': {
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  drawerPaper: {
    width: `${LEFT_DRAWER_WIDTH}px`,
    height: '72%',
    top: '20%',
    left: '37%',
    border: '1px solid black',
  },
  indentedObservation: {
    'margin-left': `${LEFT_DRAWER_PADDING}px`,
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '15px',
  },
  calendarSize: {
    width: '10%',
    height: '10%',
  },
  filterObservationTitle: {
    backgroundColor: 'lightgray',
    width: '100%',
    color: 'black',
  },
  formLabelAlignment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '80%',
  },
  closeDrawerButton: {
    position: 'absolute',
    right: '3px',
  },
  rowBorder: {
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: UOM_LIGHT_GRAY,
    border: '1px solid black',
    borderCollapse: 'collapse',
    padding: '5px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
  },
});

interface Intervention {
  interventionType: string;
  interventionDate: string;
  cost: string;
  energySavings: string;
  comments: string;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid black',
    fontSize: 16,
    padding: '5px 26px 5px 12px',
    height: 25,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}));

export const MaintenanceLogDrawer = (): ReactElement => {
  const buildingNumber = history.location.pathname.split('/')[4];
  const campusId = history.location.pathname.split('/')[2];

  const showDrawer = useSelector(selectIsDrawerOpen) && !isNil(buildingNumber);
  const showNote = useSelector(selectIsNotesOpen);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [interventionType, setInterventionType] = useState('All Values');
  const [interventionDate, setInterventionDate] = useState(momentTZ.tz(DisplayTimeZone).format('DD MMM YYYY'));
  const [invalidDateMessage, setInvalidDateMessage] = useState('');
  const idToken = useSelector(selectAuthIDToken);
  const energyInterventions = useSelector(selectBuildingEnergyInterventions);
  let isAdmin: boolean = false;
  if (!isNil(idToken)) {
    const decodedToken: Token = jwtDecode(idToken);
    const isTokenAdmin: boolean[] = map(
      (group: string) => includes('Campus-Services-Admin', group),
      decodedToken.CampusNowGroups,
    );
    if (includes(true, isTokenAdmin)) {
      isAdmin = true;
    }
  }
  const interventionArr: BuildingEnergyIntervention[] = [];
  const [interventions, setInterventions] = useState(interventionArr);
  const [formProps, setFormProps] = useState({
    type: '',
    date: '',
    costValue: '',
    energySavingsValue: '',
    comments: '',
  });

  useEffect((): void => {
    setInterventions(sortBy(prop('interventionDate'), energyInterventions));
  }, [energyInterventions]);

  const interventionTypes = [
    'All Values',
    'Building Optimisation',
    'Building HVAC Tuning',
    'Building Envelope Improvement',
    'Plant Upgrade',
    'HVAC Deoperationalisation',
    'Lighting & Power Deoperationalisation',
    'Lighting Occupancy Sensors',
    'LED Upgrade',
  ];

  const toggleDrawer = (): void => {
    dispatch(toggleIsEnergyInterventionDrawerOpened());
  };

  const toggleInterventionNote = (): void => {
    dispatch(toggleIsNotesOpened());
    dispatch(addOrUpdateMaintenanceNote('', '', '', '', '', '', '', '', false));
    setFormProps({
      type: '',
      date: '',
      costValue: '',
      energySavingsValue: '',
      comments: '',
    });
  };

  const changeField = (newDate: any): void => {
    const date = momentTZ(newDate).tz(DisplayTimeZone).format('DD MMM YYYY');
    if (!isNil(newDate) && equals('Invalid date', date)) {
      setInvalidDateMessage('Invalid date format');
    } else {
      setInvalidDateMessage('');
    }
    setInterventionDate(date);
  };
  const changeInterventionType = (e: any): void => {
    setInterventionType(e.target.value);
  };

  const filterCriteria = (interventions: Intervention[], searchType?: string, searchDate?: string): any[] => {
    if (!isNil(searchType) && !isNil(searchDate)) {
      return filter(
        (intervention: BuildingEnergyIntervention) =>
          intervention.interventionType === interventionType &&
          interventionDate === momentTZ(intervention.interventionDate, 'YYYY-MM-DD').format('DD MMM YYYY'),
        interventions,
      );
    } else if (!isNil(searchDate)) {
      return filter(
        (intervention: BuildingEnergyIntervention) =>
          interventionDate === momentTZ(intervention.interventionDate, 'YYYY-MM-DD').format('DD MMM YYYY'),
        interventions,
      );
    } else {
      return filter(
        (intervention: BuildingEnergyIntervention) => interventionType === intervention.interventionType,
        interventions,
      );
    }
  };
  const searchByTypeAndDate = (): void => {
    if (isNil(interventionDate) || interventionDate === 'Invalid date') {
      if (interventionType !== 'All Values') {
        setInterventions(
          sortBy(prop('interventionDate'), filterCriteria(energyInterventions, interventionType, undefined)),
        );
      } else {
        setInterventions(sortBy(prop('interventionDate'), energyInterventions));
      }
    } else {
      if (interventionType !== 'All Values') {
        setInterventions(
          sortBy(prop('interventionDate'), filterCriteria(energyInterventions, interventionType, interventionDate)),
        );
      } else {
        setInterventions(
          sortBy(prop('interventionDate'), filterCriteria(energyInterventions, undefined, interventionDate)),
        );
      }
    }
  };

  const resetSearchResults = (): void => {
    setInvalidDateMessage('');

    setInterventionDate(momentTZ(new Date()).tz(DisplayTimeZone).format('DD MMM YYYY'));
    setInterventionType('All Values');
    setInterventions(sortBy(prop('interventionDate'), energyInterventions));
  };

  const menuItems: any = interventionTypes.map(
    (type: string): React.ReactElement => (
      <option value={type} key={type}>
        {type}
      </option>
    ),
  );

  return (
    <div>
      <Drawer
        className={classes.drawer}
        anchor="top"
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={showDrawer}
        onClose={toggleDrawer}>
        {' '}
        <AppBar
          position="fixed"
          style={{
            width: `${LEFT_DRAWER_WIDTH}px`,
            top: '20%',
            left: '37%',
          }}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div" style={{ flexGrow: 1 }}>
              Event Intervention - Maintenance Log
            </Typography>
            <IconButton color="inherit" onClick={toggleDrawer}>
              <CloseIcon fontSize="medium" titleAccess="Close" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <fieldset
          style={{
            border: `2px solid ${UOM_DARK_BLUE}`,
            marginTop: '10%',
            marginLeft: '3%',
            marginRight: '3%',
          }}>
          <legend>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              &nbsp;Search&nbsp;
            </Typography>
          </legend>
          <Grid container spacing={1}>
            <Grid item xs={5} className={classes.formLabelAlignment}>
              <Typography>Intervention Type&nbsp;:</Typography>
            </Grid>
            <Grid item xs={4}>
              <NativeSelect
                id="demo-simple-select"
                onChange={changeInterventionType}
                value={interventionType}
                variant="filled"
                /* defaultValue={'All Values'} */
                input={<BootstrapInput />}>
                {menuItems}
              </NativeSelect>
            </Grid>

            <Grid item xs={5} className={classes.formLabelAlignment}>
              <Typography>Intervention Date&nbsp;:</Typography>
            </Grid>
            <Grid item xs={4} style={{ backgroundColor: 'white' }}>
              <div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  {/* <ThemeProvider theme={defaultMaterialTheme}> */}
                  <KeyboardDatePicker
                    disableToolbar={true}
                    autoOk
                    variant="inline"
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    inputVariant="outlined"
                    format="DD MMM yyyy"
                    value={interventionDate}
                    onChange={changeField}
                    name="interventionDate"
                    invalidDateMessage={''}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <InputLabel
                disabled
                style={{ color: 'red' }}
                /* value={invalidDateMessage} */
              >
                {invalidDateMessage}
              </InputLabel>
            </Grid>
            {/* ) : null} */}
          </Grid>
          <br />
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '95%',
                paddingRight: '4%',
              }}>
              <Button
                disabled={!isEmpty(invalidDateMessage)}
                variant="contained"
                color="primary"
                onClick={searchByTypeAndDate}>
                Search
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '5%',
                paddingLeft: '4%',
              }}>
              <Button variant="contained" color="primary" onClick={resetSearchResults}>
                Reset
              </Button>
            </div>
          </div>
        </fieldset>
        <br />
        <fieldset
          style={{
            border: `1px solid ${UOM_DARK_BLUE}`,
            margin: '1% 3% 0 3%',
            marginLeft: '3%',
            marginRight: '3%',
            marginBottom: '5%',
          }}>
          <legend>
            <Typography variant="subtitle2">&nbsp;Energy Intervention Details&nbsp;</Typography>
          </legend>
          {isAdmin ? (
            <Grid container>
              <Grid item xs={9} />
              <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '-2%',
                  marginBottom: '1%',
                }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleInterventionNote}
                  endIcon={<SVGIcon type="plus-sign" size={IconSizes.small} alttext="Create new Note" />}
                  style={{ textTransform: 'none' }}>
                  <Typography variant="body2">Add Intervention</Typography>&nbsp;
                </Button>
              </Grid>
            </Grid>
          ) : null}
          <InterventionTable campusId={campusId} buildingNumber={buildingNumber} interventions={interventions} />
        </fieldset>
        {showNote ? (
          <MaintenanceLogForm buildingNumber={buildingNumber} campusId={campusId} {...formProps}></MaintenanceLogForm>
        ) : null}
      </Drawer>
    </div>
  );
};
