import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconSizes, SVGIcon } from 'components/common/Icons';
import React from 'react';
import { UOM_LIGHT_BLUE, UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';

const useStyles = makeStyles({
  root: {
    width: '120%',
    border: `1px solid ${UOM_LIGHT_BLUE}`,
  },
  paper: {
    width: '80px',
  },
});

export const TemperatureForecastWidget = (props: any): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={20}>
        <Grid
          container
          style={{
            paddingTop: '1%',
            paddingBottom: '0.2%',
            backgroundColor: UOM_LIGHT_BLUE,
          }}>
          <Grid item xs={3} style={{ paddingLeft: '1%', paddingTop: '5%' }}>
            <div>
              <SVGIcon size={IconSizes.medium} type={props.type} alttext="Temperature" />
            </div>
          </Grid>
          <Grid
            item
            direction="column"
            xs={9}
            container
            style={{ display: 'flex', flexDirection: 'column', paddingLeft: '12%' }}>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Typography variant="button" style={{ color: 'white' /* , paddingLeft: '20%', paddingTop: '80%' */ }}>
                {props.date}
              </Typography>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Typography variant="button" style={{ color: 'white' /* paddingLeft: '18%', paddingBottom: '75%' */ }}>
                {props.day}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ backgroundColor: `${UOM_LIGHT_BLUE}` }} />
        <Grid
          container
          style={{
            backgroundColor: UOM_LIGHT_BLUE,
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              variant="button"
              style={{
                color: UOM_SUPPORTING_COLOURS.green.dark,
                fontWeight: 'bold',
              }}>
              {props.min}
            </Typography>
          </Grid>

          <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              variant="button"
              style={{
                color: UOM_SUPPORTING_COLOURS.yellow.dark,
                fontWeight: 'bold',
              }}>
              {props.max}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
