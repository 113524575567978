import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduce, equals } from 'ramda';
import {
  Drawer,
  makeStyles,
  Checkbox,
  FormControl,
  FormGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { APP_HEADER_HEIGHT, RIGHT_DRAWER_WIDTH, RIGHT_DRAWER_PADDING } from 'styles/styleConstants';
import { ObservationType, HeatmapType } from 'models/Observation';
import {
  toggleAlertsVisibility,
  toggleEnergyVisibility,
  toggleSpaceComfortVisibility,
  toggleSpaceUtilisationVisibility,
  changeSpaceUtilisationHeatMapVisibility,
  toggleMapDrawOpenClosed,
  setAlertsVisibility,
  setEnergyVisibility,
  setSpaceComfortVisibility,
  setSpaceUtilisationVisibility,
} from 'store/UISettings/actions';
import {
  selectRightDrawerIsOpen,
  selectIsVisibleEnergy,
  selectIsVisibleSpaceComfort,
  selectIsVisibleSpaceUtilisation,
  selectSpaceUtilisationHeatMapSelection,
  selectIsVisibleAlerts,
} from 'store/selectors';
import { SpaceUtilisationHeatMapTypes } from 'types';

interface ButtonState {
  name: string;
  type: HeatmapType | string;
  value: boolean;
  disabled?: boolean | false;
}

interface HeatmapOptionsState {
  name: string;
  type: ObservationType | string;
  value: string;
  disabled?: boolean | false;
  toolTip: string;
}

const RightDrawer: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const useStyles = makeStyles({
    drawer: {
      width: `${RIGHT_DRAWER_WIDTH}px`,
      flexShrink: 0,
    },
    drawerPaper: {
      width: `${RIGHT_DRAWER_WIDTH}px`,
      top: `${APP_HEADER_HEIGHT}px`,
      padding: `${RIGHT_DRAWER_PADDING}px`,
    },
    indentedObservation: {
      'margin-left': `${RIGHT_DRAWER_PADDING}px`,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    filterObservationTitle: {
      fontWeight: 'bold',
    },
    heatmapTextTitle: {
      fontWeight: 'bold',
    },
    closeDrawerButton: {
      position: 'absolute',
      right: '10px',
    },
    alertsButton: {
      'border-top': '1px solid #999',
      'border-bottom': '1px solid #999',
    },
    heatMapSection: {
      'border-top': '1px solid #999',
    },
    label: {
      fontSize: '13px',
      fontWeight: 'bold',
    },
  });
  const classes = useStyles();

  const rightDrawerIsOpen = useSelector(selectRightDrawerIsOpen);
  const toggleDrawer = (): void => dispatch(toggleMapDrawOpenClosed());

  const isVisibleAlertsStore = useSelector(selectIsVisibleAlerts);
  const isVisibleEnergyStore = useSelector(selectIsVisibleEnergy);
  const isVisibleSpaceComfortStore = useSelector(selectIsVisibleSpaceComfort);
  const isVisibleSpaceUtilisationStore = useSelector(selectIsVisibleSpaceUtilisation);
  const changeSpaceUtilisationHeatMapSelectionStore = useSelector(selectSpaceUtilisationHeatMapSelection);

  const [isVisibleAlerts, setIsVisibleAlerts] = useState(true);
  const [isVisibleEnergy, setIsVisibleEnergy] = useState(true);
  const [isVisibleSpaceComfort, setIsVisibleSpaceComfort] = useState(true);
  const [isVisibleSpaceUtilisation, setIsVisibleSpaceUtilisation] = useState(true);
  const [spaceUtilisationHeatMapSelection, changeSpaceUtilisationHeatMapSelection] = useState(
    changeSpaceUtilisationHeatMapSelectionStore,
  );

  const buttonStates: ButtonState[] = [
    {
      name: 'Alerts',
      type: 'alerts',
      value: isVisibleAlerts,
    },
    {
      name: 'Energy',
      type: ObservationType.energy,
      value: isVisibleEnergy,
    },
    {
      name: 'Space Comfort',
      type: 'spaceComfort',
      value: isVisibleSpaceComfort,
    },
    {
      name: 'Space Utilisation',
      type: ObservationType.spaceUtilisation,
      value: isVisibleSpaceUtilisation,
    },
  ];

  const computeIsAllChecked = useCallback(
    (): boolean =>
      reduce(
        (accumulator: boolean, current: ButtonState): boolean => {
          return accumulator && current.value;
        },
        true,
        buttonStates,
      ),
    [buttonStates],
  );
  const computeIsNoneChecked = useCallback(
    (): boolean =>
      reduce(
        (accumulator: number, current: ButtonState): number => (current.value ? accumulator + 1 : accumulator),
        0,
        buttonStates,
      ) === 0,
    [buttonStates],
  );
  const [isNoneChecked, setIsNoneChecked] = useState(computeIsNoneChecked());
  const [isAllChecked, setIsAllChecked] = useState(computeIsAllChecked());
  const [isIndeterminate, setIndeterminisIndeterminate] = useState(!isAllChecked && !isNoneChecked);

  useEffect((): void => {
    setIsNoneChecked(computeIsNoneChecked());
    setIsAllChecked(computeIsAllChecked());
    setIndeterminisIndeterminate(!isAllChecked && !isNoneChecked);
  }, [computeIsAllChecked, computeIsNoneChecked, isAllChecked, isNoneChecked]);

  useEffect((): void => {
    if (!equals(isVisibleAlerts, isVisibleAlertsStore)) setIsVisibleAlerts(!isVisibleAlerts);
  }, [isVisibleAlerts, isVisibleAlertsStore]);

  useEffect((): void => {
    if (!equals(isVisibleEnergy, isVisibleEnergyStore)) setIsVisibleEnergy(!isVisibleEnergy);
  }, [isVisibleEnergy, isVisibleEnergyStore]);

  useEffect((): void => {
    if (!equals(isVisibleSpaceComfort, isVisibleSpaceComfortStore)) setIsVisibleSpaceComfort(!isVisibleSpaceComfort);
  }, [isVisibleSpaceComfort, isVisibleSpaceComfortStore]);

  useEffect((): void => {
    if (!equals(isVisibleSpaceUtilisation, isVisibleSpaceUtilisationStore))
      setIsVisibleSpaceUtilisation(!isVisibleSpaceUtilisation);
  }, [isVisibleSpaceUtilisation, isVisibleSpaceUtilisationStore]);

  /** UseEffect for Space Utilisation Heat map changes*/
  useEffect((): void => {
    if (!equals(spaceUtilisationHeatMapSelection, changeSpaceUtilisationHeatMapSelectionStore))
      changeSpaceUtilisationHeatMapSelection(spaceUtilisationHeatMapSelection);
  }, [spaceUtilisationHeatMapSelection, changeSpaceUtilisationHeatMapSelectionStore]);

  const toggleVisibilityBtn =
    (buttonState: ButtonState): any =>
    (): void => {
      switch (buttonState.type) {
        case 'alerts':
          dispatch(toggleAlertsVisibility());
          break;
        case ObservationType.energy:
          dispatch(toggleEnergyVisibility());
          break;
        case 'spaceComfort':
          dispatch(toggleSpaceComfortVisibility());
          break;
        case ObservationType.spaceUtilisation:
          dispatch(toggleSpaceUtilisationVisibility());
          break;
      }
    };
  const toggleAllVisibilityBtn = (): void => {
    if (!isIndeterminate) {
      dispatch(toggleAlertsVisibility());
      dispatch(toggleEnergyVisibility());
      dispatch(toggleSpaceComfortVisibility());
      dispatch(toggleSpaceUtilisationVisibility());
    } else {
      dispatch(setAlertsVisibility(true));
      dispatch(setEnergyVisibility(true));
      dispatch(setSpaceComfortVisibility(true));
      dispatch(setSpaceUtilisationVisibility(true));
    }
  };

  /** Radio Button options for the Space Utilisation Heatmap */
  const spaceUtilisationHeatMapOptionStates: HeatmapOptionsState[] = [
    {
      name: 'Active Wireless Clients - by Top Building (Real time)',
      type: HeatmapType.spaceUtilisation,
      value: SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1,
      toolTip: 'Larger the activity, greater the density.',
      /* 'Displayed in proportion to building with maximum connected wireless clients.', */
    },
    {
      name: 'Access Card Swipes - by Top Building (Every 6 hours)',
      type: HeatmapType.spaceUtilisation,
      value: SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE1,
      toolTip: 'Larger the activity, greater the density.',
      /* 'Displayed in proportion to building with maximum access card swipes.', */
    },
    /* {
      name: 'Access Card Swipes - by Building Capacity',
      type: HeatmapType.spaceUtilisation,
      value: SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE2,
      disabled: true,
      toolTip: 'Displayed in proportion to building capacity.',
    },
    {
      name: 'Active Wireless Clients - by Building Capacity',
      type: HeatmapType.spaceUtilisation,
      value: SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE2,
      disabled: true,
      toolTip: 'Displayed in proportion to building capacity.',
    }, */
    {
      name: 'None',
      type: HeatmapType.spaceUtilisation,
      value: SpaceUtilisationHeatMapTypes.NONE,
      toolTip: 'None',
    },
  ];
  return (
    <Drawer
      className={classes.drawer}
      open={rightDrawerIsOpen}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right">
      <div className={classes.drawerHeader}>
        <Typography className={classes.filterObservationTitle} align="center">
          Filter Observations
        </Typography>
        <IconButton aria-label="delete" size="small" className={classes.closeDrawerButton} onClick={toggleDrawer}>
          <CloseIcon titleAccess="Close" />
        </IconButton>
      </div>
      <FormControl component="fieldset">
        <FormGroup aria-label="position">
          <FormControlLabel
            label={'All'}
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={isAllChecked}
                indeterminate={isIndeterminate}
                onChange={toggleAllVisibilityBtn}
              />
            }
          />
          {buttonStates.map((buttonState: ButtonState, index: number): ReactElement => {
            return (
              <FormControlLabel
                className={`${classes.indentedObservation} ${
                  buttonState.type === 'alerts' ? classes.alertsButton : ''
                }`}
                key={index}
                label={buttonState.name}
                labelPlacement="end"
                classes={{ label: classes.label }}
                control={
                  <Checkbox color="primary" checked={buttonState.value} onChange={toggleVisibilityBtn(buttonState)} />
                }
              />
            );
          })}
        </FormGroup>
        <br />
        <FormGroup aria-label="Heat Map" className={classes.heatMapSection}>
          <div className={classes.drawerHeader}>
            <Typography className={classes.heatmapTextTitle}>Heat Map</Typography>
          </div>
          <RadioGroup
            name="spaceUtilisationHeatMap"
            key="spaceUtilisationHeatMapRadioGrp"
            aria-label="Heat Map"
            defaultValue={spaceUtilisationHeatMapSelection}
            onChange={(event, value): void => {
              dispatch(changeSpaceUtilisationHeatMapVisibility((event.target as HTMLInputElement).value));
            }}>
            {spaceUtilisationHeatMapOptionStates.map((option: HeatmapOptionsState, index: number): ReactElement => {
              return (
                <Tooltip title={option.toolTip} key={`spaceUtilisationHeatMapRadioTip${index}`}>
                  <FormControlLabel
                    key={`spaceUtilisationHeatMapRadio${index}`}
                    label={option.name}
                    value={option.value}
                    labelPlacement="end"
                    classes={{
                      label: classes.label,
                    }}
                    control={<Radio disabled={option.disabled} color="primary" />}
                  />
                </Tooltip>
              );
            })}
          </RadioGroup>
        </FormGroup>
      </FormControl>
    </Drawer>
  );
};

export default RightDrawer;
