import {
  TOGGLE_ALERTS_VISIBILITY,
  TOGGLE_ENERGY_VISIBILITY,
  TOGGLE_SPACE_COMFORT_VISIBILITY,
  TOGGLE_SPACE_UTILISATION_OBSERVATION_VISIBILITY,
  CHANGE_SPACE_UTILISATION_HEATMAP_VISIBILITY,
  TOGGLE_RIGHT_DRAWER_OPEN_CLOSED,
  SET_ALERTS_VISIBILITY,
  SET_ENERGY_VISIBILITY,
  SET_SPACE_COMFORT_VISIBILITY,
  SET_SPACE_UTILISATION_OBSERVATION_VISIBILITY,
  SET_SPACE_UTILISATION_HEATMAP_VISIBILITY,
  TOGGLE_ENERGY_INTERVENTION_DRAWER_IS_OPEN,
  TOGGLE_NOTES_IS_OPEN,
  TOGGLE_UPDATE_NOTES_IS_OPEN,
} from './types';
import { Dispatch } from 'redux';

// visibility
export const toggleAlertsVisibility = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_ALERTS_VISIBILITY,
    });
  };
};

export const setAlertsVisibility = (isVisibleAlerts: boolean): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ALERTS_VISIBILITY,
      payload: isVisibleAlerts,
    });
  };
};

export const toggleEnergyVisibility = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_ENERGY_VISIBILITY,
    });
  };
};

export const setEnergyVisibility = (isVisibleEnergy: boolean): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_ENERGY_VISIBILITY,
      payload: isVisibleEnergy,
    });
  };
};

export const toggleSpaceComfortVisibility = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_SPACE_COMFORT_VISIBILITY,
    });
  };
};

export const setSpaceComfortVisibility = (isVisibleSpaceComfort: boolean): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_SPACE_COMFORT_VISIBILITY,
      payload: isVisibleSpaceComfort,
    });
  };
};

export const toggleSpaceUtilisationVisibility = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_SPACE_UTILISATION_OBSERVATION_VISIBILITY,
    });
  };
};

export const setSpaceUtilisationVisibility = (isVisibleSpaceUtilisation: boolean): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_SPACE_UTILISATION_OBSERVATION_VISIBILITY,
      payload: isVisibleSpaceUtilisation,
    });
  };
};

export const changeSpaceUtilisationHeatMapVisibility = (heatMapType: string): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CHANGE_SPACE_UTILISATION_HEATMAP_VISIBILITY,
      payload: heatMapType,
    });
  };
};

export const setSpaceUtilisationHeatMapVisibility = (spaceUtilisationHeatMapSelection: string): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_SPACE_UTILISATION_HEATMAP_VISIBILITY,
      payload: spaceUtilisationHeatMapSelection,
    });
  };
};

// map drawer
export const toggleMapDrawOpenClosed = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_RIGHT_DRAWER_OPEN_CLOSED,
    });
  };
};

// energyInterventionDrawer drawer
export const toggleIsEnergyInterventionDrawerOpened = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_ENERGY_INTERVENTION_DRAWER_IS_OPEN,
    });
  };
};

export const toggleIsNotesOpened = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_NOTES_IS_OPEN,
    });
  };
};

export const toggleIsUpdateNotesOpened = (): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: TOGGLE_UPDATE_NOTES_IS_OPEN,
    });
  };
};
