import { MapType } from 'components/Mazemap/MapTypes';
import { RoomPoiResponse } from 'models/Room';
import { path, isNil, pathOr, any } from 'ramda';
import { Feature } from 'geojson';
import { Layer, GeoJSONSourceRaw, AnyLayer } from 'mapbox-gl';
import Logger from 'utils/logger';
import {
  ROOM_LEVEL_CLICK_ZOOM_THRESHOLD,
  BUILDING_ZOOM_LEVEL,
  MazeMapCampusDetails,
  ALARM_SEVERITY_LIST,
} from 'components/constants';
import {
  UOM_SECONDARY_BLUE,
  UOM_SUPPORTING_COLOURS,
  CMX_HEATMAP_RATIO_TO_COLOUR,
  ACCCARD_HEATMAP_RATIO_TO_COLOUR,
  UOM_LIGHT_GRAY,
} from 'styles/styleConstants';
import { FloorMapper, getHighlightColor } from 'utils/mapUtils';
import { getCampusId } from 'components/common/LocationCodeUtils';
import { SpaceUtilisationHeatMapTypes } from 'types';

declare let window: any;

export interface HighlightFeatureProps {
  features: Feature[];
  prefix: string; // Used with layer id
  color: string;
  style: 'fill' | 'line';
  opacity?: number;
}

export type BuildingCallback = (building: Feature) => void;
export type RoomCallback = (room: RoomPoiResponse) => void;

const roomsPoisLayer = 'rooms-pois-layer';
const roomsPoisSource = 'rooms-pois-source';
let buildingSourceId = '';
let buildingLayerId = '';
let heatmapLayerId = '';
let heatmapSourceId = '';
let buildingStatusLayerId = '';
let buildingStatusSourceId = '';

class MazemapHighlightService {
  private map: MapType;

  public constructor(map: MapType) {
    this.map = map;
  }

  /**
   * Utility method to remove the heatmap layer
   * @returns void
   */
  public removeSpaceUtilisationHeatMapLayer = (): void => {
    if (heatmapLayerId !== '' && heatmapSourceId !== '') {
      this.map.removeLayer(heatmapLayerId);
      this.map.removeSource(heatmapSourceId);
      heatmapLayerId = '';
      heatmapSourceId = '';
    }
  };

  public removeBuildingStatusMapLayer = (): void => {
    if (buildingStatusLayerId !== '' && buildingStatusSourceId !== '') {
      this.map.removeLayer(buildingStatusLayerId);
      this.map.removeSource(buildingStatusSourceId);
      buildingStatusLayerId = '';
      buildingStatusSourceId = '';
    }
  };

  /**
   * Public method to create the layer for the heatmap
   * based on the heatmap type provided.
   *
   * @param  {string} campusId
   * @param  {string} heatMaptype
   * @param  {Feature[]} features
   * @returns void
   */
  public highlightHeatMap = (campusId: string, heatMaptype: string, features: Feature[]): void => {
    heatmapLayerId = `${campusId}-${heatMaptype}-layer`;
    heatmapSourceId = `${campusId}-${heatMaptype}-source`;

    const source = this.createSource(features);
    if (!this.map.getSource(heatmapSourceId)) {
      this.map.addSource(heatmapSourceId, source);
      this.map.addLayer({
        id: heatmapLayerId,
        type: 'heatmap',
        source: heatmapSourceId,
        maxzoom: 24,
        paint: {
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'heatmapRatio'],
            0.1,
            1,
            0.5,
            2,
            1.1,
            3,
            2,
            4,
            4,
            7,
            7,
            12,
            12,
            15,
          ],
          'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            heatMaptype === SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1
              ? CMX_HEATMAP_RATIO_TO_COLOUR.TRANSPARENT
              : ACCCARD_HEATMAP_RATIO_TO_COLOUR.TRANSPARENT,
            0.2,
            heatMaptype === SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1
              ? CMX_HEATMAP_RATIO_TO_COLOUR.COLOR_0_2
              : ACCCARD_HEATMAP_RATIO_TO_COLOUR.COLOR_0_2,
            0.4,
            heatMaptype === SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1
              ? CMX_HEATMAP_RATIO_TO_COLOUR.COLOR_0_4
              : ACCCARD_HEATMAP_RATIO_TO_COLOUR.COLOR_0_4,
            0.6,
            heatMaptype === SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1
              ? CMX_HEATMAP_RATIO_TO_COLOUR.COLOR_0_6
              : ACCCARD_HEATMAP_RATIO_TO_COLOUR.COLOR_0_6,
            0.8,
            heatMaptype === SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1
              ? CMX_HEATMAP_RATIO_TO_COLOUR.COLOR_0_8
              : ACCCARD_HEATMAP_RATIO_TO_COLOUR.COLOR_0_8,
            1,
            heatMaptype === SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1
              ? CMX_HEATMAP_RATIO_TO_COLOUR.COLOR_1_0
              : ACCCARD_HEATMAP_RATIO_TO_COLOUR.COLOR_1_0,
          ],
          'heatmap-radius': ['interpolate', ['linear'], ['get', 'heatmapRatio'], 1, 15, 2, 16, 3, 17, 4, 18, 14, 22],
          'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 10, 1, 22, 0.5],
        },
      });
    }
  };

  private roomClickHandler =
    (callback: RoomCallback): ((ev: any) => void) =>
    (el: any): void => {
      if (this.map.getZoom() > ROOM_LEVEL_CLICK_ZOOM_THRESHOLD) {
        const lngLat = el.lngLat;
        const zLevel = this.map.zLevel;

        window.Mazemap.Data.getPoiAt(lngLat, zLevel)
          .then((poi: RoomPoiResponse): void => {
            const lngLat = window.Mazemap.Util.getPoiLngLat(poi);
            if (poi.geometry.type === 'Polygon') {
              this.map.highlighter.highlight(poi);
              this.map.flyTo({ center: lngLat, zoom: BUILDING_ZOOM_LEVEL, speed: 0.5 });
              callback(poi);
            }
          })
          .catch((): boolean => {
            return false;
          });
      }
    };

  public selectRoomByLocationCode = async (locationCode: string): Promise<void> => {
    const [roomPoi] = await window.Mazemap.Data.getPois({
      identifier: locationCode,
      campusid: MazeMapCampusDetails[getCampusId(locationCode)].id,
    });

    if (!isNil(roomPoi)) {
      this.map.highlighter.highlight(roomPoi);
      const lngLat = window.Mazemap.Util.getPoiLngLat(roomPoi);
      this.map.flyTo({ center: lngLat, zoom: BUILDING_ZOOM_LEVEL, speed: 0.5 });
    }
  };

  private buildingClickHandler =
    (callback: BuildingCallback, isRoomSelected: boolean): Function =>
    async (el: any, features: any): Promise<void> => {
      const buildingId: number | undefined = path(['properties', 'id'], features[0]);
      if (isNil(buildingId)) {
        throw Error(`Could not get buildingId from feature ${features[0]}`);
      }
      this.highlightBuildingByMazemapId(buildingId, isRoomSelected, callback);
    };

  private createLayer = (
    id: string,
    color: string,
    opacity: number,
    sourceId: string,
    style: HighlightFeatureProps['style'],
  ): Layer => {
    const fillStyle = {
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': color,
        'fill-opacity': opacity,
      },
    };
    const lineStyle = {
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': 3,
      },
    };
    const styleArgs = style === 'fill' ? (fillStyle as Layer) : (lineStyle as unknown as Layer);
    return {
      source: sourceId,
      ...styleArgs,
    };
  };

  private createSource = (features: Feature[]): GeoJSONSourceRaw => {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    };
  };

  // TODO: refactor this method to handle highlighting any feature.
  // A feature is any POI or POIs (e.g. room, rooms, building, buildings)
  // For reference the data service function 'getPoisFromSummaries' shows
  // how to use a location id to get a POI/feature from a single room.
  public highlightRoomPois = (features: Feature[], severity: string): void => {
    const containsInvalidFeature = any((f): boolean => isNil(f));
    if (containsInvalidFeature(features)) {
      Logger.error(`Invalid feature in features array: ${JSON.stringify(features)}`);
    }
    const roomsSummaryLayerId = `${severity}-${roomsPoisLayer}`;
    const roomsSummarySourceId = `${severity}-${roomsPoisSource}`;
    if (!isNil(this.map.getLayer(roomsSummaryLayerId))) {
      this.removeAbnormalRoomsLayersAndSourcesById(roomsSummaryLayerId, roomsSummarySourceId);
    }

    const source = this.createSource(features);
    this.map.addSource(roomsSummarySourceId, source);

    const layer = this.createLayer(roomsSummaryLayerId, getHighlightColor(severity), 0.8, roomsSummarySourceId, 'fill');
    const ADD_BEFORE_LAYER = 'mm-poi-label';
    this.map.addLayer(layer as AnyLayer, ADD_BEFORE_LAYER);
  };

  public selectFloorByArchibusId = (archibusFloorId: string): void => {
    const mazemapLocationId = parseInt(FloorMapper.archibusToMazemapId(archibusFloorId));
    this.map.setZLevel(mazemapLocationId);
  };

  public setFloorZLevelControl = (floor: number): void => {
    this.map.setZLevel(floor);
  };

  private highlightBuilding = (building: Feature, prefix: string, isRoomSelected: boolean): void => {
    if (buildingLayerId === `${prefix}-building-layer`) {
      return;
    }
    if (buildingLayerId !== '') {
      this.removeAllAbnormalRoomsLayersAndSources();
      this.removeBuildingLayer();
    }
    buildingLayerId = `${prefix}-building-layer`;
    buildingSourceId = `${prefix}-building-source`;
    const source = this.createSource([building]);
    this.map.addSource(buildingSourceId, source);
    const layer = this.createLayer(buildingLayerId, UOM_SECONDARY_BLUE, 0.8, buildingSourceId, 'line');
    const ADD_BEFORE_LAYER = 'mm-building-label';
    this.map.addLayer(layer as AnyLayer, ADD_BEFORE_LAYER);
    const lngLat = window.Mazemap.Util.getPoiLngLat(building);
    if (!isRoomSelected) {
      this.map.flyTo({ center: lngLat, zoom: BUILDING_ZOOM_LEVEL, speed: 0.5 });
    }
  };

  public highlightBuildingByMazemapId = async (
    mazemapBuildingId: number,
    isRoomSelected: boolean,
    callback?: BuildingCallback,
  ): Promise<void> => {
    const buildingFeature: Feature = await window.Mazemap.Data.getBuilding(mazemapBuildingId);
    const buildingId = pathOr(null, ['properties', 'id'], buildingFeature);
    if (isNil(buildingId)) {
      throw Error(`Could not get building id for feature: ${buildingFeature}`);
    }
    this.highlightBuilding(buildingFeature, `building-${buildingId}`, isRoomSelected);
    if (callback) {
      callback(buildingFeature);
    }
  };

  public removeRoomHighlight = (): void => this.map.highlighter.clear();

  public removeBuildingLayer = (): void => {
    if (buildingLayerId !== '') {
      this.map.removeLayer(buildingLayerId);
      this.map.removeSource(buildingSourceId);
      buildingLayerId = '';
      buildingSourceId = '';
    }
  };

  public removeAbnormalRoomsLayersAndSourcesById = (
    roomsSummaryLayerId: string,
    roomsSummarySourceId: string,
  ): void => {
    this.map.removeLayer(roomsSummaryLayerId);
    this.map.removeSource(roomsSummarySourceId);
  };

  public removeAllAbnormalRoomsLayersAndSources = (): void => {
    ALARM_SEVERITY_LIST.forEach((severity) => {
      const roomLayerId = `${severity}-${roomsPoisLayer}`;
      const roomSourceId = `${severity}-${roomsPoisSource}`;
      if (!isNil(this.map.getLayer(roomLayerId))) {
        this.removeAbnormalRoomsLayersAndSourcesById(roomLayerId, roomSourceId);
      }
    });
  };

  private removeAllLayers = (): void => {
    this.removeRoomHighlight();
    this.removeAllAbnormalRoomsLayersAndSources();
    this.removeBuildingLayer();
    //this.removeSpaceUtilisationHeatMapLayer()
  };

  public flyToCampus = (campusId: string, speed = 0.75): void => {
    setTimeout((): void => {
      this.map.flyTo({
        center: MazeMapCampusDetails[campusId].coordinates,
        zoom: pathOr(15, [campusId, 'defaultZoom'], MazeMapCampusDetails),
        speed: speed,
      });
    });
  };

  public resetToCampusView = (campusId: string): void => {
    try {
      if (buildingLayerId !== '' && buildingSourceId !== '') {
        this.removeAllLayers();
      }
      this.flyToCampus(campusId);
    } catch (e) {
      Logger.error(`Couldn't remove layer: ${buildingLayerId}, source: ${buildingSourceId}`);
    }
  };

  public addRoomClickHandler = (callback: RoomCallback): void => {
    this.map.on('click', this.roomClickHandler(callback));
  };

  public removeRoomClickHandler = (callback: RoomCallback): void => {
    this.map.off('click', this.roomClickHandler(callback));
  };

  public addBuildingClickHandler = (callback: BuildingCallback, isRoomSelected: boolean): void => {
    this.map.layerEventHandler.on('click', 'mm-building-fill', this.buildingClickHandler(callback, isRoomSelected));
  };

  public removeBuildingClickHandler = (callback: BuildingCallback, isRoomSelected: boolean): void => {
    this.map.layerEventHandler.off('click', 'mm-building-fill', this.buildingClickHandler(callback, isRoomSelected));
  };

  public highlightBuildingStatus = (buildingFeatures: Feature[], campusId: string): void => {
    if (buildingLayerId === `${campusId}-building-layer`) {
      return;
    }
    buildingStatusLayerId = `${campusId}-building-layer-status`;
    buildingStatusSourceId = `${campusId}-building-source-status`;
    const source = this.createSource(buildingFeatures);
    if (isNil(this.map.getSource(buildingStatusSourceId))) {
      this.map.addSource(buildingStatusSourceId, source);
    }

    if (isNil(this.map.getLayer(buildingStatusLayerId))) {
      this.map.addLayer(
        {
          id: buildingStatusLayerId,
          source: buildingStatusSourceId,
          type: 'fill',
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', 'colorCode'],
              0,
              UOM_LIGHT_GRAY,
              1,
              UOM_SUPPORTING_COLOURS.grey.dark,
              2,
              UOM_SUPPORTING_COLOURS.emerald.dark,
              3,
              UOM_SUPPORTING_COLOURS.blue.light,
              4,
              UOM_SUPPORTING_COLOURS.green.light,
              5,
              UOM_SUPPORTING_COLOURS.yellow.dark,
            ],
            'fill-opacity': 0.4,
          },
        },
        heatmapLayerId,
      );
    }
  };

  public highlightLibraryRoom = (
    libraryFeatures: Feature,
    roomLocationCode: string,
    removeHighlight?: boolean,
  ): void => {
    if (buildingLayerId === `${roomLocationCode}-building-layer`) {
      return;
    }
    const roomStatusLayerId = `${roomLocationCode}-building-layer-status`;
    const roomStatusSourceId = `${roomLocationCode}-building-source-status`;
    const source = this.createSource([libraryFeatures]);
    if (removeHighlight && !isNil(this.map.getLayer(roomStatusLayerId))) {
      this.map.removeLayer(roomStatusLayerId);
      return;
    }

    if (isNil(this.map.getSource(roomStatusSourceId))) {
      this.map.addSource(roomStatusSourceId, source);
    }

    if (!removeHighlight && isNil(this.map.getLayer(roomStatusLayerId))) {
      this.map.addLayer({
        id: roomStatusLayerId,
        source: roomStatusSourceId,
        type: 'fill',
        paint: {
          'fill-color': '#fc0',
          'fill-outline-color': 'red',
          'fill-opacity': 0.4,
        },
      });
    }
  };
}

export default MazemapHighlightService;
