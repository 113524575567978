import { Dispatch } from 'redux';
import { TOKEN_UPDATED, AUTH_ERROR } from './types';
import { AuthenticationParameters, AuthResponse, AuthError } from 'msal';
import { isNil, propOr } from 'ramda';
import { authenticationInstance, acquireToken } from 'services/AuthService';
import { OAUTH_SCOPES } from 'components/authConstants';

export const updateAccessTokenAction = (authResponse: AuthResponse): any => {
  return (dispatch: Dispatch): void => {
    const accessToken = propOr(null, 'value', authResponse.accessToken);
    dispatch({
      type: TOKEN_UPDATED,
      payload: {
        idToken: propOr(null, 'value', authResponse.idToken),
        accessToken: accessToken,
        /* scopes: propOr([], 'scopes', authResponse),
        expiryDate: propOr(null, 'expiresOn', authResponse),
         */ isAuthenticated: propOr(null, 'isAuthenticated', authResponse), // accessToken !== null,
        isErrored: false,
        errorMessage: null,
      },
    });
  };
};

export const authErrorAction = (errorMessage: string | null): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: AUTH_ERROR,
      payload: {
        isErrored: !isNil(errorMessage),
        errorMessage,
      },
    });
  };
};

const acquireTokenAndUpdateStore = async (
  dispatch: Dispatch,
  authParameters: AuthenticationParameters,
): Promise<void> => {
  const authResponse = await acquireToken(authParameters);
  if (authResponse !== null) {
    dispatch(updateAccessTokenAction(authResponse));
  }
};

export const getAuthenticationTokenAction = (): any => {
  return (dispatch: Dispatch): void => {
    const authParameters = { scopes: OAUTH_SCOPES };

    authenticationInstance.handleRedirectCallback((authErr: AuthError, response?: AuthResponse): void => {
      if (authErr) {
        dispatch(authErrorAction(authErr.errorMessage));
      }
    });

    // if we are inside renewal callback (hash contains access token), do nothing
    if (authenticationInstance.urlContainsHash(window.location.hash)) {
      // when does this flow occur? Should this be handled by a route instead?
      return;
    }

    // not logged in, perform full page redirect
    if (!authenticationInstance.getAccount()) {
      authenticationInstance.loginRedirect(authParameters);
      return;
    } else {
      // logged in, set authenticated state
      acquireTokenAndUpdateStore(dispatch, authParameters);
    }
  };
};

/* export const logoutUserAction = (authState: any, authService: any): any => {
  return (dispatch: Dispatch): void => {
    const idToken = prop('idToken', authState)
    const redirectUri = 'http://localhost:3000/'
    authService.logout('/')

    window.location.href = `https://unimelb-preprod.okta.com/oauth2/default/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`

    // dispatch(updateAccessTokenAction(buildResponseStateOnly('logged out')))
  }
} */
