import { statusReducer } from './status/reducers';
import { detailsReducer } from './details/reducer';
import { mapReducer } from './map/reducer';
import { combineReducers, Store, Unsubscribe } from 'redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from './auth/reducer';
import { UISettingsReducer } from './UISettings/reducer';
import { userReducer } from './user/reducer';
import { subscribeToStateChange } from 'services/UserService';
import { composeWithDevTools } from '@redux-devtools/extension';

export const rootReducer = combineReducers({
  user: userReducer,
  status: statusReducer,
  auth: authReducer,
  details: detailsReducer,
  map: mapReducer,
  UISettings: UISettingsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export const getStore = (): Store => store;

const storeSubscribers: Unsubscribe[] = [];

storeSubscribers.push(subscribeToStateChange(store));
