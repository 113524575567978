import React, { ReactElement, Fragment } from 'react';
import { AlarmObservation } from 'models/Observation';
import DetailCard from './DetailCard';
import { isEmpty } from 'ramda';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { SVGIcon, IconSizes, getIconTypeFromObservationType } from './Icons';
import { UOM_LIGHT_BLUE, FONT_SIZE_SMALL, APP_GUTTER } from 'styles/styleConstants';
import { formatObservationTime } from './ObservationFormatters';
import { useSelector } from 'react-redux';
import { selectIsVisibleAlerts } from 'store/selectors';

type ObservationClasses = Record<
  'index' | 'observation' | 'observationItem' | 'observationTime' | 'observationMetadata',
  string
>;

const useObservationDetailStyles = makeStyles({
  index: {
    margin: `${APP_GUTTER}px 0 0 ${APP_GUTTER}px`,
    padding: 0,
    border: `2px solid ${UOM_LIGHT_BLUE}`,
    borderRadius: '50%',
    minWidth: '20px',
    maxWidth: '20px',
    width: '20px',
    minHeight: '20px',
    maxHeight: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  indexNumber: {
    alignSelf: 'center',
  },
  observation: {
    display: 'flex',
    alignItems: 'start',
  },
  observationItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: `${APP_GUTTER}px`,
    width: '100%',
    '& img': {
      padding: '4px 16px',
    },
  },
  observationMetadata: {
    flexGrow: 1,
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: 0,
    listStyle: 'none',
    maxWidth: '100%',
  },
  observationTime: {
    fontSize: FONT_SIZE_SMALL,
  },
});

const renderAlarm = (alarm: AlarmObservation, classes: ObservationClasses): ReactElement => {
  const iconType = getIconTypeFromObservationType(alarm.observationType);
  return (
    <div className={classes.observationItem}>
      <SVGIcon size={IconSizes.large} type={iconType} alttext={iconType} />
      <ul className={classes.observationMetadata}>
        <li>
          <Typography>{alarm.description}</Typography>
        </li>
        <li className={classes.observationTime}>
          <Typography variant="caption">{formatObservationTime(alarm.reportedTime)}</Typography>
        </li>
      </ul>
    </div>
  );
};

interface AlarmObservationDetailProps {
  observations: AlarmObservation[];
}

const AlarmObservationDetail = ({ observations }: AlarmObservationDetailProps): ReactElement | null => {
  const classes = useObservationDetailStyles();

  const alarmRenderer = (alarm: AlarmObservation): ReactElement => renderAlarm(alarm, classes);

  return (
    <DetailCard title="Alerts">
      <ul className={classes.observationMetadata}>
        {(observations as []).map((observation: AlarmObservation, index: number): ReactElement => {
          return (
            <li data-testid={`alarms-${index}`} key={index}>
              <div className={classes.observation}>
                <div className={classes.index}>
                  <Typography className={classes.indexNumber}>{`${index + 1}`}</Typography>
                </div>
                {alarmRenderer(observation)}
              </div>
              <Divider />
            </li>
          );
        })}
      </ul>
    </DetailCard>
  );
};

interface AlarmSectionProps {
  alarms: AlarmObservation[];
}

const AlarmDetails = ({ alarms }: AlarmSectionProps): ReactElement => {
  const isVisibleAlerts = useSelector(selectIsVisibleAlerts);
  return (
    <Fragment>{isEmpty(alarms) || !isVisibleAlerts ? null : <AlarmObservationDetail observations={alarms} />}</Fragment>
  );
};

export default AlarmDetails;
