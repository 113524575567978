import { createTheme, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Palette } from '@material-ui/core/styles/createPalette';
import { Typography } from '@material-ui/core/styles/createTypography';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';
import {
  UOM_SECONDARY_DARK,
  UOM_LIGHT_BLUE,
  UOM_WHITE,
  HEADING_FONT_FAMILY,
  DEFAULT_FONT_FAMILY,
  UOM_SUPPORTING_COLOURS,
  UOM_DARK_BLUE,
} from 'styles/styleConstants';

type CustomThemeOptions = ThemeOptions & {
  typography: {
    serifFontFamily: string;
    secondaryDarkColor: string;
  };
};

interface CustomTypography extends Typography {
  serifFontFamily?: string;
  secondaryDarkColor?: string;
}

export interface CustomTheme extends Theme {
  typography: CustomTypography;
  palette: Palette;
}

const themeOptions: CustomThemeOptions = {
  typography: {
    serifFontFamily: HEADING_FONT_FAMILY,
    fontFamily: DEFAULT_FONT_FAMILY,
    secondaryDarkColor: UOM_SECONDARY_DARK,
    subtitle1: {
      fontSize: 'large',
      fontWeight: 'bold',
      fontFamily: HEADING_FONT_FAMILY,
    },
    subtitle2: {
      fontSize: 'large',
      fontWeight: 'bold',
      fontFamily: DEFAULT_FONT_FAMILY,
    },
    body1: {
      fontSize: '16px',
      fontFamily: DEFAULT_FONT_FAMILY,
    },
  },
  palette: {
    primary: {
      main: UOM_LIGHT_BLUE,
      contrastText: UOM_WHITE,
    },
    secondary: {
      main: UOM_WHITE,
      contrastText: UOM_LIGHT_BLUE,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1450,
      xl: 2560, // For 4k TV
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::webkit-scrollbar': {
          width: '40px',
          height: '40px',
        },
        '*::-webkit-scrollbar': {
          width: '120px',
        },
      },
    },
    MuiTableHead: {
      root: {
        '& tr': {
          backgroundColor: UOM_SUPPORTING_COLOURS.grey.light,
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: UOM_SUPPORTING_COLOURS.grey.light,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
      head: {
        color: UOM_DARK_BLUE,
        borderBottom: '1px solid rgb(0, 0, 0, 0.25)',
        padding: '0px',
        paddingRight: '10px',
        '&:first-child': {
          paddingLeft: '5px',
        },
        '&:last-child': {
          paddingRight: '5px',
        },
      },
      body: {
        color: UOM_LIGHT_BLUE,
        padding: '0px',
        paddingRight: '10px',
        '&:first-child': {
          paddingLeft: '5px',
        },
        '&:last-child': {
          paddingRight: '5px',
        },
      },
    },
    MuiPaper: {
      root: {
        color: UOM_LIGHT_BLUE,
      },
    },
    MuiCardContent: {
      root: {
        padding: '0px',
        '&:last-child': {
          paddingBottom: '0px',
        },
      },
    },
    //   MuiInput: {
    //     root: {
    //       '&$focused': {
    //         color: SPACEMAN_COLOR_DARK,
    //       },
    //     },
    //   },
    //   MuiFormLabel: {
    //     root: {
    //       '&$focused': {
    //         color: SPACEMAN_COLOR_DARK,
    //       },
    //     },
    //   },
    //   MuiAvatar: {
    //     root: {
    //       background: '#0c3e83',
    //     },
    //   },
    //   MuiCheckbox: {
    //     colorPrimary: {
    //       color: SPACEMAN_COLOR_DARK,
    //       '&$checked': {
    //         color: UOM_SUPPORTING_COLOURS.teal.dark,
    //       },
    //     },
    //     colorSecondary: {
    //       color: SPACEMAN_COLOR_DARK,
    //       '&$checked': {
    //         color: UOM_SUPPORTING_COLOURS.teal.dark,
    //       },
    //     },
    //   },
  },
};

export const theme = createTheme(themeOptions);

export const useAppStyles = makeStyles({
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  appContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flexGrow: 1,
    minHeight: '100%',
  },
  appStartupProgressIndicator: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    minHeight: '100%',
  },
});
