import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { history } from '../App/AppHistory';
import Header from 'components/Header/Header';
import Logger from 'utils/logger';

interface LoginCallbackProps {
  errorComponent?: React.ComponentType<{ error: Error }>;
  // onAuthResume?: OnAuthResumeFunction;
  loadingElement?: React.ReactElement;
}

const LoginCallback: React.FC<LoginCallbackProps> = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = React.useState(null);

  const onAuthResume = async (): Promise<void> => {
    history.push('/login');
  };

  // const ErrorReporter = errorComponent || OktaError;
  React.useEffect(() => {
    if (onAuthResume && oktaAuth.isInteractionRequired?.()) {
      onAuthResume();
      return;
    }
    oktaAuth
      .handleLoginRedirect()
      .then(() => {
        if (!oktaAuth.isAuthenticated()) {
          oktaAuth.signInWithRedirect();
        } else {
          history.push('/campus/PAR');
        }
      })
      .catch((e: any) => {
        Logger.error('Error during redirect', e);
        setCallbackError(e);
      });
  }, [oktaAuth]);

  const authError = authState?.error;
  const displayError = callbackError || authError;
  if (displayError) {
    return (
      <React.Fragment>
        <Header />
        <h2>Access Denied</h2>
        <h3>
          Please{' '}
          <a
            href="https://unimelb.service-now.com/it?id=sc_cat_item&sys_id=861204371bcb14d0ef32a68b274bcbf1&category_id=5171f147dbeda74035dc403c3a961963"
            style={{ color: 'red' }}>
            click here
          </a>{' '}
          here for requesting access to the application
        </h3>
      </React.Fragment>
    );
  }

  return null;
};

export default LoginCallback;
