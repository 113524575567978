import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserName } from 'store/selectors';
import { isEmpty, equals } from 'ramda';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  welcomeText: {
    marginLeft: '10px',
  },
});

export const WelcomeMessage = (): ReactElement | null => {
  const userNameStore = useSelector(selectUserName);
  const [userName, setUserName] = useState(userNameStore);

  useEffect((): void => {
    if (!equals(userNameStore, userName)) setUserName(userNameStore);
  }, [userName, userNameStore]);

  const classes = useStyles();
  return isEmpty(userName) ? null : (
    <Typography data-testid="APP-BAR-WELCOME-TEXT" className={classes.welcomeText}>
      Welcome {userName}
    </Typography>
  );
};
