import { SET_NAME, SET_INITIALS, User, SET_USER } from './types';
import { Dispatch } from 'redux';

export const setUser = (user: User): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_USER,
      payload: user,
    });
  };
};

export const setName = (name: string): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_NAME,
      payload: name,
    });
  };
};

export const setInitials = (userInitials: string): any => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_INITIALS,
      payload: userInitials,
    });
  };
};
