import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';
import App from 'components/App/App';
import * as serviceWorker from './serviceWorker';
import { setMapServiceAction } from 'store/map/actions';
import { renderMap, MapServiceType } from 'services/Map/MapService';
import { getStore } from 'store';

const store = getStore();

const loadMap = async (): Promise<MapServiceType> => {
  const mapService = await renderMap();
  return mapService;
};
(async (): Promise<any> => {
  loadMap().then((mapService: MapServiceType): void => {
    store.dispatch(setMapServiceAction(mapService));
  });
  ReactDOM.render(<App />, document.getElementById('root'));
  serviceWorker.unregister();
})();
