import React, { Fragment, ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { APP_GUTTER, FONT_SIZE_SMALL } from 'styles/styleConstants';
import DetailCard from './DetailCard';
import { IconSizes, SVGIcon } from './Icons';
import { Typography } from '@material-ui/core';
import { MetricCard } from 'components/Observation/EnergyGroupDetails';
import { buildingStatus } from 'buildingStatus';
import { equals, filter, includes, isNil, keys } from 'ramda';
import { ObservationClass, ObservationType } from 'models/Observation';

const useStyles = makeStyles({
  chart: {
    maxWidth: `calc(100% - ${APP_GUTTER})`,
    margin: 0,
  },
  HeadCountGroupItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${APP_GUTTER}px`,
    '& img': {
      padding: '4px 16px',
    },
  },
  HeadCountGroupMetadata: {
    flexGrow: 1,
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: 0,
    listStyle: 'none',
    maxWidth: '100%',
  },
  observationIcon: {
    paddingTop: '16px',
  },
  observationTime: {
    fontSize: FONT_SIZE_SMALL,
  },
  HeadCountGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      padding: '4px 2px',
    },
    position: 'relative',
  },
  HeadCountGroupTitle: {
    fontWeight: 'bold',
  },
  /* HeadCountGroupChartCollapser: {
    display: 'flex',
    position: 'relative',
    width: 'auto',
    top: 0,
    right: 0,
  }, */
});

interface VentilationProps {
  locationCode: string;
}

interface BuildingStatus {
  statusCode: string;
  buildingNumber: number;
}

const descriptionFormatter = (value: string | number): string | number => value;

const ventilationDescription: { [index: string]: string } = {
  'Mix Full outside air and some fixed ventilation':
    'Air Handling Units (AHU) within the building will be in 100% outside air mode when the correct conditions are met in ambient. This has been set to 18 degrees ambient',
  'Modified Economy Cycle':
    'Air Handling Units (AHU) within the building will be in 100% outside air mode when the correct conditions are met in ambient. This has been set to 18 degrees ambient',
  'Fixed Ventilation':
    'Fan Coil Units (FCU) or Air Handling Units (AHU) with either fixed or motorised outside air dampers that can be adjustable when ambient temps are met. The motorised ones will open at 16 degrees for buildings that are on the AX or N4 platform of the BAS. ',
  'Natural Ventilation': 'Natural Ventilation Via Openable Windows',
  'Car Park Ventilation': 'Forced mechanical systems with supply and exhaust ductwork',
  'Full outside air':
    'Air Handling Units (AHU) within the building will be in 100% outside air mode when the correct conditions are met in ambient. This has been set to 18 degrees ambient',
  'Full outside air and some fixed ventilation':
    'Air Handling Units (AHU) within the building will be in 100% outside air mode when the correct conditions are met in ambient. This has been set to 18 degrees ambient',
  'Natural Ventilation with fixed ventilation': 'Natural Ventilationn Via Openable Windows',
};

const VentilationDetails = ({ locationCode }: VentilationProps): ReactElement | null => {
  const classes = useStyles();

  const ventilationSystem: BuildingStatus = filter((status: BuildingStatus) => {
    return equals(String(status.buildingNumber), locationCode);
  }, buildingStatus.status)[0];

  // console.log(keys(ventilationDescription))
  return !isNil(ventilationSystem) && includes(ventilationSystem.statusCode, keys(ventilationDescription)) ? (
    <DetailCard title={'COVID Ventilation Status'}>
      <Fragment>
        <div className={classes.HeadCountGroupItem}>
          <div className={classes.HeadCountGroupMetadata}>
            <div className={classes.HeadCountGroupRow}>
              <SVGIcon size={IconSizes.small} type={'ventilate'} alttext="Head Count" />
              <Typography className={classes.HeadCountGroupTitle}>Ventilation Type</Typography>
            </div>
            <div style={{ width: '200%' }}>
              <MetricCard
                key={`${ventilationSystem.buildingNumber}-${ventilationSystem.statusCode}`}
                data-testid={ventilationSystem.statusCode}
                observation={{
                  locationCode: locationCode,
                  observationStartTime: '',
                  observationEndTime: '',
                  observationClass: ObservationClass.spaceComfort,
                  observationType: ObservationType.ventilation,
                  observationTag: '',
                  unitOfMeasure: '',
                  value: ventilationDescription[ventilationSystem.statusCode],
                }}
                title={ventilationSystem.statusCode}
                valueFormatter={descriptionFormatter}
                valueScaler={descriptionFormatter}
              />
            </div>
          </div>
        </div>
      </Fragment>
    </DetailCard>
  ) : null;
};

export default VentilationDetails;
