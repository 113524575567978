import { Backdrop, Button, Fade, Grid, IconButton, makeStyles, Modal, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStockReact from 'highcharts/highstock';
import { BuildingEnergyIntervention } from 'models/Observation';
import { filter, isEmpty, isNil, map, prop, sortBy } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectBuildingEnergyInterventions } from 'store/selectors';
import { UOM_LIGHT_BLUE, UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import { DisplayTimeZone, getHalfYearlyDateRange } from './DateTime';
import momentTZ from 'moment-timezone';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '5',
    width: '50%',
    height: '70%',
  },
  closeButton: {
    leftPadding: '',
  },
  formControl: {
    margin: '1px',
    minWidth: 120,
    backgroundColor: UOM_LIGHT_BLUE,
  },
});

interface EnergyImpactProps {
  locationCode: string;
  actualObservations: any;
  baselineObservations: any;
}

export const EnergyImpactComponent = ({
  locationCode,
  actualObservations,
  baselineObservations,
}: EnergyImpactProps): React.ReactElement => {
  const classes = useStyles();
  const [showImpact, setShowImpact] = useState(false);
  const interventions = useSelector(selectBuildingEnergyInterventions);
  console.log(`EnergyImpactComponent: ${momentTZ.tz(DisplayTimeZone).quarter()}`);

  const handleOpen = (): void => {
    setShowImpact(true);
  };

  const handleClose = (): void => {
    setShowImpact(false);
  };

  const generatePlotLineOptions = (/* actuals: any[] */): any[] => {
    let x = 1;
    let options: any = {};
    if (!isNil(interventions)) {
      options = map((intervention: BuildingEnergyIntervention): any => {
        /* const index = findIndex(
          propEq('interventionDate', intervention.interventionDate),
          actuals,
        ) */
        x += 1;
        const dateSplits = intervention.interventionDate.split('-');

        return {
          value: Date.UTC(Number(dateSplits[0]), Number(dateSplits[1]) - 1, Number(dateSplits[2])),
          color: UOM_SUPPORTING_COLOURS.pink.dark,
          events: {
            mouseout: function (chart: any) {
              setTimeout(() => {
                document.getElementById('test')?.remove();
              }, 2000);
            },
            mouseover: function (chart: any) {
              const element: any = !isNil(document.getElementById('chartsContainer'))
                ? document.getElementById('chartsContainer')
                : document.createElement('div');
              const renderer = new Highcharts.SVGRenderer(
                element,
                0,
                0,
                { top: chart.y, left: chart.x },
                undefined,
                true,
              );
              renderer.rect(chart.x, chart.y, 50, 40, 20, 2).attr({ stroke: 'black', fill: 'grey' }).add();
              renderer
                .text(
                  '<div id="test">' +
                    `Energy Savings: ${!isNil(intervention.energySavings) ? intervention.energySavings : 'N/A'} <br/>` +
                    `Cost: ${intervention.cost} <br/>` +
                    /* `BuildingNumber: ${locationCode.split(';')[1]} <br/>`+
                  `Campus: ${locationCode.split(';')[0]}<br/>`+ */
                    `Type: ${intervention.interventionType}<br/></div>`,
                  chart.x,
                  chart.y,
                  true,
                )
                .css({
                  fontSize: '8pt',
                  color: 'black',
                  backgroundColor: UOM_SUPPORTING_COLOURS.grey.light,
                  border: '1px solid black',
                })
                .add();
            },
          },
          width: 2,
          /* label: {
            useHtml: true,
            align: 'center',
            verticalAlign: 'bottom',
            style: { fontSize: 8 },
            text: `<span>${
              intervention.interventionType.split(' ')[0]
            }<br/>${intervention.interventionType.split(' ').slice(1)}</span>`,
          }, */
        };
      }, interventions);
    }

    return options;
  };

  const createSplineOptions = (actualObs: any, baselineObs: any): Highcharts.Options => {
    let actuals = map((observation) => {
      const observationDate = observation.observationStartTime.split('T')[0].split('-');
      return {
        x: Date.UTC(Number(observationDate[0]), Number(observationDate[1]) - 1, Number(observationDate[2]), 0, 0, 0),
        y: Number(observation.value) / 1000,
      };
    }, actualObs);

    actuals = sortBy(prop('x'), actuals);

    let baseline = map((observation) => {
      const observationDate = observation.observationStartTime.split('T')[0].split('-');
      const filterActual = filter(
        (obs: any) => obs.observationStartTime === observation.observationStartTime,
        actualObs,
      );
      if (!isEmpty(filterActual)) {
        return {
          x: Date.UTC(Number(observationDate[0]), Number(observationDate[1]) - 1, Number(observationDate[2]), 0, 0, 0),
          y: Number(observation.value) / 1000,
        };
      }
      return {
        x: Date.UTC(Number(observationDate[0]), Number(observationDate[1]) - 1, Number(observationDate[2]), 0, 0, 0),
        y: Number(observation.value) / 1000,
      };
    }, baselineObs);

    baseline = sortBy(prop('x'), baseline);

    const [qStartDate] = getHalfYearlyDateRange(momentTZ.tz(DisplayTimeZone).quarter());
    const pointStart = qStartDate.split('-');
    const options: HighStockReact.Options = {
      title: {
        text: '',
        align: 'center',
      },
      /* chart: {
        events: {
          load: function() {
            let chart = this

            // chart.rGroup = chart.renderer.g('rGroup').add() // create an SVG group to allow translate
          },
        },
      }, */
      xAxis: {
        type: 'datetime',
        accessibility: {
          rangeDescription: 'Range:',
        },
        plotLines: generatePlotLineOptions(/* actuals */),
      },
      yAxis: {
        title: {
          text: 'Energy Consumption(kWh)',
        },
        // plotLines: generatePlotLineOptions(actuals)
      },
      tooltip: {
        valueSuffix: ' kWH',
        shared: true,
      },
      lang: {
        noData: 'No forecast available for the period',
      },
      noData: {
        position: {
          align: 'center',
          verticalAlign: 'middle',
        },
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
      rangeSelector: {
        enabled: true,
        buttons: [
          {
            type: 'month',
            count: 1,
            text: '1m',
            /* events: {
              click: function() {
                  alert('Clicked button');
              }
          } */
          },
          {
            type: 'month',
            count: 3,
            text: '3m',
          },
          {
            type: 'month',
            count: 6,
            text: '6m',
          },
          {
            type: 'year',
            count: 1,
            text: '1y',
          },
          {
            type: 'all',
            text: 'All',
          },
        ],
      },
      plotOptions: {
        spline: {
          lineWidth: 3,
          states: {
            hover: {
              lineWidth: 5,
            },
          },
          pointInterval: 3600000 * 24,
          pointStart: Date.UTC(Number(pointStart[0]), Number(pointStart[1]) - 1, Number(pointStart[2]), 0, 0, 0),
          marker: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Actual Consumption',
          data: actuals,
          type: 'spline',
        },
        {
          name: 'Baseline Consumption (5yr average)',
          data: baseline,
          type: 'spline',
        },
      ],
      navigation: {
        menuItemStyle: {
          fontSize: '10px',
        },
      },
    };

    return options;
  };

  return (
    <div>
      <div>
        <Button variant="contained" color="primary" onClick={handleOpen} style={{ width: '100%', height: '100%' }}>
          <Typography variant="body2" style={{ textTransform: 'none', padding: '2%' }}>
            Analyse Intervention Impact
          </Typography>
        </Button>
      </div>
      <div style={{ backgroundColor: 'yellow', borderRadius: '50px' }}>
        {/* graph code */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showImpact}
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 100,
          }}>
          <Fade in={showImpact} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={classes.paper}>
              <Grid container>
                <Grid
                  item
                  xs={11}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: UOM_SUPPORTING_COLOURS.grey.dark,
                    borderRadius: '20px 0 0 0',
                  }}>
                  <Typography variant="h6">Energy Intervention Impact - Analysis</Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    backgroundColor: UOM_SUPPORTING_COLOURS.grey.dark,
                    borderRadius: '0 20px 0 0',
                  }}>
                  <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              {!isEmpty(actualObservations) && !isEmpty(baselineObservations) ? (
                <div
                  id="chartsContainer"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: '3%',
                  }}>
                  <HighchartsReact
                    key="energyForecasts"
                    highcharts={HighStockReact}
                    immutable={true}
                    options={createSplineOptions(actualObservations, baselineObservations)}
                  />
                </div>
              ) : null}
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};
