import {
  ROOM_OBSERVATIONS_REQUESTED,
  ROOM_OBSERVATIONS_RECEIVED,
  ROOM_OBSERVATIONS_FAILED,
  ROOM_OBSERVATIONS_HISTORY_REQUESTED,
  ROOM_OBSERVATIONS_HISTORY_RECEIVED,
  ROOM_OBSERVATIONS_HISTORY_FAILED,
  DetailsActionTypes,
  ROOM_SPACE_DETAILS_REQUESTED,
  ROOM_SPACE_DETAILS_RECEIVED,
  ROOM_SPACE_DETAILS_FAILED,
  FLOOR_OBSERVATIONS_REQUESTED,
  FLOOR_OBSERVATIONS_RECEIVED,
  FLOOR_OBSERVATIONS_FAILED,
  FLOOR_OBSERVATIONS_HISTORY_REQUESTED,
  FLOOR_OBSERVATIONS_HISTORY_RECEIVED,
  FLOOR_OBSERVATIONS_HISTORY_FAILED,
  FLOOR_SPACE_DETAILS_REQUESTED,
  FLOOR_SPACE_DETAILS_RECEIVED,
  FLOOR_SPACE_DETAILS_FAILED,
  BUILDING_SPACE_DETAILS_REQUESTED,
  BUILDING_SPACE_DETAILS_RECEIVED,
  BUILDING_SPACE_DETAILS_FAILED,
  CAMPUS_SPACE_DETAILS_REQUESTED,
  CAMPUS_SPACE_DETAILS_RECEIVED,
  CAMPUS_SPACE_DETAILS_FAILED,
  FLOORS_SUMMARY_REQUESTED,
  FLOORS_SUMMARY_RECEIVED,
  FLOORS_SUMMARY_FAILED,
  BUILDING_OBSERVATIONS_REQUESTED,
  BUILDING_OBSERVATIONS_RECEIVED,
  BUILDING_OBSERVATIONS_FAILED,
  BUILDINGS_SUMMARY_REQUESTED,
  BUILDINGS_SUMMARY_RECEIVED,
  BUILDINGS_SUMMARY_FAILED,
  ROOMS_SUMMARY_REQUESTED,
  ROOMS_SUMMARY_RECEIVED,
  ROOMS_SUMMARY_FAILED,
  CAMPUS_OBSERVATIONS_REQUESTED,
  CAMPUS_OBSERVATIONS_RECEIVED,
  CAMPUS_OBSERVATIONS_FAILED,
  ROOM_SPACE_CHANGED,
  FLOOR_SPACE_CHANGED,
  BUILDING_SPACE_CHANGED,
  CAMPUS_SPACE_CHANGED,
  CAMPUS_OBSERVATIONS_HISTORY_REQUESTED,
  CAMPUS_OBSERVATIONS_HISTORY_RECEIVED,
  CAMPUS_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_OBSERVATIONS_HISTORY_FAILED,
  TEMPERATURE_FORECAST_REQUESTED,
  TEMPERATURE_FORECAST_RECEIVED,
  TEMPERATURE_FORECAST_ERROR,
  CAMPUS_ENERGY_FORECAST_REQUESTED,
  CAMPUS_ENERGY_FORECAST_RECEIVED,
  CAMPUS_ENERGY_FORECAST_ERROR,
  CAMPUS_TOTAL_ENERGY_HISTORY_REQUESTED,
  CAMPUS_TOTAL_ENERGY_HISTORY_RECEIVED,
  CAMPUS_TOTAL_ENERGY_HISTORY_ERROR,
  BUILDING_ENERGY_FORECAST_RECEIVED,
  BUILDING_ENERGY_FORECAST_ERROR,
  BUILDING_ENERGY_FORECAST_REQUESTED,
  BUILDING_MONTHLY_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_MONTHLY_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_MONTHLY_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_POPULAR_TIMINGS_REQUESTED,
  BUILDING_POPULAR_TIMINGS_RECEIVED,
  BUILDING_POPULAR_TIMINGS_FAILED,
  BUILDING_ENERGY_INTERVENTION_POST_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_POST_RECEIVED,
  BUILDING_ENERGY_INTERVENTION_POST_FAILED,
  BUILDING_ENERGY_INTERVENTION_POST_NOT_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_GET_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_GET_FAILED,
  BUILDING_ENERGY_INTERVENTION_GET_RECEIVED,
  BUILDING_ENERGY_INTERVENTION_DELETE_REQUESTED,
  BUILDING_ENERGY_INTERVENTION_DELETE_RECEIVED,
  BUILDING_ENERGY_INTERVENTION_DELETE_FAILED,
  BUILDING_ENERGY_INTERVENTION_DELETE_NOT_REQUESTED,
  BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_FAILED,
  BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_RECEIVED,
  BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_REQUESTED,
  BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_FAILED,
} from './types';
import { BuildingSpace } from 'models/Building';
import { RoomSpace } from 'models/Room';
import { FloorSpace } from 'models/Floor';
import { CampusSpace } from 'models/Campus';
import { Summary } from 'models/Summary';
import {
  API_REQUEST_STATUS_NOT_REQUESTED,
  API_REQUEST_STATUS_REQUESTED,
  API_REQUEST_STATUS_RECEIVED,
  API_REQUEST_STATUS_FAILED,
} from 'components/constants';
import {
  Observations,
  Observation,
  ForecastObservations,
  PopularTimings,
  BuildingEnergyIntervention,
  EnergyObservation,
} from 'models/Observation';
import { assocPath, pathOr, equals, replace, includes, concat } from 'ramda';
import { getArchibusCodefromMap } from 'services/ApiService';

const defaultSpaceDetails = {
  costCentre: '',
  description: '',
  locationCode: '',
  name: '',
  grossInternalArea: 0,
  capacity: 0,
};

const defaultSummary = [
  {
    location: {
      locationCode: '',
    },
    alarms: {
      abnormal: [],
      normal: [],
      endTime: '',
      severityAggregate: '',
    },
    observations: [],
  },
];

const defaultPopularTimings = {
  type: '',
  locationCode: '',
  academicCalendarActivity: '',
  hours: '',
  liveBusyIndex: '',
  liveBusyIndexDescription: '',
  historicalBusyIndexDescription: '',
  historicPopularTimings: [
    {
      dayOfTheWeek: '',
      hours: '',
      busyIndex: '',
    },
  ],
};

const emptyAlarmAndLatestObservations = {
  alarms: [],
  latest: [],
};

const emptyForecastObservations: ForecastObservations = {
  forecasts: [],
};

const emptyEnergyInterventions = [
  {
    cost: '',
    interventionType: '',
    interventionDate: '',
    energySavings: '',
    comments: '',
  },
];
const emptyObservationHistory = {};

const initialState: DetailsReducer = {
  roomDetails: {
    selectedSpace: null,
    observations: emptyAlarmAndLatestObservations,
    historicalObservations: emptyObservationHistory,
    spaceDetails: defaultSpaceDetails,
  },
  floorDetails: {
    selectedSpace: null,
    observations: emptyAlarmAndLatestObservations,
    historicalObservations: emptyObservationHistory,
    spaceDetails: defaultSpaceDetails,
    roomsSummary: defaultSummary,
  },
  buildingDetails: {
    selectedSpace: null,
    observations: emptyAlarmAndLatestObservations,
    historicalObservations: emptyObservationHistory,
    monthlyHistoricalObservations: emptyObservationHistory,
    quarterlyHistoricalObservations: emptyObservationHistory,
    quarterlyBaselineObservations: emptyObservationHistory,
    halfYearlyCostTagsObservations: emptyObservationHistory,
    energyForecastObservations: emptyForecastObservations,
    energyInterventions: emptyEnergyInterventions,
    spaceDetails: defaultSpaceDetails,
    floorsSummary: defaultSummary,
    popularTimings: defaultPopularTimings,
    libraryDensity: null,
  },
  campusDetails: {
    selectedSpace: null,
    observations: emptyAlarmAndLatestObservations,
    temperatureForecastObservations: emptyForecastObservations,
    energyForecastObservations: emptyForecastObservations,
    energyHistoryObservations: emptyObservationHistory,
    historicalObservations: emptyObservationHistory,
    spaceDetails: defaultSpaceDetails,
    buildingsSummary: defaultSummary,
  },
  roomObservationsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  roomObservationsHistoryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  roomSpaceDetailsRequestStatus: {
    status: API_REQUEST_STATUS_NOT_REQUESTED,
    message: 'test message',
  },
  roomsSummaryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  floorObservationsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  floorObservationsHistoryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  floorSpaceDetailsRequestStatus: {
    status: API_REQUEST_STATUS_NOT_REQUESTED,
    message: 'test message',
  },
  floorsSummaryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingsSummaryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingDetailsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingObservationsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingObservationsHistoryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingMonthlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingQuarterlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingQuarterlyBaselineObservationsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingSpaceDetailsRequestStatus: {
    status: API_REQUEST_STATUS_NOT_REQUESTED,
    message: 'test message',
  },
  campusSpaceDetailsRequestStatus: {
    status: API_REQUEST_STATUS_NOT_REQUESTED,
    message: 'test message',
  },
  campusObservationsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  campusObservationsHistoryRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  forecastObservationRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  energyForecastObservationRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingEnergyForecastRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingPopularTimingsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingEnergyInterventionPostStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingEnergyInterventionGetStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingEnergyInterventionDeleteStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  energyHistoryObservationsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
  buildingHalfYearlyCostTagsRequestStatus: API_REQUEST_STATUS_NOT_REQUESTED,
};

interface HistoricalObservations {
  [observationHistoryIndex: string]: { [observationTypeIndex: string]: Observation[] };
}

interface HistoricalEnergyObservations {
  [observationHistoryIndex: string]: {
    [observationTypeIndex: string]: EnergyObservation[];
  };
}

interface RoomDetails {
  selectedSpace: string | null;
  observations: Observations;
  historicalObservations: HistoricalObservations;
  spaceDetails: RoomSpace;
}

interface FloorDetails {
  selectedSpace: string | null;
  observations: Observations;
  historicalObservations: HistoricalObservations;
  spaceDetails: FloorSpace;
  roomsSummary: Summary[];
}

interface EnergyIntervention {
  cost: string;
  interventionType: string;
  energySavings: string;
  interventionDate: string;
  comments: string;
}

interface BuildingDetails {
  selectedSpace: string | null;
  observations: Observations;
  historicalObservations: HistoricalObservations;
  monthlyHistoricalObservations: HistoricalObservations;
  quarterlyHistoricalObservations: HistoricalObservations;
  quarterlyBaselineObservations: HistoricalObservations;
  halfYearlyCostTagsObservations: HistoricalEnergyObservations;
  energyForecastObservations: ForecastObservations;
  energyInterventions: BuildingEnergyIntervention[];
  spaceDetails: BuildingSpace;
  floorsSummary: Summary[];
  popularTimings: PopularTimings | null;
  libraryDensity: string | null;
}

interface CampusDetails {
  selectedSpace: string | null;
  observations: Observations;
  temperatureForecastObservations: ForecastObservations;
  energyForecastObservations: ForecastObservations;
  energyHistoryObservations: HistoricalObservations;
  historicalObservations: HistoricalObservations;
  spaceDetails: CampusSpace;
  buildingsSummary: Summary[];
}

interface DetailsReducer {
  campusDetails: CampusDetails;
  buildingDetails: BuildingDetails;
  roomDetails: RoomDetails;
  floorDetails: FloorDetails;
  roomObservationsRequestStatus: string;
  roomObservationsHistoryRequestStatus: string;
  roomSpaceDetailsRequestStatus: { status: string; message: any };
  roomsSummaryRequestStatus: string;
  floorObservationsRequestStatus: string;
  floorObservationsHistoryRequestStatus: string;
  floorSpaceDetailsRequestStatus: { status: string; message: any };
  floorsSummaryRequestStatus: string;
  buildingDetailsRequestStatus: string;
  buildingObservationsRequestStatus: string;
  buildingObservationsHistoryRequestStatus: string;
  buildingMonthlyObservationsHistoryRequestStatus: string;
  buildingQuarterlyObservationsHistoryRequestStatus: string;
  buildingQuarterlyBaselineObservationsRequestStatus: string;
  buildingSpaceDetailsRequestStatus: { status: string; message: any };
  buildingsSummaryRequestStatus: string;
  buildingEnergyForecastRequestStatus: string;
  buildingPopularTimingsRequestStatus: string;
  buildingEnergyInterventionPostStatus: string;
  buildingEnergyInterventionGetStatus: string;
  buildingEnergyInterventionDeleteStatus: string;
  campusSpaceDetailsRequestStatus: { status: string; message: any };
  campusObservationsRequestStatus: string;
  campusObservationsHistoryRequestStatus: string;
  forecastObservationRequestStatus: string;
  energyForecastObservationRequestStatus: string;
  energyHistoryObservationsRequestStatus: string;
  buildingHalfYearlyCostTagsRequestStatus: string;
  error?: string;
}

export const replaceGM = (locationCode: any): any => {
  const buildingNumber = locationCode.split(';')[1];
  const floorNumber = locationCode.split(';')[2];
  const archibusfloor = getArchibusCodefromMap(buildingNumber, floorNumber);
  return replace(floorNumber, archibusfloor, locationCode);
};
//  contains('GM', locationCode) ? replace('GM', '0.5', locationCode) : locationCode

export const detailsReducer = (state = initialState, action: DetailsActionTypes): DetailsReducer => {
  switch (action.type) {
    case ROOM_OBSERVATIONS_REQUESTED:
      state = { ...state, roomObservationsRequestStatus: API_REQUEST_STATUS_REQUESTED };
      break;
    case ROOM_OBSERVATIONS_RECEIVED:
      state = {
        ...state,
        roomDetails: {
          ...state.roomDetails,
          ...action.payload,
        },
        roomObservationsRequestStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;
    case ROOM_OBSERVATIONS_FAILED:
      state = {
        ...state,
        roomObservationsRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case ROOM_OBSERVATIONS_HISTORY_REQUESTED:
      state = {
        ...state,
        roomDetails: {
          ...state.roomDetails,
          historicalObservations: {},
        },
        roomObservationsHistoryRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case ROOM_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentRoom = pathOr('', ['roomDetails', 'selectedSpace']);
        const isCurrentRoom = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, replaceGM(currentRoom(state)));
        if (isCurrentRoom(locationCode, state)) {
          const historicalObservations = { ...state.roomDetails.historicalObservations };
          const newHistoricalObservationsForLocation = assocPath(
            [action.observationHistoryIndex, action.observationType],
            action.payload.observations.historical,
            historicalObservations,
          );

          state = {
            ...state,
            roomDetails: {
              ...state.roomDetails,
              historicalObservations: newHistoricalObservationsForLocation,
            },
            roomObservationsHistoryRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case ROOM_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        roomObservationsHistoryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case ROOM_SPACE_DETAILS_REQUESTED:
      state = {
        ...state,
        roomSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_REQUESTED,
          message: 'Room Space Details  requested',
        },
      };
      break;
    case ROOM_SPACE_DETAILS_RECEIVED:
      state = {
        ...state,
        roomDetails: {
          ...state.roomDetails,
          spaceDetails: action.payload,
        },
        roomSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_RECEIVED,
          message: 'Room Space Details RECEIVED',
        },
      };
      break;
    case ROOM_SPACE_DETAILS_FAILED:
      state = {
        ...state,
        roomSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_FAILED,
          message: action.payload.name + ': ' + action.payload.message,
        },
      };
      break;

    case ROOMS_SUMMARY_REQUESTED:
      state = { ...state, roomsSummaryRequestStatus: API_REQUEST_STATUS_REQUESTED };
      break;
    case ROOMS_SUMMARY_RECEIVED:
      state = {
        ...state,
        roomsSummaryRequestStatus: API_REQUEST_STATUS_RECEIVED,
        floorDetails: {
          ...state.floorDetails,
          roomsSummary: action.payload,
        },
      };
      break;
    case ROOMS_SUMMARY_FAILED:
      state = {
        ...state,
        roomsSummaryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case BUILDING_SPACE_DETAILS_REQUESTED:
      state = {
        ...state,
        buildingDetailsRequestStatus: API_REQUEST_STATUS_REQUESTED,
        buildingSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_REQUESTED,
          message: 'Building Space Details requested',
        },
      };
      break;
    case BUILDING_SPACE_DETAILS_RECEIVED:
      state = {
        ...state,
        buildingSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_RECEIVED,
          message: 'Building Space Details RECEIVED',
        },
        buildingDetails: {
          ...state.buildingDetails,
          spaceDetails: action.payload,
        },
      };
      break;
    case BUILDING_SPACE_DETAILS_FAILED:
      state = {
        ...state,
        buildingSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_FAILED,
          message: action.payload.name + ': ' + action.payload.message,
        },
      };
      break;

    case BUILDING_OBSERVATIONS_REQUESTED: {
      state = {
        ...state,
        buildingObservationsRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    }
    case BUILDING_OBSERVATIONS_RECEIVED: {
      if (
        (includes('148A', pathOr('', ['buildingDetails', 'selectedSpace'], state)) ||
          includes('148B', pathOr('', ['buildingDetails', 'selectedSpace'], state))) &&
        includes('PC_ARTSWEST', action.payload.location.locationCode)
      ) {
        state = {
          ...state,
          buildingDetails: {
            ...state.buildingDetails,
            ...action.payload,
            observations: {
              ...state.buildingDetails.observations,
              ...action.payload.observations,
              latest: [...state.buildingDetails.observations.latest, ...action.payload.observations.latest],
            },
          },
          buildingObservationsRequestStatus: API_REQUEST_STATUS_RECEIVED,
        };
      } else {
        state = {
          ...state,
          buildingDetails: {
            ...state.buildingDetails,
            ...action.payload,
          },
          buildingObservationsRequestStatus: API_REQUEST_STATUS_RECEIVED,
        };
      }
      break;
    }
    case BUILDING_OBSERVATIONS_FAILED:
      state = {
        ...state,
        buildingObservationsRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case BUILDING_OBSERVATIONS_HISTORY_REQUESTED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          historicalObservations: {},
        },
        buildingObservationsHistoryRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentBuilding = pathOr('', ['buildingDetails', 'selectedSpace']);
        const isCurrentBuilding = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, currentBuilding(state)) ||
          (includes('PC_ARTSWEST', locationCode) && includes('148', currentBuilding(state)));

        if (isCurrentBuilding(locationCode, state)) {
          const historicalObservations = {
            ...state.buildingDetails.historicalObservations,
          };
          const newHistoricalObservationsForLocation = assocPath(
            [action.observationHistoryIndex, action.observationType],
            action.payload.observations.historical,
            historicalObservations,
          );

          state = {
            ...state,
            buildingDetails: {
              ...state.buildingDetails,
              historicalObservations: newHistoricalObservationsForLocation,
            },
            buildingObservationsHistoryRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case BUILDING_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        buildingObservationsHistoryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case BUILDING_MONTHLY_OBSERVATIONS_HISTORY_REQUESTED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          monthlyHistoricalObservations: {},
        },
        buildingMonthlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_MONTHLY_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentBuilding = pathOr('', ['buildingDetails', 'selectedSpace']);
        const isCurrentBuilding = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, currentBuilding(state)) ||
          (includes('PC_ARTSWEST', locationCode) && includes('148', currentBuilding(state)));

        if (isCurrentBuilding(locationCode, state)) {
          const monthlyHistoricalObservations = {
            ...state.buildingDetails.monthlyHistoricalObservations,
          };
          const newMonthlyHistoricalObservationsForLocation = assocPath(
            [action.observationHistoryIndex, action.observationType],
            action.payload.observations.historical,
            monthlyHistoricalObservations,
          );

          state = {
            ...state,
            buildingDetails: {
              ...state.buildingDetails,
              monthlyHistoricalObservations: newMonthlyHistoricalObservationsForLocation,
            },
            buildingMonthlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case BUILDING_MONTHLY_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        buildingMonthlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_REQUESTED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          quarterlyHistoricalObservations: {},
        },
        buildingQuarterlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentBuilding = pathOr('', ['buildingDetails', 'selectedSpace']);
        const isCurrentBuilding = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, currentBuilding(state)) ||
          (includes('PC_ARTSWEST', locationCode) && includes('148', currentBuilding(state)));

        if (isCurrentBuilding(locationCode, state)) {
          const quarterlyHistoricalObservations = {
            ...state.buildingDetails.quarterlyHistoricalObservations,
          };
          const newQuarterlyHistoricalObservationsForLocation = assocPath(
            [action.observationHistoryIndex, action.observationType],
            action.payload.observations.historical,
            quarterlyHistoricalObservations,
          );

          state = {
            ...state,
            buildingDetails: {
              ...state.buildingDetails,
              quarterlyHistoricalObservations: newQuarterlyHistoricalObservationsForLocation,
            },
            buildingQuarterlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case BUILDING_QUARTERLY_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        buildingQuarterlyObservationsHistoryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_REQUESTED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          quarterlyBaselineObservations: {},
        },
        buildingQuarterlyBaselineObservationsRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentBuilding = pathOr('', ['buildingDetails', 'selectedSpace']);
        const isCurrentBuilding = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, currentBuilding(state)) ||
          (includes('PC_ARTSWEST', locationCode) && includes('148', currentBuilding(state)));

        if (isCurrentBuilding(locationCode, state)) {
          const quarterlyBaselineObservations = {
            ...state.buildingDetails.quarterlyBaselineObservations,
          };
          const newQuarterlyBaselineObservationsForLocation = assocPath(
            [action.observationHistoryIndex, action.observationType],
            action.payload.observations.historical,
            quarterlyBaselineObservations,
          );

          state = {
            ...state,
            buildingDetails: {
              ...state.buildingDetails,
              quarterlyBaselineObservations: newQuarterlyBaselineObservationsForLocation,
            },
            buildingQuarterlyBaselineObservationsRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case BUILDING_QUARTERLY_BASELINE_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        buildingQuarterlyBaselineObservationsRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_REQUESTED: {
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          halfYearlyCostTagsObservations: {},
        },
        buildingHalfYearlyCostTagsRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };

      break;
    }
    case BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentBuilding = pathOr('', ['buildingDetails', 'selectedSpace']);
        const isCurrentBuilding = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, currentBuilding(state)) ||
          (includes('PC_ARTSWEST', locationCode) && includes('148', currentBuilding(state)));

        if (isCurrentBuilding(locationCode, state)) {
          const halfYearCostTagsObservations = {};
          const merged = concat(
            action.payload.observations.historical,
            pathOr([], [action.observationType, action.observationHistoryIndex], halfYearCostTagsObservations),
          );
          const newHalfYearCostTagsObservationsForLocation = assocPath(
            [action.observationType, action.observationHistoryIndex],
            merged,
            halfYearCostTagsObservations,
          );

          state = {
            ...state,
            buildingDetails: {
              ...state.buildingDetails,
              halfYearlyCostTagsObservations: newHalfYearCostTagsObservationsForLocation,
            },
            buildingHalfYearlyCostTagsRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case BUILDING_HALFYEARLY_COST_TAG_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        buildingHalfYearlyCostTagsRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case BUILDING_ENERGY_FORECAST_RECEIVED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          energyForecastObservations: {
            ...state.campusDetails.energyForecastObservations,
            ...action.payload,
          },
        },
        buildingEnergyForecastRequestStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;
    case BUILDING_ENERGY_FORECAST_ERROR:
      state = {
        ...state,
        buildingEnergyForecastRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case BUILDING_ENERGY_FORECAST_REQUESTED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          energyForecastObservations: {
            forecasts: [],
          },
        },
        buildingEnergyForecastRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;

    case BUILDING_POPULAR_TIMINGS_REQUESTED:
      state = {
        ...state,
        buildingPopularTimingsRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_POPULAR_TIMINGS_RECEIVED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          popularTimings: {
            historicPopularTimings: action.payload.popularTimings.historicPopularTimings,
            academicCalendarActivity: action.payload.popularTimings.academicCalendarActivity,
            historicalBusyIndexDescription: action.payload.popularTimings.historicalBusyIndexDescription,
            hours: action.payload.popularTimings.hours,
            liveBusyIndex: action.payload.popularTimings.liveBusyIndex,
            liveBusyIndexDescription: action.payload.popularTimings.liveBusyIndexDescription,
            locationCode: action.payload.popularTimings.locationCode,
            type: action.payload.popularTimings.type,
          },
          /* action.payload
              .popularTimings */
        },
      };
      break;
    case BUILDING_POPULAR_TIMINGS_FAILED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          popularTimings: null /* .popularTimings,
            libraryDensity: action.payload.liveBusyIndex, */,
        },
      };
      break;

    case BUILDING_ENERGY_INTERVENTION_POST_NOT_REQUESTED:
      state = {
        ...state,
        buildingEnergyInterventionPostStatus: API_REQUEST_STATUS_NOT_REQUESTED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_POST_REQUESTED:
      state = {
        ...state,
        buildingEnergyInterventionPostStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_POST_RECEIVED:
      state = {
        ...state,
        buildingEnergyInterventionPostStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_POST_FAILED:
      state = {
        ...state,
        buildingEnergyInterventionPostStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_GET_REQUESTED:
      state = {
        ...state,
        buildingEnergyInterventionGetStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_GET_RECEIVED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          energyInterventions: action.payload,
        },
        buildingEnergyInterventionGetStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_GET_FAILED:
      state = {
        ...state,
        buildingEnergyInterventionGetStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_DELETE_REQUESTED:
      state = {
        ...state,
        buildingEnergyInterventionDeleteStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_DELETE_RECEIVED:
      state = {
        ...state,
        buildingEnergyInterventionDeleteStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_DELETE_FAILED:
      state = {
        ...state,
        buildingEnergyInterventionDeleteStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case BUILDING_ENERGY_INTERVENTION_DELETE_NOT_REQUESTED:
      state = {
        ...state,
        buildingEnergyInterventionDeleteStatus: API_REQUEST_STATUS_NOT_REQUESTED,
      };
      break;
    case FLOOR_OBSERVATIONS_REQUESTED:
      state = { ...state, floorObservationsRequestStatus: API_REQUEST_STATUS_REQUESTED };
      break;
    case FLOOR_OBSERVATIONS_RECEIVED:
      state = {
        ...state,
        floorObservationsRequestStatus: API_REQUEST_STATUS_RECEIVED,
        floorDetails: {
          ...state.floorDetails,
          ...action.payload,
        },
      };
      break;
    case FLOOR_OBSERVATIONS_FAILED:
      state = {
        ...state,
        floorObservationsRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case FLOOR_SPACE_DETAILS_REQUESTED:
      state = {
        ...state,
        floorSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_REQUESTED,
          message: 'Floor Space Details requested',
        },
      };
      break;
    case FLOOR_SPACE_DETAILS_RECEIVED:
      state = {
        ...state,
        floorSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_RECEIVED,
          message: 'Floor Space Details  RECEIVED',
        },
        floorDetails: {
          ...state.floorDetails,
          spaceDetails: action.payload,
        },
      };
      break;
    case FLOOR_SPACE_DETAILS_FAILED:
      state = {
        ...state,
        floorSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_FAILED,
          message: action.payload.name + ': ' + action.payload.message,
        },
      };
      break;
    case FLOORS_SUMMARY_REQUESTED:
      state = { ...state, floorsSummaryRequestStatus: API_REQUEST_STATUS_REQUESTED };
      break;
    case FLOORS_SUMMARY_RECEIVED:
      state = {
        ...state,
        floorsSummaryRequestStatus: API_REQUEST_STATUS_RECEIVED,
        buildingDetails: {
          ...state.buildingDetails,
          floorsSummary: action.payload,
        },
      };
      break;
    case FLOORS_SUMMARY_FAILED:
      state = {
        ...state,
        floorsSummaryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case FLOOR_OBSERVATIONS_HISTORY_REQUESTED:
      state = {
        ...state,
        floorDetails: {
          ...state.floorDetails,
          historicalObservations: {},
        },
        floorObservationsHistoryRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case FLOOR_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentFloor = pathOr('', ['floorDetails', 'selectedSpace']);
        const isCurrentFloor = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, replaceGM(currentFloor(state)));
        if (isCurrentFloor(locationCode, state)) {
          const historicalObservations = { ...state.floorDetails.historicalObservations };
          const newHistoricalObservationsForLocation = assocPath(
            [action.observationHistoryIndex, action.observationType],
            action.payload.observations.historical,
            historicalObservations,
          );

          state = {
            ...state,
            floorDetails: {
              ...state.floorDetails,
              historicalObservations: newHistoricalObservationsForLocation,
            },
            floorObservationsHistoryRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case FLOOR_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        floorObservationsHistoryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case BUILDINGS_SUMMARY_REQUESTED:
      state = { ...state, buildingsSummaryRequestStatus: API_REQUEST_STATUS_REQUESTED };
      break;
    case BUILDINGS_SUMMARY_RECEIVED:
      state = {
        ...state,
        buildingsSummaryRequestStatus: API_REQUEST_STATUS_RECEIVED,
        campusDetails: {
          ...state.campusDetails,
          buildingsSummary: action.payload,
        },
      };
      break;
    case BUILDINGS_SUMMARY_FAILED:
      state = {
        ...state,
        buildingsSummaryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case CAMPUS_SPACE_DETAILS_REQUESTED:
      state = {
        ...state,
        campusSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_REQUESTED,
          message: 'Campus Space Details Requested',
        },
      };
      break;
    case CAMPUS_SPACE_DETAILS_RECEIVED:
      state = {
        ...state,
        campusSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_RECEIVED,
          message: 'Campus Space Details RECEIVED',
        },
        campusDetails: {
          ...state.campusDetails,
          spaceDetails: action.payload as CampusSpace,
        },
      };
      break;
    case CAMPUS_SPACE_DETAILS_FAILED:
      state = {
        ...state,
        campusSpaceDetailsRequestStatus: {
          status: API_REQUEST_STATUS_FAILED,
          message: action.payload.name + ': ' + action.payload.message,
        },
      };
      break;
    case CAMPUS_OBSERVATIONS_REQUESTED:
      state = {
        ...state,
        campusObservationsRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case CAMPUS_OBSERVATIONS_RECEIVED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          ...action.payload,
        },
        campusObservationsRequestStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;
    case CAMPUS_OBSERVATIONS_FAILED:
      state = {
        ...state,
        ...action.payload,
        campusObservationsRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;
    case CAMPUS_OBSERVATIONS_HISTORY_REQUESTED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          historicalObservations: {},
        },
        campusObservationsHistoryRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;
    case CAMPUS_OBSERVATIONS_HISTORY_RECEIVED:
      {
        const locationCode = action.payload.location.locationCode;

        const currentCampus = pathOr('', ['campusDetails', 'selectedSpace']);
        const isCurrentCampus = (locationCode: string, state: DetailsReducer): boolean =>
          equals(locationCode, currentCampus(state));

        if (isCurrentCampus(locationCode, state)) {
          const historicalObservations = {
            ...state.campusDetails.historicalObservations,
          };
          const newHistoricalObservationsForLocation = assocPath(
            [action.observationHistoryIndex, action.observationType],
            action.payload.observations.historical,
            historicalObservations,
          );

          state = {
            ...state,
            campusDetails: {
              ...state.campusDetails,
              historicalObservations: newHistoricalObservationsForLocation,
            },
            campusObservationsHistoryRequestStatus: API_REQUEST_STATUS_RECEIVED,
          };
        }
      }
      break;
    case CAMPUS_OBSERVATIONS_HISTORY_FAILED:
      state = {
        ...state,
        campusObservationsHistoryRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case TEMPERATURE_FORECAST_REQUESTED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          temperatureForecastObservations: {
            forecasts: [],
          },
        },
        forecastObservationRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;

    case TEMPERATURE_FORECAST_RECEIVED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          temperatureForecastObservations: {
            ...state.campusDetails.temperatureForecastObservations,
            ...action.payload,
          },
        },
        forecastObservationRequestStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;

    case TEMPERATURE_FORECAST_ERROR:
      state = {
        ...state,
        forecastObservationRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case CAMPUS_ENERGY_FORECAST_REQUESTED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          energyForecastObservations: {
            forecasts: [],
          },
        },
        energyForecastObservationRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;

    case CAMPUS_ENERGY_FORECAST_RECEIVED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          energyForecastObservations: {
            ...state.campusDetails.energyForecastObservations,
            ...action.payload,
          },
        },
        energyForecastObservationRequestStatus: API_REQUEST_STATUS_RECEIVED,
      };
      break;

    case CAMPUS_ENERGY_FORECAST_ERROR:
      state = {
        ...state,
        energyForecastObservationRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case CAMPUS_TOTAL_ENERGY_HISTORY_REQUESTED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          energyHistoryObservations: {},
        },
        energyHistoryObservationsRequestStatus: API_REQUEST_STATUS_REQUESTED,
      };
      break;

    case CAMPUS_TOTAL_ENERGY_HISTORY_RECEIVED:
      {
        const energyHistoricalObs = { ...state.campusDetails.energyHistoryObservations };
        const newEnergyObs = assocPath(
          ['TotalEnergyHistory', 'Energy'],
          action.payload.observations.historical,
          energyHistoricalObs,
        );
        state = {
          ...state,
          campusDetails: {
            ...state.campusDetails,
            energyHistoryObservations: newEnergyObs,
          },
          energyHistoryObservationsRequestStatus: API_REQUEST_STATUS_RECEIVED,
        };
      }
      break;

    case CAMPUS_TOTAL_ENERGY_HISTORY_ERROR:
      state = {
        ...state,
        energyHistoryObservationsRequestStatus: API_REQUEST_STATUS_FAILED,
      };
      break;

    case CAMPUS_SPACE_CHANGED:
      state = {
        ...state,
        campusDetails: {
          ...state.campusDetails,
          selectedSpace: action.payload.locationCode,
        },
      };
      break;
    case ROOM_SPACE_CHANGED:
      state = {
        ...state,
        roomDetails: {
          ...state.roomDetails,
          selectedSpace: action.payload.locationCode,
        },
      };
      break;
    case FLOOR_SPACE_CHANGED:
      state = {
        ...state,
        floorDetails: {
          ...state.floorDetails,
          selectedSpace: action.payload.locationCode,
        },
      };
      break;
    case BUILDING_SPACE_CHANGED:
      state = {
        ...state,
        buildingDetails: {
          ...state.buildingDetails,
          selectedSpace: action.payload.locationCode,
        },
      };
      break;
  }
  return state;
};
