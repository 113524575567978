import { UserActionTypes, SET_USER, SET_NAME, SET_INITIALS } from './types';

export const initialUserState: UserReducer = {
  initials: '',
  name: '',
  groups: [],
};

interface UserReducer {
  initials: string;
  name: string;
  groups: string[];
}

export const userReducer = (state = initialUserState, action: UserActionTypes): UserReducer => {
  switch (action.type) {
    case SET_USER:
      state = {
        ...state,
        name: action.payload.name,
        initials: action.payload.initials,
        groups: action.payload.groups,
      };
      break;
    case SET_NAME:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case SET_INITIALS:
      state = {
        ...state,
        initials: action.payload,
      };
      break;
  }
  return state;
};
