import React, { ReactElement, useState, useLayoutEffect, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { RoomSpace } from 'models/Room';
import Section from 'components/common/Section';
import SpaceDetails from 'components/common/SpaceDetails';
import SpaceComfortDetails from 'components/Observation/SpaceComfortDetails';
import { AlarmObservation, Observation } from 'models/Observation';
import {
  selectRoomSpaceDetails,
  selectRoomSpaceUtilisation,
  selectRoomAlarms,
  selectRoomSpaceComfortObservations,
} from 'store/selectors';
import { scrollToBottomOfLeftPanel } from 'utils/mapUtils';
import SpaceUtilisation from 'components/Observation/SpaceUtilisation';
import AlarmDetails from 'components/common/AlarmDetails';
import { equals } from 'ramda';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { match } from 'react-router';

const useStyles = makeStyles({
  root: {
    display: 'block',
    '& ul': {
      listStyle: 'none',
    },
  },
  subtitle: {
    margin: '10px 0 0 20px',
    padding: 0,
  },
  observation: {
    display: 'flex',
  },
});

interface RoomSectionProps {
  match: match<UrlParams>;
}

const RoomSection = ({ match }: RoomSectionProps): ReactElement | null => {
  const [space, setSpace] = useState({
    locationCode: '',
    description: '',
    costCentre: '',
  });
  const spaceDetails: RoomSpace = useSelector(selectRoomSpaceDetails);
  const alarms: AlarmObservation[] = useSelector(selectRoomAlarms);
  const spaceComfortObservations: Observation[] = useSelector(selectRoomSpaceComfortObservations);
  const spaceUtilisation = useSelector(selectRoomSpaceUtilisation);

  const [locationCode, setLocationCode] = useState('');

  const classes = useStyles();

  useEffect((): void => {
    const newLocationCode = `${match.params.campusId};${match.params.buildingNumber};${match.params.floorId};${match.params.roomNumber}`;
    if (!equals(locationCode, newLocationCode)) {
      setLocationCode(newLocationCode);
    }
  }, [locationCode, match.params.campusId, match.params.buildingNumber, match.params.floorId, match.params.roomNumber]);

  /* useLayoutEffect will trigger before painting to the screen.
     By doing this, we can update the details before rendering,
     as opposed to rendering, updating, and re-rendering. All sub components
     will need to be updated to use the same layout effect if anomalies
     on the screen are observed (i.e. component flickering)*/
  useLayoutEffect((): void => {
    scrollToBottomOfLeftPanel();
    setSpace(spaceDetails);
  }, [spaceDetails]);

  return (
    <div className={classes.root}>
      <Section title={space ? `Room ${space.locationCode.split(';')[3]}` : ''}>
        <SpaceDetails spaceDetails={space} spaceType="room" />
        <AlarmDetails alarms={alarms} />
        <SpaceUtilisation data={spaceUtilisation} locationCode={locationCode} />
        <SpaceComfortDetails observations={spaceComfortObservations} location={space.locationCode} />
      </Section>
    </div>
  );
};

export default RoomSection;
