import { filter, anyPass, propOr } from 'ramda';
import { Observation, ObservationType, ObservationTag } from 'models/Observation';

export const isEnergyObservationType = (observation: Observation): boolean =>
  observation.observationType === ObservationType.energy;

const isEnergyCo2Observation = (observation: Observation): boolean =>
  observation.observationType === ObservationType.co2 &&
  propOr<string, Observation, string>('', 'observationTag', observation).startsWith(ObservationTag.totalKgConsumption);

export const isGroupedEnergyObservation = anyPass([isEnergyObservationType, isEnergyCo2Observation]);

export const groupEnergyObservations = (observations: Observation[]): Observation[] => {
  return filter(isGroupedEnergyObservation, observations);
};

const isWhConsumptionObservation = (observation: Observation): boolean =>
  propOr<string, Observation, string>('', 'observationTag', observation).startsWith(ObservationTag.whConsumption);
const isWhGenerationObservation = (observation: Observation): boolean =>
  propOr<string, Observation, string>('', 'observationTag', observation).startsWith(ObservationTag.whGeneration);

export const isWhConsumptionOrGenerationObservation = anyPass([isWhConsumptionObservation, isWhGenerationObservation]);
