import { useState, useRef, useEffect, useCallback } from 'react';
import { pathOr, isNil } from 'ramda';
import { ZLevelControl } from 'components/Map/ZLevelControl';
import { useSelector, useDispatch } from 'react-redux';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { mapService } from 'services/Map/MapService';
import { RouteComponentProps } from 'react-router';
import { Room } from 'models/Building';
import {
  getFloorObservationDetailsAction,
  getFloorSpaceDetailsAction,
  getRoomsSummaryAction,
  floorChangedAction,
} from 'store/details/actions';
import { FETCH_INTERVAL } from 'components/constants';
import { FloorMapper } from 'utils/mapUtils';
import {
  selectBuildingSpaceDetails,
  selectFloorSpaceDetails,
  selectMap,
  selectMapHighlightService,
  selectMapDataService,
  selectAuthAccessToken,
} from 'store/selectors';

export const FloorControl: React.FC<RouteComponentProps<UrlParams>> = ({
  match,
  history,
}: RouteComponentProps<UrlParams>): null => {
  const dispatch = useDispatch();
  const map = useSelector(selectMap);
  const floorSpaceDetails = useSelector(selectFloorSpaceDetails);
  const buildingSpaceDetails = useSelector(selectBuildingSpaceDetails);
  const mapHighlightService = useSelector(selectMapHighlightService);
  const mapDataService = useSelector(selectMapDataService);
  const accessToken = useSelector(selectAuthAccessToken);

  const fetchFloorDetails = useCallback(
    (campusId: string, buildingNumber: string, floorId: string, accessToken: any): void => {
      const formattedFloorId = FloorMapper.mazemapToArchibusId(floorId);
      dispatch(getFloorSpaceDetailsAction(campusId, buildingNumber, formattedFloorId, accessToken));
      dispatch(floorChangedAction(campusId, buildingNumber, floorId));
      dispatch(getFloorObservationDetailsAction(campusId, buildingNumber, formattedFloorId, accessToken));
      dispatch(getRoomsSummaryAction(campusId, buildingNumber, formattedFloorId, accessToken));
    },
    [dispatch],
  );

  const refreshFloorDetails = useCallback(
    (campusId: string, buildingNumber: string, floorId: string, accessToken: any): void => {
      const formattedFloorId = FloorMapper.mazemapToArchibusId(floorId);
      dispatch(getFloorObservationDetailsAction(campusId, buildingNumber, formattedFloorId, accessToken));
      dispatch(getRoomsSummaryAction(campusId, buildingNumber, formattedFloorId, accessToken));
    },
    [dispatch],
  );

  // triggers whenever url parameters change
  useEffect((): void => {
    const campusId = match.params.campusId;
    const buildingSpaceLibraries = filterLibraryRooms(buildingSpaceDetails.rooms);
    const buildingNumber = match.params.buildingNumber;
    const floorId = match.params.floorId;

    if (buildingSpaceLibraries) {
      // remove highlighted library on ground floor which set in BuildingSection
      const removeHighlight = true;
      mapService.highlightLibraryRooms(campusId, buildingSpaceLibraries, removeHighlight);
    }

    if (!isNil(buildingNumber) && !isNil(floorId) && !isNil(accessToken)) {
      fetchFloorDetails(campusId, buildingNumber, floorId, accessToken);
      mapHighlightService.selectFloorByArchibusId(floorId);
    }
  }, [
    match.params.campusId,
    match.params.buildingNumber,
    match.params.floorId,
    fetchFloorDetails,
    mapHighlightService,
    mapDataService,
    accessToken,
  ]);

  const filterLibraryRooms = (rooms: Room[] | undefined): Room[] | undefined => {
    return rooms ? rooms.filter((room) => room.roomCategory === '5') : undefined;
  };

  const [prevLibraryLocations, setPrevLibararyLocations] = useState<Room[] | undefined>();
  const prevLibLocationsRef = useRef<Room[]>();
  const campusId = match.params.campusId;

  if (prevLibLocationsRef.current) {
    // remove highlighted library when navigate floors
    const removeHighlight = true;
    const libraryRooms = prevLibLocationsRef.current;
    mapService.highlightLibraryRooms(campusId, libraryRooms, removeHighlight);
  }

  useEffect((): (() => void) => {
    const buildingNumber = match.params.buildingNumber;
    mapService.checkSmartMeterLocationsAndAddMarkers(floorSpaceDetails, campusId);
    const floorSpaceLibraries = filterLibraryRooms(floorSpaceDetails.rooms);

    if (floorSpaceLibraries) {
      mapService.highlightLibraryRooms(campusId, floorSpaceLibraries);
      setPrevLibararyLocations(floorSpaceLibraries);
      prevLibLocationsRef.current = prevLibraryLocations;
    }

    const onChangeFloor = (): void => {
      if (
        floorSpaceLibraries &&
        floorSpaceLibraries.length > 0 &&
        prevLibLocationsRef.current &&
        prevLibLocationsRef.current.length > 0 &&
        prevLibLocationsRef.current[0].locationCode === floorSpaceLibraries[0].locationCode
      ) {
        mapService.highlightLibraryRooms(campusId, floorSpaceLibraries, true);
      }
      const floorId = pathOr(null, ['zLevelUpdater', 'zLevels', map.zLevel], map);
      if (floorId !== null) {
        const existingPath = history.location.pathname;
        const newPath = `/campus/${campusId}/building/${buildingNumber}/floor/${FloorMapper.mazemapToArchibusId(
          floorId,
        )}`;
        if (existingPath.indexOf(`/floor/${floorId}`) === -1) {
          history.push(newPath);
        }
      }
    };

    const floorBar = new ZLevelControl(
      {
        autoUpdate: true,
        maxHeight: 300,
      },
      onChangeFloor,
    );
    map.addControl(floorBar, 'bottom-left');

    return (): void => {
      map.removeControl(floorBar);
    };
  }, [
    prevLibLocationsRef,
    floorSpaceDetails,
    match.params.campusId,
    match.params.floorId,
    match.params.buildingNumber,
    history,
    map,
  ]);

  // fetch latest data every 2 minutes
  useEffect((): (() => void) => {
    const campusId = match.params.campusId;
    const buildingNumber = match.params.buildingNumber;
    const floorId = match.params.floorId;

    let fetchInterval: NodeJS.Timeout;

    if (!isNil(buildingNumber) && !isNil(floorId)) {
      const fetch = (): void => refreshFloorDetails(campusId, buildingNumber, floorId, accessToken);
      fetchInterval = setInterval(fetch, FETCH_INTERVAL);
    }

    return (): void => {
      clearInterval(fetchInterval);
    };
  }, [match.params.campusId, match.params.buildingNumber, match.params.floorId, refreshFloorDetails, accessToken]);

  return null;
};

export default FloorControl;
