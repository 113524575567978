export const SET_USER = 'SET_USER';
export const SET_NAME = 'SET_NAME';
export const SET_INITIALS = 'SET_INITIALS';

export interface User {
  name: string;
  initials: string;
  groups: string[];
}

interface SetUserAction {
  type: typeof SET_USER;
  payload: User;
}

interface SetNameAction {
  type: typeof SET_NAME;
  payload: string;
}

interface SetIntialsAction {
  type: typeof SET_INITIALS;
  payload: string;
}

export type UserActionTypes = SetUserAction | SetNameAction | SetIntialsAction;
