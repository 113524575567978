import React, { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { GreenTick, YellowQuestionMark, RedCross } from '../common/Icons';
import './Popup.css';
import { AlarmDetail } from 'models/Summary';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from 'components/App/AppStyles';

const AlertPopup = ({ buildingNumber, alarms }: AlertPopupProps): ReactElement => {
  const getIcon = (total: number | undefined): ReactElement | undefined => {
    if (total === undefined) {
      return <YellowQuestionMark />;
    } else if (total > 0) {
      return <RedCross />;
    } else {
      return <GreenTick />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="popup-content">
        <Typography>Building: {buildingNumber}</Typography>
        {alarms.map((alarm: AlarmDetail, index: number): ReactElement => {
          const icon = getIcon(alarm.value);
          return (
            <div key={index}>
              {`${alarm.observationType}: ${alarm.value}`}
              {icon}
            </div>
          );
        })}
      </div>
    </ThemeProvider>
  );
};

interface AlertPopupProps {
  buildingNumber: string;
  alarms: AlarmDetail[];
}

export const alertPopupAsHtml = ({ buildingNumber, alarms }: AlertPopupProps): string => {
  return ReactDOMServer.renderToStaticMarkup(<AlertPopup buildingNumber={buildingNumber} alarms={alarms} />);
};
