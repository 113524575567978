import { DisplayTimeZone } from 'components/common/DateTime';
import React, { useState } from 'react';
import momentTZ from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { addOrUpdateMaintenanceNote } from 'store/details/actions';
import { toggleIsNotesOpened, toggleIsUpdateNotesOpened } from 'store/UISettings/actions';
import {
  selectAuthAccessToken,
  selectInterventionPostStatus,
  selectIsNotesOpen,
  selectIsUpdateNotesOpen,
} from 'store/selectors';
import {
  Backdrop,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  TextareaAutosize,
  NativeSelect,
  styled,
  InputBase,
  InputLabel,
  FormLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { UOM_GRAY, UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import { CheckCircleOutlined, Close } from '@material-ui/icons';
import {
  API_REQUEST_STATUS_NOT_REQUESTED,
  API_REQUEST_STATUS_RECEIVED,
  API_REQUEST_STATUS_REQUESTED,
  interventionTypes,
} from 'components/constants';
import { WaitingProgressIndicator } from 'components/common/WaitingProgressIndicator';
import { isEmpty, isNil } from 'ramda';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid black',
    fontSize: 16,
    padding: '5px 26px 5px 10px',
    height: 25,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}));

const useStyles = makeStyles({
  formLabelAlignment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '5',
    width: '54%',
    height: '64%',
  },
  gridStyle: {
    // backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});

interface FormProps {
  buildingNumber: string;
  campusId: string;
  type: string;
  date: string;
  costValue: string;
  energySavingsValue: string;
  comments: string;
}
export const MaintenanceLogForm = ({
  buildingNumber,
  campusId,
  type,
  date,
  costValue,
  energySavingsValue,
  comments,
}: FormProps): React.ReactElement => {
  console.log('Maintenance Log form', {
    type,
    date,
    costValue,
    energySavingsValue,
    comments,
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const dateValue = !isEmpty(date) ? date : momentTZ.tz(DisplayTimeZone).format('DD MMM YYYY');

  const typeValue = !isEmpty(type) ? type : 'Building Optimisation';

  const [additionalComments, setAdditionalComments] = useState(comments);
  const [cost, setCost] = useState(costValue);
  const [energySavings, setEnergySavings] = useState(energySavingsValue);
  const [interventionDate, setInterventionDate] = useState(dateValue);
  const [interventionType, setInterventionType] = useState(typeValue);
  const [energySavingsError, setEnergySavingsError] = useState('');
  const [costError, setCostError] = useState('');
  const [interventionDateError, setInterventionDateError] = useState('');
  const showForm = useSelector(selectIsNotesOpen);
  const showUpdateForm = useSelector(selectIsUpdateNotesOpen);
  const accessToken = useSelector(selectAuthAccessToken);
  const postStatus = useSelector(selectInterventionPostStatus);

  const handleFormSubmit = (e: any): void => {
    e.preventDefault();
    dispatch(
      addOrUpdateMaintenanceNote(
        accessToken,
        additionalComments,
        buildingNumber,
        campusId,
        cost,
        energySavings,
        interventionDate.toString(),
        interventionType,
        true,
      ),
    );
    // dispatch(toggleIsEnergyInterventionDrawerOpened())
  };

  const checkIsSubmitDisabled = (): boolean =>
    !(!isEmpty(cost) && isEmpty(energySavingsError) && isEmpty(costError) && isEmpty(interventionDateError));

  const changeField = (event: any): void => {
    const name = !isNil(event) && !isNil(event.target) ? event.target.name : 'interventionDate';
    const value = !isNil(event) && !isNil(event.target) ? event.target.value : momentTZ(event).format('DD MMM YYYY');
    const decimalRegex = /^\d+\.?\d{0,2}$/;
    switch (name) {
      case 'interventionType':
        setInterventionType(value);
        break;
      case 'interventionDate': {
        setInterventionDate(momentTZ(event).tz(DisplayTimeZone).format('DD MMM YYYY'));
        setInterventionDateError('');
        if (value === 'Invalid date') {
          setInterventionDateError('Please select valid date from calendar');
          break;
        }
        break;
      }
      case 'cost':
        setCost(value);
        setCostError('');
        if (!decimalRegex.test(value)) {
          setCostError('Please enter a valid input. Ex: 12.34');
          break;
        }
        break;
      case 'energySavings':
        setEnergySavings(value);
        setEnergySavingsError('');
        if (!decimalRegex.test(value) && !isEmpty(value)) {
          setEnergySavingsError('Please enter a valid input. Ex: 12.34');
          break;
        }
        break;
      case 'additionalComments':
        setAdditionalComments(value);
        break;
      default:
        console.log('Do nothing');
        break;
    }
  };

  const closeForm = (): void => {
    // setShowForm(!showForm)
    if (showForm) {
      dispatch(toggleIsNotesOpened());
    } else {
      dispatch(toggleIsUpdateNotesOpened());
    }
  };

  const menuItems: any = interventionTypes.map(
    (type: string): React.ReactElement => (
      <option value={type} key={type}>
        {type}
      </option>
    ),
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showForm || showUpdateForm}
      disableEnforceFocus
      disableAutoFocus
      onClose={closeForm}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}>
      <Fade
        in={showForm || showUpdateForm}
        /* style={{ backgroundColor: 'green' }} */
      >
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '20px',
            border: '2px solid black',
            boxShadow: '5',
            width: '54%',
            height: '64%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }} /* className={classes.paper} */
        >
          <Grid container>
            <Grid
              item
              xs={11}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: UOM_GRAY,
                borderRadius: '18px 0 0 0',
              }}>
              <Typography variant="h6">Add Energy Intervention</Typography>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: UOM_GRAY,
                borderRadius: '0 18px 0 0',
              }}>
              <IconButton aria-label="close" onClick={closeForm}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <br />
          {postStatus === API_REQUEST_STATUS_NOT_REQUESTED ? (
            <form
              onSubmit={handleFormSubmit}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Grid container spacing={1}>
                <Grid item xs={5} className={classes.formLabelAlignment}>
                  <Typography>
                    <FormLabel
                      style={{
                        color: UOM_SUPPORTING_COLOURS.orange.dark,
                        fontWeight: 'bold',
                      }}>
                      *&nbsp;
                    </FormLabel>
                    Intervention Type&nbsp;:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <NativeSelect
                    id="demo-simple-select"
                    name="interventionType"
                    onChange={(e: any): void => changeField(e)}
                    value={interventionType}
                    variant="outlined"
                    style={{ width: '96%', height: '70%' }}
                    input={<BootstrapInput />}>
                    {menuItems}
                  </NativeSelect>
                </Grid>
                <Grid item xs={3} />

                <Grid item xs={5} className={classes.formLabelAlignment}>
                  <Typography>
                    <FormLabel
                      style={{
                        color: UOM_SUPPORTING_COLOURS.orange.dark,
                        fontWeight: 'bold',
                      }}>
                      *&nbsp;
                    </FormLabel>
                    Intervention Date:
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.gridStyle}>
                  <div>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                        autoOk
                        variant="inline"
                        disableToolbar={true}
                        inputVariant="outlined"
                        format="DD MMM yyyy"
                        value={interventionDate}
                        onChange={changeField}
                        name="interventionDate"
                        invalidDateMessage={''}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <InputLabel
                    style={{ color: 'red', fontSize: '0.8em' }}
                    /* value={invalidDateMessage} */
                  >
                    {interventionDateError}
                  </InputLabel>
                </Grid>

                <Grid item xs={5} className={classes.formLabelAlignment}>
                  <Typography>
                    <FormLabel
                      style={{
                        color: UOM_SUPPORTING_COLOURS.orange.dark,
                        fontWeight: 'bold',
                      }}>
                      *&nbsp;
                    </FormLabel>
                    Cost:
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridStyle}>
                  <TextField
                    inputMode="text"
                    name="cost"
                    variant="outlined"
                    size="small"
                    value={cost}
                    onChange={changeField}
                    error={!isEmpty(costError)}
                    /* helperText={costError} */
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <InputLabel
                    style={{ color: 'red', fontSize: '0.8em' }}
                    /* value={invalidDateMessage} */
                  >
                    {costError}
                  </InputLabel>
                </Grid>

                <Grid item xs={5} className={classes.formLabelAlignment}>
                  <Typography>Expected Energy Savings p.a.(kWH):</Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridStyle}>
                  <TextField
                    name="energySavings"
                    variant="outlined"
                    size="small"
                    value={energySavings}
                    onChange={changeField}
                    error={!isEmpty(energySavingsError)}
                    /* helperText={energySavingsError} */
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <InputLabel
                    style={{ color: 'red', fontSize: '0.8em' }}
                    /* value={invalidDateMessage} */
                  >
                    {energySavingsError}
                  </InputLabel>
                </Grid>

                <Grid item xs={5} className={classes.formLabelAlignment}>
                  <Typography>Additional Comments:</Typography>
                </Grid>
                <Grid item xs={7} className={classes.gridStyle}>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Additional Comments..."
                    name={'additionalComments'}
                    onChange={changeField}
                    style={{ width: 200, height: 70, maxHeight: 80, maxWidth: 300 }}
                    value={additionalComments}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}>
                  <Button
                    disabled={checkIsSubmitDisabled()}
                    variant="contained"
                    color="primary"
                    onClick={handleFormSubmit}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : postStatus === API_REQUEST_STATUS_REQUESTED ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <WaitingProgressIndicator progressMessage={'Please wait while the request is processing'} />
            </div>
          ) : postStatus === API_REQUEST_STATUS_RECEIVED ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <CheckCircleOutlined color="primary" />
              <Typography>Data saved successfully</Typography>
            </div>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};
