import React, { ReactElement, Fragment } from 'react';
import { RoomSpace } from 'models/Room';
import { FloorSpace } from 'models/Floor';
import { BuildingSpace } from 'models/Building';
import { CampusSpace } from 'models/Campus';
import DetailCard from './DetailCard';
import { SVGIcon, IconSizes } from './Icons';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { pathOr } from 'ramda';
import { SpaceType } from 'types';
import CampusAttributes from '../../components/CampusSection/CampusAttributes';
import BuildingAttributes from '../../components/BuildingSection/BuildingAttributes';

const useStyles = makeStyles({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  detailList: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    listStyle: 'none',
    marginLeft: 0,
    paddingInlineStart: 0,
    justifyContent: 'space-around',
    width: '100%',
    '& li': {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        paddingRight: '10px',
      },
    },
  },
  imageListItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'self-start',
    width: 'auto',
    maxHeight: '200px',
    minHeight: '200px',
    margin: 0,
  },
});

const useRoomDescriptionStyles = makeStyles({
  roomCategory: {
    paddingLeft: '5px',
  },
  description: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignSelf: 'center',
    justifyContent: 'center',
  },
});

const useBuildingImageStyles = makeStyles({
  image: {
    width: '60%',
    paddingRight: '10px',
    maxHeight: '225px',
    minHeight: '200px',
  },
});

export interface SpaceDetailsProps {
  spaceDetails: RoomSpace | FloorSpace | BuildingSpace | CampusSpace;
  imgSrc?: string;
  spaceType?: SpaceType;
}

const formatNumber = (num: number = 0): string => {
  return new Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: 0,
    useGrouping: true,
  }).format(num);
};

const BuildingImage = (props: SpaceDetailsProps): ReactElement => {
  const classes = useBuildingImageStyles();
  return <img className={classes.image} src={props.imgSrc} alt={`${props.spaceDetails.description}`} />;
};

const BuildingQuality = (props: SpaceDetailsProps): ReactElement => (
  <Fragment>
    <SVGIcon size={IconSizes.large} type="building-quality" alttext="Building Quality" />
    <Typography>{`${pathOr('Not available', ['spaceDetails', 'buildingQuality'], props)}`}</Typography>
  </Fragment>
);

const RoomDescription = (props: SpaceDetailsProps): ReactElement => {
  const classes = useRoomDescriptionStyles();
  return (
    <div className={classes.description}>
      <Typography>{`${props.spaceDetails.description}`}</Typography>
      <Typography className={classes.roomCategory}>
        {`(Category: ${pathOr('Unknown', ['spaceDetails', 'roomCategory'], props)})`}
      </Typography>
    </div>
  );
};

const CostCentre = (props: SpaceDetailsProps): ReactElement => (
  <Fragment>
    <SVGIcon size={IconSizes.large} type="cost-center" alttext="Cost Center" />
    <Typography>{`${props.spaceDetails.costCentre}`}</Typography>
  </Fragment>
);

const SpaceDetails = (props: SpaceDetailsProps): ReactElement | null => {
  const classes = useStyles();

  return (
    <DetailCard title={'Space details'}>
      <div className={classes.detailsContainer}>
        {props.spaceType === 'room' ? <RoomDescription {...props} /> : null}
        <div className={classes.content}>
          {props.spaceType === 'building' ? <BuildingImage {...props} /> : null}
          <ul className={`${classes.detailList} ${props.spaceType === 'building' ? classes.imageListItem : ''}`}>
            <li>
              <SVGIcon size={IconSizes.large} type="area" alttext="Area" />
              <Typography>{`${formatNumber(props.spaceDetails.grossInternalArea)} m²`}</Typography>
            </li>
            <li>
              <SVGIcon size={IconSizes.large} type="capacity" alttext="Capacity" />
              <Typography>{`${formatNumber(props.spaceDetails.capacity)}`}</Typography>
            </li>
            {/* <li>
              <SVGIcon
                size={IconSizes.large}
                type="keep-distance"
                alttext="Four square meter maximum capacity"
              />
              <Typography>{`${formatNumber(
                props.spaceDetails.fourSqmMaxCapacity,
              )}`}</Typography>
            </li> */}
            {props.spaceType === 'building' ? (
              <li>
                <BuildingQuality {...props} />
              </li>
            ) : null}
            {props.spaceType === 'room' ? (
              <li>
                <CostCentre {...props} />
              </li>
            ) : null}
          </ul>
        </div>
        {props.spaceType === 'campus' ? <CampusAttributes campusAttributes={props.spaceDetails} /> : null}
        {props.spaceType === 'building' ? <BuildingAttributes buildingAttributes={props.spaceDetails} /> : null}
      </div>
    </DetailCard>
  );
};

export default SpaceDetails;
