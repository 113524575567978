import * as React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import jwtDecode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAuthAccessToken,
  selectAuthIDToken,
  selectInterventionDeleteStatus,
  selectIsUpdateNotesOpen,
} from 'store/selectors';
import { includes, isNil, map } from 'ramda';
import { BuildingEnergyIntervention } from 'models/Observation';
import { IconSizes, SVGIcon } from 'components/common/Icons';
import { toggleIsUpdateNotesOpened } from 'store/UISettings/actions';
import {
  removeSelectedInterventionAction,
  addOrUpdateMaintenanceNote,
  resetInterventionDeletion,
} from 'store/details/actions';
import { MaintenanceLogForm } from './MaintenanceLogForm';
import TablePagination from '@material-ui/core/TablePagination';
import { UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import { API_REQUEST_STATUS_RECEIVED, API_REQUEST_STATUS_REQUESTED } from 'components/constants';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

interface Intervention {
  interventionDate: string;
  interventionType: string;
  cost: string;
  energySavings: string;
  comments: string;
  actions?: string;
}

function createData(
  interventionDate: string,
  interventionType: string,
  cost: string,
  energySavings: string,
  comments: string,
): any {
  return {
    interventionDate,
    interventionType,
    cost,
    energySavings,
    comments,
  };
}

const descendingComparator = (a: any, b: any, orderBy: keyof any): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Intervention;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'interventionDate',
    numeric: false,
    disablePadding: true,
    label: 'Intervention Date',
  },
  {
    id: 'interventionType',
    numeric: true,
    disablePadding: false,
    label: 'Intervention Type',
  },
  {
    id: 'cost',
    numeric: true,
    disablePadding: false,
    label: 'Cost($)',
  },
  {
    id: 'energySavings',
    numeric: true,
    disablePadding: false,
    label: 'Expected Energy Savings p.a.(kWh)',
  },
  {
    id: 'comments',
    numeric: true,
    disablePadding: false,
    label: 'Comments',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
  },
];

interface Token {
  name: string;
  preferred_username: string;
  CampusNowGroups: string[];
}

interface InterventionTableProps {
  interventions: BuildingEnergyIntervention[];
  campusId: string;
  buildingNumber: string;
}

const useStyles = makeStyles({
  buttonTextStyle: {
    color: UOM_SUPPORTING_COLOURS.black.dark,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonStyle: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: UOM_SUPPORTING_COLOURS.blue.dark,
    fontSize: '1em',
  },
});

export const InterventionTable = ({
  interventions,
  campusId,
  buildingNumber,
}: InterventionTableProps): React.ReactElement => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Intervention>('interventionDate');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [interventionDeletionMarker, setInterventionDeletionMarker] = React.useState<Intervention>({
    interventionDate: '',
    interventionType: '',
    cost: '',
    energySavings: '',
    comments: '',
  });
  const dispatch = useDispatch();
  const showUpdateNote = useSelector(selectIsUpdateNotesOpen);
  const deleteStatus = useSelector(selectInterventionDeleteStatus);
  const [showDeletionPopup, setShowDeletionPopup] = React.useState(
    deleteStatus === API_REQUEST_STATUS_REQUESTED || deleteStatus === API_REQUEST_STATUS_RECEIVED ? true : false,
  );

  React.useEffect(() => {
    setShowDeletionPopup(
      deleteStatus === API_REQUEST_STATUS_REQUESTED || deleteStatus === API_REQUEST_STATUS_RECEIVED ? true : false,
    );
  }, [deleteStatus]);

  const showDeletionAlert = (row: Intervention): void => {
    setInterventionDeletionMarker(row);
    setShowDeleteAlert(!showDeleteAlert);
  };
  const idToken = useSelector(selectAuthIDToken);
  const accessToken = useSelector(selectAuthAccessToken);
  const [formProps, setFormProps] = React.useState({
    type: '',
    date: '',
    costValue: '',
    energySavingsValue: '',
    comments: '',
  });
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Intervention) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const closeDeletionPopup = (): void => {
    setShowDeletionPopup(!showDeletionPopup);
    dispatch(resetInterventionDeletion());
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string): boolean => selected.indexOf(name) !== -1;

  let isAdmin: boolean = false;
  if (!isNil(idToken)) {
    const decodedToken: Token = jwtDecode(idToken);
    const isTokenAdmin: boolean[] = map(
      (group: string) => includes('Campus-Services-Admin', group),
      decodedToken.CampusNowGroups,
    );
    if (includes(true, isTokenAdmin)) {
      isAdmin = true;
    }
  }

  const rows = map(
    (i: Intervention) => createData(i.interventionDate, i.interventionType, i.cost, i.energySavings, i.comments),
    interventions,
  );

  const updateIntervention = (intervention: BuildingEnergyIntervention): void => {
    dispatch(toggleIsUpdateNotesOpened());
    dispatch(addOrUpdateMaintenanceNote('', '', '', '', '', '', '', '', false));
    setFormProps({
      type: intervention.interventionType,
      date: intervention.interventionDate,
      costValue: intervention.cost,
      energySavingsValue: intervention.energySavings,
      comments: intervention.comments,
    });
  };

  const deleteIntervention = (intervention: BuildingEnergyIntervention): void => {
    setShowDeleteAlert(!showDeleteAlert);
    dispatch(
      removeSelectedInterventionAction(
        accessToken,
        campusId,
        buildingNumber,
        intervention.interventionDate,
        intervention.interventionType,
      ),
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <React.Fragment>
      <Box style={{ backgroundColor: 'grey' }}>
        <Paper style={{ backgroundColor: 'darkgrey' }}>
          <TableContainer style={{ maxHeight: '300px' }} component={Paper}>
            <Table
              stickyHeader
              style={{ width: '100%', border: '1px solid black' }}
              aria-labelledby="tableTitle"
              size={'small'}>
              <TableHead>
                <TableRow style={{ borderWidth: '1px 1px 0 1px' }}>
                  {headCells.map((headCell) => (
                    <TableCell
                      style={{
                        border: '1px solid black',
                        backgroundColor: UOM_SUPPORTING_COLOURS.grey.light,
                      }}
                      key={headCell.id}
                      align={'center'}
                      padding="none"
                      sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={(e: any) => handleRequestSort(e, headCell.id)}>
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * 5, page * 5 + 5)
                  .sort(getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.interventionDate);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.interventionDate)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.interventionDate + '_' + row.interventionType}
                        selected={isItemSelected}>
                        <TableCell
                          style={{ border: '1px solid black', backgroundColor: 'white' }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center">
                          {row.interventionDate}
                        </TableCell>
                        <TableCell style={{ border: '1px solid black', backgroundColor: 'white' }} align="center">
                          {row.interventionType}
                        </TableCell>
                        <TableCell style={{ border: '1px solid black', backgroundColor: 'white' }} align="center">
                          {row.cost}
                        </TableCell>
                        <TableCell style={{ border: '1px solid black', backgroundColor: 'white' }} align="center">
                          {row.energySavings}
                        </TableCell>
                        <TableCell style={{ border: '1px solid black', backgroundColor: 'white' }} align="center">
                          {row.comments}
                        </TableCell>
                        {isAdmin ? (
                          <TableCell
                            style={{
                              border: '1px solid black',
                              backgroundColor: 'white',
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <div role="button" onClick={(e: any): void => updateIntervention(row)}>
                                <SVGIcon type={'update-intervention'} size={IconSizes.small} alttext="Update"></SVGIcon>
                              </div>
                              <div role="button" onClick={(e: any): void => showDeletionAlert(row)}>
                                <SVGIcon type={'delete'} size={IconSizes.small} alttext="Delete"></SVGIcon>
                              </div>
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{
                              border: '1px solid black',
                              backgroundColor: 'white',
                            }}
                          />
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={rows.length}
            rowsPerPage={5}
            page={page}
            rowsPerPageOptions={[]}
            onPageChange={handleChangePage}
            component="div"
          />
        </Paper>

        {showUpdateNote ? (
          <MaintenanceLogForm buildingNumber={buildingNumber} campusId={campusId} {...formProps}></MaintenanceLogForm>
        ) : null}
      </Box>
      {showDeleteAlert ? (
        <Dialog open={showDeleteAlert} onClose={(e: any): void => setShowDeleteAlert(!showDeleteAlert)}>
          <DialogTitle style={{ color: UOM_SUPPORTING_COLOURS.black.dark }}>
            <b>Delete</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogContent}>
              <Typography component="label" style={{ color: UOM_SUPPORTING_COLOURS.black.dark }}>
                Are you sure you want to delete this record ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  onClick={(e: any): void => setShowDeleteAlert(!showDeleteAlert)}
                  className={classes.buttonStyle}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  onClick={(e: any): void => deleteIntervention(interventionDeletionMarker)}
                  className={classes.buttonStyle}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      ) : null}
      {deleteStatus === API_REQUEST_STATUS_REQUESTED ? (
        <Dialog open={showDeletionPopup} onClose={closeDeletionPopup}>
          <DialogTitle className={classes.buttonTextStyle}>
            <b>Delete</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogContent}>
              <CircularProgress color="primary" />
              &nbsp;
              <Typography className={classes.buttonTextStyle}>
                Please wait while the intervention deletion is processing
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.buttonStyle} onClick={closeDeletionPopup}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      ) : deleteStatus === API_REQUEST_STATUS_RECEIVED ? (
        <Dialog open={showDeletionPopup} onClose={closeDeletionPopup}>
          <DialogTitle className={classes.buttonTextStyle}>
            <b>Delete</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogContent}>
              <CheckCircleOutlined color="primary" />
              <Typography className={classes.buttonTextStyle}>Intervention deleted successfully</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.buttonStyle} onClick={closeDeletionPopup}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
};
