import React, { useEffect, ReactElement, useRef, Fragment, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Map.css';
import { selectMapService, selectRightDrawerIsOpen } from 'store/selectors';
import MapLegend from './MapLegend';
import MapSearch from './MapSearch';
import { Feature } from 'geojson';
import { updateSearchResultAction } from 'store/map/actions';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Fab, makeStyles } from '@material-ui/core';
import RightDrawer from '../RightDrawer/RightDrawer';
import { toggleMapDrawOpenClosed } from 'store/UISettings/actions';
import { SVGIcon, IconSizes } from 'components/common/Icons';
import { RIGHT_DRAWER_WIDTH, UOM_LIGHT_BLUE, UOM_SECONDARY_BLUE } from 'styles/styleConstants';
import { prop } from 'ramda';
import { useOktaAuth } from '@okta/okta-react';
import { MaintenanceLogDrawer } from 'components/MaintenanceLog/MaintenanceLogDrawer';

const useStyles = makeStyles({
  FAB: {
    position: 'absolute',
    top: '10px',
    'z-index': 999,
    backgroundColor: UOM_SECONDARY_BLUE,
  },
  drawerClosedFAB: {
    right: '13px',
  },
  drawerOpenFAB: {
    right: `${RIGHT_DRAWER_WIDTH + 30}px`,
    backgroundColor: UOM_LIGHT_BLUE,
  },
});

const Map: React.FC = (): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapService = useSelector(selectMapService);
  const mapElement = mapService.getMap();
  const container = useRef<HTMLDivElement>(null);
  const { authState } = useOktaAuth();
  const accessToken: string | undefined = prop('accessToken', authState.accessToken);

  const searchCallback = useCallback(
    (searchResult: Feature): void => {
      dispatch(updateSearchResultAction(searchResult));
    },
    [dispatch],
  );

  useEffect((): (() => void) => {
    const mapContainer = mapElement.getContainer();
    if (container.current) {
      container.current.appendChild(mapElement.getContainer());
      mapContainer.style.display = 'block';
      mapElement.resize();
    }
    return (): void => {
      mapContainer.style.display = 'none';
    };
  }, [container, mapElement]);

  const rightDrawerIsOpen = useSelector(selectRightDrawerIsOpen);
  // const notesOpen = useSelector(selectIsDrawerOpen)

  const [isDrawerOpen, setIsDrawerOpen] = useState(rightDrawerIsOpen);

  useEffect((): void => {
    setIsDrawerOpen(rightDrawerIsOpen);
  }, [isDrawerOpen, rightDrawerIsOpen]);

  const toggleDraw = (): void => {
    dispatch(toggleMapDrawOpenClosed());
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Fragment>
      <div ref={container} className={'map-content'}>
        <Fab
          color="primary"
          aria-label="Open drawer"
          title="Toggle drawer"
          className={`${classes.FAB} ${isDrawerOpen ? classes.drawerOpenFAB : classes.drawerClosedFAB}`}
          onClick={toggleDraw}
          size="small">
          <SVGIcon size={IconSizes.extraSmall} type="filter" alttext="Filter" />
        </Fab>
        <MapLegend></MapLegend>
        <Route
          path={'/campus/:campusCode'}
          render={(props: RouteComponentProps<any>): ReactElement => (
            <MapSearch
              campusCode={props.match.params.campusCode}
              searchCallback={searchCallback}
              dispatchProp={dispatch}
              accessToken={accessToken}></MapSearch>
          )}
        />
        <RightDrawer></RightDrawer>
        <MaintenanceLogDrawer></MaintenanceLogDrawer>
      </div>
    </Fragment>
  );
};

export default Map;
