import React, { ReactElement, useState, Fragment, ChangeEvent, useEffect } from 'react';
import { makeStyles, Collapse, Divider, Select, FormControl, MenuItem, Typography, Input } from '@material-ui/core';
import { UOM_LIGHT_BLUE, HEADING_FONT_FAMILY, APP_GUTTER, UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import { map, keys } from 'ramda';
import { CampusNames } from 'components/constants';
import { RouteComponentProps } from 'react-router';
import { UrlParams } from 'components/Mazemap/MapTypes';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#cccccc',
    padding: '10px 0 10px 0',
  },
  header: {
    display: 'flex',
    padding: `${APP_GUTTER}px 0`,
    backgroundColor: UOM_LIGHT_BLUE,
    justifyContent: 'center',
    textAlign: 'center',
  },
  selectorForm: {
    '& div': {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  selectIcon: {
    color: 'white',
  },
  menuItemPaper: {
    backgroundColor: UOM_SUPPORTING_COLOURS.blue.dark,
    border: '1px solid black',
  },
  menuItemSelected: {
    // TODO: style without important
    backgroundColor: `${UOM_LIGHT_BLUE} !important`,
  },
  headerText: {
    display: 'flex',
    alignSelf: 'center',
    fontFamily: HEADING_FONT_FAMILY,
    height: 'auto',
  },
});

interface SectionHeaderProps extends RouteComponentProps<UrlParams> {
  campusId: string;
  children: ReactElement | ReactElement[];
}

const CampusSectionSelector = ({ match, history, campusId, children }: SectionHeaderProps): ReactElement => {
  const [showSection, setShowSection] = useState(true);
  const handleDetailsClick = (): void => {
    setShowSection(!showSection);
  };

  const [selectedCampusId, setCampusId] = useState(campusId);
  const handleChangeCampus = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
    setCampusId(event.target.value as string);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect((): void => {
    const existingPath = history.location.pathname;
    const newPath = `/campus/${selectedCampusId}`;
    if (existingPath.indexOf(`/campus/${selectedCampusId}`) === -1) {
      history.push(newPath);
    }
  }, [selectedCampusId, history, match.params.campusId]);

  const classes = useStyles();
  return (
    <Fragment>
      <div role="button" className={classes.header} onClick={handleDetailsClick}>
        <FormControl className={classes.selectorForm}>
          <Select
            classes={{ icon: classes.selectIcon }}
            MenuProps={{ classes: { paper: classes.menuItemPaper } }}
            value={selectedCampusId}
            input={<Input disableUnderline={true} />}
            onChange={handleChangeCampus}
            onClick={handleClick}>
            {map(
              (campusId: string | number): ReactElement => (
                <MenuItem value={campusId} key={campusId} classes={{ selected: classes.menuItemSelected }}>
                  <Typography className={classes.headerText} variant="subtitle1" color="secondary">
                    {CampusNames[campusId]}
                  </Typography>
                </MenuItem>
              ),
              keys(CampusNames),
            )}
          </Select>
        </FormControl>
      </div>
      <Collapse in={showSection}>{children}</Collapse>
      {showSection ? null : <Divider />}
    </Fragment>
  );
};

export default CampusSectionSelector;
