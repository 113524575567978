import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Feature } from 'geojson';
import { includes, isNil, path } from 'ramda';
import {
  getBuildingObservationsAction,
  getBuildingSpaceDetailsAction,
  getFloorsSummaryAction,
  buildingChangedAction,
  getBuildingEnergyForecastAction,
  getBuildingPopularTimingsAction,
  getBuildingEnergyInterventionsAction,
} from 'store/details/actions';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { FETCH_INTERVAL, MazeMapCampusDetails } from 'components/constants';
import {
  selectMapDataService,
  selectMapHighlightService,
  selectMapSearchResult,
  selectAuthAccessToken,
} from 'store/selectors';
import { extractUomBuildingId } from 'utils/mapUtils';
import moment from 'moment';
import { getQuarterlyDateRange } from 'components/common/DateTime';
import { ObservationType } from 'models/Observation';

const BuildingControl = ({ match, history }: RouteComponentProps<UrlParams>): null => {
  const dispatch = useDispatch();
  const [isRoomSelected, setIsRoomSelected] = useState(false);

  const mapHighlightService = useSelector(selectMapHighlightService);
  const mapDataService = useSelector(selectMapDataService);
  const mapSearchResult = useSelector(selectMapSearchResult);
  const accessToken = useSelector(selectAuthAccessToken);

  const fetchBuildingDetails = useCallback(
    (campusId: string, buildingNumber: string, startDate: string, endDate: string, accessToken: any): void => {
      dispatch(buildingChangedAction(campusId, buildingNumber));
      dispatch(getBuildingSpaceDetailsAction(campusId, buildingNumber, accessToken));
      dispatch(getFloorsSummaryAction(campusId, buildingNumber, accessToken));
      dispatch(getBuildingObservationsAction(campusId, buildingNumber, accessToken, undefined));
      dispatch(getBuildingPopularTimingsAction(/* campusId, */ buildingNumber, accessToken));
      if (includes('148', buildingNumber)) {
        setTimeout(() => {
          dispatch(getBuildingObservationsAction(campusId, buildingNumber, accessToken, ObservationType.energy));
        }, 3000);
      }
      dispatch(getBuildingEnergyForecastAction(campusId, buildingNumber, startDate, endDate, accessToken));
      dispatch(getBuildingEnergyInterventionsAction(campusId, buildingNumber, accessToken));
    },
    [dispatch],
  );

  const refreshBuildingDetails = useCallback(
    (campusId: string, buildingNumber: string, accessToken: any): void => {
      dispatch(getFloorsSummaryAction(campusId, buildingNumber, accessToken));
      if (!includes('148', buildingNumber)) {
        dispatch(getBuildingObservationsAction(campusId, buildingNumber, accessToken, undefined));
      }
    },
    [dispatch],
  );

  const updateRouteIfBuildingChanged = useCallback(
    (campus: string, buildingNumber: string): void => {
      const currentPath = history.location.pathname;
      const newPath = `/campus/${campus}/building/${buildingNumber}`;
      if (currentPath.indexOf(`/building/${buildingNumber}`) === -1) {
        // order is important - once history is updated, the useEffect
        // handling url changes will trigger instantly and any state changes
        // in subsequent calls will most likely not have taken effect.
        // If the order here is changed, after selecting a room and then selecting
        // a new building, the map will not fly to the new building
        setIsRoomSelected(false);
        history.push(newPath);
      }
    },
    [history],
  );

  useEffect((): void => {
    const roomNumber = match.params.roomNumber;
    setIsRoomSelected(!isNil(roomNumber));
  }, [match.params.roomNumber]);

  // triggers whenever url parameters change
  useEffect((): void => {
    const campusId = match.params.campusId;
    const buildingNumber = match.params.buildingNumber;
    const [startDate, endDate] = getQuarterlyDateRange(moment.tz('Australia/Melbourne').quarter());

    if (!isNil(buildingNumber) && !isNil(accessToken) && !isRoomSelected) {
      fetchBuildingDetails(campusId, buildingNumber, startDate, endDate, accessToken);
    }
    if (!isNil(buildingNumber) && !isNil(accessToken)) {
      const building = mapDataService.getBuilding(buildingNumber);
      if (building) {
        mapHighlightService.highlightBuildingByMazemapId(building.id, isRoomSelected);
      }
    }
  }, [
    match.params.campusId,
    match.params.buildingNumber,
    isRoomSelected,
    fetchBuildingDetails,
    mapHighlightService,
    mapDataService,
    accessToken,
  ]);

  // Register/unregister building click handler
  useEffect((): (() => void) => {
    const campusId = match.params.campusId;

    const callback = (building: Feature): void => {
      const buildingNumber = extractUomBuildingId(building.properties!.name);
      updateRouteIfBuildingChanged(campusId, buildingNumber);
    };

    mapHighlightService.addBuildingClickHandler(callback, isRoomSelected);
    return (): void => {
      mapHighlightService.removeBuildingClickHandler(callback, isRoomSelected);
    };
  }, [mapHighlightService, history, match.params.campusId, isRoomSelected, updateRouteIfBuildingChanged]);

  // handle search selection if a building in the search result is selected
  useEffect((): void => {
    const campusId = match.params.campusId;

    const searchResultType: string | undefined = path(['properties', 'type'], mapSearchResult);
    const mazemapCampusId: number | undefined = path([campusId, 'id'], MazeMapCampusDetails);
    const mazemapBuildingId: number | undefined = path(['properties', 'id'], mapSearchResult);

    if (mapSearchResult && mazemapCampusId && mazemapBuildingId && searchResultType === 'building') {
      const building = mapDataService.getBuildingByMazemapId(mazemapCampusId, mazemapBuildingId);
      if (building) {
        updateRouteIfBuildingChanged(campusId, building.uomBuildingId);
      }
    }
  }, [mapSearchResult, history, match.params.campusId, mapDataService, updateRouteIfBuildingChanged]);

  // fetch latest data every 2 minutes
  useEffect((): (() => void) => {
    const campusId = match.params.campusId;
    const buildingNumber = match.params.buildingNumber;
    let fetchInterval: NodeJS.Timeout;

    if (!isNil(buildingNumber) && !isNil(accessToken)) {
      const fetch = (): void => refreshBuildingDetails(campusId, buildingNumber, accessToken);
      fetchInterval = setInterval(fetch, FETCH_INTERVAL);
    }

    return (): void => {
      clearInterval(fetchInterval);
    };
  }, [match.params.campusId, match.params.buildingNumber, refreshBuildingDetails, accessToken]);

  return null;
};

export default BuildingControl;
