/* eslint-disable no-console */
const Logger = {
  log: (...args: any): void => {
    console.log(...args);
  },
  info: (...args: any): void => {
    console.info(...args);
  },
  warn: (...args: any): void => {
    console.warn(...args);
  },
  debug: (...args: any): void => {
    console.debug(...args);
  },
  error: (...args: any): void => {
    console.error(...args);
  },
};

export default Logger;
