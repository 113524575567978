import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import momentTZ from 'moment-timezone';
import { includes, isNil, map } from 'ramda';
import React from 'react';
import { mapService } from '../../services/Map/MapService';
import { DisplayTimeZone } from './DateTime';
interface BuildingkWHProps {
  energyConsumption: any;
}

export const BuildingkWHComponent = ({ energyConsumption }: BuildingkWHProps): React.ReactElement => {
  const createPieChartOptions = (energyConsumptionArr: any): Highcharts.Options => {
    const pieOptions = map((item) => {
      let locationCode = item.locationCode;
      if (includes('PC_ARTSWEST', locationCode)) {
        locationCode = 'PAR;148A';
      }
      const buildingDetails = mapService.getDataService().getBuilding(locationCode.split(';')[1]);
      return {
        name: !isNil(buildingDetails)
          ? buildingDetails?.uomBuildingId + ' ' + buildingDetails!.name.split('(')[0]
          : `Building ${locationCode.split(';')[1]}`,
        y: Math.floor(item.value / 1000),
      };
    }, energyConsumptionArr);

    const options: Highcharts.Options = {
      chart: {
        plotShadow: false,
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 50,
        },
      },
      title: {
        text: `Top 5 Energy Consumers(in kWH) - ${momentTZ(energyConsumptionArr[0].observationStartTime.split('T')[0])
          .tz(DisplayTimeZone)
          .format('DD MMM YYYY')}`,
        align: 'center',
        style: {
          fontSize: '18px',
        },
      },
      tooltip: {
        pointFormat: '{point.name}: <b>{point.y} kWH</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          depth: 45,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
          showInLegend: true,
        },
      },
      legend: {
        maxHeight: 100,
      },
      series: [
        {
          name: 'Energy usage - Top 5',
          type: 'pie',
          animation: false,
          data: pieOptions,
        },
      ],
    };
    return options;
  };

  return (
    <div>
      <HighchartsReact
        key="buildingKWHChart"
        highcharts={Highcharts}
        options={createPieChartOptions(energyConsumption)}
        immutable={true}
      />
    </div>
  );
};
