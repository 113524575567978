import { FilterActionTypes, TOGGLE_ALARM_FILTER, TOGGLE_UNKNOWN_FILTER, TOGGLE_NORMAL_FILTER } from './types';

const initialState: any = {
  filters: {
    Alarm: true,
    Unknown: true,
    Normal: true,
  },
};

interface Alarms {
  Alarm: boolean;
  Unknown: boolean;
  Normal: boolean;
}
interface StatusReducer {
  filters: Alarms;
}

export const statusReducer = (state = initialState, action: FilterActionTypes): StatusReducer => {
  switch (action.type) {
    case TOGGLE_ALARM_FILTER:
      state = { ...state, filters: { ...state.filters, Alarm: !state.filters.Alarm } };
      break;
    case TOGGLE_UNKNOWN_FILTER:
      state = {
        ...state,
        filters: { ...state.filters, Unknown: !state.filters.Unknown },
      };
      break;
    case TOGGLE_NORMAL_FILTER:
      state = {
        ...state,
        filters: { ...state.filters, Normal: !state.filters.Normal },
      };
      break;
  }
  return state;
};
