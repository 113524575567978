import { MapActionTypes, SET_MAP_SERVICE, MAP_SEARCH_RESULT } from './types';
import { MapServiceType } from 'services/Map/MapService';
import { Feature } from 'geojson';

const initialState: any = {
  mapService: undefined,
  searchResult: null,
};

interface MapReducer {
  mapService: MapServiceType;
  searchResult: Feature | null;
}

export const mapReducer = (state = initialState, action: MapActionTypes): MapReducer => {
  switch (action.type) {
    case SET_MAP_SERVICE:
      state = { ...state, mapService: action.payload };
      break;
    case MAP_SEARCH_RESULT:
      state = {
        ...state,
        searchResult: action.payload,
      };
      break;
  }
  return state;
};
