declare let window: any;
declare let Mazemap: any;

export const createMazemapMap = (mapOptions: any): any => new Mazemap.Map(mapOptions);

export const createMazemapHighlighter = (mazemap: any, options: any): any =>
  new window.Mazemap.Highlighter(mazemap, options);

export const createMazemapNavigationControl = (options: any): any => new Mazemap.mapboxgl.NavigationControl(options);

export const createMazemapSearchController = (options: any): any => new Mazemap.Search.SearchController(options);

export const createMazemapSearchInput = (options: any): any => new Mazemap.Search.SearchInput(options);
