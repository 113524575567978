import { propOr, split } from 'ramda';

const separator = ';';

const splitLocationCode = (locationCode: string): string[] => locationCode.split(separator);

export const getCampusId = (locationCode: string): string => {
  const locationParts = splitLocationCode(locationCode);
  return propOr('', '0', locationParts);
};

export const getBuildingId = (locationCode: string): string => {
  const locationParts = splitLocationCode(locationCode);
  return propOr('', '1', locationParts);
};

export const getFloorId = (locationCode: string): string => {
  const locationParts = splitLocationCode(locationCode);
  return propOr('', '2', locationParts);
};

export const getRoomId = (locationCode: string): string => {
  const locationParts = splitLocationCode(locationCode);
  return propOr('', '3', locationParts);
};

export const isBuildingLocationCode = (locationCode: string): boolean =>
  propOr(0, 'length', split(separator, locationCode)) === 2;

export const isFloorLocationCode = (locationCode: string): boolean =>
  propOr(0, 'length', split(separator, locationCode)) === 3;

export const isRoomLocationCode = (locationCode: string): boolean =>
  propOr(0, 'length', split(separator, locationCode)) === 4;
