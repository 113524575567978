export const APP_GUTTER = 5;
export const APP_HEADER_HEIGHT = 138;
export const RIGHT_DRAWER_WIDTH = 340;
export const RIGHT_DRAWER_PADDING = 10;

export const LEFT_DRAWER_WIDTH = 820;
export const LEFT_DRAWER_PADDING = 10;

export const FONT_SIZE_SMALL = 'small';
export const FONT_WEIGHT_BOLD = 600;
export const HEADING_FONT_FAMILY = ['"Noto Serif"', 'serif'].join(',');
export const DEFAULT_FONT_FAMILY = ['"Source Sans Pro"', 'sans-serif'].join(',');

export const BORDER_STYLE = '1px solid #dbdbdb';

export const MEDICINE_FACULTY_COLOR = '#ffa478';
export const METRIC_MARGIN = 8;

export const UOM_WHITE = '#fff';
export const UOM_LIGHT_BLUE = '#094183';
export const UOM_DARK_BLUE = '#0c2f4a';
export const UOM_SECONDARY_BLUE = '#5db1e9';
export const UOM_SECONDARY_DARK = '#0c2f4a';
export const UOM_LIGHT_GRAY = '#f7f7f7';
// New style guide colour scheme
// export const SPACEMAN_COLOR_DARK = '#3e444a'

// export const TRANSPARENT_UOM_GRAY = 'rgba(136, 142, 146, 0.38)'

export interface UomSupportingColour {
  dark: string;
  light: string;
}
export interface UomSupportingColours {
  [colour: string]: UomSupportingColour;
}

export const UOM_SUPPORTING_COLOURS: UomSupportingColours = {
  black: {
    dark: '#000',
    light: '#99A2AA',
  },
  teal: {
    dark: '#3FA7C2',
    light: '#7CBDCE',
  },
  emerald: {
    dark: '#59B17F',
    light: '#98D6C3',
  },
  blue: {
    dark: '#4074B2',
    light: '#ADCDF0',
  },
  yellow: {
    dark: '#ECAE65',
    light: '#FFDCA2',
  },
  orange: {
    dark: '#E77052',
    light: '#FFA478',
  },
  green: {
    dark: '#A1D172',
    light: '#DAE299',
  },
  purple: {
    dark: '#7283BF',
    light: '#B0BCE1',
  },
  pink: {
    dark: '#EE8889',
    light: '#FFBDCC',
  },
  grey: {
    dark: '#999999',
    light: '#E3E3E3',
  },
};

export const UOM_GRAY = UOM_SUPPORTING_COLOURS.black.light;

export const ACCCARD_HEATMAP_RATIO_TO_COLOUR = {
  TRANSPARENT: 'rgba(0,0,255,0)',
  COLOR_0_2: '#1FAFFC',
  COLOR_0_4: '#5BD76F',
  COLOR_0_6: '#FFE61E',
  COLOR_0_8: '#FA8072',
  COLOR_1_0: '#FF3333',
};

export const CMX_HEATMAP_RATIO_TO_COLOUR = {
  TRANSPARENT: 'rgba(0,0,255,0)',
  COLOR_0_2: '#ffffb2',
  COLOR_0_4: '#feb24c',
  COLOR_0_6: '#fd8d3c',
  COLOR_0_8: '#d9ffb2',
  COLOR_1_0: '#e31a1c',
};
