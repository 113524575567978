import React, { ReactElement, Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { logoutUserAction, updateAccessTokenAction } from 'store/auth/actions'
import { IconButton, Avatar, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { test, equals } from 'ramda';
import { UOM_SECONDARY_BLUE } from 'styles/styleConstants';
import { selectUserInitials } from 'store/selectors';
import { useOktaAuth } from '@okta/okta-react';
import Logger from 'utils/logger';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: UOM_SECONDARY_BLUE,
  },
});

const hasInitials = (initials: string): boolean => test(/^[a-zA-Z]{1,3}$/, initials);

export const UserAccountButton = (): ReactElement => {
  const classes = useStyles();
  const userInitialsStore = useSelector(selectUserInitials);
  const [userInitials, setUserInitials] = useState(userInitialsStore);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { oktaAuth } = useOktaAuth();

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  /* const logout = useCallback((): void => {
    dispatch(logoutUserAction(authState, authService))
  }, [dispatch]) */

  const logout = async (): Promise<void> => {
    Logger.info('UserAccountButton', window.location.origin);
    oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/' });
  };

  const handleLogout = (): void => {
    handleClose();
    logout();
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  useEffect((): void => {
    if (!equals(userInitialsStore, userInitials)) setUserInitials(userInitialsStore);
  }, [userInitials, userInitialsStore]);

  return (
    <Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="medium">
        {hasInitials(userInitials) ? (
          <Avatar data-testid="APP-BAR-AVATAR" className={classes.avatar}>
            {userInitials}
          </Avatar>
        ) : (
          <AccountCircle data-testid="APP-BAR-ACCOUNT-CIRCLE-ICON" fontSize="large" />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={handleLogout}>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
