import React, { ReactElement, useState, Fragment } from 'react';
import { Typography, makeStyles, Collapse, Divider } from '@material-ui/core';
import { UOM_LIGHT_BLUE, HEADING_FONT_FAMILY, APP_GUTTER } from 'styles/styleConstants';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#cccccc',
    padding: '10px 0 10px 0',
  },
  header: {
    display: 'flex',
    padding: `${APP_GUTTER}px 0`,
    backgroundColor: UOM_LIGHT_BLUE,
    justifyContent: 'center',
    textAlign: 'center',
  },
  headerText: {
    display: 'flex',
    alignSelf: 'center',
    fontFamily: HEADING_FONT_FAMILY,
    height: 'auto',
  },
});

interface SectionHeaderProps {
  title: string;
  children: ReactElement | ReactElement[];
}

const Section = (props: SectionHeaderProps): ReactElement => {
  const [showSection, setShowSection] = useState(true);
  const handleDetailsClick = (): void => {
    setShowSection(!showSection);
  };
  const classes = useStyles(props);
  return (
    <Fragment>
      <div role="button" className={classes.header} onClick={handleDetailsClick}>
        <Typography className={classes.headerText} variant="subtitle1" color="secondary">{`${props.title}`}</Typography>
      </div>
      <Collapse in={showSection}>{props.children}</Collapse>
      {showSection ? null : <Divider />}
    </Fragment>
  );
};

export default Section;
