export const AUTHORITY_URI = 'https://login.microsoftonline.com/0e5bf3cf-1ff4-46b7-9176-52c538c22a4d';
// default AAD_CLIENT_ID to test instance if not defined
export const AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID || '2768a94e-fa03-4286-8891-e4090143b379';
export const OAUTH_REDIRECT_URI = process.env.REACT_APP_OAUTH_REDIRECT_URI;
export const LOGOUT_REDIRECT_URI = process.env.REACT_APP_LOGOUT_REDIRECT_URI || 'https://myapps.microsoftonline.com';

// API_SCOPE_PREFIX is the 'Application ID URI' in AAD for the API's registered app.
// Default to test instance if not defined.
export const API_SCOPE_PREFIX = process.env.REACT_APP_API_SCOPE_PREFIX || 'https://campusnow-test';
export const OAUTH_SCOPES = [`${API_SCOPE_PREFIX}/Api.Read`];
export const HAS_SSO = process.env.REACT_APP_HAS_SSO === 'false' ? false : true;

export const TOKEN_RENEWAL_OFFSET_SECONDS = process.env.REACT_APP_TOKEN_RENEWAL_OFFSET_SECONDS || '600';
