import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Observation } from 'models/Observation';
import { isEmpty, keys, join, forEach } from 'ramda';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/styles';
import { SVGIcon, IconSizes, IconTypes } from '../common/Icons';
import { UOM_LIGHT_BLUE, FONT_SIZE_SMALL, APP_GUTTER } from 'styles/styleConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomHistoricalObservationsAction } from 'store/details/actions';
import { getCampusId, getBuildingId, getFloorId, getRoomId, isRoomLocationCode } from '../common/LocationCodeUtils';
import ObservationHistory from './ObservationHistory';
import { DisplayTimeZone } from '../common/DateTime';
import { Collapse } from '@material-ui/core';
import Chevron from '../common/Chevron';
import { FETCH_INTERVAL } from 'components/constants';
import { AppState } from 'store';
import { selectAuthAccessToken } from 'store/selectors';

type ObservationGroupClasses = Record<
  | 'observationGroupItem'
  | 'observationGroupMetadata'
  | 'observationGroupRow'
  | 'observationGroupTitle'
  | 'observationGroupTypeRow'
  | 'observationGroupObservationTime'
  | 'observationGroupZoneName'
  | 'observationGroupChartCollapser',
  string
>;

const useObservationDetailStyles = makeStyles({
  observationGroupItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${APP_GUTTER}px`,
    '& img': {
      padding: '4px 16px',
    },
  },
  observationGroupMetadata: {
    flexGrow: 1,
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: 0,
    listStyle: 'none',
    maxWidth: '100%',
  },
  observationIcon: {
    paddingTop: '16px',
  },
  observationTime: {
    fontSize: FONT_SIZE_SMALL,
  },
  observationGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      padding: '4px 6px',
    },
    position: 'relative',
  },
  observationGroupTitle: {
    fontWeight: 'bold',
  },
  observationGroupTypeRow: {
    verticalAlign: 'top',
  },
  observationGroupObservationTime: {
    wordBreak: 'break-word',
  },
  observationGroupZoneName: {
    wordBreak: 'break-word',
    maxWidth: '200px',
    minWidth: '200px',
  },
  observationGroupChartCollapser: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export interface ObservationGroupHeaderProps {
  classes: ObservationGroupClasses;
}

export type ObservationGroupHeader = (props: ObservationGroupHeaderProps) => ReactElement;

export interface ObservationGroupRowProps {
  zoneName: string | number;
  index: number;
  zoneObservations: Observation[];
  classes: ObservationGroupClasses;
}

export type ObservationGroupRow = (props: ObservationGroupRowProps) => ReactElement;

export interface ObservationGroupProps {
  title: string;
  unit: string;
  icon: IconTypes;
  locationCode: string;
  zoneObservation: { [index: string]: Observation[] };
  historySelector: (observationHistoryIndex: string) => (state: AppState) => { [index: string]: Observation[] };
  seriesNameMapping?: { [seriesName: string]: string };
  observationClass: string;
  observationTypes: string[];
}

export const ObservationGroupDetails = (
  Header: ObservationGroupHeader,
  Row: ObservationGroupRow,
  HistoryChart = ObservationHistory,
): (({ locationCode, zoneObservation }: ObservationGroupProps) => ReactElement) => {
  const WithObservationGroupDetails = ({
    title,
    icon,
    unit,
    locationCode,
    zoneObservation,
    historySelector,
    seriesNameMapping,
    observationClass,
    observationTypes,
  }: ObservationGroupProps): ReactElement => {
    const classes = useObservationDetailStyles();
    const groupHistoryIndex = `${observationClass}-${join('-', observationTypes)}`;

    const dispatch = useDispatch();
    /* const { authState } = useOktaAuth()
    const accessToken = prop('accessToken', authState) */
    const accessToken = useSelector(selectAuthAccessToken);

    const currentDate = moment.tz(DisplayTimeZone).format('YYYY-MM-DD');
    const fetchHistory = useCallback(
      (
        groupHistoryIndex: string,
        observationTypes: string[],
        locationCode: string,
        observationClass: string,
        accessToken: string,
      ): void => {
        const campusId = getCampusId(locationCode);
        const buildingId = getBuildingId(locationCode);
        const floorId = getFloorId(locationCode);
        const roomId = getRoomId(locationCode);
        if (!isEmpty(locationCode) && isRoomLocationCode(locationCode)) {
          forEach((observationType: string): void =>
            dispatch(
              getRoomHistoricalObservationsAction(
                groupHistoryIndex,
                campusId,
                buildingId,
                floorId,
                roomId,
                currentDate,
                currentDate,
                observationClass,
                observationType,
                undefined,
                accessToken,
              ),
            ),
          )(observationTypes);
        }
      },
      [dispatch, currentDate],
    );

    const observationTypesStr = JSON.stringify(observationTypes);
    useEffect((): void => {
      const observationTypesArray = JSON.parse(observationTypesStr);
      fetchHistory(groupHistoryIndex, observationTypesArray, locationCode, observationClass, accessToken);
    }, [fetchHistory, groupHistoryIndex, observationTypesStr, locationCode, observationClass, accessToken]);

    useEffect((): (() => void) => {
      const observationTypesArray = JSON.parse(observationTypesStr);
      const fetch = (): void =>
        fetchHistory(groupHistoryIndex, observationTypesArray, locationCode, observationClass, accessToken);

      const fetchInterval: NodeJS.Timeout = setInterval(fetch, FETCH_INTERVAL);

      return (): void => {
        clearInterval(fetchInterval);
      };
    }, [
      fetchHistory,
      groupHistoryIndex,
      locationCode,
      currentDate,
      observationClass,
      observationTypesStr,
      accessToken,
    ]);

    const observationHistory: { [index: string]: Observation[] } = useSelector(historySelector(groupHistoryIndex));

    const [showChart, setShowChart] = useState(false);

    const handleShowChartClick = (): void => {
      setShowChart(!showChart);
    };

    return (
      <div className={classes.observationGroupItem}>
        <div className={classes.observationGroupMetadata}>
          <div className={classes.observationGroupRow}>
            <SVGIcon size={IconSizes.small} type={icon} alttext={icon} />
            <Typography className={classes.observationGroupTitle}>
              {title} ({unit})
            </Typography>
            <div role="button" className={classes.observationGroupChartCollapser} onClick={handleShowChartClick}>
              <SVGIcon size={IconSizes.small} type={'line-chart'} alttext={'line-chart'} />
              <Chevron styles={{ color: UOM_LIGHT_BLUE }} direction={showChart ? 'up' : 'down'} />
            </div>
          </div>
          <Table>
            <TableHead>
              <Header classes={classes} />
            </TableHead>
            <TableBody>
              {keys(zoneObservation).map(
                (zoneName: string | number, index: number): ReactElement => (
                  <Row
                    key={`observation-group-${groupHistoryIndex}-${index}`}
                    zoneName={zoneName}
                    index={index}
                    zoneObservations={zoneObservation[zoneName]}
                    classes={classes}
                  />
                ),
              )}
            </TableBody>
          </Table>
        </div>
        <Collapse in={showChart}>
          <div>
            <HistoryChart
              title="Today's Trend"
              unitOfMeasure={unit}
              observations={observationHistory}
              seriesNameMapping={seriesNameMapping}
            />
          </div>
        </Collapse>
      </div>
    );
  };
  return WithObservationGroupDetails;
};
