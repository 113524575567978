import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { XAxisPlotBandsOptions } from 'highcharts';
import { Observation } from 'models/Observation';
import moment, { Moment } from 'moment-timezone';
import {
  range,
  propOr,
  last,
  sort,
  forEach,
  isEmpty,
  map,
  path,
  isNil,
  pathOr,
  any,
  head,
  compose,
  groupWith,
  filter,
  addIndex,
  aperture,
  append,
  contains,
  groupBy,
  split,
  reduce,
  has,
  keysIn,
} from 'ramda';
import { DisplayTimeZone } from 'components/common/DateTime';

interface PlotOptionsSeriesGroupPadding extends Highcharts.PlotSeriesOptions {
  groupPadding: number;
  minPointLength: number;
}

interface ClassDetails {
  code: string | undefined;
  totalRegistered: number | undefined;
  plannedSize: number | undefined;
}

interface TotalObservationCount {
  count: number | null;
  totalObservations: number;
}

interface GroupedMaxOccupancy {
  [sensorName: string]: TotalObservationCount;
}

interface CumulativeObservation {
  ingressEgressSensorName: string | null;
  ingressions: TotalObservationCount;
  egressions: TotalObservationCount;
  maxOccupancy: GroupedMaxOccupancy;
  totalObservations: number;
  // class details are at the beginning of the first observation in the cumulative time period
  classDetails: ClassDetails | null;
}

interface GraphData {
  ingressEgressSensorName: string;
  ingressions: IngressEgress[];
  egressions: IngressEgress[];
  occupancy: GroupedOccupancy;
  plannedClassSize: PlannedClassSize[];
}

interface IngressEgress {
  x: number;
  y: number;
  totalObservations: number;
}

interface Occupancy {
  x: number;
  y: number | null;
  totalObservations: number;
}

interface PlannedClassSize {
  x: number;
  y: number | null;
  className: string | undefined;
  registered: number | undefined;
}

interface ClassStartEndRanges {
  className: string | undefined;
  start: number;
  end: number;
}

const classPlotBandColours = ['#e6ebf5', '#f5ece6'];

interface GroupedObservation {
  [sensorName: string]: Observation[];
}

interface GroupedOccupancy {
  [sensorName: string]: Occupancy[];
}

const getStartOfDayMidnight = (observation: Observation): Moment =>
  moment.tz(observation.observationEndTime, DisplayTimeZone).startOf('day');

const cumulativeObservationsArrayIndexByDiffMidnight = (
  observationEndTime: string,
  midnight: Moment,
  interval: number,
): number => {
  const diffMinutes = moment.tz(observationEndTime, DisplayTimeZone).diff(midnight, 'minutes');
  return Math.floor(diffMinutes / interval);
};

const createXAxisTimes = (midnight: Moment, intervalMins: number, totalElements: number): string[] =>
  map((i): string => midnight.add(i === 0 ? 0 : intervalMins, 'minutes').format('HH:mm'), range(0, totalElements + 1));

const sortComparator = (a: Observation, b: Observation): number =>
  a.observationEndTime === b.observationEndTime ? 0 : a.observationEndTime > b.observationEndTime ? 1 : -1;

const updateClassDetails = (cumulativeObservation: CumulativeObservation, observation: Observation): void => {
  const code: string | undefined = path(['extension', 'activityName'], observation);
  const totalRegistered: number | string | undefined = path(['extension', 'totalRegistered'], observation);
  const plannedSize: number | string | undefined = path(['extension', 'classPlannedSize'], observation);

  if (
    !(
      (isNil(code) && isNil(totalRegistered) && isNil(plannedSize)) ||
      (isEmpty(code) && isEmpty(totalRegistered) && isEmpty(plannedSize))
    )
  ) {
    cumulativeObservation.classDetails = {
      code,
      totalRegistered: isNil(totalRegistered) ? undefined : Number(totalRegistered),
      plannedSize: isNil(plannedSize) ? undefined : Number(plannedSize),
    };
  }
};

const createPlannedClassSizeElement = (
  cumulativeObservation: CumulativeObservation,
  index: number,
): PlannedClassSize => ({
  x: index,
  y: pathOr(null, ['classDetails', 'plannedSize'], cumulativeObservation),
  className: path(['classDetails', 'code'], cumulativeObservation),
  registered: path(['classDetails', 'totalRegistered'], cumulativeObservation),
});

const calculateClassStartEndRanges = (plannedClassSizes: PlannedClassSize[]): ClassStartEndRanges[] => {
  const filterNullClasses = filter<PlannedClassSize>(
    (plannedClassSize: PlannedClassSize) => plannedClassSize.y !== null,
  );

  const groupByClassPredicate = (a: PlannedClassSize, b: PlannedClassSize): boolean =>
    `${a.x + 1}-${a.className}` === `${b.x}-${b.className}`;

  const calcClassDetails = (classTimeRanges: PlannedClassSize[]): ClassStartEndRanges => ({
    className: head(classTimeRanges)!.className,
    start: head(classTimeRanges)!.x,
    end: last(classTimeRanges)!.x,
  });

  const extendClassEndToNextPeriodIfClassImmediatelyAfter = (
    plannedClasses: ClassStartEndRanges[],
  ): ClassStartEndRanges[] => {
    const plannedClassesWithExtendedEndtimes = compose<
      ClassStartEndRanges[],
      ClassStartEndRanges[],
      ClassStartEndRanges[][],
      ClassStartEndRanges[]
    >(
      map((classTuple: ClassStartEndRanges[]) => ({
        ...classTuple[0],
        end: classTuple[0].end + 1 === classTuple[1].start ? classTuple[1].start : classTuple[0].end,
      })),
      aperture(2),
      (classRanges: ClassStartEndRanges[]) =>
        classRanges.length > 0 ? append(last(classRanges)!, classRanges) : classRanges,
    )(plannedClasses);

    return plannedClassesWithExtendedEndtimes;
  };

  const classBands = compose<
    PlannedClassSize[],
    PlannedClassSize[],
    PlannedClassSize[][],
    ClassStartEndRanges[],
    ClassStartEndRanges[]
  >(
    extendClassEndToNextPeriodIfClassImmediatelyAfter,
    map(calcClassDetails),
    groupWith(groupByClassPredicate),
    filterNullClasses,
  )(plannedClassSizes);

  return classBands;
};

const generateClassPlotBands = (classBands: ClassStartEndRanges[]): XAxisPlotBandsOptions[] => {
  const mapIndexed = addIndex<ClassStartEndRanges, XAxisPlotBandsOptions>(map);
  return mapIndexed(
    (classBand: ClassStartEndRanges, index: number): XAxisPlotBandsOptions => ({
      from: classBand.start,
      to: classBand.end,
      color: classPlotBandColours[index % classPlotBandColours.length],
    }),
    classBands,
  );
};

const groupHeadCountsBySensorName = (observations: Observation[]): GroupedObservation =>
  groupBy((observation: Observation): string => split('#', observation.observationTag)[1], observations);

const getSensorNamesFromObservations = (observations: Observation[]): string[] =>
  keysIn(groupHeadCountsBySensorName(observations));

const sortObservationsChronologically = (headCountHistoryObservations: Observation[]): Observation[] =>
  sort(sortComparator, headCountHistoryObservations);

const getUltimateChronologicalObservation = (headCountHistoryObservations: Observation[]): Observation =>
  last(headCountHistoryObservations)!;

const createGraphData = (sensorNames: string[]): GraphData => ({
  ingressEgressSensorName: '',
  ingressions: [],
  egressions: [],
  occupancy: reduce(
    (acc: GroupedOccupancy, cur: string): GroupedOccupancy => {
      if (!has(cur, acc)) acc[cur] = [];
      return acc;
    },
    {},
    sensorNames,
  ),
  plannedClassSize: [],
});

const getSensorNameFromObservationTag = (observationTag: string): string => split('#', observationTag)[1];

const initialiseMaxOccupancy = (sensorNames: string[]): GroupedMaxOccupancy =>
  reduce(
    (acc: any, cur: string): any => {
      acc[cur] = { totalObservations: 0, count: null };
      return acc;
    },
    {},
    sensorNames,
  );

const initialiseCumulativeObservations = (sensorNames: string[], numberOfElements: number): CumulativeObservation[] =>
  map((): CumulativeObservation => {
    return {
      ingressEgressSensorName: null,
      ingressions: { totalObservations: 0, count: 0 },
      egressions: { totalObservations: 0, count: 0 },
      maxOccupancy: initialiseMaxOccupancy(sensorNames),
      totalObservations: 0,
      classDetails: null,
    };
  }, range(0, numberOfElements + 1));

const updateCumulativeObservation = (cumulativeObservation: CumulativeObservation, observation: Observation): void => {
  const sensorName = getSensorNameFromObservationTag(observation.observationTag);

  if (contains('CountIn', observation.observationTag)) {
    cumulativeObservation.ingressEgressSensorName = sensorName;
    if (!isNil(cumulativeObservation.ingressions.count))
      cumulativeObservation.ingressions.count += Number(observation.value);
    cumulativeObservation.ingressions.totalObservations++;
  }

  if (contains('CountOut', observation.observationTag)) {
    cumulativeObservation.ingressEgressSensorName = sensorName;
    if (!isNil(cumulativeObservation.egressions.count))
      cumulativeObservation.egressions.count += Number(observation.value);
    cumulativeObservation.egressions.totalObservations++;
  }

  if (contains('HeadCount', observation.observationTag)) {
    // sometimes there are no observations for the period of a cumulative observation and we don't want the spline to plot a node and vertice
    cumulativeObservation.maxOccupancy[sensorName].count = isNil(cumulativeObservation.maxOccupancy[sensorName].count)
      ? Number(observation.value)
      : cumulativeObservation.maxOccupancy[sensorName].count; // Fixed this as part of SCIOT-912
    cumulativeObservation.maxOccupancy[sensorName].totalObservations++;
    cumulativeObservation.totalObservations++;
  }

  if (cumulativeObservation.totalObservations <= 2) {
    // populate class details from the first two observations. This assumes that observations are
    // processed in chronological order and if back to back classes are scheduled then the
    // class details in the second observation will overwrite the first observation.
    updateClassDetails(cumulativeObservation, observation);
  }
};

const populateCumulativeObservations = (
  sensorNames: string[],
  historyObservations: Observation[],
  totalCumulativeObservations: number,
  minuteInterval: number,
  midnight: Moment,
): CumulativeObservation[] => {
  // initialise cumulative observations array
  const cumulativeObservations = initialiseCumulativeObservations(sensorNames, totalCumulativeObservations);
  // update cumulative observations array
  forEach((historyObservation: Observation): void => {
    const cumulativeObservation: CumulativeObservation =
      cumulativeObservations[
        cumulativeObservationsArrayIndexByDiffMidnight(historyObservation.observationEndTime, midnight, minuteInterval)
      ];

    updateCumulativeObservation(cumulativeObservation, historyObservation);
  }, historyObservations);
  return cumulativeObservations;
};

const setup = (sensorNames: string[], historyObservations: Observation[], minuteInterval: number): any => {
  let xAxisTimes: string[] = [];
  let cumulativeObservations: CumulativeObservation[] = [];

  if (historyObservations.length > 0) {
    const lastObservation: Observation = getUltimateChronologicalObservation(historyObservations);
    const midnight: Moment = getStartOfDayMidnight(lastObservation);

    const totalCumulativeObservations = cumulativeObservationsArrayIndexByDiffMidnight(
      lastObservation.observationEndTime,
      midnight,
      minuteInterval,
    );

    cumulativeObservations = populateCumulativeObservations(
      sensorNames,
      historyObservations,
      totalCumulativeObservations,
      minuteInterval,
      midnight,
    );

    xAxisTimes = createXAxisTimes(midnight, minuteInterval, totalCumulativeObservations);
  }

  return {
    cumulativeObservations,
    xAxisTimes,
  };
};

const populateGraphData = (sensorNames: string[], cumulativeObservations: CumulativeObservation[]): GraphData => {
  const graphData: GraphData = createGraphData(sensorNames);
  // populate the graph data object
  cumulativeObservations.forEach((cumulativeObservation, index): void => {
    if (!isNil(cumulativeObservation.ingressEgressSensorName))
      graphData.ingressEgressSensorName = cumulativeObservation.ingressEgressSensorName!;

    graphData.ingressions.push({
      x: index,
      y: cumulativeObservation.ingressions.count!,
      totalObservations: cumulativeObservation.ingressions.totalObservations,
    });
    graphData.egressions.push({
      x: index,
      y: cumulativeObservation.egressions.count!,
      totalObservations: cumulativeObservation.egressions.totalObservations,
    });
    if (cumulativeObservation.totalObservations !== 0) {
      map((sensorName: string): void => {
        graphData.occupancy[sensorName].push({
          x: index,
          y: isNil(cumulativeObservation.maxOccupancy[sensorName].count)
            ? 0
            : cumulativeObservation.maxOccupancy[sensorName].count,
          totalObservations: cumulativeObservation.maxOccupancy[sensorName].totalObservations,
        });
      }, sensorNames);
    }

    graphData.plannedClassSize.push(createPlannedClassSizeElement(cumulativeObservation, index));
  });
  return graphData;
};

interface SpaceUtilisationHistoryProps {
  headCountHistoryObservations: Observation[];
}

const SpaceUtilisationHistory = ({
  headCountHistoryObservations,
}: SpaceUtilisationHistoryProps): ReactElement | null => {
  const plotMinuteInterval = 5;
  const plotOptionsSeriesGroupPadding: PlotOptionsSeriesGroupPadding = {
    groupPadding: 0,
    minPointLength: 3,
  };

  const [defaultOccupancyGraphOptions] = useState<Highcharts.Options>({
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: 'Daily Occupancy',
      align: 'center',
    },
    xAxis: [],
    yAxis: [
      {
        gridLineWidth: 1,
        allowDecimals: false,
        title: {
          text: 'Occupancy',
        },
      },
      {
        gridLineWidth: 1,
        allowDecimals: false,
        title: {
          text: 'Ingress / Egress',
        },
        opposite: true,
      },
    ],
    plotOptions: {
      series: plotOptionsSeriesGroupPadding,
    },
    annotations: [],
    tooltip: {
      shared: true,
    },
    series: [],
  });

  const [occupancyGraphOptions, setOccupancyGraphOptions] = useState<Highcharts.Options>(defaultOccupancyGraphOptions);

  const getOccupancyGraphOptions = useCallback(
    (sensorNames: string[], graphData: GraphData, xAxisTimes): Highcharts.Options => {
      const plotBands = compose(generateClassPlotBands, calculateClassStartEndRanges)(graphData.plannedClassSize);

      const options: Highcharts.Options = {
        ...defaultOccupancyGraphOptions,
        xAxis: [
          {
            categories: xAxisTimes,
            crosshair: true,
            labels: {
              rotation: 90,
            },
            visible: true,
            plotBands,
          },
        ],
        series: [
          {
            name: `Ingress#${graphData.ingressEgressSensorName}`,
            type: 'column',
            yAxis: 1,
            data: graphData.ingressions,
            visible: false,
          },
          {
            name: `Egress#${graphData.ingressEgressSensorName}`,
            type: 'column',
            yAxis: 1,
            data: graphData.egressions,
            visible: false,
          },
        ],
      };

      map(
        (sensorName: string): number =>
          options.series!.push({
            name: `Max Occupancy#${sensorName}`,
            type: 'spline',
            yAxis: 0,
            data: graphData.occupancy[sensorName],
          }),
        sensorNames,
      );

      const isPlannedClassInObservations = any(
        (plannedClassElement: PlannedClassSize) => plannedClassElement.y !== null,
      )(graphData.plannedClassSize);

      if (isPlannedClassInObservations) {
        options.series!.push({
          name: 'Planned Class Size',
          type: 'spline',
          yAxis: 0,
          data: graphData.plannedClassSize,
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/><span style="color:{point.color}">●</span> Class Code: <b>{point.className}</b><br/>',
          },
          connectNulls: false,
        });
        options.series!.push({
          name: 'Registered Class Size',
          type: 'spline',
          yAxis: 0,
          data: map(
            (plannedClassSize: PlannedClassSize): any => ({
              x: plannedClassSize.x,
              y: propOr(null, 'registered', plannedClassSize),
            }),
            graphData.plannedClassSize,
          ),
          connectNulls: false,
          visible: false,
        });
      }

      return options;
    },
    [defaultOccupancyGraphOptions],
  );

  useEffect((): void => {
    if (!isEmpty(headCountHistoryObservations)) {
      const sensorNames = getSensorNamesFromObservations(headCountHistoryObservations);
      const sortedHeadCountHistoryObservations = sortObservationsChronologically(headCountHistoryObservations);
      const { cumulativeObservations, xAxisTimes } = setup(
        sensorNames,
        sortedHeadCountHistoryObservations,
        plotMinuteInterval,
      );
      const graphData = populateGraphData(sensorNames, cumulativeObservations);

      const options = getOccupancyGraphOptions(sensorNames, graphData, xAxisTimes);

      setOccupancyGraphOptions(options);
    }
  }, [getOccupancyGraphOptions, headCountHistoryObservations]);

  // Use Highcharts option immutable=true to workaround a bug when changing from a room without
  // a scheduled class to a room with a scheduled class. See SCIOT-657.
  // Also tried setting the id option on configuration objects that take arrays but the issue still occurs.

  if (headCountHistoryObservations.length === 0) {
    return null;
  }
  return <HighchartsReact highcharts={Highcharts} options={occupancyGraphOptions} immutable={true} />;
};

export default SpaceUtilisationHistory;
