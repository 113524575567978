import { split, concat, reduce, toUpper, splitAt, trim, isEmpty, isNil, filter, join, map, equals } from 'ramda';
import { DEFAULT_USER_NAME } from '../components/constants';
import { Unsubscribe, Store } from 'redux';
import { selectAuthUserName, selectUserName, selectUserGroups } from 'store/selectors';
import { setUser } from 'store/user/actions';

const removeWhiteSpace = (str: string): string[] => filter((element) => !isEmpty(trim(element)), split(' ', str));

export const getCleanedUserName = (userName: string | null): string => {
  userName = isNil(userName) ? DEFAULT_USER_NAME : isEmpty(trim(userName)) ? DEFAULT_USER_NAME : trim(userName);

  return join(
    ' ',
    map((name: string): string => {
      return concat(toUpper(name.charAt(0)), splitAt(1, name)[1]);
    }, removeWhiteSpace(userName)),
  );
};

export const getUserInitialsFromName = (userName: string | null, maxNumberOfInitials: number = 3): string => {
  if (isNil(userName)) userName = DEFAULT_USER_NAME;
  if (isEmpty(trim(userName))) userName = DEFAULT_USER_NAME;
  return splitAt(
    maxNumberOfInitials,
    toUpper(reduce((acc: string, cur: string): string => concat(acc, trim(cur).charAt(0)), '', split(' ', userName))),
  )[0];
};

export const subscribeToStateChange = (store: Store): Unsubscribe =>
  store.subscribe((): void => {
    const state = store.getState();
    const authUserName = selectAuthUserName(state);
    const userName = selectUserName(state);
    const groups = selectUserGroups(state);

    if (!equals(authUserName, userName) && isEmpty(userName) && !isNil(authUserName) && !isEmpty(authUserName)) {
      const cleanedName = getCleanedUserName(authUserName);
      const userInitials = getUserInitialsFromName(cleanedName);
      store.dispatch(
        setUser({
          name: cleanedName,
          initials: userInitials,
          groups,
        }),
      );
      store.dispatch(
        setUser({
          name: cleanedName,
          initials: userInitials,
          groups,
        }),
      );
    }
  });
