const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '0oa8n695moC2nAoLL3l6';
const ISSUER = process.env.REACT_APP_OKTA_DOMAIN || 'https://sso-preprod.unimelb.edu.au/oauth2/default';
const ENABLE_PKCE = Boolean(process.env.REACT_APP_ENABLE_PKCE) || true;
const OKTA_TESTING_DISABLEHTTPSCHECK = Boolean(process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK) || false;

export const authConfig = {
  issuer: `${ISSUER}`,
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: `${CLIENT_ID}`,
  scopes: ['openid', 'profile', 'email', 'CampusNowGroups', 'offline_access'],
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  pkce: ENABLE_PKCE,
  tokenManager: {
    autoRenew: true,
    secure: true,
    storage: 'localStorage',
  },
};
