import { pathOr, equals } from 'ramda';
import { Summary } from 'models/Summary';
import { SpaceUtilisationHeatMapModel } from 'models/Observation';
import { DisplayTimeZone } from 'components/common/DateTime';
import moment from 'moment';
import 'moment-timezone';
import {
  CMX_HEATMAP_RECENT_OBSERVATION_THRESHOLD_IN_MINS,
  ACCCARD_HEATMAP_RECENT_OBSERVATION_THRESHOLD_IN_MINS,
  SEVERITY_LOW,
  SEVERITY_MEDIUM,
  SEVERITY_SEVERE,
} from '../components/constants';
import { SpaceUtilisationHeatMapTypes, SpaceUtilisationObservationTags } from 'types';

/**
 * @param  {Summary} summary
 * @returns boolean
 */
export const abnormalAlarmSummaryFilter = (summary: Summary): boolean => {
  const abnormalAlarmCount = pathOr(0, ['alarms', 'abnormal', 'length'], summary);
  // const currentTime = moment.tz(moment(), DisplayTimeZone)
  // const obsTime = moment.tz(endTime, DisplayTimeZone)

  const bool = abnormalAlarmCount > 0; /*&&
    currentTime.diff(obsTime, 'minutes') <= BMS_ALARMS_OBSERVATION_THRESHOLD_IN_MINS
 */
  return bool;
};

/**
 * @param  {Summary} summary
 * @returns boolean
 */
export const severeAlarmSummaryFilter = (summary: Summary): boolean =>
  'severityAggregate' in summary.alarms && summary.alarms.severityAggregate === SEVERITY_SEVERE ? true : false;

/**
 * @param  {Summary} summary
 * @returns boolean
 */
export const mediumAlarmSummaryFilter = (summary: Summary): boolean =>
  'severityAggregate' in summary.alarms && summary.alarms.severityAggregate === SEVERITY_MEDIUM ? true : false;

/**
 * @param  {Summary} summary
 * @returns boolean
 */
export const lowAlarmSummaryFilter = (summary: Summary): boolean =>
  'severityAggregate' in summary.alarms && summary.alarms.severityAggregate === SEVERITY_LOW ? true : false;

const thresholdInMins = (heatMapType: string): number => {
  // Defaulted to CMX Threshold value
  let retVal: number = CMX_HEATMAP_RECENT_OBSERVATION_THRESHOLD_IN_MINS;
  if (
    equals(SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE1, heatMapType) ||
    equals(SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE2, heatMapType)
  ) {
    retVal = ACCCARD_HEATMAP_RECENT_OBSERVATION_THRESHOLD_IN_MINS;
  }
  return retVal;
};

/**
 * Method to filter the required building headcount observations for
 * populating the heatmap source.
 *
 * @param  {SpaceUtilisationHeatMapModel[]} buildingHeadCounts
 * @param  {string} heatMapType
 * @returns SpaceUtilisationHeatMapType
 */
export const filterBuildingHeadCountsForHeatMap = (
  buildingHeadCounts: SpaceUtilisationHeatMapModel[],
  heatMapType: string,
): SpaceUtilisationHeatMapModel[] => {
  let filteredBuildingHeadCounts: SpaceUtilisationHeatMapModel[] = [];

  // Filter only head count observations by relative time - only with in the last 15 mins.
  const mostRecentBuildingHeadCounts: SpaceUtilisationHeatMapModel[] = buildingHeadCounts.filter(
    (observation: SpaceUtilisationHeatMapModel): boolean => {
      const currentTime = moment.tz(moment(), DisplayTimeZone);
      const obsTime = moment.tz(observation.observationEndTime, DisplayTimeZone);
      return currentTime.diff(obsTime, 'minutes') <= thresholdInMins(heatMapType);
    },
  );
  if (
    equals(SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE1, heatMapType) ||
    equals(SpaceUtilisationHeatMapTypes.CMX_HEATMAP_TYPE2, heatMapType)
  ) {
    filteredBuildingHeadCounts = mostRecentBuildingHeadCounts.filter(
      (observation: SpaceUtilisationHeatMapModel): boolean =>
        observation.observationTag === SpaceUtilisationObservationTags.CMX_HEADCOUNT,
    );
  } else if (
    equals(SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE1, heatMapType) ||
    equals(SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE2, heatMapType)
  ) {
    filteredBuildingHeadCounts = mostRecentBuildingHeadCounts.filter(
      (observation: SpaceUtilisationHeatMapModel): boolean =>
        observation.observationTag === SpaceUtilisationObservationTags.ACCESS_CARD_HEADCOUNT,
    );
  }
  return filteredBuildingHeadCounts;
};
