import React, { ReactElement, Fragment, useState, useEffect } from 'react';
import { Observation, ObservationType, ObservationTag } from 'models/Observation';
import DetailCard from '../common/DetailCard';
import { isEmpty, equals, pathOr } from 'ramda';
import { makeStyles } from '@material-ui/styles';
import { getIconTypeFromObservationType } from '../common/Icons';
import { convertUnit, UnitOfMeasure } from '../common/UnitOfMeasure';
import {
  isGroupedSpaceComfortObservation,
  groupTemperatureObservations,
  groupHumidityObservations,
  groupCo2Observations,
} from './SpaceComfortPredicates';
import { TemperatureGroupDetails, HumidityGroupDetails, Co2GroupDetails } from './SpaceComfortObservations';
import {
  selectRoomObservationsHistoryWithBomTemperature,
  selectRoomObservationsHistory,
  selectRoomObservationsHistoryWithBomHumidity,
  selectIsVisibleSpaceComfort,
} from 'store/selectors';
import { useSelector } from 'react-redux';

const useObservationDetailStyles = makeStyles({
  observationMetadata: {
    flexGrow: 1,
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: 0,
    listStyle: 'none',
    maxWidth: '100%',
  },
});

const bomTemperatureSeriesNameMapping: { [index: string]: string } = {};
bomTemperatureSeriesNameMapping[ObservationTag.apparentTemperature] = 'External Apparent Temperature';
const bomHumiditySeriesNameMapping: { [index: string]: string } = {};
bomHumiditySeriesNameMapping[ObservationTag.humidity] = 'External Relative Humidity';

interface SpaceComfortObservationDetailProps {
  observations: Observation[];
  location: any;
}

const SpaceComfortObservationDetail = ({
  observations,
  location,
}: SpaceComfortObservationDetailProps): ReactElement | null => {
  const classes = useObservationDetailStyles();

  const [locationCode, setLocationCode] = useState('');

  useEffect((): void => {
    const currentLocationCode = pathOr('', ['0', 'locationCode'], observations);
    if (!equals(currentLocationCode, locationCode)) {
      setLocationCode(currentLocationCode);
    }
  }, [locationCode, observations]);

  const groupedTemperatures = groupTemperatureObservations(observations);
  const groupedHumidities = groupHumidityObservations(observations);
  const groupedCo2Observations = groupCo2Observations(observations);

  return (
    <DetailCard title="Space Comfort">
      <ul className={classes.observationMetadata}>
        {Object.keys(groupedTemperatures).length > 0 ? (
          <li data-testid={'temp-group'}>
            <TemperatureGroupDetails
              title={'Temperature'}
              unit={convertUnit(UnitOfMeasure.Celsius)}
              icon={getIconTypeFromObservationType(ObservationType.temperature)}
              locationCode={location}
              zoneObservation={groupedTemperatures}
              historySelector={selectRoomObservationsHistoryWithBomTemperature}
              seriesNameMapping={bomTemperatureSeriesNameMapping}
              observationClass={'space-comfort'}
              observationTypes={[ObservationType.temperature, ObservationType.temperatureSetPoint]}
            />
          </li>
        ) : null}
        {Object.keys(groupedHumidities).length > 0 ? (
          <li data-testid={'humidity-group'}>
            <HumidityGroupDetails
              title={'Humidity'}
              unit={convertUnit(UnitOfMeasure.Percent)}
              icon={getIconTypeFromObservationType(ObservationType.humidity)}
              locationCode={location}
              zoneObservation={groupedHumidities}
              observationClass={'space-comfort'}
              historySelector={selectRoomObservationsHistoryWithBomHumidity}
              seriesNameMapping={bomHumiditySeriesNameMapping}
              observationTypes={[ObservationType.humidity, ObservationType.humiditySetPoint]}
            />
          </li>
        ) : null}
        {Object.keys(groupedCo2Observations).length > 0 ? (
          <li data-testid={'co2-group'}>
            <Co2GroupDetails
              title={'CO₂'}
              unit={convertUnit(UnitOfMeasure['Parts per million'])}
              icon={getIconTypeFromObservationType(ObservationType.co2)}
              locationCode={location}
              zoneObservation={groupedCo2Observations}
              historySelector={selectRoomObservationsHistory}
              observationClass={'space-comfort'}
              observationTypes={[ObservationType.co2, ObservationType.co2SetPoint]}
            />
          </li>
        ) : null}
      </ul>
    </DetailCard>
  );
};

interface SpaceComfartDetailsProps {
  observations: Observation[];
  location: any;
}

const SpaceComfortDetails = ({ observations, location }: SpaceComfartDetailsProps): ReactElement => {
  const observationDetails = observations.filter((observation: Observation): boolean =>
    isGroupedSpaceComfortObservation(observation),
  );
  const isVisibleSpaceComfort = useSelector(selectIsVisibleSpaceComfort);
  return (
    <Fragment>
      {isEmpty(observationDetails) || !isVisibleSpaceComfort ? null : (
        <SpaceComfortObservationDetail observations={observationDetails} location={location} />
      )}
    </Fragment>
  );
};

export default SpaceComfortDetails;
