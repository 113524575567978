import { UserAgentApplication, AuthenticationParameters, AuthResponse } from 'msal';
import {
  AUTHORITY_URI,
  AAD_CLIENT_ID,
  OAUTH_REDIRECT_URI,
  LOGOUT_REDIRECT_URI,
  OAUTH_SCOPES,
  TOKEN_RENEWAL_OFFSET_SECONDS,
} from 'components/authConstants';
import Logger from 'utils/logger';
import { propOr } from 'ramda';
import { getStore } from 'store';
import { updateAccessTokenAction } from 'store/auth/actions';

export const DefaultAuthParameters = { scopes: OAUTH_SCOPES };
let CurrentAccessToken: string | undefined = undefined;

/*
  MSAL Configuration Settings
  - Auth related configuration settings are documented in the README.md
 */
export const authenticationInstance = new UserAgentApplication({
  auth: {
    authority: AUTHORITY_URI,
    clientId: AAD_CLIENT_ID,
    redirectUri: OAUTH_REDIRECT_URI || window.location.origin,
    navigateToLoginRequestUrl: false,
    // navigateToLoginRequestUrl should be true to redirect the browser to the initial URL after
    // login and the auth redirect. But this appears to cause a browser redirect loop in Chrome
    postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
  },
  cache: {
    /*
      All of the following combinations cause a browser redirect loop in Chrome.

      navigateToLoginRequestUrl = true
      cacheLocation       storeAuthStateInCookie  navigateFrameWait
      -------------       ----------------------  -----------------
      sessionStorage      false
      localStorage        false                   default and 1500
      localStorage        true
    */
    cacheLocation: 'localStorage',
    // use localStorage so a logout in a browser tab also forces a logout in another tab
    storeAuthStateInCookie: true,
    // use cookie for IE/Edge support to prevent redirect loops.
    // See https://docs.microsoft.com/bs-latn-ba/azure/active-directory/develop/msal-js-initializing-client-applications
  },
  system: {
    tokenRenewalOffsetSeconds: Number(TOKEN_RENEWAL_OFFSET_SECONDS),
  },
});

const requiresInteraction = (errorCode: any): boolean => {
  if (!errorCode || !errorCode.length) {
    return false;
  }
  return errorCode === 'consent_required' || errorCode === 'interaction_required' || errorCode === 'login_required';
};

export const acquireToken = (request: AuthenticationParameters): Promise<AuthResponse | null> => {
  return authenticationInstance
    .acquireTokenSilent(request)
    .then((authResponse: AuthResponse): AuthResponse => {
      if (CurrentAccessToken !== authResponse.accessToken) {
        CurrentAccessToken = authResponse.accessToken;
        getStore().dispatch(updateAccessTokenAction(authResponse));
      }
      return authResponse;
    })
    .catch((error: any): null => {
      Logger.error('Error acquiring token');
      Logger.error(error);
      if (requiresInteraction(error.errorCode)) {
        authenticationInstance.acquireTokenRedirect(request);
      }
      return null;
    });
};

export const acquireBearerToken = (authParameters: AuthenticationParameters): Promise<string | null> => {
  return acquireToken(authParameters).then((authResponse: AuthResponse | null): Promise<string | null> => {
    const token: string | null = propOr(null, 'accessToken', authResponse);
    return Promise.resolve(token);
  });
};
