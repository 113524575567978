import React, { ReactElement, useCallback, useEffect, useState, Fragment } from 'react';
import DetailCard from '../common/DetailCard';
import { SpaceUtilisationType, Observation, ObservationClass, ObservationType } from 'models/Observation';
import Highcharts, { SeriesBarOptions, YAxisOptions, XAxisOptions } from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import { makeStyles } from '@material-ui/styles';
import { Collapse, Tooltip, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import { ObservationComponent } from './ObservationDetails';
import { APP_GUTTER, UOM_LIGHT_BLUE, FONT_SIZE_SMALL } from 'styles/styleConstants';
import { formatObservationDateTime } from '../common/ObservationFormatters';
import { isEmpty, propOr, pathOr, test, any, isNil, find } from 'ramda';
import moment from 'moment-timezone';
import {
  isBuildingLocationCode,
  getCampusId,
  getBuildingId,
  getFloorId,
  isRoomLocationCode,
  isFloorLocationCode,
  getRoomId,
} from 'components/common/LocationCodeUtils';
import { DisplayTimeZone } from 'components/common/DateTime';
import { SVGIcon, IconSizes } from 'components/common/Icons';
import Chevron from 'components/common/Chevron';
import {
  getBuildingObservationsHistoryAction,
  getFloorObservationsHistoryAction,
  getRoomHistoricalObservationsAction,
} from 'store/details/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBuildingHeadCountActivityHistoryObservations,
  selectRoomHeadCountActivityHistoryObservations,
  selectFloorHeadCountActivityHistoryObservations,
  selectIsVisibleSpaceUtilisation,
  selectAuthAccessToken,
} from 'store/selectors';
import SpaceUtilisationHistory from './SpaceUtilisationHistory';
import { FETCH_INTERVAL } from 'components/constants';
import Logger from 'utils/logger';
import BuildingBusyTime from 'components/BuildingSection/BuildingBusyTime';

highchartsMore(Highcharts);
Highcharts3d(Highcharts);
solidGauge(Highcharts);

interface SpaceUtilisationProps {
  data: SpaceUtilisationType;
  locationCode: string;
  popularTimings?: any;
  popularTimingsType?: any;
  liveBusyIndex?: any;
  liveDescription?: any;
  historicalDescription?: any;
}

const useStyles = makeStyles({
  chart: {
    maxWidth: `calc(100% - ${APP_GUTTER})`,
    margin: 0,
  },
  HeadCountGroupItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${APP_GUTTER}px`,
    '& img': {
      padding: '4px 16px',
    },
  },
  HeadCountGroupMetadata: {
    flexGrow: 1,
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    padding: 0,
    listStyle: 'none',
    maxWidth: '100%',
  },
  observationIcon: {
    paddingTop: '16px',
  },
  observationTime: {
    fontSize: FONT_SIZE_SMALL,
  },
  HeadCountGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      padding: '4px 2px',
    },
    position: 'relative',
  },
  HeadCountGroupTitle: {
    fontWeight: 'bold',
  },
  HeadCountGroupTypeRow: {
    verticalAlign: 'top',
  },
  HeadCountGroupObservationTime: {
    wordBreak: 'break-word',
  },
  HeadCountGroupZoneName: {
    wordBreak: 'break-word',
    maxWidth: '200px',
    minWidth: '200px',
  },
  /* HeadCountGroupChartCollapser: {
    display: 'flex',
    position: 'relative',
    width: 'auto',
    top: 0,
    right: 0,
  }, */
});

const getProgressBarTitle = (observationTag: string): string => {
  const observationTagEnd = observationTag.split('#')[1];
  const CMXMessage = test(/cmx/, observationTagEnd) ? '(Individual active wireless clients)' : '';
  const accessCardAddonText = test(/access-card-transactions/, observationTagEnd)
    ? '(Individual access card swipes)'
    : '';
  const qrAddOnText = test(/qr/, observationTagEnd) ? '(Individual total open check ins)' : '';
  return `${observationTagEnd} ${CMXMessage} ${accessCardAddonText} ${qrAddOnText}`;
};

const isToday = (dateTimeString: string): boolean =>
  moment.tz(dateTimeString, DisplayTimeZone).isSame(moment.tz(DisplayTimeZone), 'day');

const SpaceUtilisation = ({
  data,
  locationCode,
  popularTimings,
  popularTimingsType,
  liveBusyIndex,
  liveDescription,
  historicalDescription,
}: SpaceUtilisationProps): ReactElement | null => {
  const tooltipTitle = 'Popular timing derived from data on collected for ' + popularTimingsType + '.';
  const classes = useStyles();
  const { capacity, observations } = data;
  const visible = locationCode.length === 3;
  const dispatch = useDispatch();
  const [showChart, setShowChart] = useState(visible);
  const accessToken = useSelector(selectAuthAccessToken);

  const [showPieChart, setShowPieChart] = useState(visible);
  const [showLineChart, setShowLineChart] = useState(false);
  const [showPopularTimingChart, setShowPopularTimingChart] = useState(true);

  let piePos = '10%';
  const linePos = '4%';
  let pieChartTitleText = 'Individual daily access card swipes by card type';
  if (visible) {
    piePos = '5%';
    pieChartTitleText = 'Daily access card swipes by card type';
  }

  const headCountHistoryObservations: Observation[] = useSelector(
    isBuildingLocationCode(locationCode)
      ? selectBuildingHeadCountActivityHistoryObservations
      : isFloorLocationCode(locationCode)
      ? selectFloorHeadCountActivityHistoryObservations
      : selectRoomHeadCountActivityHistoryObservations,
  );
  const startDate: string = moment.tz(DisplayTimeZone).format('YYYY-MM-DD');
  const endDate: string = startDate;
  const title = 'Occupancy - Head Count';
  const fetchOccupancyObservationsHistory = useCallback(
    (locationCode: string, accessToken: any): void => {
      const fetchBuildingObservationHistory = (): any => {
        const campusId = getCampusId(locationCode);
        const buildingId = getBuildingId(locationCode);
        dispatch(
          getBuildingObservationsHistoryAction(
            'SpaceUtilisation',
            campusId,
            buildingId,
            startDate,
            endDate,
            ObservationClass.spaceComfort,
            ObservationType.spaceUtilisation,
            undefined,
            accessToken,
          ),
        );
      };
      const fetchFloorObservationHistory = (): any => {
        const campusId = getCampusId(locationCode);
        const buildingId = getBuildingId(locationCode);
        const floorId = getFloorId(locationCode);
        dispatch(
          getFloorObservationsHistoryAction(
            'SpaceUtilisation',
            campusId,
            buildingId,
            floorId,
            startDate,
            endDate,
            ObservationClass.spaceComfort,
            ObservationType.spaceUtilisation,
            undefined,
            accessToken,
          ),
        );
      };
      const fetchRoomObservationHistory = (): any => {
        const campusId = getCampusId(locationCode);
        const buildingId = getBuildingId(locationCode);
        const floorId = getFloorId(locationCode);
        const roomId = getRoomId(locationCode);
        dispatch(
          getRoomHistoricalObservationsAction(
            'SpaceUtilisation',
            campusId,
            buildingId,
            floorId,
            roomId,
            startDate,
            endDate,
            ObservationClass.spaceComfort,
            ObservationType.spaceUtilisation,
            undefined,
            accessToken,
          ),
        );
      };
      if (isBuildingLocationCode(locationCode)) {
        fetchBuildingObservationHistory();
      } else if (isFloorLocationCode(locationCode)) {
        fetchFloorObservationHistory();
      } else if (isRoomLocationCode(locationCode)) {
        fetchRoomObservationHistory();
      }
    },
    [dispatch, endDate, startDate],
  );
  useEffect((): void => {
    if (!isEmpty(locationCode)) fetchOccupancyObservationsHistory(locationCode, accessToken);
  }, [fetchOccupancyObservationsHistory, locationCode, startDate, endDate, accessToken]);

  useEffect((): (() => void) => {
    const fetch = (): void => fetchOccupancyObservationsHistory(locationCode, accessToken);
    const fetchInterval: NodeJS.Timeout = setInterval(fetch, FETCH_INTERVAL);

    return (): void => {
      clearInterval(fetchInterval);
    };
  }, [fetchOccupancyObservationsHistory, locationCode, accessToken]);

  const charts: Highcharts.Options[] = [];

  const isVisibleSpaceUtilisation = useSelector(selectIsVisibleSpaceUtilisation);

  if (isEmpty(observations)) {
    return null;
  }

  const createProgressBar = (observation: Observation, capacity: number): Highcharts.Options => {
    const { value, observationTag } = observation;
    const headCount = Number(value);
    const normalisedHeadCount = headCount > 0 ? headCount : 0;
    const percentFull = Number(((normalisedHeadCount / capacity) * 100).toFixed(0));

    const observationEndTime: string = propOr('', 'observationEndTime', observation);
    const observationTime = isToday(observationEndTime)
      ? formatObservationDateTime(observationEndTime, 'LT')
      : formatObservationDateTime(observationEndTime);

    const dataLabel = {
      enabled: true,
      inside: true,
      style: {
        color: 'white',
        textOutline: false,
        fontSize: '16px',
      },
    };

    const seriesConfig: SeriesBarOptions = {
      type: 'bar',
      enableMouseTracking: false,
      showInLegend: false,
      grouping: false,
      pointWidth: 30,
      borderWidth: 0,
      animation: false,
    };

    const series: SeriesBarOptions[] = [
      {
        ...seriesConfig,
        data: [100],
        color: 'lightskyblue',
        dataLabels: {
          ...dataLabel,
          format: `${normalisedHeadCount} / ${capacity}`,
          align: 'right',
          style: {
            ...dataLabel.style,
            textOutline: percentFull > 75 ? '2px contrast' : dataLabel.style.textOutline,
            color: 'black',
          },
        },
      },
      {
        ...seriesConfig,
        name: observationTag,
        data: [percentFull],
        color: 'navy',
        dataLabels: {
          ...dataLabel,
          align: 'left',
          format: '{point.y}%',
        },
      },
    ];

    const options: Highcharts.Options = {
      chart: {
        type: 'bar',
        height: '100px',
        showAxes: false,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: getProgressBarTitle(observationTag),
        align: 'left',
        margin: 0,
        style: {
          fontSize: '12px',
        },
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        min: 0,
        max: 100,
        visible: true,
        title: {
          text: `People Count / Capacity${observationTime ? ` (${observationTime})` : ''}`,
          align: 'high',
          offset: 0,
        },
        tickAmount: 0,
        showFirstLabel: false,
        showLastLabel: false,
      },
      legend: {
        enabled: false,
      },
      series,
    };
    return options;
  };

  const createBarChart = (observations: Observation[], capacity: number): Highcharts.Options => {
    const data = find((obs) => {
      return !isNil(obs.extension) && !isEmpty(obs.extension.classPlannedSize) && !isEmpty(obs.extension.activityName);
    }, observations); // observations[0]

    const dataLabels = {
      enabled: true,
      inside: true,
      align: 'left',
      format: '{point.y}',
    };

    const defaultSeriesOptions = {
      minPointLength: 3,
    };

    const series: SeriesBarOptions[] = [
      {
        ...defaultSeriesOptions,
        type: 'bar',
        name: 'Class planned size',
        data: [Number(data!.extension!.classPlannedSize)],
        dataLabels,
      },
      {
        ...defaultSeriesOptions,
        type: 'bar',
        name: 'Total registered',
        data: [Number(data!.extension!.totalRegistered)],
        dataLabels,
      },
    ];

    // add headcounts from all observations
    observations.map((o: Observation): number =>
      series.push({
        ...defaultSeriesOptions,
        type: 'bar',
        name: `People count: ${o.observationTag.split('#')[1]}`,
        data: [Number(o.value)],
        dataLabels,
      }),
    );

    const observationTime = formatObservationDateTime(pathOr('', ['0', 'observationEndTime'], observations), 'LT');

    const yAxis: YAxisOptions = {
      min: 0,
      max: capacity,
      title: {
        text: `People count${observationTime ? ` (${observationTime})` : ''}`,
        align: 'high',
      },
      labels: {
        overflow: 'justify',
      },
      plotLines: [
        {
          color: 'red',
          width: 2,
          value: capacity,
          dashStyle: 'Solid',
          label: {
            text: `Capacity (${capacity})`,
            style: {
              textOutline: '1px contrast',
            },
          },
          zIndex: 5,
        },
      ],
    };

    const xAxis: XAxisOptions = {
      categories: [''],
      title: {
        text: null,
      },
    };

    return {
      chart: {
        type: 'bar',
        height: '240px',
      },
      title: {
        text: `Class: ${data!.extension!.activityName}`,
        style: {
          fontSize: '16px',
        },
      },
      xAxis,
      exporting: {
        enabled: false,
      },
      yAxis,
      tooltip: {
        valueSuffix: ' people',
      },
      legend: {
        layout: 'horizontal',
        borderWidth: 1,
      },
      credits: {
        enabled: false,
      },
      series,
    };
  };

  const createPieChart = (observations: Observation[]): Highcharts.Options => {
    const observation = observations[0];

    const observationEndTime: string = propOr('', 'observationEndTime', observation);
    const observationTime = isToday(observationEndTime)
      ? formatObservationDateTime(observationEndTime, 'LT')
      : formatObservationDateTime(observationEndTime);

    const countByCardholderDivision = observation.extension?.CountByCardholderDivision;
    const seriesOptions: Highcharts.SeriesPieDataOptions[] = Object.keys(countByCardholderDivision).map((key) => {
      return {
        name: key,
        y: countByCardholderDivision[key],
      };
    });

    const options: Highcharts.Options = {
      chart: {
        plotShadow: false,
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 50,
        },
      },
      title: {
        /* text: `Cumulative daily access card swipes by cardholder division  ${ */
        text: `${pieChartTitleText} ${observationTime ? ` (at ${observationTime})` : ''}`,
        style: {
          fontSize: '14px',
        },
      },
      tooltip: {
        pointFormat: '{point.name}: <b>{point.y}</b> , Total: <b>{point.total}</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          depth: 45,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: <b> {point.y}</b>',
          },
          showInLegend: true,
        },
      },
      legend: {
        maxHeight: 100,
      },
      series: [
        {
          name: 'Access Card Transactions',
          type: 'pie',
          animation: false,
          data: seriesOptions,
        },
      ],
    };
    return options;
  };

  const headCountsWithoutCapacity: Observation[] = [];
  // if (observations[0].extension && observations[0].extension.classPlannedSize) {
  const isValidActivityName = any((obs) => {
    return (
      !isNil(obs.extension) &&
      !isEmpty(obs.extension.activityName) &&
      !isNil(obs.extension.activityName) &&
      !isEmpty(obs.extension.classPlannedSize) &&
      !isNil(obs.extension.classPlannedSize)
    );
  }, observations);
  if (isValidActivityName) {
    Logger.debug('bar chart');
    charts.push(createBarChart(observations, capacity));
  } else {
    if (capacity > 0) {
      observations.map((observation: Observation): number => {
        return charts.push(createProgressBar(observation, capacity));
      });
    } else {
      if (capacity !== 0) {
        observations.map((o: Observation): number => {
          return headCountsWithoutCapacity.push(o);
        });
      }
    }
  }

  const handlePieChartClick = (): void => {
    setShowPieChart(!showPieChart);
    setShowLineChart(false);
    setShowChart(!showPieChart);
  };
  const handleLineChartClick = (): void => {
    setShowLineChart(!showLineChart);
    setShowPieChart(false);
    setShowChart(!showLineChart);
  };

  const handleShowChartClick = (): void => {
    setShowChart(!showChart);
    setShowPieChart(!showChart);
    setShowLineChart(!showChart);
    /* setShowLineChart(false)
    setShowPieChart(false) */
  };

  const handlePopularTimingChartClick = (): void => {
    setShowPopularTimingChart(!showPopularTimingChart);
  };

  return !isVisibleSpaceUtilisation ? null : (
    <DetailCard title={'Space utilisation'}>
      <Fragment>
        <div className={classes.HeadCountGroupItem}>
          <div className={classes.HeadCountGroupMetadata}>
            <div className={classes.HeadCountGroupRow}>
              <SVGIcon size={IconSizes.small} type={'head-count'} alttext="Head Count" />
              <Typography className={classes.HeadCountGroupTitle}>{title}</Typography>
              <div
                role="button"
                /* className={classes.HeadCountGroupChartCollapser} */
                onClick={handlePieChartClick}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  width: 'auto',
                  top: 0,
                  right: piePos,
                }}>
                <SVGIcon
                  size={IconSizes.small}
                  type={'pie-chart'}
                  alttext="Cumulative daily individual access card swipes by cardholder division"
                />
              </div>

              {locationCode.length > 3 ? ( // if building TODO: Need a better way to check whether viewpoint is campus / building.
                <div
                  role="button"
                  /* className={classes.HeadCountGroupChartCollapser} */
                  onClick={handleLineChartClick}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    width: 'auto',
                    top: 0,
                    right: linePos,
                  }}>
                  <SVGIcon size={IconSizes.small} type={'line-chart'} alttext="Daily occupancy trend" />
                </div>
              ) : null}

              <div
                role="button"
                /* className={classes.HeadCountGroupChartCollapser} */
                style={{
                  display: 'flex',
                  position: 'absolute',
                  width: 'auto',
                  top: 0,
                  right: 0,
                }}
                onClick={handleShowChartClick}>
                <Chevron styles={{ color: UOM_LIGHT_BLUE }} direction={showChart ? 'up' : 'down'} />
              </div>
              {/*) : null} */}
            </div>
            <div className={classes.chart}>
              {capacity !== null && observations
                ? [
                    charts.map((chart: Highcharts.Options, index: number): ReactElement => {
                      return <HighchartsReact key={index} highcharts={Highcharts} options={chart} immutable={true} />;
                    }),
                    headCountsWithoutCapacity.map(
                      (headCountObservation: Observation, index: number): ReactElement => (
                        <div key={index}>
                          <ObservationComponent observation={headCountObservation} />
                        </div>
                      ),
                    ),
                  ]
                : null}
            </div>
          </div>
          <Collapse in={showChart && (showPieChart || showLineChart)}>
            {showLineChart ? (
              <SpaceUtilisationHistory headCountHistoryObservations={headCountHistoryObservations} />
            ) : null}
            {showPieChart && observations[0].extension?.CountByCardholderDivision ? (
              <HighchartsReact
                key="piechart"
                highcharts={Highcharts}
                options={createPieChart(data.observations)}
                immutable={true}
              />
            ) : null}
          </Collapse>
          {locationCode.split(';').length === 2 && !isNil(popularTimings) && !isEmpty(popularTimings) ? (
            <React.Fragment>
              <div className={classes.HeadCountGroupRow}>
                <SVGIcon size={IconSizes.small} type={'enterprise'} alttext="Building Occupancy" />
                <Typography className={classes.HeadCountGroupTitle}>
                  Building Popular Timings
                  <Tooltip title={tooltipTitle}>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </Typography>
                <div
                  role="button"
                  /* className={classes.HeadCountGroupChartCollapser} */
                  onClick={handlePopularTimingChartClick}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    width: 'auto',
                    top: 0,
                    right: '5%',
                  }}>
                  <SVGIcon
                    size={IconSizes.small}
                    type={'bar-chart'}
                    alttext="Busy Index for a building at a given day and time"
                  />
                </div>
                <div
                  role="button"
                  /* className={classes.HeadCountGroupChartCollapser} */
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    width: 'auto',
                    top: 0,
                    right: 0,
                  }}
                  onClick={handlePopularTimingChartClick}>
                  <Chevron styles={{ color: UOM_LIGHT_BLUE }} direction={showPopularTimingChart ? 'up' : 'down'} />
                </div>
              </div>
              <Collapse in={showPopularTimingChart}>
                <BuildingBusyTime
                  popularTimings={popularTimings}
                  type={popularTimingsType}
                  liveBusyIndex={liveBusyIndex}
                  liveDescription={liveDescription}
                  historicalDescription={historicalDescription}
                  locationCode={locationCode}
                />
              </Collapse>
            </React.Fragment>
          ) : null}
        </div>
      </Fragment>
    </DetailCard>
  );
};

export default SpaceUtilisation;
