import { compose, propOr, split, either, filter, groupBy, anyPass } from 'ramda';
import { Observation, ObservationType } from 'models/Observation';

const bmsZone = (observationTag: string): string => propOr('', '1', split('#', observationTag));

// Temperature observation predicates
const isTemperatureObservationType = (observation: Observation): boolean =>
  observation.observationType === ObservationType.temperature;

const isTemperatureSetPointObservationType = (observation: Observation): boolean =>
  observation.observationType === ObservationType.temperatureSetPoint;

const isInTemperatureObservationGroup = (observation: Observation): boolean =>
  either(isTemperatureObservationType, isTemperatureSetPointObservationType)(observation);

export const groupTemperatureObservations = (observations: Observation[]): { [key: string]: Observation[] } => {
  const temperatureObservations = filter(isInTemperatureObservationGroup, observations);

  return compose(groupBy((observation: Observation): string => bmsZone(observation.observationTag)))(
    temperatureObservations,
  );
};

// Humidity observations predicates
const isHumidityObservationType = (observation: Observation): boolean =>
  observation.observationType === ObservationType.humidity;

const isHumiditySetPointObservationType = (observation: Observation): boolean =>
  observation.observationType === ObservationType.humiditySetPoint;

const isInHumidityObservationGroup = (observation: Observation): boolean =>
  either(isHumidityObservationType, isHumiditySetPointObservationType)(observation);

export const groupHumidityObservations = (observations: Observation[]): { [key: string]: Observation[] } => {
  const humidityObservations = filter(isInHumidityObservationGroup, observations);

  return compose(groupBy((observation: Observation): string => bmsZone(observation.observationTag)))(
    humidityObservations,
  );
};

// CO2 observations predicates
const isCo2ObservationType = (observation: Observation): boolean =>
  observation.observationType === ObservationType.co2 &&
  propOr<string, Observation, string>('', 'observationTag', observation).startsWith('ROOM_CO2');

const isCo2SetPointObservationType = (observation: Observation): boolean =>
  observation.observationType === ObservationType.co2SetPoint &&
  propOr<string, Observation, string>('', 'observationTag', observation).startsWith('ROOM_CO2');

const isInCo2ObservationGroup = (observation: Observation): boolean =>
  either(isCo2ObservationType, isCo2SetPointObservationType)(observation);

export const groupCo2Observations = (observations: Observation[]): { [key: string]: Observation[] } => {
  const co2Observations = filter(isInCo2ObservationGroup, observations);

  return compose(groupBy((observation: Observation): string => bmsZone(observation.observationTag)))(co2Observations);
};

export const isGroupedSpaceComfortObservation = anyPass([
  isInTemperatureObservationGroup,
  isInHumidityObservationGroup,
  isInCo2ObservationGroup,
]);
