import {
  UISettingsActionTypes,
  // visibility
  TOGGLE_ALERTS_VISIBILITY,
  TOGGLE_ENERGY_VISIBILITY,
  TOGGLE_SPACE_COMFORT_VISIBILITY,
  TOGGLE_SPACE_UTILISATION_OBSERVATION_VISIBILITY,
  CHANGE_SPACE_UTILISATION_HEATMAP_VISIBILITY,
  SET_ALERTS_VISIBILITY,
  SET_ENERGY_VISIBILITY,
  SET_SPACE_COMFORT_VISIBILITY,
  SET_SPACE_UTILISATION_OBSERVATION_VISIBILITY,
  // map drawer
  TOGGLE_RIGHT_DRAWER_OPEN_CLOSED,
  TOGGLE_NOTES_IS_OPEN,
  TOGGLE_ENERGY_INTERVENTION_DRAWER_IS_OPEN,
  TOGGLE_UPDATE_NOTES_IS_OPEN,
} from './types';
import { SpaceUtilisationHeatMapTypes } from '../../types';

export const initialUISettingsState: UISettingsReducer = {
  rightDrawer: {
    isOpen: false,
  },
  energyInterventionDrawer: {
    isDrawerOpen: false,
    isNotesOpen: false,
    isUpdateNotesOpen: false,
  },
  visibility: {
    isVisibleAlerts: true,
    isVisibleEnergy: true,
    isVisibleSpaceComfort: true,
    isVisibleSpaceUtilisation: true,
    spaceUtilisationHeatMapSelection: SpaceUtilisationHeatMapTypes.ACCESS_CARD_TYPE1,
  },
};

interface RightDrawerStore {
  isOpen: boolean;
}

interface NotesDrawerStore {
  isDrawerOpen: boolean;
  isNotesOpen: boolean;
  isUpdateNotesOpen: boolean;
}
interface ObservationsVisibilityStore {
  isVisibleAlerts: boolean;
  isVisibleEnergy: boolean;
  isVisibleSpaceComfort: boolean;
  isVisibleSpaceUtilisation: boolean;
  spaceUtilisationHeatMapSelection: string;
}

interface UISettingsReducer {
  rightDrawer: RightDrawerStore;
  energyInterventionDrawer: NotesDrawerStore;
  visibility: ObservationsVisibilityStore;
}

export const UISettingsReducer = (state = initialUISettingsState, action: UISettingsActionTypes): UISettingsReducer => {
  switch (action.type) {
    case TOGGLE_ALERTS_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleAlerts: !state.visibility.isVisibleAlerts,
        },
      };
      break;
    case SET_ALERTS_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleAlerts: action.payload,
        },
      };
      break;
    case TOGGLE_ENERGY_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleEnergy: !state.visibility.isVisibleEnergy,
        },
      };
      break;
    case SET_ENERGY_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleEnergy: action.payload,
        },
      };
      break;
    case TOGGLE_SPACE_COMFORT_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleSpaceComfort: !state.visibility.isVisibleSpaceComfort,
        },
      };
      break;
    case SET_SPACE_COMFORT_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleSpaceComfort: action.payload,
        },
      };
      break;
    case TOGGLE_SPACE_UTILISATION_OBSERVATION_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleSpaceUtilisation: !state.visibility.isVisibleSpaceUtilisation,
        },
      };
      break;
    case SET_SPACE_UTILISATION_OBSERVATION_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          isVisibleSpaceUtilisation: action.payload,
        },
      };
      break;
    case CHANGE_SPACE_UTILISATION_HEATMAP_VISIBILITY:
      state = {
        ...state,
        visibility: {
          ...state.visibility,
          spaceUtilisationHeatMapSelection: action.payload,
        },
      };
      break;
    case TOGGLE_RIGHT_DRAWER_OPEN_CLOSED:
      state = {
        ...state,
        rightDrawer: {
          ...state.rightDrawer,
          isOpen: !state.rightDrawer.isOpen,
        },
      };
      break;
    case TOGGLE_ENERGY_INTERVENTION_DRAWER_IS_OPEN:
      state = {
        ...state,
        energyInterventionDrawer: {
          ...state.energyInterventionDrawer,
          isDrawerOpen: !state.energyInterventionDrawer.isDrawerOpen,
        },
      };
      break;
    case TOGGLE_NOTES_IS_OPEN:
      state = {
        ...state,
        energyInterventionDrawer: {
          ...state.energyInterventionDrawer,
          isNotesOpen: !state.energyInterventionDrawer.isNotesOpen,
        },
      };
      break;
    case TOGGLE_UPDATE_NOTES_IS_OPEN:
      state = {
        ...state,
        energyInterventionDrawer: {
          ...state.energyInterventionDrawer,
          isUpdateNotesOpen: !state.energyInterventionDrawer.isUpdateNotesOpen,
        },
      };
      break;
  }
  return state;
};
