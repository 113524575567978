import React, { ReactElement } from 'react';
import './MapLegend.css';

const MapLegend: React.FC = (): ReactElement => {
  return (
    <React.Fragment>
      <div className={'map-overlay-legend'}>
        <div className={'MuiTypography-body1 map-legend-heading'}>Alerts</div>
        <table>
          <tbody>
            <tr>
              <td>
                <div className={'map-overlay-legend-colorbox pin-icon alert-red'}></div>
              </td>
              <td>
                <div className={'MuiTypography-body1'}>Building Alerts</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={'map-overlay-legend-2'}>
        <div className={'MuiTypography-body1 map-legend-heading'}>Ventilation</div>
        <table>
          <tbody>
            <tr>
              <td>
                <div className={'map-overlay-legend-colorbox building-status grey-dark'}></div>
              </td>
              <td>
                <div className={'MuiTypography-body1'}>Car Park</div>
              </td>
              <td>
                <div className={'map-overlay-legend-colorbox building-status green-light'}></div>
              </td>
              <td>
                <div className={'MuiTypography-body1'}>Fixed</div>
              </td>
              <td>
                <div className={'map-overlay-legend-colorbox building-status blue-light'}></div>
              </td>
              <td>
                <div className={'MuiTypography-body1'}>Natural</div>
              </td>
              <td>
                <div className={'map-overlay-legend-colorbox building-status emerald-dark'}></div>
              </td>
              <td>
                <div className={'MuiTypography-body1'}>Mixed full outside air</div>
              </td>
              <td>
                <div className={'map-overlay-legend-colorbox building-status yellow-dark'}></div>
              </td>
              <td>
                <div className={'MuiTypography-body1'}>Modified Cycle</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default MapLegend;
