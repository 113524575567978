import React, { ReactElement, Fragment } from 'react';
import { History } from 'history';
import { makeStyles } from '@material-ui/styles';
import { UOM_WHITE, UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import { Summary, AlarmDetail } from 'models/Summary';
import { Typography, Divider } from '@material-ui/core';
import DetailCard from './DetailCard';
import { isEmpty, cond, equals, T } from 'ramda';
import { SVGIcon, ObservationTypeToIconType, IconSizes } from './Icons';
import { SpaceTypes, SpaceType } from 'types';
import { FloorMapper } from 'utils/mapUtils';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { match } from 'react-router';
import { useSelector } from 'react-redux';
import { selectIsVisibleAlerts } from 'store/selectors';

const useSummaryStyles = makeStyles({
  summaryList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  summaryItem: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    padding: '2px 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: UOM_SUPPORTING_COLOURS.blue.light,
      transition: 'all 0.2s ease 0s',
    },
    '&:active': {
      boxShadow: 'inset 0 0 3px #000000',
      backgroundColor: UOM_SUPPORTING_COLOURS.blue.dark,
    },
  },
  summaryItemHeader: {
    fontWeight: 'bold',
    padding: '5px',
    width: '50px',
    verticalAlign: 'middle',
    height: '100%',
  },
  alarmInfo: {
    display: 'flex',
    textAlign: 'left',
    padding: '0 10px',
  },
  alarmInfoItem: {
    display: 'flex',
    position: 'relative',
  },
  alarmCount: {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: 'red',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    color: UOM_WHITE,
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 'small',
    fontWeight: 'bold',
  },
});

interface SummaryProps {
  summaries: Summary[];
  spaceType: SpaceType;
  history: History;
  match: match<UrlParams>;
}

const SummaryDetails = ({ summaries, spaceType, history, match }: SummaryProps): ReactElement => {
  const classes = useSummaryStyles();

  const locationIdToMazemapLabel = (locationId: string, spaceType: string): string => {
    const getMazemapFloorLabel =
      (archibusLocationId: string): (() => string) =>
      (): string => {
        return FloorMapper.archibusToMazemapLabel(archibusLocationId);
      };

    const unchangedLocationId = (): string => locationId;

    const getLocationId: Function = cond([
      [equals(SpaceTypes.floor), getMazemapFloorLabel(locationId)],
      [equals(SpaceTypes.room), unchangedLocationId],
      [T, unchangedLocationId],
    ]);

    return getLocationId(spaceType);
  };

  const summaryItemClickHandler =
    (locationId: string, spaceType: string, match: match<UrlParams>, history: History): (() => void) =>
    (): void => {
      const selectFloor =
        (archibusLocationId: string): (() => void) =>
        (): void => {
          const { campusId, buildingNumber } = match.params;
          const newUrl = `/campus/${campusId}/building/${buildingNumber}/floor/${archibusLocationId}`;
          history.push(newUrl);
        };

      const selectRoom =
        (archibusLocationId: string): (() => void) =>
        (): void => {
          const { campusId, buildingNumber, floorId } = match.params;
          const newUrl = `/campus/${campusId}/building/${buildingNumber}/floor/${floorId}/room/${archibusLocationId}`;
          history.push(newUrl);
        };

      const selectLocationOnMap: Function = cond([
        [equals(SpaceTypes.floor), selectFloor(locationId)],
        [equals(SpaceTypes.room), selectRoom(locationId)],
        [T, (): null => null],
      ]);
      selectLocationOnMap(spaceType);
    };
  const isVisibleAlerts = useSelector(selectIsVisibleAlerts);

  return (
    <Fragment>
      {isEmpty(summaries) || !isVisibleAlerts ? null : (
        <DetailCard title={'Alert summary'}>
          <ul className={classes.summaryList}>
            {summaries.map((summary, index): ReactElement | null => {
              const {
                location,
                alarms: { abnormal },
              } = summary;
              if (isEmpty(abnormal)) {
                return null;
              }

              // the last element of a split location array is the building/floor/room id
              const locationId = location.locationCode.split(';').slice(-1)[0];

              return (
                <Fragment key={index}>
                  <li>
                    <div
                      className={classes.summaryItem}
                      role="button"
                      onClick={summaryItemClickHandler(locationId, spaceType, match, history)}>
                      <Typography className={classes.summaryItemHeader}>
                        {locationIdToMazemapLabel(locationId, spaceType)}
                      </Typography>
                      <div className={classes.alarmInfo}>
                        {abnormal.map((alarm: AlarmDetail, index: number): ReactElement => {
                          return (
                            <div key={index} className={classes.alarmInfoItem}>
                              <SVGIcon
                                size={IconSizes.medium}
                                type={ObservationTypeToIconType[alarm.observationType]}
                                alttext={ObservationTypeToIconType[alarm.observationType]}
                              />
                              <div className={classes.alarmCount}>{alarm.value}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </li>
                  <Divider />
                </Fragment>
              );
            })}
          </ul>
        </DetailCard>
      )}
    </Fragment>
  );
};

export default SummaryDetails;
