import React, { ReactElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { history } from 'components/App/AppHistory';
import { APP_HEADER_HEIGHT } from 'styles/styleConstants';
import { makeStyles } from '@material-ui/styles';
import WeatherSection from './WeatherSection';
import { match, matchPath } from 'react-router';
import { UrlParams } from 'components/Mazemap/MapTypes';
import { UserAccountButton } from './UserAccountButton';
import { WelcomeMessage } from './WelcomeMessage';

const useStyles = makeStyles({
  headerContent: {
    height: `${APP_HEADER_HEIGHT}px`,
    flexDirection: 'row',
  },
  headerLogo: {
    padding: '15px 30px 15px 15px',
    verticalAlign: 'middle',
    width: '80px',
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  weatherSection: {
    flexGrow: 1,
  },
});

const Header: React.FC = (): ReactElement => {
  const classes = useStyles();

  const homeClickHandler = (): void => {
    const path = history.location.pathname;
    const matchedPath: match<UrlParams> | null = matchPath(path, {
      path: '/campus/:campusId',
    });
    if (matchedPath) {
      history.push(`/campus/${matchedPath.params.campusId}`);
    }
  };

  return (
    <AppBar className={classes.headerContent} position="static" color="primary">
      <Toolbar>
        <div role="button" className={classes.link} onClick={homeClickHandler}>
          <img
            data-testid="APP-BAR-LOGO"
            className={classes.headerLogo}
            alt="unimelb logo"
            src={process.env.PUBLIC_URL + '/img/unimelb-logo.svg'}
          />
        </div>
        <div>
          <Typography
            data-testid="APP-BAR-TITLE-TEXT"
            className={classes.link}
            onClick={homeClickHandler}
            variant="h3"
            color="inherit">
            CampusNow
          </Typography>
          <WelcomeMessage></WelcomeMessage>
        </div>
      </Toolbar>
      <div className={classes.weatherSection}>
        <WeatherSection />
      </div>
      <UserAccountButton></UserAccountButton>
    </AppBar>
  );
};

export default Header;
