import { Card, CardContent, CardHeader, Paper, Typography } from '@material-ui/core';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Observation } from 'models/Observation';
import { filter, forEach, groupBy, isEmpty, isNil, keys, last, map, prop, sortBy, takeLast } from 'ramda';
import React, { useEffect, useState } from 'react';
import { UOM_SUPPORTING_COLOURS } from 'styles/styleConstants';
import momentTZ from 'moment-timezone';
import { DisplayTimeZone } from 'components/common/DateTime';
import { useSelector } from 'react-redux';
import { selectBuildingCostTagObservations } from 'store/selectors';
import { useObservationDetailStyles } from 'styles/accessControlStyle';
import logger from 'utils/logger';

interface EnergyCostDetailsProps {
  observations: Observation[];
  locationCode: string;
}

export const EnergyCostDetails = ({ observations, locationCode }: EnergyCostDetailsProps): React.ReactElement => {
  const costTagObservations = useSelector(selectBuildingCostTagObservations);
  const classes = useObservationDetailStyles();
  const [latestCost, setLatestCost] = useState(0);
  const [latestCostObservations, setLatestCostObservations] = useState<Observation[]>([]);

  // calculate total of latest costs
  useEffect(() => {
    logger.debug({ costTagObservations });
    logger.debug({ latestCostObservations });
    if (!isNil(costTagObservations) && !isEmpty(costTagObservations)) {
      const sortedCostObservations: any[] = sortBy(prop('observationEndTime'), costTagObservations);

      const latestRecord: Observation = last(sortedCostObservations);
      let totalLatestCost: number = 0;
      forEach((ob: Observation) => {
        if (ob.observationEndTime.split('-')[1] === latestRecord.observationEndTime.split('-')[1]) {
          totalLatestCost += Number(ob.value);
        }
      }, sortedCostObservations);
      setLatestCost(totalLatestCost);
      setLatestCostObservations([latestRecord]);
    } else {
      setLatestCostObservations([]);
    }
  }, [costTagObservations, locationCode]);

  // calaculate total of last month's costs
  let totalLastMonthCost: any = 0;
  let relativeValue = {
    diff: 0,
    color: UOM_SUPPORTING_COLOURS.green.dark,
    arrow: true,
  };
  if (!isEmpty(latestCostObservations)) {
    const lastMonth = momentTZ(latestCostObservations[0].observationEndTime)
      .tz(DisplayTimeZone)
      .subtract(1, 'month')
      .format('MM');
    if (!isEmpty(costTagObservations) && !isNil(costTagObservations)) {
      let latestHistoryObservation = filter(
        (ob: Observation) => ob.observationStartTime.split('-')[1] === lastMonth,
        costTagObservations,
      );
      latestHistoryObservation =
        !isNil(latestHistoryObservation) && !isEmpty(latestHistoryObservation)
          ? latestHistoryObservation
          : takeLast(1, sortBy(prop('observationStartTime'), costTagObservations));
      totalLastMonthCost = 0;
      forEach((ob: Observation) => {
        totalLastMonthCost += Number(ob.value);
      }, latestHistoryObservation);
    }

    relativeValue =
      Number(totalLastMonthCost) > 0 && Number(totalLastMonthCost) > Number(latestCost)
        ? {
            diff: Math.ceil(((Number(totalLastMonthCost) - Number(latestCost)) * 100) / Number(totalLastMonthCost)),
            color: UOM_SUPPORTING_COLOURS.green.dark,
            arrow: true, // for down arrow
          }
        : {
            diff: Math.ceil(((Number(latestCost) - Number(totalLastMonthCost)) * 100) / Number(totalLastMonthCost)),
            color: 'red',
            arrow: false, // for up arrow
          };
  }

  const createCostChartOptions = (
    energyObservations: Observation[],
    costHistoryObservations: any,
  ): Highcharts.Options => {
    const groupedObservation = groupBy(
      (ob: Observation) => {
        return ob.observationEndTime.split('-')[1];
      },
      [...costHistoryObservations],
    );
    const months = sortBy((key) => {
      return groupedObservation[key][0].observationStartTime;
    }, keys(groupedObservation));
    const data = map((key) => {
      const arr = groupedObservation[key];
      let total = 0;
      forEach((item) => {
        total += Number(item.value);
      }, arr);
      return {
        name: momentTZ(arr[0].observationEndTime.split('-')[1]).format('MMM'),
        y: total,
      };
    }, months);

    const options: Highcharts.Options = {
      chart: {
        type: 'area',
        height: '90px',
      },
      title: {
        text: '',
      },
      xAxis: {
        visible: false,
        allowDecimals: false,
      },
      yAxis: {
        visible: false,
        title: {
          text: 'Test data',
        },
      },
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
        menuItemDefinitions: {},
      },
      tooltip: {
        pointFormat: '{series.name}  <b>{point.y:,.0f}</b>',
      },
      plotOptions: {
        area: {
          pointStart: Number(momentTZ.tz(DisplayTimeZone).subtract(6, 'months').format('MM')),
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      series: [
        {
          name: 'Total Cost:',
          type: 'area',
          data,
          color: UOM_SUPPORTING_COLOURS.green.dark, // UOM_LIGHT_BLUE,
        },
      ],
    };

    return options;
  };

  return (
    <React.Fragment>
      {!isNil(latestCostObservations) && !isEmpty(latestCostObservations) ? (
        <div>
          <Paper style={{ margin: '1% 0 1% 0' }} elevation={3}>
            <Card className={classes.returnCampusResearchCardLayout}>
              <CardHeader
                component="span"
                titleTypographyProps={{
                  align: 'center',
                  variant: 'subtitle1',
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                style={{ paddingTop: '0px', paddingBottom: '2px' }}
                title={
                  'Energy Costs - ' +
                  momentTZ(latestCostObservations[latestCostObservations.length - 1].observationEndTime).format(
                    'MMM YY',
                  )
                }
              />
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: UOM_SUPPORTING_COLOURS.emerald.dark,
                  }}>
                  ${latestCost.toLocaleString().split('.')[0]}
                </Typography>
                {!isEmpty(costTagObservations) && !isNil(costTagObservations) ? (
                  <React.Fragment>
                    <Typography variant="subtitle1" style={{ color: `${relativeValue.color}`, paddingLeft: '2%' }}>
                      {relativeValue.diff}%
                    </Typography>
                    {relativeValue.arrow ? (
                      <ArrowDownIcon
                        fontSize="default"
                        style={{ color: UOM_SUPPORTING_COLOURS.green.dark }}
                        viewBox="4 2 20 20"
                      />
                    ) : (
                      <ArrowUpIcon fontSize="default" color="error" viewBox="4 2 20 20" />
                    )}
                  </React.Fragment>
                ) : null}
              </CardContent>
            </Card>

            {!isEmpty(costTagObservations) && !isNil(costTagObservations) ? (
              <div style={{ borderRadius: '0 0 10% 10%', border: '1px solid white' }}>
                <HighchartsReact
                  options={createCostChartOptions(observations, costTagObservations)}
                  highcharts={Highcharts}
                />
              </div>
            ) : null}
          </Paper>
        </div>
      ) : null}
    </React.Fragment>
  );
};
