import moment from 'moment-timezone';
import { DisplayTimeZone } from './DateTime';

// truncate a decimal to a fixed number of digits (from https://stackoverflow.com/a/4912870/1373856)
export const truncateDecimalDigits = (number: number, digits: number): number => {
  const regExp = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)');
  const match = number.toString().match(regExp);
  return match ? parseFloat(match[1]) : number.valueOf();
};

export const truncateDecimalString = (numberStr: string, digits: number): string => {
  const float = Number.parseFloat(numberStr);
  if (Number.isNaN(float)) {
    return numberStr;
  } else {
    return truncateDecimalDigits(float, digits).toString();
  }
};

export const formatTemperatureValue = (numberStr: string): string => truncateDecimalString(numberStr, 2);

export const formatObservationDateTime = (dateTimeString: string, formatString: string = 'D MMM, LT'): string => {
  const observationMoment = moment.tz(dateTimeString, DisplayTimeZone);
  return observationMoment.isValid() ? observationMoment.format(formatString) : dateTimeString;
};

export const formatObservationTime = (dateTimeString: string): string => formatObservationDateTime(dateTimeString);

export const formatObservationDate = (dateTimeString: string): string =>
  formatObservationDateTime(dateTimeString, 'D MMM');
