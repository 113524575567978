import React, { useState, Fragment } from 'react';
import { BuildingSpace, getBuildingSpaceProperty } from 'models/Building';
import { Grid } from '@material-ui/core';
import { FlipCardSpaceAttribute } from 'components/common/FlipCard';
import { useSpaceAttributeStyles } from 'styles/spaceAttributeStyle';

interface BuildingAttributeProps {
  buildingAttributes: BuildingSpace | undefined;
}

const BuildingAttributes = (props: BuildingAttributeProps): JSX.Element => {
  const classes = useSpaceAttributeStyles();
  const [showSection] = useState(true);

  return (
    <div className={classes.FlipCardItem}>
      <div>
        {' '}
        <Fragment>
          <div>
            {/* Header */}
            {showSection ? (
              <div style={{ backgroundColor: 'white' }}>
                <Grid container item xs={12} spacing={1} className={classes.gridContainer} sm>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexGrow: 1,
                      width: '100%',
                    }}>
                    <FlipCardSpaceAttribute
                      spaceAttribute={{
                        name: 'Floors Count',
                        value: getBuildingSpaceProperty('floorsCount', props.buildingAttributes),
                        description: 'Total number of floors in the building.',
                      }}
                    />
                    <FlipCardSpaceAttribute
                      spaceAttribute={{
                        name: 'Functionality',
                        value: getBuildingSpaceProperty('functionalityType', props.buildingAttributes),
                        description:
                          'Stores the TEFMA Facilities Functionality Index providing an objective methodology for qualitative ratings of the building stock.',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexGrow: 1,
                    }}>
                    <FlipCardSpaceAttribute
                      spaceAttribute={{
                        name: 'Ownership',
                        value: getBuildingSpaceProperty('ownershipType', props.buildingAttributes),
                        description:
                          "The University's state of exclusive right and control over the building. e.g., Owned – Freehold, Leased, etc.",
                      }}
                    />
                    <FlipCardSpaceAttribute
                      spaceAttribute={{
                        name: 'Status',
                        value: getBuildingSpaceProperty('statusType', props.buildingAttributes),
                        description: 'Status of the building. e.g., Active, Inactive, Demolished, etc.',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flexGrow: 1,
                    }}>
                    <FlipCardSpaceAttribute
                      spaceAttribute={{
                        name: 'Type',
                        value: getBuildingSpaceProperty('buildingType', props.buildingAttributes),
                        description: 'Type of the building. e.g., Teaching Office, Residential , Clinical, etc.',
                      }}
                    />
                  </div>
                </Grid>
              </div>
            ) : null}
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default BuildingAttributes;
