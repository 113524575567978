import { useOktaAuth } from '@okta/okta-react';
import { ReactElement, useCallback, useEffect } from 'react';
import { history } from '../App/AppHistory';
import Logger from '../../utils/logger';
import { isNil } from 'ramda';
import { updateAccessTokenAction } from 'store/auth/actions';

const Login = (): ReactElement | null => {
  Logger.info('in Login.tsx');
  const { authState, oktaAuth } = useOktaAuth();
  const login = useCallback(() => oktaAuth.signInWithRedirect('/campus/PAR'), [oktaAuth]);

  const directToScanRoute = useCallback(() => {
    Logger.info('authstate');
    Logger.debug(authState);
    history.push('/campus/PAR');
  }, []);

  useEffect((): void => {
    if (authState.isPending) {
      Logger.info('auth pending');
    } else if (!isNil(authState.isAuthenticated) && authState.isAuthenticated) {
      Logger.debug('Login directToScanRoute()', authState);
      updateAccessTokenAction(authState.accessToken.accessToken);
      directToScanRoute();
    } else {
      Logger.info('Login login()');
      login();
    }
  }, [authState, directToScanRoute, login]);

  return null;
};

export default Login;
